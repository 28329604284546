import store from "../redux/store/store";
import { clearUserState, updateUserInfo } from "../redux/actions/user.action";

import { USER_ROLES } from "../constants/user-role";
import { envConfig } from "../environment";

import appAxios from "../util/app.axios";
import StorageUtil from "../util/storage.util";

/**
 * Responsible for user related operations
 */
class UserService {

    static BASE_URL = `${envConfig.REACT_APP_API_BASE_URL}`;

    /**
     * Get all the users from the DB as per filter object.
     * Filter Object : {
     *  {Integer}  count Count of the users to be fetched at a time, 
     *  {Integer} pageNo Page to be displayed, 
     *   {String} searchText Email of the user to be searched 
     * }
     * @param {{ count, pageNo, searchText } } filter
     * @returns {Promise<{data: Array<Users>, dataCount: number}>} List of users with count
     */
    static getUsers(filter) {
        const { searchText = "", count = 10, pageNo = 1 } = filter;
        const url = `${this.BASE_URL}/users?count=${count}&pageNo=${pageNo}&searchText=${searchText}`;
        return appAxios.get(url);
    }

    /**
     * Creates the new user for the application
     * @param {Object} user {firstName, lastName, email, password, roleId, status}
     * @returns {Promise<{msg: String}>} response from the /users post api
     */
    static saveUser(user) {
        const url = `${this.BASE_URL}/users`;
        return appAxios.post(url, user);
    }

    /**
     * Updates the existing user of the application
     * @param {Object} user {firstName, lastName, roleId, status}
     * @returns  {Promise<{msg: String}>} response from the /users put api
     */
    static updateUser(user) {
        const url = `${this.BASE_URL}/users`;
        return appAxios.put(url, user);
    }

    /**
     * Converting object into string and saving in local storage
     * Also, saving the object onto the redux
     * @param {{email, firstName,  lastName, roleId, status, token}} userInfo 
     */
    static setCurrentUser(userInfo) {
        StorageUtil.setStorageValue(StorageUtil.STORAGE.CURRENT_USER, JSON.stringify(userInfo));
        store.dispatch(updateUserInfo({ userInfo }));
    }

    /**
     * Getting the current user info object from redux.
     * @returns {{email, firstName, lastName, roleId, status, token}} Current User Object 
     */
    static getCurrentUser() {
        return store.getState().user.user;
    }

    /**
     * Clearing the user from redux
     */
    static clearCurrentUser() {
        store.dispatch(clearUserState());
    }

    /**
     * Check if user has permission to access admin pages
     * if role is admin allow
     * @param {string} permissionId permission id to check for the permission
     * @returns {boolean}
     */
    static hasPermission = (permissionId = null) => {
        const user = this.getCurrentUser();
        return ([USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN].includes(user?.roleId));
    };

    /**
     * Getting remember me info for the user
     * @returns {{rememberMe,email}} remember me info
     */
    static getRememberMeInfo() {
        return JSON.parse(StorageUtil.getStorageValue(StorageUtil.STORAGE.REMEMBER_ME_INFO));
    }

    /**
     * Set the remember me info in local storage
     * @param {{rememberMe,email}} rememberMeInfo
     */
    static setRememberMeInfo(rememberMeInfo) {
        StorageUtil.setStorageValue(StorageUtil.STORAGE.REMEMBER_ME_INFO, JSON.stringify(rememberMeInfo))
    }

    /**
     * Getting last visited url by user
     * @returns {string} last visited url
     */
    static getLastVisitedUrl() {
        return StorageUtil.getStorageValue(StorageUtil.STORAGE.LAST_URL);
    }

    /**
     * Set the last visited url in local storage
     * @param {string} url last visited url by user
     */
    static setlastVisitedUrl(url) {
        StorageUtil.setStorageValue(StorageUtil.STORAGE.LAST_URL, url);
    }

    /**
     * Updating user's password
     * @param {string} email - email of the user for which password is updated
     * @param {string} password - updated password
     * @returns  {Promise<{msg: String}>} 
     */
    static updatePassword(email, password) {
        const url = `${this.BASE_URL}/users/password`;
        return appAxios.put(url, { email, password });
    }

    /**
     * Gets the initials of the current user by taking the first and second letter of
     * user eamil. Returns an empty string for missing names.
     *  @param {string} userEmail - user email Id
     * @returns {string} User initials.
     */
    static getUserInitials(userEmail) {
        const firstInitial = userEmail?.substring(0, 1)?.toUpperCase();
        const lastInitial = userEmail?.substring(1, 2);
        return `${firstInitial}${lastInitial}`;
    }
}

export default UserService;