import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";

import { is } from "bpmn-js/lib/util/ModelUtil";

export default function SessionDetailProps(group, element, translate) {
  // Only return an entry, if the currently selected
  // element is a start event.

  if (is(element, "bpmn:UserTask")) {
    group.entries.push(
      entryFactory.textField(translate, {
        id: "stepName",
        label: "Step Name",
        modelProperty: "stepName"
      })
    );

  }
}
