import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    voiceFilePrefixObj: {}
}

// auto-generate action types and action creators, based on the names of the reducer functions that we supply
// state info : client related info 
const slice = createSlice({
    name: 'voiceFile',
    initialState,
    reducers: {
        updateVoiceFilePrefixObj: (state, action) => ({
            ...state,
            voiceFilePrefixObj: action.payload.voiceFilePrefixObj
        }),
        clearState: () => initialState
    }
});

export const { updateVoiceFilePrefixObj, clearState } = slice.actions;

export default slice.reducer;
