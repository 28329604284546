import moment from "moment";

import { MESSAGES } from "../constants/messages";

/**
 * For random tasks 
 */
class AppUtil {
    /**
     * Format the date as per local format
     * @param {Object} date 
     * @returns {Date} Formated date
     */
    static formatDateInLocal(date) {
        if (!date) {
            return "";
        }
        const dateFormat = 'YYYY-DD-MM HH:mm';
        const dateUtc = moment.utc(date);
        return dateUtc.local().format(dateFormat);
    }

    /**
     * Navigation works like local # routing
     * @param {string} link 
     */
    static navigateTo(link) {
        window.location.href = `#${link}`;
    }

    /**
     * Get the origin from the url
     * example http://example.com/abc/def/something here origin= http://example.com
     * @param {string} url
     */
    static getOriginFromUrl(url) {
        try {
            const urlObject = new URL(url);
            return urlObject?.origin;
        } catch (error) {
            return null;
        }
    }

    /**
     * Redirect to url
     * @param {*} url 
     */
    static redirectTo(url) {
        window.location.href = url;
    }

    /**
     * Check if both values match
     * Undefined/null is considered same
     * @param {Any} value1 
     * @param {Any} value2 
     * @returns {boolean} Result of the match
     */
    static isMatch(value1, value2) {
        // to cover undefined and null values assign empty string
        return ((value1 ? value1 : "") === (value2 ? value2 : ""));
    }

    /**
     * Generates the unique string.
     * @param {number} length length of the random string that we want
     * @returns {string} generate unquie ID
     */
    static uid(length) {
        // toString(36) indicates you want that number converted to base-36
        // default length of 18 unique string
        let uid = Date.now().toString(36) + Math.random().toString(36).substr(2);
        if (length) {
            // generates the string of desired length
            uid = uid.substr(uid.length - length, length);
        }
        return uid;
    }

    /**
     * Reseting the values for an object to empty spaces
     * @param {Object} jsonObj 
     */
    static resetObject(jsonObj) {
        for (let key in jsonObj) {
            jsonObj[key] = '';
        }
    }

    /**
     * Checking the value as valid or not
     * Value should not be null undefined or empty
     * @param {string} value 
     * @returns {boolean} true, if valid. Otherwise, return false
     */
    static isValueValid(value) {
        const falsyValues = ["undefined", "null", ""];
        switch (typeof value) {
            case "string":
                return !(falsyValues.includes(value));
            case "object":
                return (!(falsyValues.includes(value)) && value?.length);
            case "boolean":
                return true;
            default:
                return;
        }
    }

    /**
     * Convert array of words in the format word1-word2
     * @param {Array} words
     * @returns {string} Result of the combined string
     */
    static getUniqueKey(words) {
        return words.join('-');
    }

    /**
     * Serializes a Nested Map object to a plain JSON object.
     * For instance , Nested Map object looks like this { "key1": "one", "value": {
     *    "English": true,
     *     "details": {
     *       "mapSize": 2 // This represents the size of the nested map
     *       // The actual content of the nested map
     *     }
     *   }
     * }
     * @param {Map} map - The Map object to be serialized.
     * @returns {Object} - The serialized plain JSON object.
     */
    static serializeMapToObject = (map) => {
        const serializedObj = {};

        for (const [key, value] of map) {
            // If the value is another Map, recursively serialize it
            if (value instanceof Map) {
                serializedObj[key] = AppUtil.serializeMapToObject(value);
            } else {
                // Otherwise, assign the value directly
                serializedObj[key] = value;
            }
        }

        return serializedObj;
    };

    /**
     * sorts the elements based on specified key.
     * @param {Array} elements The array of objects to be sorted
     * @param {string} key - The key to sort the objects by.
     * @returns {Array} - The sorted array of objects
     */
    static sortElements = (elements, key) => {
        return elements.sort((a, b) => (a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0));
    }

    /**
     * Download the file with given fileName and having given data
     * @param {String} data data to be in the file
     * @param {String} fileName name of the downloaded file
     */
    static downloadFile(data, fileName) {
        let url = "";
        try {
            // creating a blob from the response data
            const blob = new Blob([data], { type: 'ápplication/xml' });
            // create the blob url
            url = window.URL.createObjectURL(blob);
            // creating a download link
            const downloadLink = document.createElement('a');
            // appending attributes to the download link
            downloadLink.href = url;
            downloadLink.download = fileName;
            // adding the download link onto the DOM
            document.body.appendChild(downloadLink);
            // clicking the download link
            downloadLink.click();
            // removing the download link from the DOM
            document.body.removeChild(downloadLink);
        } finally {
            // Clean up resources (revoke the Blob url)
            window.URL.revokeObjectURL(url);
        }
    }

    /**
     * Copies text to clipboard
     * @param {string} text 
     */
    static copyToClipboard = async (text) => {
        await navigator.clipboard.writeText(text);
    }

    /**
     * Checks whether the key is enter or not
     * @param {Event} event - event triggered
     * @returns {boolean} whether the pressed key is 'enter' or not
     */
    static isEnterKey = (event) => {
        return event.key === 'Enter' || event.which === 13 || event.code === 'Enter';
    }

    /**
     * Returns task name by appending random characters at end of task type
     * @param {string} taskType Type of the task
     * @returns {string} Name of the task
     */
    static getTaskName = (taskType) => {
        return `${taskType}_${this.uid(4)}`;
    }

    /**
     * Checks if the given value matches regex or not
     * @param {string} regex Regular expression pattern
     * @param {string} value Value to check if it is valid or not 
     * @returns {boolean} If the value matches regular expression or not
     */
    static checkRegex = (regex, value) => {
        return regex.test(value);
    }

    /**
     * Sorting the given array based on given field
     * @param {Array} array array to be sorted
     * @param {string} field sorting to be done based on this field
     * @returns {Array} sorted array
     */
    static sort = (array, field) => {
        return array.sort((var1, var2) => var1[field].localeCompare(var2[field]));
    }

    /**
     * Converts an object into a URL query string.
     * @param {Object} object - The object to convert into a query string.
     * @returns {string} The URL query string representation of the object.
     */
    static convertObjectToQueryString(object) {
        return new URLSearchParams(object).toString();
    }

    /**
     * Check if xml is valid means does not contains any error
     * @param {string} xml
     * @returns {boolean} valid xml or not
     */
    static isXmlValid(xml) {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'application/xml');
        const parseError = xmlDoc.querySelector('parsererror');
        if (parseError) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * Generates a URL which will be clicked automatically whenever download button is clicked
     * the generated URL will be appended in DOM (anchor tag) and clicked automatically
     * @param {string} xml 
     * @returns {string} url
     */
    static generateXmlDownloadUrl(xml) {
        // Create a blob from the flow XML data
        const blob = new Blob([xml], { type: 'application/xml' });
        // Url which will be clicked automatically to download
        const url = URL.createObjectURL(blob);
        return url;
    }

    /**
     * Creates a deep clone of the given object.
     * This method uses JSON.parse and JSON.stringify to create a deep copy of the object.
     * @param {Object} obj - The object to be cloned.
     * @returns {Object} A deep clone of the input object.
     */
    static deepClone(obj) {
        return JSON.parse(JSON.stringify(obj));
    }

    /**
     * Checks if the provided pattern is a valid regex or not
     * @param {string} input - pattern to check is it is a regex 
     * @returns {boolean} returns true if valid regex else false
     */
    static isValidRegex(input) {
        const regexSpecialChars = /[.*+?^${}()|[\]\\]/;
        if (!regexSpecialChars.test(input)) {
            return false;
        }
        try {
            new RegExp(input);
            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * returns the error message as per given status
     * @param {string} status response status code
     * @param {string} message  message to be set for the error
     * @returns {string} error message as per given status
     */
    static getErrorMsg(status, message = "") {
        let _message = MESSAGES.SOMETHING_WENT_WRONG;
        if (status === 403) {
            _message = message || MESSAGES.ERR.UNAUTHORIZED;
        } else if (status === 409) {
            _message = message || MESSAGES.ERR.EXISTING_RECORD;
        }
        return _message;
    }
}

export default AppUtil;