import { useContext } from 'react';

import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import PmivrLabel from '../../common/label/pmivr-label.js';

import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";
import { TOOLTIP } from "../../../constants/messages";
import { CSS_CLASSES } from '../../../constants/css-classes.js';
import { VoiceContext } from '../../../contexts/app-context';
import { envConfig } from '../../../environment/index.js';

import TextToSpeechOption from "../tts-option/TextToSpeechOption";
import UploadVoiceFileOption from "../upload-voice-file-option/UploadVoiceFileOption";
import AudioPlayer from '../../common/audio-player/audio-player.js';
import VoiceFilesSelect from '../../common/voice-files-select/voice-files-select';

const UserInputProperties = () => {
  const { selectedLanguage, userInputInvalidInputVoiceFileUploadType, invalidVoiceFilePath,
    supportedLanguages, invalidVoiceFileInfo, element, updateVoiceFileStateInfo, handleVoiceFileUploadMethod,
    updateVoiceFilePathState, rightPanelEventHandler, resetStates, uploadRef, ttsRef } = useContext(VoiceContext);

  return (
    <>
      <div>
        <ul className="nav nav-tabs text-center" role="tablist">
          {supportedLanguages.map((language) => {
            return (
              <li key={language} className="nav-item  px-2" role="presentation">
                <button
                  className={selectedLanguage === language ? "nav-link active " : "nav-link"}
                  data-bs-target="#invalid-voice-file-language-options"
                  type="button"
                  role="tab"
                  title={language}
                  aria-controls="invalid-voice-file-language-options"
                  onClick={(event) => { updateVoiceFileStateInfo(language); }}
                >
                  {language}
                </button>
              </li>
            );
          })}
        </ul>
        <div className="mt-3" id="invalid-voice-file-language-options">
          <ul
            className="nav nav-pills mb-3 mx-2 pmivr-rounded-circle-tabs text-center d-flex"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item flex-grow-1" role="presentation">
              <button
                className={userInputInvalidInputVoiceFileUploadType === VOICE_FILE_UPLOAD_TYPE.LIBRARY ? "nav-link active d-inline w-100" : "nav-link d-inline w-100"}
                id="filePath-tab"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Add file path"
                data-bs-target="#file-path"
                type="button"
                role="tab"
                aria-controls="file-path"
                aria-selected={userInputInvalidInputVoiceFileUploadType === VOICE_FILE_UPLOAD_TYPE.LIBRARY}
                onClick={(event) => { handleVoiceFileUploadMethod(event.currentTarget.id); }}
              >
                <i className="bi bi-file-earmark-music"></i>
                <p>Voice File</p>
              </button>
            </li>
            <li className="nav-item flex-grow-1" role="presentation">
              <button
                className={userInputInvalidInputVoiceFileUploadType === VOICE_FILE_UPLOAD_TYPE.TTS ? "nav-link active d-inline w-100" : "nav-link d-inline w-100"}
                id="tts-file-tab"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Text to speech"
                data-bs-target="#tts-voice-file"
                type="button"
                role="tab"
                aria-controls="tts-voice-file"
                aria-selected={userInputInvalidInputVoiceFileUploadType === VOICE_FILE_UPLOAD_TYPE.TTS}
                onClick={(event) => { handleVoiceFileUploadMethod(event.currentTarget.id); }}
              >
                <i className="bi bi-chat-square-text"></i>
                <p>Text To Speech</p>
              </button>
            </li>
            <li className="nav-item flex-grow-1" role="presentation">
              <button
                className={userInputInvalidInputVoiceFileUploadType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? "nav-link active d-inline w-100" : "nav-link d-inline w-100"}
                id="uploadFile-tab"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Upload voice file"
                data-bs-target="#upload-file"
                type="button"
                role="tab"
                aria-controls="upload-file"
                aria-selected={userInputInvalidInputVoiceFileUploadType === VOICE_FILE_UPLOAD_TYPE.UPLOAD}
                onClick={(event) => { handleVoiceFileUploadMethod(event.currentTarget.id); }}
              >
                <i class="bi bi-upload"></i>
                <p>Upload File</p>
              </button>
            </li>
          </ul>

          <div className="tab-content" id="pills-tabContent">
            <div
              className={userInputInvalidInputVoiceFileUploadType === VOICE_FILE_UPLOAD_TYPE.LIBRARY ? "tab-pane fade show active mx-2" : "tab-pane fade "}
              id="file-path"
              role="tabpanel"
              aria-labelledby="ttsFile-tab"
            >
              <div className=" mt-3">
                <div className="d-flex justify-content-between">
                  <div className="pmivr-title pt-3">Library Audio File</div>
                  <AudioPlayer filePath={invalidVoiceFilePath} cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE}></AudioPlayer>
                </div>
                <div className="form-group custom-input mb-3">
                  <div className="pmivr-label">
                    <label className="pb-2">Base file path</label>
                  </div>
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BASE_FILE_PATH}>
                    <input
                      disabled
                      type="text"
                      className="form-control pmivr-input pmivr-disabled"
                      size="50"
                      value={`${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}`}
                    />
                  </PmivrOverlayTrigger>
                </div>

                <div className="form-group mb-3">
                  <PmivrLabel label="File path (File name without .wav extension)" tooltip={TOOLTIP.VOICE_TYPE_PATH_INFO} cssClass={'pb-2'} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.FILE_PATH_INFO}>
                    <VoiceFilesSelect
                      onSelect={updateVoiceFilePathState}
                      selectedItem={invalidVoiceFilePath}
                      selectedLanguage={selectedLanguage}
                    />
                  </PmivrOverlayTrigger>
                </div>
              </div>
            </div>
            <div
              className={userInputInvalidInputVoiceFileUploadType === VOICE_FILE_UPLOAD_TYPE.TTS ? "tab-pane fade show active mx-2" : "tab-pane fade "}
              id="tts-voice-file"
              role="tabpanel"
              aria-labelledby="createFile-tab"
            >
              <TextToSpeechOption
                ref={ttsRef}
                element={element}
                selectedLanguage={selectedLanguage}
                voiceFileInfo={invalidVoiceFileInfo}
                rightPanelEventHandler={rightPanelEventHandler}
                invaildVoiceFile={true}
                autoSave={false}
                resetStates={resetStates}
                showUploadBtn={true}
              />
            </div>
            <div
              className={userInputInvalidInputVoiceFileUploadType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? "tab-pane fade show active px-2 py-3" : "tab-pane fade "}
              id="upload-file"
              role="tabpanel"
              aria-labelledby="uploadFile-tab"
            >
              <UploadVoiceFileOption
                ref={uploadRef}
                element={element}
                selectedLanguage={selectedLanguage}
                voiceFileInfo={invalidVoiceFileInfo}
                rightPanelEventHandler={rightPanelEventHandler}
                autoSave={true}
                invaildVoiceFile={true}
                resetStates={resetStates}
                showUploadBtn={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInputProperties;