// css classes being used at different components (declared at one place) 
export const CSS_CLASSES = {
    V_PILLS_TABCONTENT: "v-pills-tabContent",
    DJS_OVERLAY_CONTEXT_PAD: "djs-overlay-context-pad",
    SUBPROCESS_CONTEXT_PAD: "context-pad-element-bpmn:SubProcess",
    SHOW_SAVE_MESSAGE: "show-save-message",
    NONE: "none",
    BLOCK: "block",
    HIDDEN: "hidden",
    VISIBLE: "visible",
    HIDE_DISPLAY: "pmivr-hide-display",
    INLINE_DISPLAY: "pmivr-inline-display",
    BLOCK_DISPLAY: "pmivr-block-display",
    AUDIO_BUTTON_LARGE: "audio-button-large",
    MODAL_OVERLAY: "pmivr-modal-overlay"
};

// Icons classes for different tasks
export const TASK_ICONS = {
    VOICE_TASK: "bi bi-mic",
    OPTION_USER_TASK: "bi bi-grid-3x3-gap-fill",
    USER_INPUT_TASK: "bi bi-person-vcard",
    SAY_DATA_TASK: "bi bi-megaphone",
    HANGUP_TASK: "bi bi-telephone-x",
    TRANSFER_CALL: "'bi bi-telephone-forward",
    VOICE_RECORD_TASK: "bi bi bi-record-btn",
    SERVICE_TASK: "bi bi-person-workspace pad-icons",
    DISPLAY_INFO: "bi bi-info-circle",
    DISABLE_TASK: "bi bi-slash-circle pad-icons",
    ENABLE_TASK: "bi bi-check-circle-fill pad-icons",
    RE_USABLE_COMPONENT: "bi bi-share pad-icons",
    SUB_PROCESS: "bi bi-aspect-ratio",
    VOICE_RECORD_START: "bi bi-record-circle",
    VOICE_RECORD_STOP: "bi bi-stop-circle"
}

// Types of message variants for alert (like want to show error, warning, or info)
export const MESSAGE_TYPE_VARIANTS = {
    INFO: "primary",
    WARNING: "warning",
    ERROR: "danger"
}