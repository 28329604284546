import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';

import { MESSAGES } from "../../../../constants/messages";
import { FLOW_TYPE_ID } from "../../../../constants/flow";
import { APP_PAGES } from "../../../../constants/app-pages";

import FlowService from "../../../../services/flow.service";

import PmivrSnackbar from "../../../../components/common/dialog/pmivr-snackbar";
import InteractiveDesign from "../integrations/interactive-design";

import { updateChatFlowInfo } from "../../../../redux/actions/interactive-design.action";
import { updateSelectedFlowTypeInformation } from "../../../../redux/actions/client.action";

/**
 * List of interactive chat flows for a particular client
 * @returns {React.Component} Html element to render
 */
const InteractiveChatFlows = ({ businessCode, showPopUp, setFlowVersionInfo, handleNewFlow }) => {
	// using the open method from the snackbar component
	const snackbarRef = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// Created Chat flows: already created chat flows
	const [createdChatFlows, setCreatedChatFlows] = useState({ data: [], dataCount: 0 });

	// Pending Chat flows: flows that are yet to be created
	const [pendingChatFlows, setPendingChatFlows] = useState({ data: [], dataCount: 0 });

	// border color for the chat flows
	const [borderColor, setBorderColor] = useState('');

	// props that set the active index i.e. highlight active flow
	const [uiState, setUiState] = useState({ activeIndex: null });

	// to show interactive design dialog
	const [showInteractiveDesign, setShowInteractiveDesign] = useState(false);
	// active event keys of accordian
	const [activeKey, setActiveKey] = useState(['0', '1']);

	const [chatFlowState, setChatFlowState] = useState({ errMsg: "", showRefreshBtn: false });

	useEffect(() => {
		init();
	}, []);

	/**
	 * Init function to be loaded on the page load
	 */
	const init = () => {
		try {
			// show to flow name asking modal if the client-flows is opened from url directly so show pop gets true
			if (showPopUp) {
				setShowInteractiveDesign(true);
			}
			setUiState({ ...uiState, loader: false });
			loadChatFlows();
		} catch (err) {
			setUiState({ ...uiState, loader: false });
			// opening the snackbar
			if (snackbarRef?.current) {
				snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
			}
		}
	}

	/**
	 * Load chat flows
	 */
	const loadChatFlows = async () => {
		try {
			setChatFlowState({ errMsg: "", showRefreshBtn: false });
			const flowsInfo = await FlowService.getChatFlows(businessCode);
			const noChatFlows = flowsInfo?.createdChatFlows.dataCount === 0 && flowsInfo?.pendingChatFlows.dataCount === 0;
			if (noChatFlows) {
				setChatFlowState({ errMsg: MESSAGES.ERR.NO_RECORDS, showRefreshBtn: false });
			}

			// Set the chat flows variables as received from the backend
			setCreatedChatFlows(flowsInfo?.createdChatFlows);
			setPendingChatFlows(flowsInfo?.pendingChatFlows);
			// left border color for the chat flows as received from the db
			setBorderColor(flowsInfo?.createdChatFlows?.borderColor);
			// To prevent error during redirection from AD
			if (!showPopUp) {
				snackbarRef.current.close();
			}
		} catch (err) {
			// To prevent error during redirection from AD
			if (!showPopUp) {
				snackbarRef.current.close();
			}
			setChatFlowState({ errMsg: MESSAGES.ERR.CHAT_FLOW_ERR, showRefreshBtn: true });
		}
	}

	/**
	 * Selected chat flow id is saved in state and redux
	 * @param {Object} chatFlow
	 * @param {string} index 
	 */
	const handleSelectedChatFlow = (chatFlow, index) => {
		setUiState({ ...uiState, activeIndex: index });
		dispatch(updateChatFlowInfo({ chatFlowId: chatFlow?.nodeid, chatFlowTitle: chatFlow?.title }));
		// interactive design pop up will come to ask for flow name only.
		setShowInteractiveDesign(true);
	}

	/**
	 * Interactive design integration popup submit handler
	 */
	const handleInteractiveIntegrationSubmit = async (idFlowInfo) => {
		_updateInteractiveDesignInfoAndNavigate(idFlowInfo);
	}

	/**
	 * get flow name from interactive design form and navigate to wizard
	 */
	const _updateInteractiveDesignInfoAndNavigate = async (interactiveDesignForm) => {
		try {
			const _flowName = interactiveDesignForm?.flowName;

			// update the seleted flow type info so that it can be used by diagram
			dispatch(updateSelectedFlowTypeInformation({
				selectedFlowTypeInfo: { flowTypeId: FLOW_TYPE_ID.INTERACTIVE_DESIGN_FLOW, flowName: _flowName }
			}));

			if (interactiveDesignForm) {
				navigateToWizard(FLOW_TYPE_ID.INTERACTIVE_DESIGN_FLOW, _flowName);
			}

		} catch (err) {
			// opening the snackbar
			if (snackbarRef?.current) {
				snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
			}
		}
	};

	/**
	 * Navigate to wizard page and set selected flow type information
	 * @param {string} flowTypeId 
	 * @param {string} flowName 
	 */
	const navigateToWizard = async (flowTypeId, flowName) => {
		dispatch(updateSelectedFlowTypeInformation({ selectedFlowTypeInfo: { flowTypeId, flowName } }));
		navigate(`${APP_PAGES.WIZARD}/${businessCode}`);
	}

	/**
	 * Shows the flows when clicked on the created chat flows
	 * @param {Object} chatFlow 
	 */
	const showCreatedChatFlow = async (chatFlow) => {
		const flowData = {
			flowName: chatFlow?.name, flowTypeId: FLOW_TYPE_ID.INTERACTIVE_DESIGN_FLOW
		};
		// When we click on a chatFlow under created chat flows, it should display the published and draft version of the flow
		await setFlowVersionInfo(businessCode, flowData, 0, chatFlow?.name);
	}

	/**
	 * handling the selection of accordian item
	 * @param {String} eventKey accoridan key pressed 
	 */
	const handleAccordianItemSelect = (eventKey) => {
		setActiveKey((previousActiveKeys) => {
			if (previousActiveKeys.includes(eventKey)) {
				return previousActiveKeys.filter((key) => key !== eventKey);
			} else {
				return [...previousActiveKeys, eventKey];
			}
		})
	}

	return (
		<>
			<PmivrSnackbar ref={snackbarRef} />
			<div className="px-3 pmivr-interactive-chat-flow">
				{/* Header specifying the current user role */}
				<div className="d-flex align-items-center mt-5 mb-1">
					<div className="back-icon" onClick={handleNewFlow}>
						<i className="bi bi-arrow-left-circle"></i>
					</div>
					<h4 className="pmivr-sub-title large">Chat Flows</h4>
				</div>
				{!chatFlowState.errMsg ?
					<div className="row setting-tabs">
						<Accordion activeKey={activeKey} className="pmivr-accordion" onSelect={handleAccordianItemSelect} flush>
							<Accordion.Item eventKey="0">
								<Accordion.Header onClick={() => setUiState({ ...uiState, activeIndex: null })}>
									Created Flows
								</Accordion.Header>
								<Accordion.Body>
									{createdChatFlows?.dataCount ?
										<div className="row setting-tabs scroll">
											{createdChatFlows?.data?.map((chatFlow, index) => (
												<>
													<div className="col-md-3" key={index} title={chatFlow?.title}
														onClick={() => showCreatedChatFlow(chatFlow)}>
														<div className={`${uiState.activeIndex === index ? "active-flow" : ""}
                                        				pmivr-card card card-effect text-center pmivr-text-link mb-3 p-3 mt-2 fs-1
														left-border left-border-${borderColor}`}>
															{/* Icon Heading */}
															<i className="bi bi-layout-text-window-reverse"></i>
															{/* Option Heading */}
															<span>
																<h5 className="pmivr-title pmivr-ellipsis pt-3 pb-2">{chatFlow?.title}</h5>
															</span>
															{/* Option Description */}
															<span>
																<h6 className="pmivr-ellipsis chat-flow-description">{chatFlow?.description}</h6>
															</span>
														</div>
													</div>
												</>
											))}
										</div>
										:
										<div className="no-chat-flow-msg">
											{MESSAGES.NO_CHAT_FLOWS_CREATED}
										</div>
									}
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header onClick={() => setUiState({ ...uiState, activeIndex: null })}>
									Pending Flows
								</Accordion.Header>
								<Accordion.Body>
									{pendingChatFlows?.dataCount ?
										<div className="row setting-tabs scroll">
											{pendingChatFlows?.data?.map((chatFlow, index) => (
												<>
													<div className="col-md-3" key={index} title={chatFlow?.title}
														onClick={() => handleSelectedChatFlow(chatFlow, index)}>
														<div className={`${uiState.activeIndex === index ? "active-flow" : ""} 
														pmivr-card card card-effect text-center pmivr-text-link mb-3 p-3 mt-2 fs-1
														left-border left-border-${borderColor}`}>
															{/* Icon Heading */}
															<i className="bi bi-layout-text-window-reverse"></i>
															{/* Option Heading */}
															<span>
																<h5 className="pmivr-title pmivr-ellipsis pt-3 pb-2">{chatFlow?.title}</h5>
															</span>
															{/* Option Description */}
															<span>
																<h6 className="pmivr-ellipsis chat-flow-description">{chatFlow?.description}</h6>
															</span>
														</div>
													</div>
												</>
											))}
										</div>
										:
										<div className="no-chat-flow-msg">
											{MESSAGES.NO_CHAT_FLOWS_PENDING}
										</div>
									}
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
					:
					<div className="row text-center">
						<div className="col-md-8 mt-4 ">
							<div className="no-chat-flow-msg">
								{chatFlowState.errMsg}
							</div>
							{chatFlowState.showRefreshBtn &&
								<button className="pmivr-btn-app mb-4 btn-lg btn-add-new create-chat-flow-btn"
									onClick={() => { loadChatFlows() }}>Try again </button>
							}
						</div>
					</div>
				}
				{
					showInteractiveDesign ?
						<InteractiveDesign onCancel={() => setShowInteractiveDesign(false)} onSubmit={(data) => handleInteractiveIntegrationSubmit(data)} />
						: ""
				}
			</div>
		</>
	);
}

InteractiveChatFlows.propTypes = {
	businessCode: PropTypes.string,
	showPopUp: PropTypes.bool,
	// Function that displays the flow that has already been created 
	// when we click on any flow under created flows section
	setFlowVersionInfo: PropTypes.func,
	// Moves back to new flow page and handles new flow
	handleNewFlow: PropTypes.func
}

export default InteractiveChatFlows;