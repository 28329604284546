import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    // to open the confirm dialog for making re usable component
    showReUsableDialog: false
}

// slice for controlling data related to re usable export elements
const slice = createSlice({
    name: 'reUsableControl',
    initialState,
    reducers: {
        updateShowReUsableElementDialog: (state, action) => ({
            ...state,
            showReUsableDialog: action.payload.showReUsableDialog
        })
    }
});

export const { updateShowReUsableElementDialog } = slice.actions;

export default slice.reducer;
