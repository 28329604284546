import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { CSS_CLASSES } from "../../../constants/css-classes";
import { VOICE_FILE_UPLOAD_TYPE, VOICE_GENDER } from "../../../constants/voice-file";
import { REGEX } from "../../../config/config";

import PmivrOverlayTrigger from "../../../components/common/overlay-trigger/pmivr-overlay-trigger";
import PmivrSnackBar from "../../../components/common/dialog/pmivr-snackbar";
import PmivrLabel from "../../../components/common/label/pmivr-label";
import AudioPlayer from "../../../components/common/audio-player/audio-player";

import AudioUtil from "../../../util/audio.util";

import AudioService from "../../../services/audio.service";

/**
 * Text to speech option for adding system voice files which are used internally on AGI side
 */
const SystemVoiceFileTextToSpeech = (props) => {
  const { selectedLanguage, updateVoiceFilePathState, voiceFileInfo } = props;
  const { businessCode } = useSelector(state => state.client);
  const snackbarRef = useRef();
  /**
   * uploadTtsBtnDisable : flag to disable and enable tts upload button
   * showSpeedError : flag to show speed error message
   */
  const [uiState, setUiState] = useState({ uploadTtsBtnDisable: true, showSpeedError: false });
  const [showTtsTextWarning, setShowTtsTextWarning] = useState(false);
  const [ttsVoiceFileInfo, setTtsVoiceFileInfo] = useState({
    "filePath": voiceFileInfo?.language[selectedLanguage]?.filePath || "",
    "ttsText": voiceFileInfo?.language[selectedLanguage]?.ttsText || "",
    "selectedGender": voiceFileInfo?.language[selectedLanguage]?.selectedGender || "",
    "playSpeed": voiceFileInfo?.language[selectedLanguage]?.speedRate || ""
  });


  /**
   * Handles TTS gender selection (male/female)
   * @param {Event} event 
   */
  const genderRadioBtnHandler = (event) => {
    // enable the upload button on gender change
    setUiState({ ...uiState, uploadTtsBtnDisable: false });
    // update the gender value in voice file info
    setTtsVoiceFileInfo({ ...ttsVoiceFileInfo, selectedGender: event?.target?.value });
  }

  /**
   * Handles TTS voice speed (10-100)
   * @param {Event} event 
   */
  const setVoiceSpeed = (event) => {
    // enable the upload button on speed change
    setUiState({ ...uiState, showSpeedError: false, uploadTtsBtnDisable: false });
    // update the playSpeed of voice file in voice file info
    setTtsVoiceFileInfo({ ...ttsVoiceFileInfo, playSpeed: event?.target?.value });
  }

  /**
   * Uploads the tts file to server
   */
  const uploadTts = async () => {
    try {
      // check if entered the valid speed or not
      if (ttsVoiceFileInfo?.playSpeed < 10 || ttsVoiceFileInfo.playSpeed > 100) {
        setUiState({ ...uiState, showSpeedError: true });
        return;
      }
      
      setUiState({ ...uiState, uploadTtsBtnDisable: true });
      const ttsVoiceIdInfo = AudioUtil.getTtsVoiceId(selectedLanguage, ttsVoiceFileInfo.selectedGender);
      const response = await AudioService.uploadVoiceFileToRepo({ text: ttsVoiceFileInfo.ttsText, language: selectedLanguage, 
        voiceId: ttsVoiceIdInfo?.voiceId, languageCode: ttsVoiceIdInfo?.languageCode, speedRate: ttsVoiceFileInfo.playSpeed, businessCode });
      if(response?.data?.filePath) {
        const newFilePath = response.data.filePath.replace(/^voice-files\//, '').replace(/\.wav$/, '');
        const newInfo = {
          filePath: newFilePath,
          voiceFileType: VOICE_FILE_UPLOAD_TYPE.TTS,
          ttsText: ttsVoiceFileInfo.ttsText,
          selectedGender: ttsVoiceFileInfo.selectedGender,
          speedRate: ttsVoiceFileInfo.playSpeed
        }
        const newVoiceFileInfo = _updateVoiceFileInfo(voiceFileInfo, selectedLanguage, newInfo);
        setTtsVoiceFileInfo({ ...ttsVoiceFileInfo, filePath: newFilePath });
        updateVoiceFilePathState(response.data.filePath, newVoiceFileInfo);
        // get the info of the voice file attribute
        snackbarRef.current.open(MESSAGES.FILE_UPLOAD_SUCCESS);
      }
      setUiState({ ...uiState, uploadTtsBtnDisable: true });
    } catch (err) {
      setUiState({ ...uiState, uploadTtsBtnDisable: false });
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.FILE_UPLOAD);
      }
    }
  }

  /**
   * Updates the voice file info object with new details
   * @param {Object} oldInfo - old details of the voice file
   * @param {string} language - language of the voice file to be updated
   * @param {Object} newInfo - new details of the voice file 
   * @returns {Object} full updated voiceFileInfo 
   */
  function _updateVoiceFileInfo(oldInfo, language, newInfo) {
    // Return a new object with the updated language info
    return {
        ...oldInfo,
        language: {
            ...oldInfo.language,
            [language]: {
                ...oldInfo.language[language],
                ...newInfo
            }
        }
    };
  }

  return (
    <>
      <PmivrSnackBar ref={snackbarRef} />
      <div className="d-flex justify-content-between">
        <div className="pmivr-title pt-2">Create custom Audio</div>
        <AudioPlayer filePath={ttsVoiceFileInfo?.filePath} cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE}
          isUploadedOnGit={true}></AudioPlayer>
      </div>
      <div className="px-0">
        <div className="form-group mb-2 pmivr-relative">
          <PmivrLabel label="Type Message" tooltip={TOOLTIP.INFO.TTS} />
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TTS}>
            <textarea id="greetingAudioMessage" className="form-control pmivr-input" value={ttsVoiceFileInfo.ttsText}
              onChange={(event) => {
                const inputValue = event.target.value;
                const ttsRegex = REGEX.TTS_TEXT;
                // If the input value contains special characters(&,*,'',$), disable the upload button and show tts warning  
                if (!ttsRegex.test(inputValue)) {
                  setShowTtsTextWarning(true);
                  setUiState({ ...uiState, uploadTtsBtnDisable: true });
                } else {
                  setShowTtsTextWarning(false);
                  setUiState({ ...uiState, uploadTtsBtnDisable: false });
                }
                const tempInfo = ttsVoiceFileInfo;
                tempInfo.ttsText = inputValue;
                setTtsVoiceFileInfo(tempInfo);
              }}
            />
            {showTtsTextWarning && <div className='field-error'>{MESSAGES.TTS_TEXT_WARNING}</div>}
          </PmivrOverlayTrigger>
        </div>
        <div className="row px-2">
          {/* voice type gender - male/female */}
          <div className="col-sm-7 p-1">
            <label>Gender: </label>
            <Form className="mt-2">
              <Form.Check inline className="pmivr-check-radio" label="male" type="radio" value="male"
                checked={ttsVoiceFileInfo.selectedGender === VOICE_GENDER.MALE} onChange={(e) => genderRadioBtnHandler(e)} />
              <Form.Check inline className="pmivr-check-radio" label="female" type="radio" value="female"
                checked={ttsVoiceFileInfo.selectedGender === VOICE_GENDER.FEMALE} onChange={(e) => genderRadioBtnHandler(e)} />
            </Form>
          </div>
          <div className="col-sm-5 p-1">
            <div className="form-group">
              <PmivrLabel label="Speed" tooltip={TOOLTIP.INPUT.TTS_SPEED} cssClass={`mt-0 mb-1`} />
              <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TTS_SPEED}>
                <input type="number" className="flow-control pmivr-input" aria-label="Default input example" 
                  value={ttsVoiceFileInfo.playSpeed} onChange={(e) => setVoiceSpeed(e)} min={10} max={100} />
              </PmivrOverlayTrigger>
            </div>
          </div>
          {uiState?.showSpeedError && <div className='field-error'>{MESSAGES.TTS_SPEED_WARNING}</div>}
          <button className="pmivr-btn-secondary mt-3 p-3" disabled={uiState.uploadTtsBtnDisable || !ttsVoiceFileInfo.ttsText}
            onClick={() => uploadTts()}>
              Generate Speech File
          </button>
        </div>
      </div>
    </>
  );
};

SystemVoiceFileTextToSpeech.propTypes = {
  // function that updates the voice file info state in parent component
  updateVoiceFilePathState: PropTypes.func,
  // voice file details for the system voice file
  voiceFileInfo: PropTypes.object,
  // selected language for the voice file (en/es etc.)
  selectedLanguage: PropTypes.string
}

export default SystemVoiceFileTextToSpeech;
