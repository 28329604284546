import { useEffect } from 'react';

/**
 * Custom hook that debounces the effect execution.
 * 
 * @param {Function} callback - The callback to be executed after the debounce delay.
 * @param {Array} deps - The dependency array that controls when to trigger the effect.
 * @param {number} delay - The debounce delay time in milliseconds.
 */
export function useDebouncedEffect(callback, deps, delay = 500) {
    useEffect(() => {
        // Set up the debounce mechanism
        const handler = setTimeout(() => callback(), delay);
        // Cleanup the timeout on dependency change
        return () => {
            clearTimeout(handler);  // Clear previous timeout if dependencies change
        };
    }, [delay, ...deps]); // Run effect when deps or delay change
}