import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from 'prop-types';
/**
 * Dialog box to be popped with optional custom footer
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html code to render dialog
 */
export const PmivrDialog = (props) => {
  const { showDialog, title, message, footer, closeDialog, saveDialogChanges, cssClass = '',
    staticBackdrop = false, showCloseButton = true } = props;
  return (
    <>
      <Modal className={`pmivr-modal ${cssClass}`} show={showDialog} onHide={closeDialog}
        backdrop={staticBackdrop ? "static" : true}>
        {(title) ?
          <Modal.Header className="modal-header" closeButton={showCloseButton}>
            <Modal.Title className="header-title">{title}</Modal.Title>
          </Modal.Header>
          : <></>}
        {(message) ?
          <Modal.Body className="pmivr-card pmivr-scroll">{message}</Modal.Body>
          : <></>}
        {(footer) ?
          <Modal.Footer>
            {footer}
          </Modal.Footer>
          :
          <Modal.Footer>
            <button className="pmivr-btn-cancel" onClick={closeDialog}>
              Cancel
            </button>
            {/* save changes button will only be shown when saveDialogChanges function is passed */}
            {(saveDialogChanges)
              ?
              <button className="pmivr-btn-app" onClick={saveDialogChanges}>
                Save Changes
              </button>
              : <></>
            }
          </Modal.Footer>
        }
      </Modal>
    </>
  );
};

// Types of props passed in component
PmivrDialog.propTypes = {
  // boolean value to show or hide dialog
  showDialog: PropTypes.bool,
  // close the dialogue box
  closeDialog: PropTypes.func,
  // title of the dialog 
  title: PropTypes.string,
  // message to display in the dialog content area
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  // function triggered on saveDialog changes
  saveDialogChanges: PropTypes.func,
  // optional custom footer
  footer: PropTypes.object,
  // new css class to be added on pmivr-modal
  cssClass: PropTypes.string,
  // to make dialog static , outer click will be block
  staticBackdrop: PropTypes.bool,
  // flag to show cross button on top right 
  showCloseButton: PropTypes.bool
}