import { combineReducers } from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import client from '../slices/client.slice';
import user from '../slices/user.slice';
import interactiveDesign from "../slices/interactive-design.slice";
import voiceFile from "../slices/voice-file.slice";
import tourGuide from "../slices/tour-guide.slice";
import uiState from "../slices/ui-state.slice";
import config from "../slices/config.slice";
import reUsableControl from "../slices/re-usable-control.slice";

import { enableMapSet } from 'immer';


//immer allows you to work with immutable state in a more convenient way.
// To use Map in redux we need immer and enableMapSet function to call when application store runs.
enableMapSet();

// combines different reducers (redux) 
// (turns an object whose values are different reducing functions into a single reducing function)
const reducer = combineReducers({
    client, user, interactiveDesign, voiceFile, tourGuide, uiState, config, reUsableControl
});

// config for the persist
// redux-persist basically maintains the state of redux, when refresh it gets lost but this maintains it.
const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducer);

export default persistedReducer;
