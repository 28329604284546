import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from "formik";

import { TOOLTIP } from '../../../constants/messages';
import { API_METHOD_TYPES } from '../../../constants/api-config';

import PmivrSnackbar from "../../../components/common/dialog/pmivr-snackbar";
import PmivrOverlayTrigger from '../../../components/common/overlay-trigger/pmivr-overlay-trigger';
import PmivrLabel from '../../../components/common/label/pmivr-label';

/**
 * Edit flow type settings
 * @param {{closeAction, flowTypeInfo, updateFlowType}} props props data from parent component
 * @returns {React.Component} React component to edit the flow type details
 */
const FlowTypeSettings = (props) => {
    const { closeAction, flowTypeInfo, updateFlowType } = props;
    const apiMethodTypes = [API_METHOD_TYPES.GET, API_METHOD_TYPES.POST];
    
    const snackbarRef = useRef();

    // to disable the form immediately after submit
    const [uiState,setUiState] = useState({ formDisabled: false });

    // for formik form validation
    const validate = Yup.object({
        apiUrl: Yup.string().required('API URL is required'),
        flowDetailApiUrl: Yup.string().required('Flow Detail API URL is required'),
        queryParams: Yup.string().required('Query Params should at least be an empty object {}'),
        flowDetailQueryParams: Yup.string().required('Query Params should at least be an empty object {}')
    });

    // initial values of the form fields
    const initialValues = {
        apiUrl: flowTypeInfo.apiUrl, 
        apiType: flowTypeInfo.apiType, 
        urlParams: flowTypeInfo.urlParams ? flowTypeInfo.urlParams.join(',') : '', 
        queryParams: flowTypeInfo.queryParams ? JSON.stringify(flowTypeInfo.queryParams) : JSON.stringify({}),
        flowDetailApiUrl: flowTypeInfo.flowDetail.apiUrl, 
        flowDetailApiType: flowTypeInfo.flowDetail.apiType,
        flowDetailUrlParams:  flowTypeInfo.flowDetail.urlParams ? flowTypeInfo.flowDetail.urlParams.join(',') : '', 
        flowDetailQueryParams: flowTypeInfo.flowDetail.queryParams ? JSON.stringify(flowTypeInfo.flowDetail.queryParams) : JSON.stringify({})
    };

    /**
     * Update the flow type details
     * @param {Object} values
     */
    const handleSubmit = async (values) => {
        // disable the form when submit button is clicked
        setUiState({...uiState, formDisabled: true});

        // if url params for flow list and flow detail are sent in values, then split it to array, 
        // otherwise send empty array
        ['urlParams', 'flowDetailUrlParams'].forEach(param => {
            if (values[param]?.trim()) {
                // remove extra spaces from the elements in csv
                const paramsArray = values[param].split(',').map(value => value.trim());
                values[param] = paramsArray;
            } else {
                values[param] = [];
            }
        });

        // add the query params to the formik values field to pass it in the flowInfo
        values.flowDetailQueryParams = JSON.parse(values.flowDetailQueryParams);
        values.queryParams = JSON.parse(values.queryParams);

        await updateFlowType(values);
        // close the popup on saving the updated values
        closeAction();
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />
            <Formik initialValues={initialValues} validationSchema={validate} onSubmit={handleSubmit} disabled={uiState.formDisabled}>
            {({ setFieldValue, values }) => (
            <Form>
                {/* API to get list of flows */}
                <div className="flowListApi pb-4">
                    <h6 className='mb-4'>Flows List API</h6>
                    <div className="form-group my-2">
                        <PmivrLabel label="API URL" tooltip={TOOLTIP.INFO.API_URL} />
                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.FLOW_TYPE_API_URL}>
                            <input className="form-control pmivr-input" name="apiUrl" id="apiUrl" value={ values.apiUrl } onChange={(event) => { setFieldValue("apiUrl", event.target.value) }}  />
                        </PmivrOverlayTrigger>
                        <ErrorMessage name="apiUrl" component="div" className="field-error error pt-2" />
                    </div>
                    <div className="form-group my-2">
                        <PmivrLabel label="API Type" tooltip={TOOLTIP.INFO.API_METHOD} />
                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_METHOD}>
                            <select className="form-control pmivr-select" name="apiType" id="apiType"
                                value={ values.apiType.toUpperCase() }
                                onChange={(event) => { setFieldValue("apiType", event.target.value.toLowerCase()) }} >
                                {apiMethodTypes.map((apiType, index) => (
                                    <option key={index} value={apiType}>
                                        { apiType }
                                    </option>
                                ))}
                            </select>
                        </PmivrOverlayTrigger>
                    </div>
                    <div className="form-group my-2">
                        <PmivrLabel label="URL Params" tooltip={TOOLTIP.INFO.URL_PARAMS} />
                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.URL_PARAMS}>
                            <textarea className="form-control pmivr-input" rows="3" name="urlParams" id="urlParams" value={ values.urlParams } 
                                onChange={(event) => { setFieldValue("urlParams", event.target.value) }}  />
                        </PmivrOverlayTrigger>
                    </div>
                    <div className="form-group my-2">
                        <PmivrLabel label="Query Params" tooltip={TOOLTIP.INFO.QUERY_PARAMS} />
                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.QUERY_PARAMS}>
                            <textarea className="form-control pmivr-input" name="queryParams" id="queryParams" 
                                value={ values.queryParams } rows="3"
                                onChange={(event) => { setFieldValue("queryParams", event.target.value) }}  />
                        </PmivrOverlayTrigger>
                        <ErrorMessage name="queryParams" component="div" className="field-error error pt-2" />
                    </div>
                </div>
                {/* API to get flow details */}
                <div className="flowDetailApi">
                    <h6 className='mb-4'>Flow Details API</h6>
                    <div className="form-group my-2">
                        <PmivrLabel label="API URL" tooltip={TOOLTIP.INFO.API_URL} />
                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.FLOW_DETAIL_API_URL}>
                            <input className="form-control pmivr-input" name="flowDetailApiUrl" id="flowDetailApiUrl" 
                                value={values.flowDetailApiUrl} 
                                onChange={(event) => { setFieldValue("flowDetailApiUrl", event.target.value) }}  />
                        </PmivrOverlayTrigger>
                        <ErrorMessage name="flowDetailApiUrl" component="div" className="field-error error pt-2" />
                    </div>
                    <div className="form-group my-2">
                        <PmivrLabel label="API Type" tooltip={TOOLTIP.INFO.API_METHOD} />
                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_METHOD}>
                            <select className="form-control pmivr-select" name="flowDetailApiType" id="flowDetailApiType"
                                value={ values.flowDetailApiType.toUpperCase() }
                                onChange={(event) => { setFieldValue("flowDetailApiType", event.target.value.toLowerCase()) }} >
                                {apiMethodTypes.map((apiType, index) => (
                                    <option key={index} value={apiType}>
                                        {apiType}
                                    </option>
                                ))}
                            </select>
                        </PmivrOverlayTrigger>
                    </div>
                    <div className="form-group my-2">
                        <PmivrLabel label="URL Params" tooltip={TOOLTIP.INFO.URL_PARAMS} />
                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.URL_PARAMS}>
                            <textarea className="form-control pmivr-input" name="flowDetailUrlParams" id="flowDetailUrlParams" 
                                value={ values.flowDetailUrlParams } rows="3"
                                onChange={(event) => { setFieldValue("flowDetailUrlParams", event.target.value) }}  />
                        </PmivrOverlayTrigger>
                    </div>
                    <div className="form-group my-2">
                        <PmivrLabel label="Query Params" tooltip={TOOLTIP.INFO.QUERY_PARAMS} />
                        <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.QUERY_PARAMS}>
                            <textarea className="form-control pmivr-input" name="flowDetailQueryParams" id="flowDetailQueryParams" 
                                value={ values.flowDetailQueryParams } rows="3"
                                onChange={(event) => { setFieldValue("flowDetailQueryParams", event.target.value) }}  />
                        </PmivrOverlayTrigger>
                        <ErrorMessage name="flowDetailQueryParams" component="div" className="field-error error pt-2" />
                    </div>
                </div>
                <div className="text-end mt-4">
                    <div className="d-inline-block mx-2">
                        <button className="pmivr-btn-cancel" onClick={closeAction}>Cancel</button>
                    </div>
                    <div className="d-inline-block mx-2">
                        <button className="pmivr-btn-app" type="submit">Save</button>
                    </div>
                </div>
            </Form>
            )}
            </Formik>
        </>
    )
};

// Types of props passed in the component
FlowTypeSettings.propTypes = {
    // Function to close the popup 
    closeAction: PropTypes.func,
    // function to edit the flow type details in db
    updateFlowType: PropTypes.func,
    // flow type details
    flowTypeInfo: PropTypes.shape({
        apiType: PropTypes.string,
        apiUrl: PropTypes.string,
        borderColor: PropTypes.string,
        filters: PropTypes.array,
        // to get the details for a particular flow
        flowDetail: PropTypes.shape({
            apiType: PropTypes.string,
            apiUrl: PropTypes.string,
            queryParams: PropTypes.object,
            urlParams: PropTypes.array
        }),
        flowTypeId: PropTypes.string,
        name: PropTypes.string,
        urlParams: PropTypes.array
    })
}

export default FlowTypeSettings;