import { useContext, useEffect, useState, useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

import { ATTRIBUTES } from "../../../constants/attributes";
import { TOOLTIP } from "../../../constants/messages";
import { VoiceContext } from "../../../contexts/app-context";
import { PmivrDialog } from "../../common/dialog/pmivr-dialog.js";
import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file.js";

import { VoicePrompt } from "../../../models/voice-file.js";
import AppUtil from "../../../util/app.util.js";

import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import PmivrLabel from "../../common/label/pmivr-label.js";
import DynamicOptionVoiceFileMapping from "./DynamicOptionVoiceFileMapping.js";

import ElementService from "../../../services/element.service";
import VariableService from "../../../services/variable.service";
import DiagramService from "../../../services/diagram.service.js";

/**
 * Dynamic user option properties view on diagram page. Dynamic Options are prompted from the collection.
 * We have two collections: 
 * OptionCollection: The elements of this collection are prompted as the options for user option input, 
 * and value will be fetched from this collection as per selection
 * 
 * Example: 
 *  OptionCollection: ["AA", "BB", "CC"];
 * 
 *  The system will prompt as
 *    Press 1 for "<voice file named AA>";
 *    Press 2 for "<voice file named BB>";
 *    Press 3 for  "<voice file named CC>";
 * 
 *  User presses 2. 
 *  So, the result value will be OptionCollection[2] = "BB";
 * 
 * User can also customize the voice files for the options, where each option can have associated voice file
 * 
 * @returns {React.Component} render speech input option
 */
const DynamicOptionPropertiesView = () => {
  const { element } = useContext(VoiceContext);
  // the key for voice file and option mapping
  const keyVoiceFileOptionMapping = 'voiceFileOptionMapping';
  // for setting focus to input fields after adding and removing list values from dynamic option list voice files dialog
  // it will be referenced to the input fields for all the options in dynamic option list voice files dialog
  const optionIdInputRefs = useRef([]);
  // list of available variables names
  const [variableNames, setVariableNames] = useState([]);
  // list of variables with their info
  const [variables, setVariables] = useState([]);
  // supported languages for the flow
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  // option and voice file mapping for configured lanuguages
  // example: {"voiceFileOptionMapping": [{"mappingFieldValue: "", voiceFIle_en: "", voiceFile_es: ""}, {"mappingFieldValue: "", voiceFIle_en: "", voiceFile_es: ""}]"}
  const [optionAndVoiceFile, setOptionAndVoiceFile] = useState({});
  // state containing selected variable names and their variable description
  // selectedOptionListVariableName: The elements of this collection are prompted as the options for user option input.
  const [uiState, setUiState] = useState({
    selectedOptionListVariableName: '', selectedOptionListVariableDesc: TOOLTIP.INFO.SELECT_LIST_VARIABLE,
    selectedValueListVariableDesc: TOOLTIP.INFO.SELECT_LIST_VARIABLE,
    showVoiceFilesListDialog: false
  });


  useEffect(() => {
    const init = async () => {
      getVariables();
      // get the list of supported languages
      const supportedLanguages = await DiagramService.getSupportedLanguages();
      setSupportedLanguages(supportedLanguages);
      // get the possible option list variable values and voice files
      // this will return the object with supported languages as keys, each key will have value as array
      // the array will have voice files associated with every option possible for the dynamic option list
      let possibleListValues = ElementService.getAttribute(
        element, ATTRIBUTES.DYNAMIC_OPTION_VOICE_FILES_FOR_OPTIONS, ""
      );
      if (possibleListValues.length) {
        possibleListValues = JSON.parse(possibleListValues);
        // updating the voice files mapping , if they are string format than push in array
        // basically providing backsupport for exisiting filePath
        const updatedPossibleListValues = updateVoiceFilesMapping(possibleListValues);
        setOptionAndVoiceFile(updatedPossibleListValues);
      }
    }
    init();
  }, [element]);

  /**
   * Update the voice files mapping of list voice files since multi prompts is introduced now
   * Providing here backsupport for old files saved in string format
   * @param {{voiceFileOptionMapping:Array<{voiceFile_en:string}>}} oldVoiceFileMappings 
   * @returns {{voiceFileOptionMapping:Array<{voiceFile_en:string}>}} oldVoiceFileMappings updated values to support multi prompt
   */
  const updateVoiceFilesMapping = (oldVoiceFileMappings) => {
    oldVoiceFileMappings.voiceFileOptionMapping.forEach((option) => {
      Object.keys(option).forEach((key) => {
        // In flow xml we have voiceFile_en and voiceFile_es voiceFile_fr saved in this way for voice files
        // check the voiceFile and if it is string convert to array
        if (key.startsWith('voiceFile_') && typeof option[key] === 'string') {
          // add mappingId to know which mapping field and which prompt to update for tts and upload
          // it is the unique id to know which mapping field value , voice file prompt list to update when doing tts or uploading
          option['mappingId'] = AppUtil.getTaskName("mapping_id");
          option[key] = [new VoicePrompt({ filePath: option[key], voiceFileType: VOICE_FILE_UPLOAD_TYPE.LIBRARY })];
        }
      });
    });
    return oldVoiceFileMappings;
  }

  /**
   * updating the state values, only after variable list is updated in state
   * updates the selected variable names and their description in state
   */
  useEffect(() => {
    // selected option list variable
    const selectedOptionListVariableName = ElementService.getAttribute(
      element, ATTRIBUTES.DYNAMIC_OPTION_COLLECTION, ""
    );

    // finding description for selected option list variable
    const selectedOptionListVariableDesc = variables.find((variable) => variable.name === selectedOptionListVariableName);

    // updating the state
    setUiState({
      ...uiState, selectedOptionListVariableName: selectedOptionListVariableName,
      selectedOptionListVariableDesc: selectedOptionListVariableDesc?.description || TOOLTIP.INFO.SELECT_LIST_VARIABLE,
    });
  }, [variables]);

  /**
   * Getting the variables list to show 
   */
  const getVariables = async () => {
    const variablesInfo = await VariableService.getVariables();
    // names of all the variables
    const allVariablesNames = variablesInfo.map((varInfo) => varInfo.name);

    // populating the variables in state after getting the variable names.
    // Using timeout as getting all variable names is consuming time.
    setTimeout(() => {
      setVariableNames(allVariablesNames);
      setVariables(variablesInfo);
    }, 100);
  }

  /**
   * Updates the selected variables and it's description in state to show in the tooltip
   * @param {Array} selectedVariable - selected variable in the typeahead 
   */
  const updateSelectedVariable = (selectedVariable) => {
    const selectedVariableDescription = variables.find((variable) => variable.name === selectedVariable);
    setUiState({
      ...uiState, selectedOptionListVariableName: selectedVariable,
      selectedOptionListVariableDesc: selectedVariableDescription?.description || TOOLTIP.INFO.SELECT_LIST_VARIABLE
    });
  }

  /**
   * Updates the value of a specific field in the option list for the selected language.
   * @param {number} index - The index of the item in the option list to update.
   * @param {string} field - The field of the item to update.
   * @param {string} value - The new value to set for the specified field.
   */
  const handleInputChange = (index, field, value) => {
    // Get the voice file options for selected language, to edit the id in case of input change
    const newSelectedLanguageOptions = _getSelectedLanguageOptions();
    // Update the specific field of the item at the given index
    newSelectedLanguageOptions[index] = { ...newSelectedLanguageOptions[index], [field]: value };
    // Update the state with the modified option list
    setOptionAndVoiceFile({
      ...optionAndVoiceFile,
      [keyVoiceFileOptionMapping]: newSelectedLanguageOptions
    });
  };

  /**
   * Returns the selected language array for voice files related to all options
   */
  const _getSelectedLanguageOptions = () => {
    // If the option list is undefined or null, use an empty array as a fallback.
    return [...(optionAndVoiceFile[keyVoiceFileOptionMapping] || [])];
  }

  /**
   * Sets focus to the input element of a specified index in the inputRefs array 
   * whenever any element is deleted or added
   * @param {number} index - The index of the input element to focus.
   */
  const handleFocus = (index) => {
    optionIdInputRefs.current[index]['name'].focus();
  };

  /**
   * Adds a new empty item to the option list for the selected language 
   * and focuses on its input fields.
   */
  const handleAdd = () => {
    // get the options and voice files for selected language
    const newSelectedLanguageOptions = [..._getSelectedLanguageOptions(), { mappingId: AppUtil.getTaskName("mapping_id") }];
    setOptionAndVoiceFile({
      ...optionAndVoiceFile,
      [keyVoiceFileOptionMapping]: newSelectedLanguageOptions
    });
    // Use setTimeout to ensure focusing on the newly added input after the state update.
    setTimeout(() => {
      handleFocus(newSelectedLanguageOptions.length - 1);
    }, 0);
  };

  /**
   * Deletes an item from the option list for the selected language based on its index.
   * @param {number} index - The index of the item to delete.
   */
  const handleDelete = (index) => {
    // get the options and voice files for selected language
    const newSelectedLanguageOptions = _getSelectedLanguageOptions();
    // Remove the item at the specified index from the newData array.
    newSelectedLanguageOptions.splice(index, 1);
    setOptionAndVoiceFile({
      ...optionAndVoiceFile,
      [keyVoiceFileOptionMapping]: newSelectedLanguageOptions
    });
  };

  /**
   * Saves the updated option list values to the bpmn xml element and hides the dialog.
   */
  const handleSave = () => {
    ElementService.updateElement(element, ATTRIBUTES.DYNAMIC_OPTION_VOICE_FILES_FOR_OPTIONS, JSON.stringify(optionAndVoiceFile));
    setUiState({ ...uiState, showVoiceFilesListDialog: false });
  }

  /**
   * Closes the dialog box representing the option voice file mapping
   */
  const closeVoiceFileMappingDialogue = () => {
    setUiState({ ...uiState, showVoiceFilesListDialog: false });
  }

  return (
    <>
      <div className="mb-2 mt-1">
        <PmivrDialog showDialog={uiState.showVoiceFilesListDialog} closeDialog={closeVoiceFileMappingDialogue}
          title={`Voice File For Options`} saveDialogChanges={handleSave} message={<DynamicOptionVoiceFileMapping
            element={element} optionAndVoiceFile={optionAndVoiceFile} keyVoiceFileOptionMapping={keyVoiceFileOptionMapping}
            handleInputChange={handleInputChange} optionIdInputRefs={optionIdInputRefs} handleAdd={handleAdd}
            supportedLanguages={supportedLanguages} handleFocus={handleFocus} handleDelete={handleDelete} />} />

        <PmivrLabel label="Select Option List Variable" tooltip={TOOLTIP.INFO.DYNAMIC_OPTION_COLLECTION} />
        <PmivrOverlayTrigger tooltip={uiState.selectedOptionListVariableDesc}>
          <Typeahead options={variableNames} placeholder="Select List Variable Name" multiple={false}
            id="basic-typeahead-single" className="pmivr-variable-input" labelKey="selectedVariableInField"
            onChange={(selected) => {
              const selectedVariable = selected.length > 0 ? selected[0] : "";
              ElementService.updateElement(element, ATTRIBUTES.DYNAMIC_OPTION_COLLECTION, selectedVariable);
              updateSelectedVariable(selectedVariable);
            }}
            onInputChange={(text, event) => {
              const selectedVariable = text;
              ElementService.updateElement(element, ATTRIBUTES.DYNAMIC_OPTION_COLLECTION, selectedVariable);
              updateSelectedVariable(selectedVariable);
            }}
            selected={uiState.selectedOptionListVariableName ? [uiState.selectedOptionListVariableName] : []}
          />
        </PmivrOverlayTrigger>
      </div>
      <div className="row mt-3 mb-2">
        <div className="col-sm-10 mt-2">
          <div className="form-check pmivr-check-radio form-check-inline mb-3 mt-1">
            <input className="form-check-input" type="checkbox" id="customize-voice-files"
              checked={ElementService.getAttribute(element, ATTRIBUTES.DYNAMIC_OPTION_IS_CUSTOMIZE_VOICE_FILES, false)}
              onChange={(e) => {
                ElementService.updateElement(
                  element, ATTRIBUTES.DYNAMIC_OPTION_IS_CUSTOMIZE_VOICE_FILES, e.target.checked
                );
              }}
            />
            <label className="form-check-label">Customize Voice Files for Options</label>
          </div>
        </div>
        <div className="col-sm-2 pmivr-title pmivr-label my-1 d-flex justify-content-end">
          <PmivrOverlayTrigger tooltip={TOOLTIP.INFO.DYNAMIC_OPTION_CUSTOMIZE_VOICE_FILE}>
            <button className=" mt-1 p-2 pmivr-btn-app pmivr-btn-rounded-circle rounded-circle"
              disabled={!ElementService.getAttribute(element, ATTRIBUTES.DYNAMIC_OPTION_IS_CUSTOMIZE_VOICE_FILES, false)}
              onClick={(event) => { setUiState({ ...uiState, showVoiceFilesListDialog: true }) }}>
              <i className="bi bi-pencil"> </i>
            </button>
          </PmivrOverlayTrigger>
        </div>
      </div>
    </>
  );
};

export default DynamicOptionPropertiesView;