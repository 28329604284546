import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedFlowTypeInformation } from "../../../../redux/actions/client.action";

import PropTypes from 'prop-types';

import { MESSAGES, TOOLTIP } from '../../../../constants/messages';
import { FLOW_TYPE_ID } from "../../../../constants/flow";
import { APP_PAGES } from "../../../../constants/app-pages";

import PmivrTooltip from "../../../../components/common/tooltip/pmivr-tooltip";

/**
 * Interactive design component that will show user to choose the name of flow name
 * @param {Object} props props data from parent component
 */
const CustomFlow = ({ businessCode, parentFlowXml, closeAction }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // available flows and flows that client has created
    const { flows, clientFlows, selectedFlowTypeInfo } = useSelector(state => state.client);
    // To set name of the custom flow, error message
    const [uiState, setUiState] = useState({ customFlowName: '', errorMsg: '' });

    /**
     * Creates the Custom flow
     * It will get the custom flow name and configure client with the given info
     */
    const createCustomFlow = async () => {
        // check the validity of custom flow name
        const isValidCustomFlowName = checkCustomFlowNameValidity();
        if (isValidCustomFlowName) {
            navigateToWizard(FLOW_TYPE_ID.CUSTOM_FLOW_ID, uiState.customFlowName);
            // close popup
            closeAction();
        }
    }

    /**
     * Checks the validity of custom flow name
     * @return {boolean} Returns boolean value for validity of custom Flow name
     */
    const checkCustomFlowNameValidity = () => {
        // available flowTypes  displayed on businessCode flow state page
        let flowNames = flows.map((flowType) => flowType?.name?.toLowerCase());
        // check for flowNames already  created for specified client, to maintain the uniqueness
        if (clientFlows?.length) {
            clientFlows.forEach((flowTypeInfo) => {
                flowNames.push(flowTypeInfo?.flowName?.toLowerCase());
            });
        }
        // check if client has entered names of custom flow like standard ivr , aaivr 
        // or it contains names already used for this client
        // or if client enters the name of custom flow from which another flow is being created
        if (flowNames?.includes(uiState.customFlowName) || (uiState.customFlowName === selectedFlowTypeInfo.flowName)) {
            setUiState({ ...uiState, errorMsg: MESSAGES.ERR.CUSTOM_FLOW_NAME_INVALID });
            return false;
        } else if (uiState.customFlowName?.length < 3) {
            setUiState({ ...uiState, errorMsg: MESSAGES.ERR.CUSTOM_FLOW_VALIDATION });
            return false;
        } else {
            setUiState({ ...uiState, errorMsg: '' });
            return true;
        }
    }

    /**
     * Navigate to wizard page and set selected flow type information
     * @param {string} flowTypeId 
     * @param {string} flowName 
     */
    const navigateToWizard = async (flowTypeId, flowName) => {
        dispatch(updateSelectedFlowTypeInformation({ selectedFlowTypeInfo: { flowTypeId, flowName, parentFlowXml } }));
        navigate(`${APP_PAGES.WIZARD}/${businessCode}`);
    }

    return (
        <>
            {/* custom flow name */}
            <>
                <div className="custom-flow-name">
                    <div className="row">
                        <div className="col-md-2 pmivr-label">
                            <label className="pt-2">Name </label>
                        </div>
                        <div className="col-md-6">
                            <input className="input-sm pmivr-input" value={uiState.customFlowName} onChange={(e) => {
                                setUiState({...uiState, customFlowName: (e.target.value).toLowerCase(), errorMsg: '' })
                            }} ></input>
                        </div>
                    </div>
                    <div className="error-msg">
                        {uiState.errorMsg}
                    </div>
                </div>
                <div className="text-end mt-4">
                    <PmivrTooltip message={TOOLTIP.CANCEL}>
                        <div className="d-inline-block mx-2">
                            <button className="pmivr-btn-cancel" onClick={closeAction}>Cancel</button>
                        </div>
                    </PmivrTooltip>
                    <PmivrTooltip message={TOOLTIP.CREATE_FLOW}>
                        <div className="d-inline-block mx-2">
                            <button className={`${(!uiState.customFlowName.trim() || uiState.errorMsg) ? 
                            "pmivr-disabled pmivr-btn-app" : "pmivr-btn-app"}`} onClick={createCustomFlow}>Create</button>
                        </div>
                    </PmivrTooltip>
                </div>
            </>
        </>
    )
};

// Types of props passed in the component
CustomFlow.propTypes = {
    businessCode: PropTypes.string,
    // xml of the parent flow from which custom flow is created
    parentFlowXml: PropTypes.string,
    // Function to close the popup 
    closeAction: PropTypes.func
}

export default CustomFlow;