import { clearState, updateUser, updateSessionCheck } from "../slices/user.slice";

/**
 * information about the current user
 * @param {Object} userInfo 
 * @returns 
 */
export const updateUserInfo = ({ userInfo }) => async dispatch => {
    dispatch(updateUser({ userInfo }));
}

/**
 * Information used to open pop up for session expiring modal
 * on session expire dialog opened for password to continue user working instead of logout
 * @param {boolean} sessionCheckInfo 
 */
export const updateSessionCheckInfo = ({ sessionCheckInfo }) => async dispatch => {
    dispatch(updateSessionCheck({ sessionCheckInfo }));
}

/**
 * dispatch the action to clear the values from redux state
 */
export const clearUserState = () => async dispatch => {
    dispatch(clearState());
}