import React, { useRef } from "react";
import { useSelector } from "react-redux";

import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';

import { MESSAGES, TOOLTIP } from '../../../../constants/messages';

import { PmivrDialog } from "../../../../components/common/dialog/pmivr-dialog";
import PmivrTooltip from "../../../../components/common/tooltip/pmivr-tooltip";

/**
 * Interactive deign component that will show user to choose the name of flow name
 * @param {Object} props props data from parent component
 */
const InteractiveDesign = ({ onCancel, onSubmit }) => {

  // Access to the form's submit function using useRef
  const formikRef = useRef();

  // available flows and flows that client has created
  const { flows, clientFlows } = useSelector(state => state.client);
  const { chatFlowTitle } = useSelector(state => state.interactiveDesign);

  // Define the validation schema using Yup
  const validationSchema = Yup.object().shape({
    flowName: Yup.string().min(3, 'Flow name must have atleast 3 characters').required('Flow name is required')
      .test('validator-custom-name', function (value) {
        if (value) {
          const validationRes = checkFlowNameValidity(value);
          if (!validationRes.isValid) {
            return this.createError({
              path: this.path,
              message: validationRes.errorMessage,
            });
          }
          else {
            return true;
          }
        }
      })
  });

  const submitForm = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  const handleSubmit = async (values) => {
    const { flowName } = values;
    const interactiveDesignFormInfo = { flowName: flowName };
    onSubmit(interactiveDesignFormInfo);
  };

  /**
  * Checks the validity of flow name
  * @param {string} flowName
  * @return {boolean} Returns boolean value for validity of Flow name
  */
  const checkFlowNameValidity = (flowName) => {
    // available flowTypes  displayed on client flow page
    let flowNames = flows.map((flowType) => flowType?.name?.toLowerCase());
    // check for flowNames already  created for specified client, to maintain the uniqueness
    if (clientFlows?.length) {
      clientFlows.forEach((flowTypeInfo) => {
        flowNames.push(flowTypeInfo?.flowName?.toLowerCase());
      });
    }
    // check if client has entered names of custom flow like standard ivr , aaivr 
    // or it contains names already used for this client
    if (flowNames.includes(flowName.toLowerCase())) {
      return { isValid: false, errorMessage: MESSAGES.ERR.CUSTOM_FLOW_NAME_INVALID };
    } else {
      return { isValid: true };
    }
  }

  return (
    <>
      <PmivrDialog showDialog={true} title={`Interactive Design: ${chatFlowTitle}`}
        message={
          <>
            <Formik
              innerRef={formikRef}
              initialValues={{ flowName: chatFlowTitle }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isValid }) => {
                return (
                  <Form>
                    <div className="custom-flow-name">
                      <div className="row">
                        <div className="col-md-2 pmivr-label">
                          <label className="pt-2" htmlFor="flowName">Name</label>
                        </div>
                        <div className="col-md-6">
                          <Field className="input-sm pmivr-input" type="text" name="flowName" id="flowName"
                            onChange={(event) => {
                              const newValue = event.target.value;

                              formikRef.current.setFieldValue('flowName', newValue);
                            }} />
                        </div>
                      </div>
                      <ErrorMessage name="flowName" component="div" className="error-msg" />
                    </div>
                  </Form>

                )
              }}
            </Formik>
          </>
        }
        footer={
          <>
            <PmivrTooltip message={TOOLTIP.CANCEL}>
              <button className="pmivr-btn-cancel" onClick={onCancel} >Cancel</button>
            </PmivrTooltip>
            <PmivrTooltip message={TOOLTIP.CREATE}>
              <button className="pmivr-btn-app"
                onClick={submitForm}
                disabled={formikRef.current ? !formikRef.current.isValid : false}
              >
                Create
              </button>
            </PmivrTooltip>
          </>}
        closeDialog={onCancel}
      />
    </>
  )
}

// Types of props passed in the component
InteractiveDesign.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func
}

export default InteractiveDesign;