import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showLoader: false, // to show and hide loader during every api call
}

// slice for controlling ui states (loader, modal etc.)
const slice = createSlice({
    name: 'uiState',
    initialState,
    reducers: {
        updateShowLoader: (state, action) => ({
            ...state,
            showLoader: action.payload.showLoader
        }),
        clearState: () => initialState
    }
});

export const { updateShowLoader, clearState } = slice.actions;

export default slice.reducer;
