import { createSlice, createSelector } from "@reduxjs/toolkit";

import { envConfig } from "../../environment";

const initialState = {
    businessCode: null,  // name of the client / businessCode
    draft: [],   // latest docs saved as draft
    published: [],  // latest published docs
    versionId: '',  // doc versionId on which client is currently working 
    flowType: '', // doc flow type on which client is currently working (draft / published)
    languagesConfigured: [],  // list of languages configured for the flow
    variables: {},
    mapOfVoiceFiles: new Map(),
    selectedFlowTypeInfo: {}, // selectedFlowTypeInfo for client selected flow type
    flows: [], // to store all supported flow types
    clientFlows: [],// to store all the flows created by client
    isVerifiedClient: false, //to check for verification of client
    appCode: `${envConfig.REACT_APP_CODE}`,
    elementActionDialogParams: { showDialog: false, action: '' }, // to open the confirm disable component dialog, and action (disable/enable) 
    changeHistory: [], // change history of the current diagram
    systemVoiceFiles: [], // system voice files configured by client
    deploymentEnvironment: ''  // the deployment environment on which client is saved
}

// auto-generate action types and action creators, based on the names of the reducer functions that we supply
// state info : client related info 
const slice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        updateSystemVoiceFilesInfo: (state, action) => ({
            ...state,
            systemVoiceFiles: action.payload.systemVoiceFiles
        }),
        updateAppCodeData: (state, action) => ({
            ...state,
            appCode: action.payload.appCode
        }),
        updateDeploymentEnvData: (state, action) => ({
            ...state,
            deploymentEnvironment: action.payload.deploymentEnvironment
        }),
        updateClientVerificationData: (state, action) => ({
            ...state,
            isVerifiedClient: action.payload.isVerifiedClient
        }),
        updateLanguagesConfigured: (state, action) => ({
            ...state,
            languagesConfigured: action.payload.languagesConfigured
        }),
        updateBusinessCodeName: (state, action) => ({
            ...state,
            businessCode: action.payload.businessCode
        }),
        updateDraftsArray: (state, action) => ({
            ...state,
            draft: action.payload.draft
        }),
        updatePublishedArray: (state, action) => ({
            ...state,
            published: action.payload.published
        }),
        updateVersionId: (state, action) => ({
            ...state,
            versionId: action.payload.versionId
        }),
        updateFlowType: (state, action) => ({
            ...state,
            flowType: action.payload.flowType
        }),
        updateVariables: (state, action) => ({
            ...state,
            variables: action.payload.variables
        }),
        updateMapOfVoiceFiles: (state, action) => ({
            ...state,
            mapOfVoiceFiles: action.payload.mapOfVoiceFiles
        }),
        updateSelectedFlowTypeInfo: (state, action) => ({
            ...state,
            selectedFlowTypeInfo: action.payload.selectedFlowTypeInfo
        }),
        updateFlows: (state, action) => ({
            ...state,
            flows: action.payload.flows
        }),
        updateClientCreatedFlows: (state, action) => ({
            ...state,
            clientFlows: action.payload.clientFlows
        }),
        updateElementActionDialogParams: (state, action) => ({
            ...state,
            elementActionDialogParams: action.payload.elementActionDialogParams
        }),
        updateChangeHistoryData: (state, action) => {
            state.changeHistory.push(action.payload);
        },
        clearChangeHistoryData: (state, action) => {
            state.changeHistory = [];
        },
        clearState: () => initialState
    }
});

// Selector to get the raw client state
const selectClientState = state => state.client;

// Selector to get the mapOfVoiceFiles property from the client state
const selectMapOfVoiceFiles = createSelector(
    [selectClientState],
    client => client.mapOfVoiceFiles
);
// Selector that converts the JSON object to a Map
export const selectMapOfVoiceFilesAsMap = createSelector(
    [selectMapOfVoiceFiles],
    mapOfVoiceFiles => new Map(Object.entries(mapOfVoiceFiles)),
);
export const { updateBusinessCodeName, updateDraftsArray, updatePublishedArray, updateVersionId, updateFlowType,
    updateLanguagesConfigured, updateVariables, updateMapOfVoiceFiles, updateSelectedFlowTypeInfo, updateFlows,
    updateClientCreatedFlows, clearState, updateClientVerificationData, updateAppCodeData, updateDeploymentEnvData,
    updateElementActionDialogParams, updateChangeHistoryData, clearChangeHistoryData, updateSystemVoiceFilesInfo } = slice.actions;

export default slice.reducer;
