import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import PropTypes from 'prop-types';

/**
 * Custom Overlay Trigger
 * @param {string} tooltip Tooltip Info message to show when child component is hovered
 * @param {React.Component} children Child component where this component will be used
 * @returns {React.Component} JSX code to render overlay trigger
 */
const PmivrOverlayTrigger = ({ tooltip, children }) => {
    // Wrap the tooltip component
    const renderTooltip = (props) => <Tooltip {...props}>{tooltip}</Tooltip>;

    return (
        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
            <div>{ children }</div>
        </OverlayTrigger>
    );
};

// Types of props passed in component
PmivrOverlayTrigger.propTypes = {
    // Info message to show in the tooltip when child component is hovered
    tooltip: PropTypes.string,
    // child component at instance where the component will be used
    children: PropTypes.element,
}

export default PmivrOverlayTrigger;