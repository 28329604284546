import { forwardRef, useEffect, useImperativeHandle, useState, useRef } from "react";
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import { EVENT_TYPE } from "../../../constants/events";
import { ATTRIBUTES } from "../../../constants/attributes";
import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { TASK_TYPE } from "../../../constants/task-types";
import { CSS_CLASSES } from "../../../constants/css-classes";
import { VOICE_FILE_UPLOAD_TYPE, VOICE_FILE_TYPE, VOICE_FILE_PLAY_SPEED, VOICE_GENDER, VOICE_FILE_PLAY_SPEED_RATE } from "../../../constants/voice-file";
import { REGEX } from "../../../config/config";

import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import PmivrSnackBar from "../../common/dialog/pmivr-snackbar";
import PmivrLabel from "../../common/label/pmivr-label";
import AudioPlayer from "../../common/audio-player/audio-player";

import AudioUtil from "../../../util/audio.util";

import VoiceFile from "../../../models/voice-file";

import ElementService from "../../../services/element.service";
import DiagramEventHandlerService from "../../../services/diagram-event-handler.service";
import FlowService from "../../../services/flow.service";
import AudioService from "../../../services/audio.service";

/**
 * Text to speech option in the diagram
 */
const TextToSpeechOption = forwardRef((props, ref) => {
  const snackbarRef = useRef();
  const [isPromptUserInput, setIsPromptUserInput] = useState(false);
  /**
   * uploadTtsBtnDisable : flag to disable and enable tts upload button
   * showSpeedError : flag to show speed error message
   */
  const [uiState, setUiState] = useState({ uploadTtsBtnDisable: true, showSpeedError: false });
  const [showTtsTextWarning, setShowTtsTextWarning] = useState(false);
  const [voiceFileInfo, setVoiceFileInfo] = useState({
    "file": null,
    "ttsText": "",
    "selectedGender": "",
    "playSpeed": ""
  });

  const { rightPanelEventHandler, isMultiplePrompts, selectedLanguage, autoSave, invaildVoiceFile,
    resetStates, element, showUploadBtn, onChange, isConfirmInputVoiceFile = false } = props;

  useEffect(() => {
    const init = () => {
      setIsPromptUserInput(ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE) === TASK_TYPE.promptUserInput);
      // update ttsText state whenever selectedLanguage gets updated
      const voiceFileInformation = props.voiceFileInfo[selectedLanguage];
      setVoiceFileInfo({
        "file": null, "ttsText": voiceFileInformation?.ttsText || "",
        "selectedGender": voiceFileInformation?.gender || VOICE_GENDER.FEMALE,
        "playSpeed": voiceFileInformation?.playSpeed ? handlePlaySpeed(voiceFileInformation) : VOICE_FILE_PLAY_SPEED_RATE.NORMAL
      });
    }
    init();
  }, [selectedLanguage, props.voiceFileInfo]);

  useImperativeHandle(ref, () => ({
    saveAudio() {
      saveAudio();
    },
    resetTtsOption() {
      resetTtsOption();
    }
  }));

  /**
   * Handles the play speed of the tts voice file
   * Gives back support to already saved tts files with speed
   * @param {{playSpeed,gender,filePath,file,ttsText}} voiceFileInformation 
   * @returns speed of the tts voice file
   */
  const handlePlaySpeed = (voiceFileInformation) => {
    switch (voiceFileInformation?.playSpeed) {
      case VOICE_FILE_PLAY_SPEED.SLOW:
        return VOICE_FILE_PLAY_SPEED_RATE.SLOW;
      case VOICE_FILE_PLAY_SPEED.NORMAL:
        return VOICE_FILE_PLAY_SPEED_RATE.NORMAL;
      case VOICE_FILE_PLAY_SPEED.FAST:
        return VOICE_FILE_PLAY_SPEED_RATE.FAST;
      default:
        return voiceFileInformation?.playSpeed;
    }
  }

  // reset voice file related states 
  const resetTtsOption = () => {
    setIsPromptUserInput(ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE) === TASK_TYPE.promptUserInput);
    const voiceFileInformation = props.voiceFileInfo[selectedLanguage];
    setVoiceFileInfo({ "file": null, "ttsText": voiceFileInformation?.ttsText || "", "selectedGender": voiceFileInformation?.gender || VOICE_GENDER.FEMALE, "playSpeed": voiceFileInformation?.playSpeed ? handlePlaySpeed(voiceFileInformation) : VOICE_FILE_PLAY_SPEED_RATE.NORMAL });
  }

  /**
   * TTS gender selection 
   * @param {Event} event 
   */
  const genderRadioBtnHandler = (event) => {
    // enable the upload button on gender change
    setUiState({ ...uiState, uploadTtsBtnDisable: false });
    // update the gender value in voice file info
    setVoiceFileInfo({ ...voiceFileInfo, selectedGender: event?.target?.value });
    if (autoSave) {
      saveAudio();
    }
  }

  /**
   * TTS voice speed
   * @param {Event} event 
   */
  const setVoiceSpeed = (event) => {
    // enable the upload button on speed change
    setUiState({ ...uiState, showSpeedError: false, uploadTtsBtnDisable: false });
    // update the playSpeed of voice file in voice file info
    setVoiceFileInfo({ ...voiceFileInfo, playSpeed: event?.target?.value });
    if (autoSave) {
      saveAudio();
    }
  }

  /**
  * Update voice file infomation
  * @param {string} text Text of the voice file
  */
  const updateTTSText = (text) => {
    // update voice file info it task is not key value task
    if (!isMultiplePrompts) {
      const temVoiceFileInfo = props.voiceFileInfo;
      let info = new VoiceFile();
      info = { ...info, voiceFileType: VOICE_FILE_UPLOAD_TYPE.TTS, ttsText: text, gender: voiceFileInfo.selectedGender, playSpeed: voiceFileInfo.playSpeed };
      temVoiceFileInfo[selectedLanguage] = info;
      // update invaildVoiceFile attribute 
      if (isPromptUserInput && invaildVoiceFile && !isConfirmInputVoiceFile) {
        ElementService.updateElementAttr(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE, JSON.stringify(temVoiceFileInfo));
      } else if (isPromptUserInput && isConfirmInputVoiceFile && !invaildVoiceFile) {
        ElementService.updateElementAttr(element, ATTRIBUTES.CONFIRM_INPUT_VOICE_FILE, JSON.stringify(temVoiceFileInfo));
      } else {
        ElementService.updateElementAttr(element, ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(temVoiceFileInfo));
      }
    } else {
      setVoiceFileInfo({ ...voiceFileInfo, ttsText: text });
    }
  }

  /**
   * Save the audio with tts
   */
  const saveAudio = () => {
    let eventInfoData;
    let text = voiceFileInfo.ttsText;
    // update voice file info
    updateTTSText(voiceFileInfo.ttsText);
    let eventInfo;
    // if tts
    if (text) {
      eventInfo = {
        element: element,
        data: {
          text: text,
          language: selectedLanguage,
          fileType: VOICE_FILE_TYPE.VALID,
          eventType: EVENT_TYPE.VOICE_TTS
        },
      };
      // add fileType if task is PromptUserInput
      if (isPromptUserInput && invaildVoiceFile && !isConfirmInputVoiceFile) {
        eventInfo.data.fileType = VOICE_FILE_TYPE.INVALID;
      } else if (isPromptUserInput && !invaildVoiceFile && isConfirmInputVoiceFile) {
        eventInfo.data.fileType = VOICE_FILE_TYPE.CONFIRM_VALUE;
      }

    } else if (voiceFileInfo.file) {
      //  file upload
      eventInfo = {
        eventType: EVENT_TYPE.VOICE_FILE_UPLOAD,
        element: element,
        data: {
          file: voiceFileInfo.file,
          language: selectedLanguage
        },
      };
    }

    if ((ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE) !== TASK_TYPE.keyValueUserOption)
      && !isMultiplePrompts) {
      if (eventInfo) {
        eventInfoData = rightPanelEventHandler(eventInfo);
      }
    }
    // call the resetStates function of parent call to reset the states of other component
    if (resetStates) {
      resetStates()
    }
    return eventInfoData;
  }

  /**
   * Uploads the tts file
   */
  const uploadTts = async () => {
    try {
      // check if entered the valid speed or not
      if (voiceFileInfo?.playSpeed < 10 || voiceFileInfo.playSpeed > 100) {
        setUiState({ ...uiState, showSpeedError: true });
        return;
      }
      if (!isMultiplePrompts) {
        let metaInfo;
        setUiState({ ...uiState, uploadTtsBtnDisable: true });
        const eventInfoData = saveAudio();
        const basicFlowInfo = FlowService.getBasicFlowInfo();
        const voiceTaskType = ElementService.getAttribute(eventInfoData?.element, ATTRIBUTES.VOICE_FILE_TASK_TYPE);
        if ([TASK_TYPE.playVoiceFile, TASK_TYPE.promptUserInput, TASK_TYPE.promptUserOption].includes(voiceTaskType)) {
          metaInfo = await DiagramEventHandlerService.executeRightPanelEvents(eventInfoData, basicFlowInfo?.flowName);
        }
        // get the attribute to update or modify
        let attribute = invaildVoiceFile ? ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE : ATTRIBUTES.VOICE_FILE_INFO;
        // if confirm voice file attribute for user input
        if (isConfirmInputVoiceFile) {
          attribute = ATTRIBUTES.CONFIRM_INPUT_VOICE_FILE;
        }

        // get the info of the voice file attribute
        let voiceFileInfo = ElementService.getAttribute(eventInfoData?.element, attribute);
        voiceFileInfo = JSON.parse(voiceFileInfo);
        //update the necessary fields or add them
        voiceFileInfo[selectedLanguage]["filePath"] = metaInfo?.location;
        voiceFileInfo[selectedLanguage]["isUploadedOnGit"] = metaInfo?.isUploadedOnGit;
        // update the element
        ElementService.updateElementAttr(eventInfoData?.element, attribute, JSON.stringify(voiceFileInfo));
        snackbarRef.current.open(MESSAGES.FILE_UPLOAD_SUCCESS);
        setUiState({ ...uiState, uploadTtsBtnDisable: true });
        // to update the latest state of filePath at component level
        if (resetStates) {
          resetStates();
        }
      } else {
        // get the basic flow info
        const basicFlowInfo = FlowService.getBasicFlowInfo();
        // basic flowInfo for tts
        const flowInfo = {
          businessCode: basicFlowInfo?.businessCode, elementId: element.id,
          language: selectedLanguage, flowName: basicFlowInfo?.flowName
        };
        // necessary fields in case of tts
        flowInfo.text = voiceFileInfo?.ttsText;
        flowInfo.gender = voiceFileInfo?.selectedGender;
        // speed of the voice file generated from tts text
        flowInfo.speedRate = voiceFileInfo?.playSpeed;
        // get the voice type info from gender and selected language
        const ttsVoiceInfo = AudioUtil.getTtsVoiceId(selectedLanguage, voiceFileInfo?.selectedGender);
        flowInfo.voiceId = ttsVoiceInfo.voiceId;
        flowInfo.languageCode = ttsVoiceInfo.languageCode;
        const response = await AudioService.tts(flowInfo);
        if (response) {
          onChange({
            filePath: response?.data?.metaInfo?.location, isUploadedOnGit: response?.data?.metaInfo?.isUploadedOnGit,
            gender: voiceFileInfo.selectedGender, playSpeed: voiceFileInfo.playSpeed, ttsText: voiceFileInfo.ttsText
          });
          snackbarRef.current.open(MESSAGES.FILE_UPLOAD_SUCCESS);
          setUiState({ ...uiState, uploadTtsBtnDisable: true });
        }
      }
    } catch (err) {
      setUiState({ ...uiState, uploadTtsBtnDisable: false });
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.FILE_UPLOAD);
      }
    }
  }

  return (
    <>
      <PmivrSnackBar ref={snackbarRef} />
      <div className="d-flex justify-content-between">
        <div className="pmivr-title pt-2">Create custom Audio</div>
        <AudioPlayer filePath={props.voiceFileInfo[selectedLanguage]?.filePath}
          cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE}
          isUploadedOnGit={props.voiceFileInfo[selectedLanguage]?.isUploadedOnGit}></AudioPlayer>
      </div>
      {/* text to speech */}
      <div className="px-0">
        <div className="form-group mb-2 pmivr-relative">
          <PmivrLabel label="Type Message" tooltip={TOOLTIP.INFO.TTS} />
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TTS}>
            <textarea id="greetingAudioMessage" className="form-control pmivr-input" value={voiceFileInfo.ttsText}
              onChange={(event) => {
                const inputValue = event.target.value;
                const ttsRegex = REGEX.TTS_TEXT;
                // If the input value contains special characters(&,*,'',$), disable the upload button and show tts warning  
                if (!ttsRegex.test(inputValue)) {
                  setShowTtsTextWarning(true);
                  setUiState({ ...uiState, uploadTtsBtnDisable: true });
                } else {
                  setShowTtsTextWarning(false);
                  setUiState({ ...uiState, uploadTtsBtnDisable: false });
                }
                const tempInfo = voiceFileInfo;
                tempInfo.ttsText = inputValue;
                setVoiceFileInfo(tempInfo);
                if (autoSave) {
                  saveAudio();
                }
              }}
            />
            {showTtsTextWarning && <div className='field-error'>{MESSAGES.TTS_TEXT_WARNING}</div>}
          </PmivrOverlayTrigger>
        </div>
        <div className="row px-2">
          {/* voice type gender - male/female */}
          <div className="col-sm-7 p-1">
            <label>Gender: </label>
            <Form className="mt-2">
              <Form.Check
                inline
                className="pmivr-check-radio"
                label="male"
                type={"radio"}
                value="male"
                checked={voiceFileInfo.selectedGender === VOICE_GENDER.MALE}
                onChange={(e) => genderRadioBtnHandler(e)}
              />
              <Form.Check
                inline
                className="pmivr-check-radio"
                label="female"
                type={"radio"}
                value="female"
                checked={voiceFileInfo.selectedGender === VOICE_GENDER.FEMALE}
                onChange={(e) => genderRadioBtnHandler(e)}
              />
            </Form>
          </div>
          <div className="col-sm-5 p-1">
            <div className="form-group">
              <PmivrLabel label="Speed" tooltip={TOOLTIP.INPUT.TTS_SPEED} cssClass={`mt-0 mb-1`} />
              <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TTS_SPEED}>
                <input
                  type="number"
                  className="flow-control pmivr-input"
                  aria-label="Default input example"
                  value={voiceFileInfo.playSpeed}
                  onChange={(e) => setVoiceSpeed(e)}
                  min={10}
                  max={100}
                />
              </PmivrOverlayTrigger>
            </div>
          </div>
          {uiState?.showSpeedError && <div className='field-error'>{MESSAGES.TTS_SPEED_WARNING}</div>}
          {showUploadBtn &&
            <button
              className="pmivr-btn-secondary mt-3 p-3"
              disabled={uiState.uploadTtsBtnDisable || !voiceFileInfo.ttsText}
              onClick={() => uploadTts()}>Generate Speech File</button>
          }
        </div>
      </div>
    </>
  );
});

TextToSpeechOption.propTypes = {
  element: PropTypes.object,
  // right bar on diagram page panel handler
  rightPanelEventHandler: PropTypes.func,
  isMultiplePrompts: PropTypes.bool,
  voiceFileInfo: PropTypes.object,
  // retirn s the selected language name
  selectedLanguage: PropTypes.string,
  // boolean value to check for auo save option is true or not
  autoSave: PropTypes.bool,
  // voice file invalid or not
  invaildVoiceFile: PropTypes.bool,
  resetStates: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func
  ]),
  // onChange function to update tts values in prompts
  onChange: PropTypes.func,
  // flag specifying confirm input voice file
  isConfirmInputVoiceFile: PropTypes.bool
}

export default TextToSpeechOption;
