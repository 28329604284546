import PropTypes from "prop-types";

import { TASK_ICONS } from '../../../constants/css-classes';

import PmivrTooltip from "../tooltip/pmivr-tooltip";

// different sizes of the label 
export const LABEL_SIZE = { NORMAL: "pmivr-label", MEDIUM: "pmivr-title pmivr-label" };

/**
 * Label for form fields with info icon.
 * @param {{label, tooltip, size, cssClass}} : props data from parent component
 * @returns {React.Component} React component for label
 */
const PmivrLabel = ({ label, tooltip, size = LABEL_SIZE.NORMAL, cssClass = '', disabled = false }) => {
  // add default margin top and bottom if no css provided from parent component
  if (!cssClass) {
    cssClass = "my-1";
  }

  return (
    <div className={`${size} ${cssClass}`}>
      <label disabled={disabled}>{label}</label>
      {
        tooltip &&
        <PmivrTooltip message={tooltip}>
          <i className={TASK_ICONS.DISPLAY_INFO}></i>
        </PmivrTooltip>
      }
    </div>
  );
};

PmivrLabel.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  size: PropTypes.string,
  cssClass: PropTypes.string,
  disabled: PropTypes.bool
};

export default PmivrLabel;
