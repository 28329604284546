import { MESSAGES } from "../constants/messages";

/**
 * Service class for error and error messages
 */
class ErrorService {
    /**
     * Gets the error message according to error code
     * @param {Object} err 
     * @returns {string} error message according to error code
     */
    static getErrorMessage = (err) => {
        if (err.response?.status === 403) {
            return MESSAGES.ERR.UNAUTHORIZED;
        } else if (err.response?.status === 400) {
            return err.response?.err?.msg;
        } else {
            return MESSAGES.SOMETHING_WENT_WRONG;
        }
    }
}

export default ErrorService;