import { envConfig } from "../environment";

import appAxios from "../util/app.axios";

/**
 * Responsible for ivr call logs details and session details
 */
class AuditService {
    static BASE_URL = `${envConfig.REACT_APP_API_BASE_URL}`;
    static AUDIT_URL = `${envConfig.REACT_APP_API_BASE_URL}/audit`;

    /**
     * Gets call logs from db
     * @param {{ searchText, count, pageNo, stepName, dnid, callerIdNum, sessionId, environment, startDate, endDate }} filter - contains search text, filter option and text, pagination properties 
     * @returns {Promise<{data: {logs: Array<CallLogs>, totalCalls: number}, dataCount: number}>} list of call logs and its count
     */
    static getIvrCallLogs(filter) {
        const { searchText = "", count = 10, pageNo = 1, stepName = "",
            dnid = "", callerIdNum = "", sessionId = "", environment = "", startDate = "", endDate = "" } = filter;
        const url = `${this.AUDIT_URL}/ivrCallLogs?count=${count}&pageNo=${pageNo}&searchText=${searchText}` + 
            `&dnid=${dnid}&stepName=${stepName}&callerIdNum=${callerIdNum}&sessionId=${sessionId}&environment=${environment}` + 
            `&startDate=${startDate}&endDate=${endDate}`;
        return appAxios.get(url);
    }
}

export default AuditService;