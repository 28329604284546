// Require your custom property entries.
import serviceImplementationDetailProps from "./parts/ServiceImplementationDetailProps";
import { is } from 'bpmn-js/lib/util/ModelUtil';


// The properties are organized in groups.
function createServiceImplementationTabGroups(element, translate) {
  if (is(element, 'bpmn:ServiceTask')) {

    var serviceImplementationGroup = {
      id: "service-implementation",
      label: "Service",
      entries: []
    };

    // Add the  props to the  group.
    serviceImplementationDetailProps(serviceImplementationGroup, element, translate);

    return [serviceImplementationGroup];
  }

}

export default function ServiceImplementationPropertiesProvider(propertiesPanel, translate) {
  this.getTabs = function (element) {
    return function (entries) {
      // Add the "voiceFiles" tab
      var serviceImplementationTab = {
        id: "service-implementation",
        label: "Service",
        groups: createServiceImplementationTabGroups(element, translate)
      };

      entries.push(serviceImplementationTab);

      // Show general + "voiceFiles" tab
      return entries;
    };
  };

  // Register our custom voiceFiles properties provider.
  // Use a lower priority to ensure it is loaded after the basic BPMN properties.
  propertiesPanel.registerProvider(this);
}

ServiceImplementationPropertiesProvider.$inject = ["propertiesPanel", "translate"];
