import { useState, useRef } from "react";

import * as Yup from 'yup';
import { useFormik } from "formik";
import PropTypes from 'prop-types';

import { REGEX } from "../../../config/config";

import { MESSAGES, TOOLTIP } from "../../../constants/messages";

import PmivrSnackBar from "../../../components/common/dialog/pmivr-snackbar";
import PmivrLabel from "../../../components/common/label/pmivr-label";
import PmivrTooltip, { TOOLTIP_POSITIONS } from "../../../components/common/tooltip/pmivr-tooltip";

import StringUtil from "../../../util/string.util";

import UserService from "./../../../services/user.service";

/**
 * Form for updating the user's password
 * @param {Object} props Properties from parent component
 * @returns {React.Component} Html element to render
 */
const UpdatePasswordForm = (props) => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    // ui state object having following flags
    // disabled: flag to enable / disable the form
    const [uiState, setUiState] = useState({ disabled: false });
    // to show or hide the password and confirm password of the user
    const [passwordVisibility, setPasswordVisibility] = useState({ password: false, confirmPassword: false });

    // validation schema for the password of user
    const validatePasswordSchema = Yup.object().shape({
        password: Yup.string()
            .required(MESSAGES.ERR.FIELD_REQUIRED)
            .min(8, MESSAGES.ERR.PASSWORD_LENGTH)
            .matches(
                REGEX.PASSWORD,
                MESSAGES.ERR.PASSWORD_PATTERN
            ),
        confirmPassword: Yup.string()
            .required(MESSAGES.ERR.FIELD_REQUIRED)
            .oneOf([Yup.ref('password'), null], MESSAGES.ERR.PASSWORDS_MUST_MATCH),
    });

    // formik values for form
    const formik = useFormik({
        initialValues: { password: '', confirmPassword: '' },
        validationSchema: validatePasswordSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    /**
     * Updating user's password
     * @param {{password, confirmPassword}} user formik values: password, confirmPassword
     */
    const handleSubmit = async (user) => {
        setUiState({ ...uiState, disabled: true });
        try {
            const response = await UserService.updatePassword(props.user.email, user.password);
            // opening the snackbar
            snackbarRef.current.open(response?.msg);
            // closing the popup upon updating the password, using timeout to show the snackbar message
            setTimeout(() => { props?.closeAction() }, 2000);
        } catch (err) {
            let _message = MESSAGES.SOMETHING_WENT_WRONG;
            if (err.response?.status === 403) {
                _message = MESSAGES.ERR.UNAUTHORIZED;
            }
            setUiState({ ...uiState, disabled: false });
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(_message);
            }
        }
    }

    /**
     * Sets the state for password and confirm password visibility to previous state on click of toggle button
     * if it was visible initially, hide it
     * if it was hidden, show it
     * @param {string} field - field can be either password or confirmPassword
     */
    const toggleVisibility = (field) => {
        setPasswordVisibility(prevState => ({ ...prevState, [field]: !prevState[field] }));
    };
    /**
     * Generates a random password using the StringUtil.generateRandomPassword method
     * and sets it as the value of the "password" field in the formik form.
     * @returns {void} This function does not return a value.
     */
    const generateRandomPassword = () => {
        // Generate a random password using the utility function
        const password = StringUtil.generateRandomPassword();
        // Set the generated password in the formik state
        formik.setFieldValue("password", password);
    };

    return (
        <>
            <PmivrSnackBar ref={snackbarRef} />
            <div className="pmivr-user-modal">
                <form onSubmit={formik.handleSubmit} disabled={uiState.disabled}>
                    <div className="pmivr-container">
                        <div className="wrapper p-3 pt-0">
                            <div className="mb-2">
                                <div className="pmivr-label">Email</div>
                                <input type="text" name="email" value={props.user.email} className="form-control pmivr-input mb-3" disabled />

                                <PmivrLabel label="Password" tooltip={TOOLTIP.INFO.USER.PASSWORD} cssClass='mt-3' />
                                <div className="password-input-wrapper">
                                    <input type={passwordVisibility.password ? "text" : "password"} name="password" value={formik.values.password}
                                        className="form-control pmivr-input" onChange={formik.handleChange} />
                                    <PmivrTooltip message={TOOLTIP.GENERATE_RANDOM_PASSWORD} position={TOOLTIP_POSITIONS.LEFT}>
                                        <i className="bi bi-arrow-repeat pe-4" onClick={generateRandomPassword} />
                                    </PmivrTooltip>
                                    <PmivrTooltip message={TOOLTIP.PASSWORD_VISIBILITY} position={TOOLTIP_POSITIONS.RIGHT}>
                                        <i className={`bi ${passwordVisibility.password ? "bi-eye" : "bi-eye-slash"}`}
                                            onClick={() => toggleVisibility('password')} />
                                    </PmivrTooltip>
                                </div>
                                {formik.touched.password && formik.errors.password && (
                                    <span className='field-error text-center'>{formik.errors.password}</span>
                                )}

                                <PmivrLabel label="Confirm Password" tooltip={TOOLTIP.INFO.USER.CONFIRM_PASSWORD} cssClass='mt-3' />
                                <div className="password-input-wrapper">
                                    <input type={passwordVisibility.confirmPassword ? "text" : "password"} name="confirmPassword"
                                        value={formik.values.confirmPassword} className="form-control pmivr-input"
                                        onChange={formik.handleChange} />
                                    <PmivrTooltip message={TOOLTIP.PASSWORD_VISIBILITY} position={TOOLTIP_POSITIONS.RIGHT}>
                                        <i className={`bi ${passwordVisibility.confirmPassword ? "bi-eye" : "bi-eye-slash"}`}
                                            onClick={() => toggleVisibility('confirmPassword')} />
                                    </PmivrTooltip>
                                </div>
                                {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                                    <span className='field-error text-center'>{formik.errors.confirmPassword}</span>
                                )}

                            </div>
                            <button className={`pmivr-btn-app w-100 p-3 mt-2 ${(!formik.values.password || !formik.values.confirmPassword) ? "pmivr-disabled" : ""}`} type="submit">
                                Update
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

UpdatePasswordForm.propTypes = {
    // selected user to be updated
    user: PropTypes.object,
    // closing the update password dialogue
    closeAction: PropTypes.func
}

export default UpdatePasswordForm;