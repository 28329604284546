import store from "../redux/store/store";

import { ATTRIBUTES } from "../constants/attributes";
import { EL_TYPE } from "../constants/element";
import { TASK_TYPE } from "../constants/task-types";
import { VOICE_FILE_UPLOAD_TYPE } from "../constants/voice-file";
import { envConfig } from "../environment";

import appAxios from "../util/app.axios";
import VoiceUtil from "../util/audio.util";

import DiagramService from "./diagram.service";
import ElementService from "./element.service";
import AppUtil from "../util/app.util";
import ObjUtil from "../util/obj.util";

/**
 * Service class for the audio / voice related operations
 */
class AudioService {
  static BASE_URL = `${envConfig.REACT_APP_API_BASE_URL}/audio`;

  /**
   * Upload Voice File for the given elementId by hitting the post api
   * @param {{ businessCode, file, elementId, language, flowName,optionId }} fileInfo file information
   * @returns {Promise<{status, data: {fileName: String, metaInfo: {location,isUploadedOnGit,versionId,language}}}>}
   */
  static uploadVoiceFile(fileInfo) {
    const formData = ObjUtil.createFormData(fileInfo);
    const url = `${this.BASE_URL}/voiceFile`;
    return appAxios.post(url, formData);
  }

  /**
   * Generate voice file and upload it
   * example for optionId 1 for english 2 for spanish these will have their own ids that are called optionIds
   * @param {{ businessCode, text, elementId, gender, speedRate, language, flowName,optionId }} fileInfo voice file info
   * @returns {Promise<{data: {fileName: String, metaInfo: {location,isUploadedOnGit,versionId,language}}}>}
   */
  static tts(fileInfo) {
    const url = `${this.BASE_URL}/tts`;
    return appAxios.post(url, fileInfo);
  }

  /**
   * Fetches audio file data from the server based on the provided file path.
   * @param {string} filePath - The relative path of the audio file.
   * @param {string} isUploadedOnGit - Source of the audio file, true if uploaded on git.
   * @returns {Promise<{type,data}>} - audio file buffer
   */
  static getAudioFile(filePath, isUploadedOnGit) {
    const url = `${this.BASE_URL}/voiceFile?filePath=${filePath}&isUploadedOnGit=${isUploadedOnGit}`;
    return appAxios.get(url);
  }

  /**
   * Get the elements from the current selected flow and
   * Finds the voice files configs and prepares the map of voice file details for it
   * @returns {Prmise<Object>} mapOfVoiceFileDetails
   */
  static async getVoiceFileDetails() {
    let mapOfVoiceFileDetails = new Map();
    try {
      const supportedLanguages = await DiagramService.getSupportedLanguages();
      const allElements = ElementService.getAllElements();
      allElements.forEach((element) => {
        const voiceFileDetailObj = {
          langauge: "", isFileExists: "", uploadType: "", step: "",
          process: "", detailId: "", taskType: "", filePath: ""
        };

        // elements types we need to consider for voice file list processing
        if ([EL_TYPE.SUB_PROCESS, EL_TYPE.TASK, EL_TYPE.USER_TASK].includes(element.type)) {
          // check if element task type is one of the configured ones task types
          if ([TASK_TYPE.promptUserOption, TASK_TYPE.keyValueUserOption, TASK_TYPE.promptUserInput, TASK_TYPE.playVoiceFile]
            .includes(element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE))) {
            const elementInfo = { element, voiceFileDetailObj, mapOfVoiceFileDetails, supportedLanguages };
            switch (element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)) {
              // to extract voice file information from playVoiceFile
              case TASK_TYPE.playVoiceFile:
                VoiceUtil.parseVoiceFileTask(elementInfo);
                break;

              case TASK_TYPE.promptUserOption:
                VoiceUtil.parsePromptUserOptionTask(elementInfo);
                break;

              case TASK_TYPE.keyValueUserOption:
                VoiceUtil.parseKeyValueUserOptionTask(elementInfo);
                break;

              case TASK_TYPE.promptUserInput:
                VoiceUtil.parsePromptUserInputTask(elementInfo);
                break;

              default:
                return new Map();
            }
          }
        }
      });
      return AppUtil.serializeMapToObject(mapOfVoiceFileDetails);
    } catch (err) {
      return new Map();
    }
  }

  /**
   * Getting the voice file information of the element and property 
   * voice file related information like supportedLanguage, selectedLanguage,
   * path of the voice file and the upload method of voice file
   * @param {Object} element Object of task (process)
   * @param {string} property Property of element from which we get voice file info
   * @param {string} language selected language en or es
   * @returns {Object} voice file information {supportedLanguages, selectedLanguage, voiceFileUploadMethod, etc}
   */
  static async getVoiceFileInfo(element, property, language) {
    const supportedLanguages = await DiagramService.getSupportedLanguages();
    const selectedLanguage = language ? language : "en";
    const voiceFileInformation = element.businessObject.get(property) ?
      JSON.parse(element.businessObject.get(property))
      : {};
    const langaugeInfo = voiceFileInformation[selectedLanguage];
    const voiceFileUploadMethod = langaugeInfo?.voiceFileType ? langaugeInfo?.voiceFileType : VOICE_FILE_UPLOAD_TYPE.LIBRARY;
    const voiceFilePath = (voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.LIBRARY) && langaugeInfo?.filePath ? langaugeInfo?.filePath : "";
    const voiceFileInfo = { supportedLanguages, selectedLanguage, voiceFileInformation, voiceFileUploadMethod, voiceFilePath };

    return voiceFileInfo;
  }

  /**
   * Retrieves a list of voice files.
   * @param {string} language selected language
   * @param {string} searchText search text for the voice file
   * @returns {Promise<{fileName,filePath}>} A Promise that resolves with the response data from the server.
   */
  static async getVoiceFileList(language = 'en', searchText) {
    const businessCode = store.getState().client.businessCode;
    const url = `${this.BASE_URL}/voiceFilePath?businessCode=${businessCode}&language=${language}&searchText=${searchText}`;
    return appAxios.get(url);
  }

  /**
   * Get the voice file prefix based on the language selected and configured on wizard
   * Example es/
   * @param {string} language selected language
   * @returns {string} prefix value, voice file prefix path like es/ based on selection on wizard
   */
  static getVoiceFilePrefix(language) {
    const voiceFilePrefix = store.getState().voiceFile.voiceFilePrefixObj;
    return voiceFilePrefix[language];
  }

  /**
   * Fetches system voice files configure by the biller
   * @returns {Promise<Array<{fileName,language}>>} system voice files array
   */
  static getSystemVoiceFiles() {
    const businessCode = store.getState().client.businessCode;
    const url = `${this.BASE_URL}/systemVoiceFiles?businessCode=${businessCode}`;
    return appAxios.get(url);
  }

  /**
   * Updates the system voice files as per the biller
   * @param {Array<{fileName,language}>} systemVoiceFiles 
   * @returns {Promise<Array<{fileName,language}>>} updated system voice files array
   */
  static updateSystemVoiceFiles(systemVoiceFiles) {
    const businessCode = store.getState().client.businessCode;
    const url = `${this.BASE_URL}/systemVoiceFiles`;
    return appAxios.post(url, { systemVoiceFiles, businessCode });
  }

  /**
   * Upload the voice file to repository
   * @param {Object} voiceFileInfo 
   * @returns {{data: {filePath: string}}} Returns filePath in response
   */
  static uploadVoiceFileToRepo(voiceFileInfo) {
    const url = `${this.BASE_URL}/uploadVoiceFileToRepo`;
    // if the type of voice file is upload
    if (voiceFileInfo.file) {
      const formData = ObjUtil.createFormData(voiceFileInfo);
      return appAxios.post(url, formData);
    }
    // in case of tts option, simply pass the tts object for uploading
    return appAxios.post(url, voiceFileInfo);
  }
}

export default AudioService;