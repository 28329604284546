import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showGuide: false, // to store the run state of tour guide
};

// auto-generate action types and action creators, based on the names of the reducer functions that we supply
// state info : tour guide related info
const slice = createSlice({
  name: 'tourGuide',
  initialState,
  reducers: {
    updateShowGuide: (state, action) => ({
      ...state,
      showGuide: action.payload.showGuide,
    }),
    clearState: () => initialState,
  }
});

export const { updateShowGuide, clearState } = slice.actions;

export default slice.reducer;
