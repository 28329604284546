import React from "react";
import PropTypes from 'prop-types';

import ChangeHistoryService from "../../services/change-history.service";

/**
 * Viewing the history of changes done in current flow diagram
 * @param {Object} props props data from parent component
 */

const ChangeHistory = (props) => {

  /**
   * Generate message from  history meesage
   * @param {Object} history history info
   * @returns {React.Component} Html code to render 
   */
  const getHistoryMessage = (history) => {
    return (
      <>
        {ChangeHistoryService.getChangeHistoryMessage(history)}
      </>
    )
  }

  return (
    <>
      <div className="change-history">
        {
          Array.isArray(props?.history) && props?.history?.length ?
            props.history.map((history, index) => {
              return (
                <div key={index} className="history-item">
                  {getHistoryMessage(history)}
                </div>
              )
            })
            : <div>Nothing to show</div>
        }
      </div>
    </>
  )
}

// Types of props passed in the component
ChangeHistory.propTypes = {
  // array of history
  history: PropTypes.array
}

export default ChangeHistory;

