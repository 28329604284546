import { useEffect, useState, useRef } from 'react';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { useSelector, useDispatch } from "react-redux";

import PropTypes from 'prop-types';

import { MESSAGES } from '../../../constants/messages';
import { VOICE_FILE_DEFAULT_LANGUAGE, VOICE_FILE_LANGUAGE_NAME, VOICE_FILE_UPLOAD_TYPE } from '../../../constants/voice-file';
import { envConfig } from '../../../environment';

import { updateSystemVoiceFiles } from '../../../redux/actions/client.action';

import PmivrSnackBar from '../../../components/common/dialog/pmivr-snackbar';
import EditSystemVoiceFiles from './edit-system-voice-files';
import PmivrSearchInput from '../../../components/common/search-input/pmivr-search';

import AudioService from '../../../services/audio.service';

/**
 * The component for listing system voice files being configured and used internally in AGI call
 * @returns {React.Component} Listing of system voice files to render on UI
 */
const SystemVoiceFilesComponent = ({ langConfigured }) => {
  // to dispatch the data in redux
  const dispatch = useDispatch();
  // snackbar to display messages
  const snackbarRef = useRef();
  const { systemVoiceFiles } = useSelector(state => state.client);
  // boolean value to show voice file settings menu
  const [uiState, setUiState] = useState({ showVoiceFileSettingMenu: false, systemVoiceFiles: [] });
  // language selected in voice file info and update if change in the modal
  const [selectedLanguage, setSelectedLanguage] = useState('');
  // voice file path for the select menu
  const [voiceFilePath, setVoiceFilePath] = useState('');
  // voice file upload type for the selected voice file
  const [voiceFileType, setVoiceFileType] = useState('');
  // information about the system voice file (file path,language)
  const [voiceFileInfo, setVoiceFileInfo] = useState({});
  // voice file object to temporarily save user changes
  const [tempSystemVoiceFiles, setTempSystemVoiceFiles] = useState([]);
  const [filter, setFilter] = useState({
    searchText: '',// serach for voice file in system voice files
  });

  useEffect(() => {
    const init = async () => {
      const voiceFiles = await AudioService.getSystemVoiceFiles();
      setUiState({ ...uiState, systemVoiceFiles: voiceFiles?.data });
      setTempSystemVoiceFiles(voiceFiles?.data);
      setSelectedLanguage(VOICE_FILE_DEFAULT_LANGUAGE);
      dispatch(updateSystemVoiceFiles({ systemVoiceFiles: voiceFiles?.data }));
    };
    init();
  }, []);

  /**
   * Effect to load call logs when the filter state changes.
   * @function useEffect
   * @dependencies {Array} filter - The dependency array that triggers the effect when its value changes.
   */
  useEffect(() => {
    filterVoiceFiles();
  }, [filter?.searchText])

  /**
   * revert the changes of the voice file setting menu
   * @param {Object} event 
   */
  const handleCloseVoiceFileSettingMenu = async (event) => {
    setTempSystemVoiceFiles(uiState?.systemVoiceFiles);
    setUiState({ ...uiState, showVoiceFileSettingMenu: false });
  };

  /**
   * update the voice file state information when language tab is changed
   * @param {string} language selected language
   * @param {{fileName,language}} voiceFileInfo selected voice file information object
   */
  const updateVoiceFileStateInfo = async (language, voiceFileInfo) => {
    const voiceFilePath = voiceFileInfo?.language[language]?.filePath || null;
    const voiceFileType = voiceFileInfo?.language[language]?.voiceFileType || null;
    setVoiceFilePath(voiceFilePath);
    setVoiceFileType(voiceFileType);
    setSelectedLanguage(language);
  };

  /**
   * save the changes of the voice file setting menu and update the system voice files
   * @param {Object} event 
   */
  const handleSaveVoiceFileSettingMenu = async (event) => {
    // updates the system voice files
    const updatedVoiceFile = await AudioService.updateSystemVoiceFiles(tempSystemVoiceFiles);
    setTempSystemVoiceFiles(updatedVoiceFile?.data);
    setUiState({ ...uiState, showVoiceFileSettingMenu: false, systemVoiceFiles: updatedVoiceFile?.data });
    // save it in redux as well to avoid any loss of data
    dispatch(updateSystemVoiceFiles({ systemVoiceFiles: updatedVoiceFile?.data }));
    setFilter({ ...filter, searchText: "" });
    // show the success message on saving the system voice file
    snackbarRef.current.open(MESSAGES.SYSTEM_VOICE_FILE_UPDATED);
  };

  /**
   * update voice file path state when edit option modal is activated
   * @param {Object} event 
   * @param {string} fileName key value identifier to fetch voice files
   */
  const handleVoiceFileSettingMenu = async (event, fileName) => {
    // to stop opening of accordian when action button is clicked
    event.stopPropagation();
    const voiceFileInfo = _getVoiceFileInfo(fileName, selectedLanguage);
    const voiceFilePath = voiceFileInfo?.language[selectedLanguage]?.filePath || null;
    const voiceFileType = voiceFileInfo?.language[selectedLanguage]?.voiceFileType || null;
    setVoiceFilePath(voiceFilePath);
    setVoiceFileType(voiceFileType);
    setVoiceFileInfo(voiceFileInfo);
    setUiState({ ...uiState, showVoiceFileSettingMenu: true });
  };

  /**
   * update the voicePath state for select menu
   * @param {string} value selected file path
   * @param {{fileName,language}} voiceFileInfo selected voice file information object
   */
  const updateVoiceFilePathState = (value, voiceFileInfo) => {
    const updateVoiceFiles = _updateFilePath(value, voiceFileInfo);
    setTempSystemVoiceFiles(updateVoiceFiles);
    setVoiceFilePath(value);
  };

  /**
   * fetches the updated voice files object
   * @param {string} value selected file path
   * @param {Object} voiceFileInfo voice file details returned from child components
   * @returns {Array<{fileName,language}>} updated system voice files array
   */
  const _updateFilePath = (value, voiceFileInfo) => {
    return tempSystemVoiceFiles.map((item) => {
      if (item.fileName === voiceFileInfo.fileName) {
        const updatedVoiceFileInfo = voiceFileInfo?.language[selectedLanguage];
        const isLibrary = !([VOICE_FILE_UPLOAD_TYPE.TTS,
        VOICE_FILE_UPLOAD_TYPE.UPLOAD].includes(updatedVoiceFileInfo?.voiceFileType));
        // Build the languageData object based on whether the type is TTS, upload or library
        const languageData = isLibrary
          ? {
            filePath: value,
            voiceFileType: voiceFileInfo.voiceFileType,
          } :
          {
            ...item.language[selectedLanguage],
            filePath: value,
            voiceFileType: updatedVoiceFileInfo.voiceFileType,
            ...updatedVoiceFileInfo,
          };
        return {
          ...item,
          language: {
            ...item.language,
            [selectedLanguage]: languageData,
          },
        };
      }
      return item;
    });
  };

  /**
   * extract the voice file information from the system voice files array
   * @param {string} fileName key value identifier to fetch voice files
   * @returns {{fileName,language}} selected voice file info object
   */
  const _getVoiceFileInfo = (fileName) => {
    const file = uiState?.systemVoiceFiles.find((file) => file.fileName === fileName);
    return file ? file : null;
  };

  // Wrap tooltip
  const tooltip = (props, info) => <Tooltip {...props}>{info}</Tooltip>;

  /**
   * Saving the search input in state
   * @param {string} textTyped
   */
  const searchInput = (textTyped) => {
    // updating the state object
    setFilter({ ...filter, searchText: textTyped });
  };

  /**
   * Filter the searched text in the voice file list.
   */
  const filterVoiceFiles = () => {
    if (filter.searchText) {
      const filteredVoicefile = systemVoiceFiles.filter(file =>
        file.fileName.toLowerCase().includes(filter.searchText.trim().toLowerCase())
      );
      setUiState({ ...uiState, systemVoiceFiles: filteredVoicefile });
    }
    else {
      // If filter?.searchText is empty, reset the systemVoiceFiles in uiState
      // to ensure that the previous search state is cleared.
      setUiState({ ...uiState, systemVoiceFiles: systemVoiceFiles });
    }
  };

  /**
   * Clear all the search and filters (reset the content)
   */
  const clearAll = () => {
    // clearing the cache (reseting the state object)
    setFilter({ ...filter, searchText: "" });
    // updating cache with original list
    setUiState({ ...uiState, systemVoiceFiles: systemVoiceFiles });
  };

  return (
    <>
      <PmivrSnackBar ref={snackbarRef} />
      <div className="pmivr-container">
        <div className="wrapper pmivr-voice-file-list px-3 pb-3">
          <div className="row">
            <div className={`col-md-${uiState.showVoiceFileSettingMenu ? '8' : '12'}`}>
              <div className="row d-flex align-items-center">
                <div className="col-md-3 pt-3">
                  <div className="search-voice-file mb-2">
                    {/*Uses common search component to render the search input for filtering system voice files */}
                    <PmivrSearchInput
                      value={filter?.searchText}
                      placeholder="Search Voice File Text"
                      onSearch={(searchValue) => searchInput(searchValue)}
                      onEnter={filterVoiceFiles}
                      label="Search File Name:"
                    />
                  </div>
                </div>
                {/* Filter */}
                <div className="col-md-7 pt-3">
                  <div className="row">
                    <div className="col-md-1 mt-3 pt-1">
                      <button type="button" onClick={clearAll} className="pmivr-btn-secondary pmivr-reset-link">Reset</button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <table className="table table-hover voice-file-list pmivr-table header-fixed border mt-2" id="main-table">
                  <thead>
                    <tr>
                      <th width="20%" className="text-center">
                        Voice File Name
                      </th>
                      {langConfigured.map((lang) => {
                        return (
                          <th key={lang} width="25%" className="text-center">
                            {VOICE_FILE_LANGUAGE_NAME[lang?.toUpperCase()]}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="pmivr-scroll">
                    {/* Iterate over systemVoiceFiles to generate table rows */}
                    {
                      uiState?.systemVoiceFiles.map((value, index) => (
                        <tr key={index} id="main-tr" onClick={(event) => handleVoiceFileSettingMenu(event, value.fileName)}
                          className='cursor-pointer'>
                          {/* File Name */}
                          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, value.fileName)}>
                            <td width="20%" className="text-center pt-2 ms-4">
                              {value.fileName}
                            </td>
                          </OverlayTrigger>

                          {/* Iterate over langConfigured to display file paths */}
                          {
                            langConfigured.map((lang, langIndex) => (
                              <td key={langIndex} width="25%" className="text-center pt-1 ms-5">
                                <div className="form-check pmivr-check-radio text-center p-0">
                                  {value?.language[lang]?.filePath ? (
                                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, value?.language[lang]?.filePath)}>
                                      <span>{`${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}` + value.language[lang]?.filePath}</span>
                                    </OverlayTrigger>
                                  ) : (
                                    <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={tooltip({}, "File does not exist on server")}>
                                      <span>No file available</span>
                                    </OverlayTrigger>
                                  )}
                                </div>
                              </td>
                            ))
                          }
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            {uiState.showVoiceFileSettingMenu && (
              <div className="col-md-4 edit-system-voice-files-menu p-0">
                <div className="wrapper">
                  <EditSystemVoiceFiles supportedLanguages={langConfigured} voiceFileType={voiceFileType}
                    updateVoiceFileStateInfo={updateVoiceFileStateInfo} selectedLanguage={selectedLanguage}
                    voiceFilePath={voiceFilePath} voiceFileInfo={voiceFileInfo} updateVoiceFilePathState={updateVoiceFilePathState} />
                  <button type="submit" className="pmivr-btn-app float-end mt-2 me-1" onClick={handleSaveVoiceFileSettingMenu}>
                    Save
                  </button>
                  <button type="button" className="pmivr-btn-secondary float-end mt-2 me-1" onClick={handleCloseVoiceFileSettingMenu}>
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

SystemVoiceFilesComponent.propTypes = {
  // array of supported languages
  langConfigured: PropTypes.array
};
export default SystemVoiceFilesComponent;