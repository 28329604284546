import { updateChatFlowObj, clearState } from "../slices/interactive-design.slice";

export const updateChatFlowInfo = ({ chatFlowId, chatFlowTitle = "" }) => async dispatch => {
    dispatch(updateChatFlowObj({ chatFlowId, chatFlowTitle }));
}

/**
 * dispatch the action to clear the values from redux state
 */
export const clearInteractiveDesignState = () => async dispatch => {
    dispatch(clearState());
}