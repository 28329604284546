import React from "react";
import { Tooltip, OverlayTrigger, Popover } from "react-bootstrap";

import PropTypes from 'prop-types';

// Placement positions for tooltip
const TOOLTIP_POSITIONS = {
    BOTTOM: "bottom",
    TOP: "top",
    LEFT: "left",
    RIGHT: "right",
    AUTO: "auto"
};

/**
 * Renders a Popover or Tooltip based on the type of `message`.
 * @param {{children:React.ReactNode , message:string|string[] , position:string, popoverContent: React.ReactNode, customPopoverClass: string}} props - The component props.
 * @returns {JSX.Element} The tooltip or popover wrapped around the children.
 */
const PmivrTooltip = ({ children, message, position = TOOLTIP_POSITIONS.AUTO, popoverContent, customPopoverClass = "" }) => {
    /**
     * Renders either a Popover or Tooltip based on `info` type.
     * @param {string|string[]} info - The content to display.
     * @returns {JSX.Element} The Popover or Tooltip element.
     */
    const wrapToolTip = (info) => {
        return (
            Array.isArray(info) ?
                <Popover>
                    <Popover.Body>
                        {info.map((item, index) => (
                            <span key={index} style={index === 0 ? { fontWeight: 'bold' } : {}}>
                                {item}
                                <br />
                            </span>
                        ))
                        }
                    </Popover.Body >
                </Popover >
                :
                <Tooltip className="pmivr-tooltip"> {info}</Tooltip>
        )
    };

    /**
     * Wraps the given content in a Popover component.
     * @param {React.ReactNode} content - The content to be displayed inside the Popover.
     * @returns {JSX.Element} A Popover component containing the specified content.
     */
    const wrapPopover = (content) => {
        return (
            <Popover className={customPopoverClass}>
                <Popover.Body>
                    {content}
                </Popover.Body>
            </Popover>
        )
    }

    return (<>
        {popoverContent ?
            <OverlayTrigger placement={position} delay={{ show: 250, hide: 400 }} overlay={wrapPopover(popoverContent)} >
                {children}
            </OverlayTrigger >
            :
            <OverlayTrigger placement={position} delay={{ show: 250, hide: 400 }} overlay={wrapToolTip(message)} >
                {children}
            </OverlayTrigger>
        }
    </>);
};

PmivrTooltip.propTypes = {
    // message for the tooltip
    message: PropTypes.string,
    // children passed like button
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.element)
    ]),
    // Position of the tooltip (top, bottom etc.)
    position: PropTypes.string,
    // popover content which is in the form of jsx
    popoverContent: PropTypes.element,
    // class name with custom css for popover
    customPopoverClass: PropTypes.string
}

export { TOOLTIP_POSITIONS };
export default PmivrTooltip;