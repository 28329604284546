import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";

import { is } from "bpmn-js/lib/util/ModelUtil";

export default function VoiceFileDetailProps(group, element, translate) {
  // Only return an entry, if the currently selected
  // element is a start event.

  if (is(element, "bpmn:Task")) {
    group.entries.push(
      entryFactory.textField(translate, {
        id: "voiceFileName",
        description: "Enter Voice File Name ",
        label: "Voice File Name",
        modelProperty: "voiceFileName"
      })
    );
    group.entries.push(
      entryFactory.textField(translate, {
        id: "voiceFilePath",
        description: "Enter Voice File Path ",
        label: "Voice File Path",
        modelProperty: "voiceFilePath"
      })
    );
    group.entries.push(
      entryFactory.checkbox(translate, {
        id: "isTTS",
        label: "is TTS?",
        modelProperty: "isTTS"
      })
    );
    group.entries.push(
      entryFactory.textField(translate, {
        id: "text",
        description: "Text for TTS",
        label: "Text",
        modelProperty: "text"
      })
    );
  }
}
