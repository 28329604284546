import { EL_TYPE } from "../../constants/element";

import DiagramService from "../../services/diagram.service";

import CommandInterceptor from "diagram-js/lib/command/CommandInterceptor";

/**
 * Interceptor for bpmn commands
 * hooks can be declared to listen events
 */
class CustomCommandInterceptor extends CommandInterceptor {
  constructor(eventBus, modeling, overlays) {
    super(eventBus);

    /**
     * Called when you create a new shape
     */
    this.postExecuted(["shape.create"], ({ context }) => {
      const { shape } = context;
      // if subprocess then add expand/collapse button html
      if (shape.type === EL_TYPE.SUB_PROCESS) {
        modeling.toggleCollapse(shape);

        // expand collapse btn
        const html = DiagramService.createElement('<div style="position:absolute; right:0px;"><button class="pmivr-expand-button" id="exp' + shape.id + '"><i class="bi bi-arrows-angle-expand"></i></button><button style="display:none" class="pmivr-expand-close" id="clsp' + shape.id + '"><i class="bi bi-arrows-angle-contract"></i></button><div>');
        overlays.add(shape, {
          position: {
            left: 0,
            top: 0
          },
          html
        });

        // adds events to the expand and collapse arrows (document usage cannot be removed as it is done for bpmn element that is created dynamically)
        document.getElementById('exp' + shape.id).addEventListener('click', () => DiagramService.expandElementHandler(shape));
        document.getElementById('clsp' + shape.id).addEventListener('click', () => DiagramService.collapseElementHandler());
      }
    });
  }
}

CustomCommandInterceptor.$inject = ["eventBus", "modeling", "overlays"];

const CustomCommandInterceptorModule = {
  __init__: ["customCommandInterceptor"],
  customCommandInterceptor: ["type", CustomCommandInterceptor]
};

export default CustomCommandInterceptorModule;
