export default {
  /**
   * Adds an element to the grid, adjusting its position to handle multiple incoming connections.
   * If the element has multiple incoming connections, the grid layout is adjusted to avoid overlaps.
   * @param {Object} params - Parameters for adding the element to the grid.
   * @param {Object} params.element - The BPMN element being added to the grid.
   * @param {Object} params.grid - The grid where elements are positioned.
   * @param {Set} params.visited - A set of elements that have already been processed to avoid duplicates.
   * @returns {Array} - An array of next elements to be processed.
   */
  'addToGrid': ({ element, grid, visited }) => {
    const nextElements = [];

    const incoming = (element.incoming || [])
      .map(out => out.sourceRef)
      .filter(el => el);

    // Adjust the grid row and column if the element has multiple incoming connections
    if (incoming.length > 1) {
      grid.adjustColumnForMultipleIncoming(incoming, element);
      grid.adjustRowForMultipleIncoming(incoming, element);
    }
    return nextElements;
  },
};