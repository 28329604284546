/**
 * Checks if the provided element is a connection (i.e., has a source reference).
 * @param {Object} element - The BPMN element to check.
 * @returns {boolean} - True if the element is a connection, false otherwise.
 */
export function isConnection(element) {
  return !!element.sourceRef;
}

/**
 * Checks if the provided element is a boundary event (i.e., attached to another element).
 * @param {Object} element - The BPMN element to check.
 * @returns {boolean} - True if the element is a boundary event, false otherwise.
 */
export function isBoundaryEvent(element) {
  return !!element.attachedToRef;
}

/**
 * Recursively searches for a target element within the outgoing connections of a current element.
 * Detects whether there is a path from the current element to the target element, preventing cycles.
 * @param {Object} currentElement - The BPMN element to start searching from.
 * @param {Object} targetElement - The BPMN element to search for.
 * @param {Set} [visited=new Set()] - A set of visited elements to prevent infinite loops.
 * @returns {boolean} - True if the target element is found in the tree, false otherwise.
 */
export function findElementInTree(currentElement, targetElement, visited = new Set()) {

  if (currentElement === targetElement) return true;

  if (visited.has(currentElement)) return false;

  visited.add(currentElement);

  // If currentElement has no outgoing connections, return false
  if (!currentElement.outgoing || currentElement.outgoing.length === 0) return false;

  // Recursively check each outgoing element
  for (let nextElement of currentElement.outgoing.map(out => out.targetRef)) {
    if (findElementInTree(nextElement, targetElement, visited)) {
      return true;
    }
  }

  return false;
}
