import { EL_TYPE } from "../constants/element";

import { layoutProcess } from "../bpmn-auto-layout";
import ModelerService from "./modeler.service";

/**
 * Reconnect elements 
 * Functions are copied from diagram-js's lib/layout/LayoutUtil
 */
export default class FlowLayoutService {

  /**
   * Get the elements for the diagram and reconnect the elements using connections
   */
  static reconnectElements() {
    const elementRegistry = ModelerService.getModeler().get('elementRegistry');
    // get all the connections
    const connections = elementRegistry.filter((element) => element.waypoints);
    // elements and its corresponding incoming connection lines
    const targetElConnections = FlowLayoutService._getAllElConnections(connections, 'target');
    // elements and its corresponding outgoing connection lines 
    const sourceElConnections = FlowLayoutService._getAllElConnections(connections, 'source');

    // Updating the waypoints for both the cases:
    // Case 1: More than 1 incoming arrows towards an element
    // Case 2: More than 1 outgoing arrows from an element
    FlowLayoutService._adjustOverlappingConnections(targetElConnections);
    FlowLayoutService._adjustOverlappingConnections(sourceElConnections);
  }

  /**
   * Updating the connections
   * @param {Object} connection 
   */
  static updateConnectionPoints(connection) {
    const modeling = ModelerService.getModeler().get('modeling');

    modeling.updateWaypoints(connection, [
      FlowLayoutService._getMid(connection.source),
      FlowLayoutService._getMid(connection.target)
    ]);

    modeling.layoutConnection(connection, {
      connectionStart: FlowLayoutService._getMid(connection.source),
      connectionEnd: FlowLayoutService._getMid(connection.target)
    });
  }

  /**
   * Getting the connection id for the connection
   * @param {string} element 
   */
  static _getMid(element) {
    if (FlowLayoutService._isConnection(element)) {
      return FlowLayoutService._getConnectionMid(element);
    }
    return FlowLayoutService._getBoundsMid(element);
  }

  /**
   * Check whether it is connection or not
   * @param {Object} element 
   */
  static _isConnection(element) {
    return !!element.waypoints;
  }

  /**
   * Getting (x,y) fro the point
   * @param {Object} point 
   * @returns {Object} axis of the round point
   */
  static _roundPoint(point) {
    return {
      x: Math.round(point.x),
      y: Math.round(point.y)
    };
  }

  /**
   * Getting (x,y) for the bound
   * @param {Object} bounds 
   */
  static _getBoundsMid(bounds) {
    return FlowLayoutService._roundPoint({
      x: bounds.x + (bounds.width || 0) / 2,
      y: bounds.y + (bounds.height || 0) / 2
    });
  }

  /**
   * Getting the connection points(x,y) for the connection
   * @param {Object} connection 
   */
  static _getConnectionMid(connection) {
    var waypoints = connection.waypoints;
    // calculate total length and length of each segment
    var parts = waypoints.reduce(function (parts, point, index) {
      var lastPoint = waypoints[index - 1];
      if (lastPoint) {
        var lastPart = parts[parts.length - 1];
        // && and || should not get mixed so put brackets
        var startLength = (lastPart && lastPart.endLength) || 0;
        var length = FlowLayoutService._distance(lastPoint, point);

        parts.push({
          start: lastPoint,
          end: point,
          startLength: startLength,
          endLength: startLength + length,
          length: length
        });
      }
      return parts;
    }, []);

    var totalLength = parts.reduce(function (length, part) {
      return length + part.length;
    }, 0);

    // find which segement contains middle point
    var midLength = totalLength / 2;
    var i = 0;
    var midSegment = parts[i];
    while (midSegment.endLength < midLength) {
      midSegment = parts[++i];
    }

    // calculate relative position on mid segment
    var segmentProgress = (midLength - midSegment.startLength) / midSegment.length;
    var midPoint = {
      x: midSegment.start.x + (midSegment.end.x - midSegment.start.x) * segmentProgress,
      y: midSegment.start.y + (midSegment.end.y - midSegment.start.y) * segmentProgress
    };

    return midPoint;
  }

  /**
   * Calculating the distance between points a and b
   * @param {Object} a 
   * @param {Object} b 
   */
  static _distance(a, b) {
    return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
  }

  /**
   * Get all connections for each target element 
   * @param {Array<Object>} connections - An array of connections to adjust.
   * @param {string} elType - It can be a source element or target element
   * @returns {Map<string, Array<Object>>} A map where key is the target element ID,
   * and the value is an array of connections connected to that target element.
   */
  static _getAllElConnections(connections, elType) {
    const elConnections = new Map();

    // update the map where we set element id as key and the
    // connections it has in an array as value
    connections.forEach((connection) => {
        // First update the connection points so that the flow does not gets disturbed
        FlowLayoutService.updateConnectionPoints(connection);
        const targetElementId = connection[elType].id;
        if (!elConnections.has(targetElementId)) {
          elConnections.set(targetElementId, [connection]);
        } else {
          elConnections.get(targetElementId).push(connection);
        }
    });
    return elConnections;
  }

  /**
   * Adjusts the positions of overlapping connections with specified spacing
   * @param {Array<Object>} elConnections - An array of connections to adjust.
   */
  static _adjustOverlappingConnections(elConnections) {
    // Taking average spacing as 15 so that flow-lines remain clearly visible.
    const spacing = 15;
    elConnections.forEach((connections) => {
      // Elements with more than one connection causes overlapping, so check for such connections
      if (connections.length > 1) {
        // Update the waypoints for overlapping connections
        connections.forEach((connection, index) => {
          // Calculate the horizontal and vertical offset for each connection for positioning them relative to center
          // Dividing the length of connections array by half to get the center position
          const horizontalOffset = (index-(connections.length-1)/2) * spacing;
          const verticalOffset = (index-(connections.length-1)/2) * spacing;
    
          // Update the waypoints with the horizontal and vertical offsets
          const waypoints = connection.waypoints.map((waypoint) => ({
            x: waypoint.x + horizontalOffset,
            y: waypoint.y + verticalOffset
          }));
    
          // Update the connection with the new waypoints
          const modeling = ModelerService.getModeler().get('modeling');
          modeling.updateWaypoints(connection, waypoints);
          modeling.layoutConnection(connection, {
            connectionStart: waypoints[0], 
            connectionEnd: waypoints[waypoints.length - 1]
          });
        });        
      }
    });
  }

  /**
   * Adjusts a BPMN XML string for subprocess visibility by consolidating all BPMN diagrams and planes into a single `BPMNDiagram` 
   * and `BPMNPlane` element.
   * Since auto layout returns multiple diagram and plane tags for each subprocess, but IVR uses xml with single diagram and plane tag, 
   * and all other subprocesses are placed inside it.  
   * This modification ensures compatibility with IVR requirements, as the auto-layout returns multiple diagram and plane tags.
   * @param {string} diagramXML - The XML string representing the BPMN diagram.
   * @returns {string} - The modified BPMN XML string with a single BPMNDiagram and BPMNPlane containing all original plane elements.
   */
  static processXmlForSubprocess(diagramXML) {
    // Convert the XML string into a DOM object
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(diagramXML, "application/xml");

    // Select all BPMNDiagram and BPMNPlane elements
    const diagrams = xmlDoc.getElementsByTagName(EL_TYPE.BPMN_DIAGRAM);
    const planes = xmlDoc.getElementsByTagName(EL_TYPE.BPMN_PLANE);

    // Create a new BPMNDiagram and BPMNPlane
    const newDiagram = xmlDoc.createElement(EL_TYPE.BPMN_DIAGRAM);
    newDiagram.setAttribute("id", EL_TYPE.BPMN_PARENT_PROCESS);

    const newPlane = xmlDoc.createElement(EL_TYPE.BPMN_PLANE);
    newPlane.setAttribute("id", "BPMNPlane_Process_1");
    newPlane.setAttribute("bpmnElement", "Process_1");

    // Iterate through all planes and move their child nodes to the new plane
    for (let i = 0; i < planes.length; i++) {
      const currentPlane = planes[i];
      while (currentPlane?.childNodes?.length > 0) {
        // Move the child nodes from the current plane to the new plane
        newPlane.appendChild(currentPlane.childNodes[0]);
      }
    }

    // Append the new plane to the new diagram
    newDiagram.appendChild(newPlane);

    // Remove all the old diagrams
    while (diagrams.length > 0) {
      diagrams[0].parentNode.removeChild(diagrams[0]);
    }

    // Append the new diagram to the root element
    xmlDoc.documentElement.appendChild(newDiagram);
    // Serialize the XML back into a string
    const serializer = new XMLSerializer();
    const newXml = serializer.serializeToString(xmlDoc);
    return newXml;
  }

  /**
   * Parses xml and returns an xml with auto layout implemented in connection lines for clear spacing and visibility
   * @param {string} xml 
   * @returns {string} Auto layouted xml
   */
  static async autoLayout(diagramXml) {
    let resXml = await layoutProcess(diagramXml);
    return FlowLayoutService.processXmlForSubprocess(resXml);
  }
}