import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";

import { is } from "bpmn-js/lib/util/ModelUtil";

export default function UserInputDetailProps(group, element, translate) {
  // Only return an entry, if the currently selected
  // element is a start event.

  if (is(element, "bpmn:UserTask")) {
    group.entries.push(
      entryFactory.textField(translate, {
        id: "maxDigits",
        description: "Enter Max Digits ",
        label: "Max Digits",
        modelProperty: "maxDigits"
      })
    );
    group.entries.push(
      entryFactory.textField(translate, {
        id: "inputVar",
        description: "Input Variable",
        label: "Input Variable",
        modelProperty: "inputVar"
      })
    );
  }
}
