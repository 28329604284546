import { ATTRIBUTES } from "../constants/attributes";
import { EL_TYPE } from "../constants/element";

export const LANGUAGES_SUPPORTED = ["English", "Spanish"];

// list of attributes to avoid track the changes in change history
export const CHANGE_ATTRS_TO_AVOID = ["usrOpt:voiceFileUploadMethod"];

// configs in voice file control task (*** used for history tracking .  adding new config can affect the history module.)
export const VOICE_FILE_TASK_ATTR = ["voice:voiceFileInfo", "usrInput:userInputInvalidInputVoiceFile"];

// list of attributes to include in change history 
// change in following attributes will be considered for change history
export const CHANGE_ATTRS_TO_INCLUDE = [
  ATTRIBUTES.VOICE_FILE_INFO, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE,
  ATTRIBUTES.SAY_DATA_TYPE, ATTRIBUTES.SAY_DATA_TASK, ATTRIBUTES.XML_LOOPTASK_COLLECTION,
  ATTRIBUTES.USER_OPTION_SPEECH_INPUT_GRAMMAR, ATTRIBUTES.PROMT_USER_OPTION_VALUE_MAP, ATTRIBUTES.USER_OPTION_VAR,
  ATTRIBUTES.USER_OPTION_RETRY_COUNT, ATTRIBUTES.KEY_VALUE_OPTION_RETRY_COUNT, ATTRIBUTES.KEY_VALUE_OPTION_VAR,
  ATTRIBUTES.DYNAMIC_OPTION_VAR, ATTRIBUTES.DYNAMIC_OPTION_RETRY_COUNT, ATTRIBUTES.DYNAMIC_OPTION_COLLECTION,
  ATTRIBUTES.DYNAMIC_OPTION_IS_CUSTOMIZE_VOICE_FILES, ATTRIBUTES.DYNAMIC_OPTION_MAPPING_FIELD,
  ATTRIBUTES.TRANSFER_NUMBER, ATTRIBUTES.TRANSFER_START_TIME, ATTRIBUTES.TRANSFER_END_TIME, ATTRIBUTES.TRANSFER_STARTTIME0,
  ATTRIBUTES.TRANSFER_ENDTIME0, ATTRIBUTES.TRANSFER_STARTTIME1, ATTRIBUTES.TRANSFER_ENDTIME1, ATTRIBUTES.TRANSFER_STARTTIME2,
  ATTRIBUTES.TRANSFER_ENDTIME2, ATTRIBUTES.TRANSFER_STARTTIME3, ATTRIBUTES.TRANSFER_ENDTIME4, ATTRIBUTES.TRANSFER_STARTTIME5,
  ATTRIBUTES.TRANSFER_ENDTIME5, ATTRIBUTES.TRANSFER_OUTSIDE_BUSINESS_HOUR_VOICE_FILE, ATTRIBUTES.TRANSFER_ENDTIME3,
  ATTRIBUTES.TRANSFER_STARTTIME4, ATTRIBUTES.TRANSFER_STARTTIME6, ATTRIBUTES.TRANSFER_ENDTIME6, ATTRIBUTES.TRANSFER_HOLIDAY,
  ATTRIBUTES.TRANSFER_ZERO_OUT_VOICE_FILE, ATTRIBUTES.USER_INPUT_MIN_DIGITS, ATTRIBUTES.USER_INPUT_READBACK_PATTERN,
  ATTRIBUTES.USER_INPUT_OPTION_RETRY_COUNT, ATTRIBUTES.USER_INPUT_OPTION_INPUT_VAR, ATTRIBUTES.USER_INPUT_READBACK_INPUT_DATA_TYPE,
  ATTRIBUTES.USER_INPUT_OPTION_INPUT_PATTERN, ATTRIBUTES.USER_INPUT_OPTION_PROMT_LAST_DIGITS_COUNT,
  ATTRIBUTES.USER_INPUT_OPTION_INPUT_SYMBOL_VOICE_FILE, ATTRIBUTES.USER_INPUT_OPTION_INPUT_PREFIX_SYMBOL,
  ATTRIBUTES.USER_INPUT_OPTION_INPUT_SEPARATOR, ATTRIBUTES.USER_INPUT_OPTION_TIMEOUT, ATTRIBUTES.END_CALL_ON_INVALID_OPTION_INPUT_AFTER_RETRIES,
  ATTRIBUTES.USER_INPUT_MAX_DIGITS, ATTRIBUTES.USER_INPUT_PRECISION, ATTRIBUTES.SKIP_SINGLE_OPTION_CONFIGURED,
  ATTRIBUTES.USER_INPUT_OPTION_INPUT_ACCOUNT_FORMAT, ATTRIBUTES.USER_INPUT_SPEECH_INPUT_GRAMMAR,
  ATTRIBUTES.ALLOW_INPUT_DURING_PROMPT, ATTRIBUTES.DYNAMIC_OPTION_VOICE_FILES_FOR_OPTIONS, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE, 
  ATTRIBUTES.USER_INPUT_REQUIRE_ACCOUNT_FORMAT, ATTRIBUTES.USER_INPUT_REQUIRE_PATTERNS, ATTRIBUTES.USER_INPUT_REQUIRE_SEPARATOR,
  ATTRIBUTES.USER_INPUT_REQUIRE_PREFIX, ATTRIBUTES.SERVICE.RESPONSE_VARIABLES, ATTRIBUTES.SERVICE_IMPLEMENTATION_METHOD,
  ATTRIBUTES.SERVICE_IMPL_EXPRESSION, ATTRIBUTES.VOICE_RECORD_AUDIO_FORMAT, ATTRIBUTES.VOICE_RECORD_VOICE_FILE, ATTRIBUTES.VOICE_RECORD_VOICE_FILE_PATH,
  ATTRIBUTES.VOICE_RECORD_TIMEOUT_IN_SEC, ATTRIBUTES.IS_PAYMENT_AUTH_RECORDING, ATTRIBUTES.GATEWAY_CONDITION,
  ATTRIBUTES.USER_INPUT_REGEX_VALIDATION, ATTRIBUTES.USER_INPUT_IS_REGEX_VALIDATION, ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES,
  ATTRIBUTES.ALLOW_INPUT_DURING_READBACK, ATTRIBUTES.USER_INPUT_MAX_VALUE, ATTRIBUTES.USER_INPUT_MIN_VALUE,
  ATTRIBUTES.SUBMIT_INPUT_WITH_HASH_KEY_ENABLED, ATTRIBUTES.USER_INPUT_ALLOW_EMPTY_VALUES_AS_INPUT, ATTRIBUTES.CONFIRM_INPUT_VOICE_FILE,
  ATTRIBUTES.VOICE_RECORD_START_AUDIO_FORMAT, ATTRIBUTES.IS_PAYMENT_AUTH_VOICE_RECORD_START,
  ATTRIBUTES.USER_OPTION_INPUT_TIMEOUT
];

// Color specifying highlights on bpmn elements
export const SHAPE_HIGHLIGHT_COLOR = {
  // stroke color specifying that attribute of element is changed
  ELEMENT_PROPERTY_CHANGE_STROKE: "orange",
  // background color of the element for which right panel is opened
  ACTIVE_ELEMENT_BACKGROUND: "rgba(15, 138, 227, 0.2)",
  // border color of the element for which right panel is opened
  ACTIVE_ELEMENT_BORDER: "rgb(15, 138, 227)",
  // border color of a bpmn element
  ELEMENT_BORDER: "rgb(221, 221, 221)",
  // background color of a bpmn element
  ELEMENT_BACKGROUND: "#fff"
};

// bpmn-js has default 100 and 80. as we have custom shape with different height/width
// to change the default in customer renderer and other places
// when element connection has too much length then avoid re conencting it 
// as new connection line will overlap other elements (CONNECTION_X_DISTANCE)
export const SHAPE = {
  "SHAPE_WIDTH": 270, "SHAPE_HEIGHT": 120, "DISTANCE": 100, "ALIEN_OBJECT_VERTICALLY": 24, "CONNECTION_X_DISTANCE": 800
}

// We have different formats for displaying history messages.
// Following attributes list that will show the default format of change history message
export const ATTRIBUTES_FOR_DEAFULT_CHANGE_HISTORY_MESSAGE = [
  ATTRIBUTES.USER_OPTION_RETRY_COUNT, ATTRIBUTES.USER_OPTION_VAR, ATTRIBUTES.ALLOW_INPUT_DURING_PROMPT,
  ATTRIBUTES.DYNAMIC_OPTION_RETRY_COUNT, ATTRIBUTES.DYNAMIC_OPTION_VAR, ATTRIBUTES.DYNAMIC_OPTION_COLLECTION,
  ATTRIBUTES.DYNAMIC_OPTION_IS_CUSTOMIZE_VOICE_FILES, ATTRIBUTES.DYNAMIC_OPTION_MAPPING_FIELD,
  ATTRIBUTES.XML_LOOPTASK_COLLECTION, ATTRIBUTES.SAY_DATA_TASK, ATTRIBUTES.SAY_DATA_TYPE, ATTRIBUTES.USER_INPUT_MIN_DIGITS,
  ATTRIBUTES.USER_INPUT_MAX_DIGITS, ATTRIBUTES.USER_INPUT_READBACK_INPUT_DATA_TYPE, ATTRIBUTES.USER_INPUT_OPTION_INPUT_VAR,
  ATTRIBUTES.USER_INPUT_OPTION_RETRY_COUNT, ATTRIBUTES.USER_INPUT_OPTION_TIMEOUT, ATTRIBUTES.USER_INPUT_OPTION_PROMT_LAST_DIGITS_COUNT,
  ATTRIBUTES.USER_INPUT_OPTION_INPUT_ACCOUNT_FORMAT, ATTRIBUTES.DYNAMIC_OPTION_VOICE_FILES_FOR_OPTIONS,
  ATTRIBUTES.USER_INPUT_REQUIRE_ACCOUNT_FORMAT, ATTRIBUTES.USER_INPUT_REQUIRE_PREFIX, ATTRIBUTES.USER_INPUT_READBACK_PATTERN,
  ATTRIBUTES.USER_INPUT_REQUIRE_SEPARATOR, ATTRIBUTES.USER_INPUT_OPTION_INPUT_SEPARATOR, ATTRIBUTES.SKIP_SINGLE_OPTION_CONFIGURED,
  ATTRIBUTES.USER_INPUT_OPTION_INPUT_PATTERN, ATTRIBUTES.USER_INPUT_REQUIRE_PATTERNS, ATTRIBUTES.USER_INPUT_IS_REGEX_VALIDATION,
  ATTRIBUTES.USER_INPUT_OPTION_INPUT_PREFIX_SYMBOL, ATTRIBUTES.USER_INPUT_PRECISION, ATTRIBUTES.USER_INPUT_REGEX_VALIDATION,
  ATTRIBUTES.TRANSFER_NUMBER, ATTRIBUTES.TRANSFER_END_TIME, ATTRIBUTES.TRANSFER_START_TIME, ATTRIBUTES.TRANSFER_ENDTIME0,
  ATTRIBUTES.TRANSFER_ENDTIME1, ATTRIBUTES.TRANSFER_ENDTIME2, ATTRIBUTES.TRANSFER_ENDTIME3, ATTRIBUTES.TRANSFER_ENDTIME4,
  ATTRIBUTES.TRANSFER_ENDTIME5, ATTRIBUTES.TRANSFER_ENDTIME6, ATTRIBUTES.TRANSFER_STARTTIME0, ATTRIBUTES.TRANSFER_STARTTIME1,
  ATTRIBUTES.TRANSFER_STARTTIME2, ATTRIBUTES.TRANSFER_STARTTIME3, ATTRIBUTES.TRANSFER_STARTTIME4, ATTRIBUTES.TRANSFER_STARTTIME5,
  ATTRIBUTES.TRANSFER_STARTTIME6, ATTRIBUTES.TRANSFER_HOLIDAY, ATTRIBUTES.TRANSFER_ZERO_OUT_VOICE_FILE,
  ATTRIBUTES.TRANSFER_OUTSIDE_BUSINESS_HOUR_VOICE_FILE, ATTRIBUTES.END_CALL_ON_INVALID_OPTION_INPUT_AFTER_RETRIES,
  ATTRIBUTES.KEY_VALUE_OPTION_RETRY_COUNT, ATTRIBUTES.KEY_VALUE_OPTION_VAR, ATTRIBUTES.USER_OPTION_SPEECH_INPUT_GRAMMAR,
  ATTRIBUTES.USER_INPUT_SPEECH_INPUT_GRAMMAR, ATTRIBUTES.SERVICE_IMPL_EXPRESSION, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE, 
  ATTRIBUTES.SERVICE.RESPONSE_VARIABLES, ATTRIBUTES.SERVICE_IMPLEMENTATION_METHOD,
  ATTRIBUTES.VOICE_RECORD_AUDIO_FORMAT, ATTRIBUTES.VOICE_RECORD_TIMEOUT_IN_SEC, ATTRIBUTES.END_CALL_ON_INVALID_USER_INPUT_AFTER_RETRIES,
  ATTRIBUTES.VOICE_RECORD_VOICE_FILE, ATTRIBUTES.VOICE_RECORD_VOICE_FILE_PATH, ATTRIBUTES.IS_PAYMENT_AUTH_RECORDING,
  ATTRIBUTES.ALLOW_INPUT_DURING_READBACK, ATTRIBUTES.USER_INPUT_MAX_VALUE, ATTRIBUTES.USER_INPUT_MIN_VALUE,
  ATTRIBUTES.SUBMIT_INPUT_WITH_HASH_KEY_ENABLED, ATTRIBUTES.USER_INPUT_ALLOW_EMPTY_VALUES_AS_INPUT, ATTRIBUTES.CONFIRM_INPUT_VOICE_FILE,
  ATTRIBUTES.VOICE_RECORD_START_AUDIO_FORMAT, ATTRIBUTES.IS_PAYMENT_AUTH_VOICE_RECORD_START,
  ATTRIBUTES.USER_OPTION_INPUT_TIMEOUT
];

// default max number of flow versions to be displayed for client  
export const DEFAULT_NUM_OF_VERSIONS_TO_DISPLAY = 15;
// default retry count
export const DEFAULT_RETRY_COUNT = 3;
// default user option input timeout (3000 ms)
export const DEFAULT_USER_OPTION_INPUT_TIMEOUT = 3000;
// task types in the diagram that can not have info icon
export const TASK_TYPES_WITHOUT_INFO_ICON = [
  EL_TYPE.SUB_PROCESS, EL_TYPE.END_EVENT, EL_TYPE.START_EVENT, EL_TYPE.SEQUENCE_FLOW, EL_TYPE.INTERMEDIATE_THROW_EVENT_TASK
];
// dafault voice file path
export const DEFAULT_VOICE_FILE_PATH = "/var/lib/asterisk/sounds/voice-files/";
// count for number of items to be displayed at a time (pagination for list of items)
export const PAGINATION_COUNT = 10;
// default voice file for invalid user input in input component
export const DEFAULT_USER_INPUT_INVALID_VOICE_FILE = "sorry-we-did-not-get-valid-inp";

// general configurations
export const APP_CONFIG = {
  MESSAGE_TIMEOUT: 3000,   // default timeout for some operation, like displaying message on screen
  DEFAULT_LANGUAGE_SUPPORTED: "en"   // default language in case no language is selected on wizard for example in custom flow
};

// Regex pattern for the different fields
export const REGEX = {
  // regex for user, it can be name of the user or his email
  // Example: "abcd", "abcd efgh", "abc@abc.com" 
  USER: /^[a-zA-Z0-9\s@._-]*$/,
  // Example: abc@abc.com
  EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  // at least one uppercase letter, at least one lowercase letter, at least one number, at least one special character
  // Example: Testing123% is valid, but testing123 is not valid
  PASSWORD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\[\]{}|;:',.<>?`~\\\/=-])[a-zA-Z\d!@#$%^&*()_+\[\]{}|;:',.<>?`~\\\/=-]{8,12}$/,
  // Example: "app-code", "appCode", "app_code", "user@gmail.com"
  API_KEY_SEARCH: /^[a-zA-Z0-9\s@._-]*$/,
  // Example: hello, welcome, testing. It does not includes & , < > { },[] , + , - , _ as a character
  // TTS will throw error on these characters , these are not allowed in aws polly
  TTS_TEXT: /^[^&<>:;{}\[\]+\-_*]*$/,
  // Example: https://www.youtube.com/
  URL: /^(https?:\/\/)?(([\da-z.-]+)(:\d+)?|localhost(:\d+)?)(\/[^\s#]*)?(\/?\?.*)?(#\/?[^\s]*)?$/,
  // Example: 31-06-2002 23:59 (DD-MM-2024 HH:MM)
  DATE_TIME: /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/
};
