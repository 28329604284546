import store from "../redux/store/store";
import { clearClientState } from "../redux/actions/client.action";
import { clearInteractiveDesignState, } from "../redux/actions/interactive-design.action";
import { clearVoiceFileState } from "../redux/actions/voice-file.action";
import { clearConfigState } from "../redux/actions/config.action";
import { clearUiState } from "../redux/actions/ui-state.action";

import UserService from "../services/user.service";

/**
 * Store the values in local storage and access them or remove them.
 */
class StorageUtil {
    // Array for storage values being used across app
    static STORAGE = {
        /**
         * basic flow info includes 
         * {businessCode // name of the businessCode,
         * flowType // status type of flow (publish or draft),
         * flowTypeId  // STANDARD_IVR | AAIVR | CUSTOM,
         * docVersionId // flow version id,
         * flowName // clients currently open flow name}
         */
        BASIC_FLOW_INFO: "basicFlowInfo",
        /**
         * remember me info
         * {rememberMe // boolean value to remember or not
         *  email // to be displayed in email section if remember me is true}
         */
        REMEMBER_ME_INFO: "rememberMeInfo",
        /**
         * Current User Object in local storage
         * {email, firstName,  lastName, roleId, status, token}
         */
        CURRENT_USER: "currentUser",
        // last url opened
        LAST_URL: "lastUrl"
    }

    /**
     * Get storage value as per key
     * @param {string} key 
     * @returns {(Object | string | Array)} Returns value based on key
     */
    static getStorageValue(key) {
        return localStorage.getItem(key);
    }

    /**
     * Add the storage value
     * @param {string} key 
     * @param {string} value 
     */
    static setStorageValue(key, value) {
        localStorage.setItem(key, value);
    }

    /**
     * Removing the storage value corressponding to the keys array
     * @param {Array} keys Array of values to be removed from storage
     */
    static removeStorageValues(keys) {
        for (let key of keys) {
            localStorage.removeItem(key);
        }
    }

    /**
     * clearing the storage
     */
    static clearStorage() {
        StorageUtil.removeStorageValues([
            StorageUtil.STORAGE.BASIC_FLOW_INFO,
            StorageUtil.STORAGE.CURRENT_USER
        ]);

        // clearing user from redux
        UserService.clearCurrentUser();
        // clear the client state from redux
        store.dispatch(clearClientState());
        // clear the voice file map files and other from state
        store.dispatch(clearVoiceFileState());
        // clear the interactive design state like chatFlowId
        store.dispatch(clearInteractiveDesignState());
        // clear the values for ui state from redux
        store.dispatch(clearUiState());
        // clear the configs state like sysVariables from redux
        store.dispatch(clearConfigState());
    }
}

export default StorageUtil;