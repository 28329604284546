import { useRef } from 'react';
import PropTypes from 'prop-types';
import { MESSAGES } from "../../../constants/messages";
import AppUtil from '../../../util/app.util';
import PmivrSnackbar from "../../../components/common/dialog/pmivr-snackbar";

/**
 * Modal for regenerating new keys
 * @param {Object} props Properties from parent component
 * @returns {React.Component} Html element to render
 */
const RegenerateKey = (props) => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();
        
    /**
     * Copy the API key to clipboard
     */
    const copyApiKeyToClipboard = async () => {
        await AppUtil.copyToClipboard(props.apiKeyInfo.apiKey);
        // opening the snackbar
        snackbarRef.current.open(MESSAGES.COPIED_TO_CLIPBOARD);
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />
            <div className="pmivr-card card-secondary pmivr-number-input">
                <div className="pmivr-container">
                    <div className="wrapper p-3 pt-0">
                        <div className="mb-2 remove-arrows">
                            <div className="pmivr-label">
                                <label className="pt-3">Email </label>
                            </div>
                            <input type="text" name="email" id="email" value={props.apiKeyInfo.email}
                                placeholder="Enter Email" className="form-control pmivr-input"
                                disabled={true} />

                            <div className="pmivr-label">
                                <label className="pt-3">App Code </label>
                            </div>
                            <input type="text" name="appCode" id="appCode" value={props.apiKeyInfo.appCode}
                                placeholder="Enter App code" className="form-control pmivr-input"
                                disabled={true} />

                            <div className="pmivr-label">
                                <label className="pt-3">New API Key: </label>
                            </div>
                            <div className="input-group">
                                <input type="text" className="form-control pmivr-input mb-3"
                                    name="apiKey" autoFocus required disabled
                                    value={props.apiKeyInfo.apiKey}></input>
                                <div className="input-group-append">
                                    <button className="pmivr-btn-secondary" type="button" onClick={copyApiKeyToClipboard} style={{ height: "50px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }} >Copy </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

RegenerateKey.propTypes = {
    // closing the user dialogue
    closeAction: PropTypes.func,
    // having email and newly generated key to show for copy to clipboard purpose
    apiKeyInfo: PropTypes.object
}

export default RegenerateKey;