import { useState, useImperativeHandle, forwardRef } from 'react';

import { Formik, Form, ErrorMessage } from "formik";
import { string, object } from 'yup';
import PropTypes from 'prop-types';

import { MESSAGES } from "../../../constants/messages";

import { PmivrDialog } from "../../../components/common/dialog/pmivr-dialog";

import ChangeHistoryService from '../../../services/change-history.service';

/**
 * Dialog box to save changes done in flow (draft or publish doc)
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html code to render dialog
 */
const SaveChangesDialog = forwardRef((props, ref) => {
    const { closeAction, changeHistory, saveDialogChanges } = props;

    /** variable to show and hide the save changes dialog
     * showDialog {boolean} - whether to show or hide dialog box
     * title {string} - title of the dialog box
     * version {string} - document version: draft / published
     */
    const [dialogProps, setDialogProps] = useState({ showDialog: false, title: '', version: '' });

    // exposing functions to parent component
    useImperativeHandle(ref, () => ({
        /**
         * Opens the dialog box
         * @param {string} title - title of dialog box
         * @param {string} version - draft/published
         */
        open(title, version) {
            setDialogProps((dialogProps) => ({ ...dialogProps, showDialog: true, title: title, version: version }));
        },
        close: closeDialog
    }));

    // validating the formik fields
    const validate = object({
        name: string().trim().required(MESSAGES.ERR.FIELD_REQUIRED),
    });

    // function to close the upload file dialog box
    const closeDialog = () => {
        setDialogProps((dialogProps) => ({ ...dialogProps, showDialog: false }));
    }

    return (
        <PmivrDialog showDialog={dialogProps.showDialog} closeDialog={closeAction}
            title={dialogProps.title}
            cssClass={`${changeHistory?.length ? 'diagram-confirm-modal' : ''}`}
            message={
                <>
                    {changeHistory?.length ?
                        <>
                            <h6>Change History</h6>
                            {
                                changeHistory?.map((history, index) => {
                                    return (
                                        <div key={index} className="change-logs">
                                            {ChangeHistoryService.getChangeHistoryMessage(history)}
                                        </div>
                                    )
                                })
                            }
                        </>
                        :
                        <div className="text-center">No Changes</div>
                    }
                </>
            }
            footer={
                <div className="row col-sm-12">
                    <Formik initialValues={{ name: '', comments: '' }} validationSchema={validate} onSubmit={saveDialogChanges}>
                        {({ setFieldValue, values, errors, setErrors }) => (
                            <Form>
                                <div className="pmivr-label pmivr-required-label">
                                    <label className="pt-3">Name</label>
                                </div>
                                <input id="name" name="name" className="form-group form-control pmivr-input"
                                    value={values.name} placeholder={`Enter name for the ${dialogProps.version} doc`}
                                    onChange={(event) => { setFieldValue("name", event.target.value); }} />
                                <ErrorMessage name="name" component="div" className="field-error error pt-2" />

                                <div className="pmivr-label">
                                    <label className="pt-3">Comments</label>
                                </div>
                                <textarea id="comments" name="comments" className="form-group form-control pmivr-input doc-comments" rows="3"
                                    value={values.comments} placeholder={`Please enter comments for saving the flow`}
                                    onChange={(event) => { setFieldValue("comments", event.target.value); }} />

                                <div className="float-end mt-2">
                                    <button className="pmivr-btn-app" type="submit">
                                        {dialogProps.version === 'draft' ? 'Save Draft' : 'Publish'}
                                    </button>
                                </div>
                                <div className="mx-2 float-end mt-2">
                                    <button className="pmivr-btn-secondary" type="button" onClick={closeDialog}>
                                        Cancel
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            }
        />
    )
});

// Types of props passed in component
SaveChangesDialog.propTypes = {
    // function to close the dialog box
    closeAction: PropTypes.func,
    // saving the dialog box changes
    saveDialogChanges: PropTypes.func,
    // Changes done in the flow diagram
    changeHistory: PropTypes.array
}

export default SaveChangesDialog;