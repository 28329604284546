/**
 * The palette coming on the left side on diagram page on page load
 */
export default class CustomPaletteProvider {
  constructor(palette) {
    palette.registerProvider(this);
  }

  /**
   * The palette entries (having different bpmn events) for building flows.
   * We iterate these entries while rendering the left tool panel.
   * @param {Object} element 
   * @returns list of entries representing different bpmn events
   */
  getPaletteEntries(element) {
    return function (entries) {
      return {
        "create-transfer-task": entries["create-transfer-task"],
        "create.exclusive-gateway": entries["create.exclusive-gateway"],
        "create.end-event": entries["create.end-event"],
        "create-hangup-task": entries["create-hangup-task"],
        "create.intermediate-event": entries["create.intermediate-event"],
        "create-option-user-task": entries["create-option-user-task"],
        "create-say-data-task": entries["create-say-data-task"],
        "create-voice-record-task": entries["create-voice-record-task"],
        "create-voice-record-start-task": entries["create-voice-record-start-task"],
        "create-voice-record-stop-task": entries["create-voice-record-stop-task"],
        "create-service-impl-task": entries["create-service-impl-task"],
        "create.start-event": entries["create.start-event"],
        "create.subprocess-expanded": entries["create.subprocess-expanded"],
        "create-user-input-task": entries["create-user-input-task"],
        "create-play-voice-file-task": entries["create-play-voice-file-task"],
      };
    };
  }
}

CustomPaletteProvider.$inject = ["palette"];