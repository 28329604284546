import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import PropTypes from "prop-types";

import { TOOLTIP } from "../../../constants/messages";
import { ATTRIBUTES } from "../../../constants/attributes";
import { CSS_CLASSES } from "../../../constants/css-classes";

import PmivrLabel from "../../common/label/pmivr-label";
import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import VoicePromptSelection from "../../common/voice-prompt-selection/VoicePromptSelection";

import ElementService from "../../../services/element.service";

/**
 * Option and Voice File Mapping View in case of "List Element As User Option"
 * MAPPING_KEY: mapping through index of list or mapping through some field of element of list
 * MappingField: name of field through which voice file is mapped to the user option
 *  
 * @param {Object} props properties from parent component
 * @returns {React.Component} Html element to render 
 */
const DynamicOptionVoiceFileMapping = (props) => {

    const {
        element, optionAndVoiceFile, keyVoiceFileOptionMapping, handleInputChange,
        optionIdInputRefs, handleFocus, supportedLanguages, handleDelete, handleAdd
    } = props;

    // mapping keys, for mapping voice files with options
    const MAPPING_KEY = {
        FIELD: "field",
        INDEX: "index"
    };

    /**
     * in customize voice files, user will map the voice file to user option using this 
     * mapping key (by index, by mapping field)
     */
    const [mappingBy, setMappingBy] = useState(MAPPING_KEY.INDEX);
    const [activeAccordionKey, setActiveAccordionKey] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState();

    useEffect(() => {
        let mappedBy = ElementService.getAttribute(element, ATTRIBUTES.DYNAMIC_OPTION_MAPPING_BY);
        if (!mappedBy) {
            // if mapping_by is not set, then update as "index" for the first time
            ElementService.updateElementAttr(element, ATTRIBUTES.DYNAMIC_OPTION_MAPPING_BY, MAPPING_KEY.INDEX);
            mappedBy = MAPPING_KEY.INDEX;
        }
        // updating the mappingBy in state
        setMappingBy(mappedBy);
        setSelectedLanguage(supportedLanguages?.includes("en") ? "en" : supportedLanguages[0]);
    }, []);

    /**
     * Handling the event of selecting the mapping key type radio button.
     * Updating the state and the element
     * @param {string} value value on selecting the type of mapping key 
     */
    const handleMappingBySelection = (value) => {
        setMappingBy(value);
        ElementService.updateElementAttr(
            element, ATTRIBUTES.DYNAMIC_OPTION_MAPPING_BY, value
        );
    }

    /**
     * updates the prompt list and also updates the element with the updated list
     * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
     * @param {string} language selected language by the user to set the voice file
     * @param {number} index
     */
    const handleUpdatePromptList = (updatedPromptsList, language, index) => {
        handleInputChange(index, `voiceFile_${language}`, updatedPromptsList);
    }

    return (
        <>
            <div className="form-group custom-input">
                <div className="row mt-2 mb-3">
                    <div className="col-md-5 m-3 mt-2 mb-3 form-check pmivr-check-radio">
                        <input className="form-check-input" id="mappingUsingIndex"
                            type="radio" value="mappingUsingIndex" name="mappingUsingIndex"
                            checked={mappingBy === MAPPING_KEY.INDEX}
                            onChange={() => handleMappingBySelection(MAPPING_KEY.INDEX)} />
                        <label className="form-check-label" htmlFor="mappingUsingIndex">
                            Mapping by index
                        </label>
                    </div>
                    <div className="col-md-5 m-2 mt-2 mb-3 form-check pmivr-check-radio">
                        <input className="form-check-input" id="mappingUsingField"
                            type="radio" value="mappingUsingField" name="mappingUsingField"
                            checked={mappingBy === MAPPING_KEY.FIELD}
                            onChange={() => handleMappingBySelection(MAPPING_KEY.FIELD)} />
                        <label className="form-check-label" htmlFor="mappingUsingField">
                            Mapping by field
                        </label>
                    </div>
                </div>
                <div className={
                    (mappingBy === MAPPING_KEY.INDEX)
                        ? CSS_CLASSES.HIDE_DISPLAY
                        : `${CSS_CLASSES.BLOCK_DISPLAY} mt-2 mb-2`
                }>
                    <PmivrLabel label="Mapping Field" tooltip={TOOLTIP.INFO.DYNAMIC_OPTION_MAPPING_FIELD} />
                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.DYNAMIC_OPTION_MAPPING_FIELD}>
                        <input id="mappingField" name="mappingField" className="form-control pmivr-input" placeholder="Enter mapping field"
                            value={
                                ElementService.getAttribute(
                                    element, ATTRIBUTES.DYNAMIC_OPTION_MAPPING_FIELD, ""
                                )
                            }
                            onChange={(e) => {
                                ElementService.updateElementAttr(
                                    element, ATTRIBUTES.DYNAMIC_OPTION_MAPPING_FIELD, e.target.value
                                );
                            }} />
                    </PmivrOverlayTrigger>
                </div>
                {(optionAndVoiceFile[keyVoiceFileOptionMapping] || [])?.map((item, index) => {
                    return (
                        <div className="row mb-2" key={index}>
                            <div className="col-sm-10 mt-3">
                                <label className="pb-1">{mappingBy === MAPPING_KEY.INDEX ? 'Index Value' : 'Mapped Value'}</label>
                                <div className="props-custom-input pt-0">
                                    <input className="form-control pmivr-input" id={`file_value_${index}`} value={item.mappingFieldValue}
                                        type="text"
                                        placeholder={mappingBy === MAPPING_KEY.INDEX ? 'Enter Index Value' : 'Enter Mapped Value'}
                                        onChange={(e) => handleInputChange(index, 'mappingFieldValue', e.target.value)}
                                        ref={(el) => (optionIdInputRefs.current[index] = { ...optionIdInputRefs.current[index], name: el })}
                                        onFocus={() => handleFocus(index)} />
                                </div>
                            </div>
                            <div className="col-sm-2 pt-2 mt-2"
                                onClick={() => handleDelete(index)}>
                                <button className="pmivr-btn-secondary mt-4">
                                    <i className="bi bi-trash" style={{ fontSize: "20px" }} id={`file_option_remove_${index}`}></i>
                                </button>
                            </div>
                            <Accordion activeKey={activeAccordionKey} className="pmivr-accordion mb-2"
                                onSelect={(selectedKey) => {
                                    setActiveAccordionKey(selectedKey);
                                    setSelectedLanguage(supportedLanguages?.includes("en") ? "en" : supportedLanguages[0]);
                                }} flush>
                                <Accordion.Item eventKey={index} className="accordion-voice-item">
                                    <Accordion.Header>
                                        <span className="pmivr-accordian-tab">
                                            Configure Voice Files
                                        </span>
                                    </Accordion.Header>
                                    <Accordion.Body className="p-2 pt-0">
                                        <div className="mt-2">
                                            <div>
                                                <ul className="nav nav-tabs text-center" role="tablist">
                                                    {supportedLanguages.map((language) => {
                                                        return (
                                                            <li className="nav-item  px-2" role="presentation">
                                                                <button className={selectedLanguage === language ? 'nav-link active ' : 'nav-link'}
                                                                    data-bs-target="#language-options" type="button" role="tab" title={language}
                                                                    aria-controls="language-options" onClick={(event) => {
                                                                        setSelectedLanguage(language);
                                                                    }} >
                                                                    {language}
                                                                </button>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                            <div className="mt-3" id="language-options">
                                                <div className="tab-content " id="pills-tabContent">
                                                    <div className={'tab-pane fade show active'} id="file-path" role="tabpanel" aria-labelledby="ttsFile-tab">
                                                        <VoicePromptSelection selectedLanguage={selectedLanguage}
                                                            element={element} promptsList={item[`voiceFile_${selectedLanguage}`] || []}
                                                            onChange={(updatedPromptsList) => handleUpdatePromptList(updatedPromptsList, selectedLanguage, index)} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <hr />
                        </div>
                    );
                })}
            </div>
            <div className="text-center mt-3">
                <button className="pmivr-add-option" onClick={handleAdd}>
                    Add Voice File Mapping<i className="ms-1">+</i>
                </button>
            </div>
        </>
    );
}

DynamicOptionVoiceFileMapping.propTypes = {
    keyVoiceFileOptionMapping: PropTypes.string,
    optionIdInputRefs: PropTypes.array,
    supportedLanguages: PropTypes.array,
    element: PropTypes.object,
    optionAndVoiceFile: PropTypes.object,
    handleDelete: PropTypes.func,
    handleAdd: PropTypes.func,
    handleInputChange: PropTypes.func,
    handleFocus: PropTypes.func,
};

export default DynamicOptionVoiceFileMapping;