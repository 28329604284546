import { APP_PAGES } from "../../../constants/app-pages";
import { TOOLTIP } from "../../../constants/messages";
const LEFT_BAR_OPTIONS = [
  {
    "linkTo": APP_PAGES.DIAGRAM,
    "title": TOOLTIP.NAVIGATION_LINKS.DIAGRAM,
    "iconClass": "bi bi-diagram-3-fill",
    "id": APP_PAGES.DIAGRAM
  },
  {
    "linkTo": APP_PAGES.LIST_VOICE_FILE,
    "title": TOOLTIP.NAVIGATION_LINKS.AUDIO_FILES,
    "iconClass": "bi bi-file-earmark-play",
    "id": APP_PAGES.LIST_VOICE_FILE
  },
  {
    "title": TOOLTIP.NAVIGATION_LINKS.CHATBOT,
    "iconClass": "bi bi-chat-text",
    "id": APP_PAGES.CHATBOT
  },
  {
    "linkTo": APP_PAGES.VARIABLES,
    "title": TOOLTIP.NAVIGATION_LINKS.VARIABLES,
    "iconClass": "bi bi-at",
    "id": APP_PAGES.VARIABLES
  },
  {
    "linkTo": APP_PAGES.WIZARD,
    "title": TOOLTIP.NAVIGATION_LINKS.WIZARD,
    "iconClass": "bi bi-sliders",
    "id": APP_PAGES.WIZARD
  }
];

export default LEFT_BAR_OPTIONS;