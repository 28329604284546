import { TOUR_GUIDE_STEPS } from '../constants/tour-guide-steps';

import UrlUtil from './url.util';

class TourGuideUtil {
  /**
   * Extracts the tour guide steps based on the provided URL path.
   * @param {string} path The current URL path.
   * @returns {Array} An array containing the steps
   */
  static getTourGuideSteps(path) {
    const tourPath = UrlUtil.extractPathFromURL(path);
    // Find the element in TOUR_GUIDE_STEPS array where path matches
    const tourGuide = TOUR_GUIDE_STEPS.find((step) => step.path === tourPath);
    // If tourGuide is found, return its steps;
    return tourGuide ? tourGuide.steps : [];
  }
}

export default TourGuideUtil;
