import { useState, useImperativeHandle, forwardRef } from 'react';

import PropTypes from 'prop-types';

import { PmivrDialog } from "../../../components/common/dialog/pmivr-dialog";

import MissingVoiceFileWarning from './missing-voice-file-warning';
import ServiceExpressionWarning from './service-expression-warning';

/**
 * Dialog box to show flow warnings
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html code to render dialog for flow warnings
 */
const FlowWarningDialog = forwardRef((props, ref) => {
    const { onClose, onConfirm } = props;

    /** variable to show and hide the dialog for showing information while publishing the flow
     * showDialog {boolean} - whether to show or hide dialog box
     */
    const [dialogProps, setDialogProps] = useState({ showDialog: false, status: "", title: "" });

    // exposing functions to parent component
    useImperativeHandle(ref, () => ({
        open(title, status) {
            setDialogProps((dialogProps) => ({ ...dialogProps, showDialog: true, status, title }));
        },
        close: closeDialog
    }));

    // function to close the upload file dialog box
    const closeDialog = () => {
        setDialogProps((dialogProps) => ({ ...dialogProps, showDialog: false }));
    }

    return (
        <PmivrDialog showDialog={dialogProps.showDialog} closeDialog={onClose}
            title={dialogProps.title}
            cssClass={`diagram-confirm-modal`}
            message={
                <>
                    <MissingVoiceFileWarning />
                    <ServiceExpressionWarning />
                </>
            }
            footer={
                <div className="d-flex justify-content-end">
                    <button className="pmivr-btn-secondary me-2 py-2" type="button" onClick={closeDialog}>
                        Cancel
                    </button>
                    <button className="pmivr-btn-app ms-1 py-2" onClick={() => onConfirm(dialogProps.status)}>
                        Continue
                    </button>
                </div>
            }
        />
    )
});

// Types of props passed in component
FlowWarningDialog.propTypes = {
    // function to close the dialog box
    onClose: PropTypes.func,
    // confirming dialog box for mising voice files
    onConfirm: PropTypes.func
}

export default FlowWarningDialog;