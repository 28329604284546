import store from "../redux/store/store";

import { APP_PAGES } from "../constants/app-pages";
import { MESSAGES } from "../constants/messages";
import { envConfig } from "../environment";

import appAxios from "../util/app.axios";
import AppUtil from "../util/app.util";
import StorageUtil from "../util/storage.util";

import { updateSessionCheckInfo } from "../redux/actions/user.action";

import UserService from "./user.service";

/**
 * Service class for user authentication and authorization
 */
class AuthService {

    static BASE_URL = `${envConfig.REACT_APP_API_BASE_URL}/auth`;

    /**
     * Logins the user
     * @param {string} email email of the user to login
     * @param {string} password password with which user logins
     * @param {boolean} updateSession  to check if we are simply login or having session check 
     * @returns response from the login api
     */
    static login(email, password, updateSession = false) {
        const creds = { email, password, updateSession };
        const url = `${this.BASE_URL}/login`;
        return appAxios.post(url, creds).then((res) => {
            const user = res.data;
            UserService.setCurrentUser(user);
            AuthService.setSessionCheckInfo({ isSessionCheckInitiated: true });
            return user;
        });
    }

    /**
     * Logout the user and redirect to login page
     */
    static logout() {
        // clear the storage
        StorageUtil.clearStorage();
        alert(MESSAGES.INVALID_CREDENTIALS);
        // redirect to login page
        AppUtil.navigateTo(APP_PAGES.LOGIN);
    }

    /**
     * Validating the session for the user
     * Set the current user returned from server
     * @returns response from the validation api
     */
    static validateSession() {
        const url = `${this.BASE_URL}/validateToken`;
        return appAxios.get(url).then((res) => {
            const user = res.data;
            UserService.setCurrentUser(user);
            return user;
        })
    };

    /** Get all the api keys from the DB as per filter object.
     *  Filter Object : {
     *  {Integer}  count Count of the api keys to be fetched at a time, 
     *  {Integer} pageNo Page to be displayed, 
     *  {String} searchText text to be searched 
     * }
     * @param {{ count, pageNo, searchText }} filter  Filter 
     * @returns {Promise<{data : Array<Keys}>} response from the /numbers get api
     */
    static async getApiKeys(filter) {
        const { searchText = "", count = 10, pageNo = 1, status = "" } = filter;
        const url = `${this.BASE_URL}/apiKeys?count=${count}&pageNo=${pageNo}&searchText=${searchText}&status=${status}`;
        return appAxios.get(url);
    }

    /**
     * Saves the new api key for the application
     * @param {{email, appCode, apiKeyName, expiryTime}} keyDetails
     * @returns {Promise<{msg: String}>} response from the /auth/apiKeys post api
     */
    static saveApiKey(keyDetails) {
        const url = `${this.BASE_URL}/apiKeys`;
        return appAxios.post(url, keyDetails);
    }

    /**
     * Delete the API key from backend
     * @param {String} email
     * @returns {Promise<{success: boolean,msg: String}>}
     */
    static deleteApiKey(email, appCode) {
        const url = `${this.BASE_URL}/apiKeys`;
        return appAxios.delete(url, { data: { email, appCode } });
    }

    /**
     * Update the API key at backend
     * @param {{ email, appCode, apiKeyValidityDays}} apiKeyConfigs
     * @returns {Promise<{success: boolean, msg: String}>}
     */
    static updateApiKey(apiKeyConfigs) {
        const url = `${this.BASE_URL}/apiKeys`;
        return appAxios.put(url, apiKeyConfigs);
    }

    /**
     * Getting the sessionCheckInfo
     * @returns {{isSessionCheckInitiated,isSessionExpiring}} session check info 
     */
    static getSessionCheckInfo() {
        return store.getState().user.sessionCheckInfo;
    }

    /**
     * Set the session check info
     * @param {{isSessionCheckInitiated,isSessionExpiring}} sessionCheckInfo
     */
    static setSessionCheckInfo(sessionCheckInfo) {
        store.dispatch(updateSessionCheckInfo({ sessionCheckInfo }));
    }
}


export default AuthService;