// available html field types
export const FIELD_TYPES = {
    RADIO : "radio",
    CHECKBOX : "checkbox",
    DROPDOWN : "dropdown",
    TEXT : "text"
}

export const ACCORDION = {
    USER_INPUT_EVENT_KEY: "validation-user-input"
}