import { DEFAULT_NUM_OF_VERSIONS_TO_DISPLAY } from "../config/config";
import { envConfig } from "../environment";

import appAxios from "../util/app.axios";
import StorageUtil from "../util/storage.util";
import AppUtil from "../util/app.util";

import ClientService from "./client.service";

class FlowService {

    static BASE_URL = `${envConfig.REACT_APP_API_BASE_URL}`;
    static TEMPLATE_URL = `${envConfig.REACT_APP_API_BASE_URL}/template`;
    static FLOW_URL = `${envConfig.REACT_APP_API_BASE_URL}/flow`;

    /**
     * Getting the flow version information (user action logs for flow) 
     * based on filters, like businessCode, versionId, flowName, flowTypeId, status, etc
     * @param {Object} filters {flowInfo, limit, status}
     * @returns {Promise<Array>} 
     */
    static async getFlowInfo(filters) {
        // hitting back end api to get the records based on businessCode, status, versionId, flowName, flowTypeId
        // status are comma separated
        const url =
            `${this.FLOW_URL}/flowVersionInfo?businessCode=${filters.flowInfo.businessCode}&count=${filters.count}&pageNo=${filters.pageNo}` +
            `&status=${filters.status}&flowTypeId=${filters.flowInfo.flowTypeId}&flowName=${filters.flowInfo.flowName}` +
            `&chatFlowId=${filters.flowInfo.chatFlowId}&objId=${filters.flowInfo._id || ""}&versionId=${filters.flowInfo.versionId}`;
        return appAxios.get(url);
    }

    /**
     * Get the flow types info
     * @returns {Promise<Array>}
     */
    static async getFlowTypesInfo() {
        const url = `${this.FLOW_URL}/flowTypesInfo`;
        return appAxios.get(url);
    }

    /**
     * Get the list of re usable elements
     * @returns {Promise<Array<{name, children, flows}>>} list of re-usable sub processes
     */
    static async getReUsableElements() {
        const url = `${this.FLOW_URL}/reUsableElements`;
        return appAxios.get(url);
    }

    /**
     * Delete the re usable element based on element id
     * @param {string} elementId
     */
    static async deleteReUsableElements(elementId) {
        const url = `${this.FLOW_URL}/reUsableElements?elementId=${elementId}`;
        return appAxios.delete(url);
    }

    /**
     * Add the re usable element in the list of elements
     * @param {{id,name,children,flows}} newElementInfo
     */
    static async addReUsableElements(newElementInfo) {
        const url = `${this.FLOW_URL}/reUsableElements`;
        return appAxios.post(url, newElementInfo);
    }

    /**
     * From json to bpmn flow xml 
     * Custom flow will be created with draft version
     * @param {FormData} flowInfo Client and flow info
     * @returns {Promise<{versionId}>}
     */
    static interactiveJsonToXml(flowInfo) {
        const url = `${this.FLOW_URL}/integrations`;
        return appAxios.post(url, flowInfo);
    }

    /**
     * Get the list of Chat flows
     * @param {string} businessCode chat flows based on businessCode
     * @returns {Promise<{Array}>}
     */
    static getChatFlows(businessCode) {
        const url = `${this.FLOW_URL}/chatflow?businessCode=${businessCode}`;
        return appAxios.get(url).then((res) => res.data);
    }

    /**
     * Upload the flow onto S3 server and save the info in DB
     * @param {{
     *      businessCode: String, xml: String, versionId: String, status: String, 
     *      dnid: Array, changeHistory: Array, email: String, flowTypeId: String, flowName: String, 
     *      comment: {name: String, description: String}, metaInfo : {voiceFiles : Array<{}>}
     *  }} flowData information related to flow
     * @returns {Promise<Object>}
     */
    static async uploadFlow(flowData) {
        const { businessCode, xml, status, versionId, metaInfo, dnid, flowTypeId, flowName,
            changeHistory, comment, createdBy, chatFlowId } = flowData;

        const flowInfo = {
            status: status, businessCode: businessCode, parentVersionId: versionId, comment,
            isDeleted: false, metaInfo, dnid, flowName, flowTypeId, xml, changeHistory, createdBy,
            chatFlowId
        };

        const url = `${this.FLOW_URL}/flowVersionInfo`;
        return appAxios.post(url, flowInfo);
    }

    /**
     * Getting all the info of templates uploaded for given flow type id
     * @param {String} flowTypeId id of flow type
     * @returns {Promise<Object>}
     */
    static async getTemplatesInfo(flowTypeId) {
        const url = `${this.TEMPLATE_URL}/templatesVersion?flowTypeId=${flowTypeId}&limit=${DEFAULT_NUM_OF_VERSIONS_TO_DISPLAY}`;
        return appAxios.get(url);
    }

    /**
     * Update template info for billers updated if flow migrated manually
     * to avoid showing message for updating latest flow
     * @param {{versionId,businessCode,action}} templateInfo templateInfo for updating billersUpdated in collection
     * @returns {Promise<Object>}
     */
    static async updateTemplateInfo(templateInfo) {
        const url = `${this.TEMPLATE_URL}/templatesVersion`;
        return appAxios.put(url, { templateInfo });
    }

    /**
     * Downloading the base flow for the given flow type and version Id
     * @param {String} versionId versionId of base flow template xml to be downloaded
     * @param {String} flowTypeId base flow of given flow type will be downloaded
     * @returns {Promise<Object>}
     */
    static async dowloadBaseFlow(versionId, flowTypeId) {
        const url = `${this.TEMPLATE_URL}/baseTemplate?versionId=${versionId}&flowTypeId=${flowTypeId}`;
        return appAxios.get(url);
    }

    /**
     * Uploads base template flow for the given flow type id onto S3 server and updates the upload info onto mongoDb for it.
     * @param {{file, flowTypeId, mimetype}} baseFlowFormData FormData having base flow info
     * @returns {Promise<Object>}
     */
    static async uploadBaseFlow(baseFlowFormData) {
        const url = `${this.TEMPLATE_URL}/baseTemplate`;
        // base flow uploaded onto S3 server and updating the mongoDb about the upload base flow
        return appAxios.post(url, baseFlowFormData);
    }

    /**
     * Download the flow as per versionId and client
     * @param {string} businessCode // businessCode searched enity
     * @param {Object} status // status of the flow
     * @param {string} versionId // version id of the flow
     * @param {string} flowName // name of he flow entered by user
     * @param {string} flowTypeId // type of flow , flowTypeId
     * @returns {Promise<Object>}
     */
    static downloadFlow({ businessCode, status, versionId, flowName, flowTypeId }) {
        const url = `${this.TEMPLATE_URL}?businessCode=${businessCode}&status=${status}&versionId=${versionId}&flowName=${flowName}`
            + `&flowTypeId=${flowTypeId}`;
        return appAxios.get(url);
    }

    /**
     * Applies auto layout 
     * Gets the formatted Xml from backend
     * @param {string} xml
     * @returns {Promise<string>}
     */
    static async autoLayout(xml) {
        const url = `${this.FLOW_URL}/autoLayout`;
        return appAxios.post(url, { xml }).then((res) => res.data);
    }

    /**
     * Getting the basic flow info from local storage
     * @returns {{docVersionId,flowTypeId,businessCode,flowType,flowName}} basic flow info saved in local storage
     */
    static getBasicFlowInfo() {
        return JSON.parse(StorageUtil.getStorageValue(StorageUtil.STORAGE.BASIC_FLOW_INFO));
    }

    /**
     * Set basic flow info in local storage
     * @param {{docVersionId,flowTypeId,businessCode,flowType,flowName}} basicFlowInfo 
     */
    static setBasicFlowInfo(basicFlowInfo) {
        StorageUtil.setStorageValue(StorageUtil.STORAGE.BASIC_FLOW_INFO, JSON.stringify(basicFlowInfo));
    }

    /**
     * Getting the detail of the flow as per businessCode and docVersionId
     * @param {{businessCode, docVersionId}} basicFlowInfo basic flow info
     * @returns {{flowName, flowTypeId, wizardConfig, dnid }} detail of the flow as per businessCode and docVersionId
     */
    static async getFlowDetails(basicFlowInfo) {
        if (basicFlowInfo?.businessCode && basicFlowInfo?.docVersionId) {
            const filters = {
                flowInfo: { businessCode: basicFlowInfo?.businessCode, versionId: basicFlowInfo?.docVersionId }, count: 1, page: 1
            };
            // getting the flow version detail of the given business code and  verion id
            // hitting dnid_settings and flow_versions to get the details of the flow 
            // [dnid, wizardConfig] - dnid_setting
            // [flowName, flowTypeId] - flow_versions
            const promises = [
                ClientService.getClientCreatedFlowTypes(basicFlowInfo.businessCode),
                FlowService.getFlowInfo(filters)];
            const [flowSettingsData, flowInfo] = await Promise.all(promises);
            if (flowSettingsData && flowInfo) {
                const flowVersionDetail = flowInfo[0] || {};
                const flowSettings = flowSettingsData?.data.find((flowInfo) =>
                    flowInfo.flowTypeId === flowVersionDetail?.flowTypeId && flowInfo.flowName === flowVersionDetail?.flowName) || {};
                return {
                    flowName: flowVersionDetail?.flowName, flowTypeId: flowVersionDetail?.flowTypeId,
                    wizardConfig: flowSettings.wizardConfig, dnid: flowSettings.dnid
                };
            }
        }
        return {};
    }

    /**
     * Updates the flow type data such as api url etc. 
     * @param {{apiUrl, apiType, flowDetailApiUrl, flowDetailApiType, flowTypeId}} flowTypeInfo - updated info for the flow type
     * @returns {Promise<{success: boolean, msg: string}>}
     */
    static async updateFlowType(flowTypeInfo) {
        const url = `${this.FLOW_URL}/flowTypesInfo`;
        return appAxios.put(url, flowTypeInfo);
    }

    /**
     * Downloads the XML for a flow using the provided flow information.
     * @param {{businessCode: string, versionId: string, status: string, flowName: string, flowTypeId: string }} flowInfo
     * @returns {Promise<Object>} A promise that resolves with the response data containing the XML.
     */
    static async downloadFlowXml(flowInfo) {
        const url = `${this.TEMPLATE_URL}`;
        // generate query params string to append at the end of url for request query
        const queryParams = AppUtil.convertObjectToQueryString(flowInfo);
        return appAxios.get(`${url}?${queryParams}`);
    }

    /**
     * Updates the flag status for a specific document.
     * @param {string} versionId - versionId of the selected flow
     * @param {string} flag - The new flag status to be set for the document.
     * @returns {Promise}
     */
    static async updateFlag(versionId, flag) {
        const url = `${this.FLOW_URL}/flag`;
        return appAxios.put(url, { versionId: versionId, flag });
    }

    /**
     * Republish a flow by updating the published version ID and the 'updatedOn' timestamp.
     * @param {{businessCode,flowName,versionId,changeHistory,comment,createdBy,createdOn,flowTypeId,
     *          flowUploadStatus,isDeleted,metaInfo,parentVersionId,status,updatedBy,updatedOn}} flowInfo - The information of the flow to be republished.
     * @returns {Promise}
     */
    static async republishFlow(flowInfo) {
        const url = `${this.FLOW_URL}/republish`;
        return appAxios.post(url, { flowInfo });
    }
}

export default FlowService;