import { VOICE_FILE_UPLOAD_TYPE } from "../constants/voice-file";

/**
 * utility for user input properties view
 */
class UserInputUtil {

  /**
   * Get configured voice file upload method as per value
   * @param {string} val Value of voice file type
   * @returns {string} Returns string based on value passed.
   */
  static getConfiguredVoiceFileUploadMethod(val) {
    switch (val) {
      case "filePath-tab":
        return VOICE_FILE_UPLOAD_TYPE.LIBRARY;
      case "uploadFile-tab":
        return VOICE_FILE_UPLOAD_TYPE.UPLOAD;
      case "tts-file-tab":
        return VOICE_FILE_UPLOAD_TYPE.TTS;
      default:
        return VOICE_FILE_UPLOAD_TYPE.LIBRARY;
    }
  }
}

export default UserInputUtil;
