import { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { TOOLTIP } from '../../../constants/messages';
import { CSS_CLASSES } from '../../../constants/css-classes';
import { VOICE_FILE_UPLOAD_TYPE } from '../../../constants/voice-file';
import { envConfig } from '../../../environment';

import PmivrLabel from '../../../components/common/label/pmivr-label';
import PmivrOverlayTrigger from '../../../components/common/overlay-trigger/pmivr-overlay-trigger';
import VoiceFilesSelect from '../../../components/common/voice-files-select/voice-files-select';
import SystemVoiceFileTextToSpeech from './system-voice-file-text-to-speech';
import SystemVoiceFileUploadOption from './system-voice-file-upload-option';
import AudioPlayer from "../../../components/common/audio-player/audio-player";

/**
 * The component for editing system voice files being configured and used internally in AGI call
 * @returns {React.Component} Editing of system voice files to render on UI
 */
const EditSystemVoiceFiles = (props) => {
  const { supportedLanguages, selectedLanguage, updateVoiceFileStateInfo, updateVoiceFilePathState, voiceFilePath, 
    voiceFileInfo, voiceFileType } = props;

  // Active language which is selected in accordion, for which the accordion is opened
  const [activeLanguage, setActiveLanguage] = useState(selectedLanguage);
  // Voice file upload type which can be library, tts file or upload voice file
  const [uploadVoiceFileType, setUploadVoiceFileType] = useState(voiceFileType || VOICE_FILE_UPLOAD_TYPE.LIBRARY);

  // Effect to reset uploadVoiceFileType when selectedLanguage changes
  useEffect(() => {
    setUploadVoiceFileType(voiceFileType || VOICE_FILE_UPLOAD_TYPE.LIBRARY);
  }, [selectedLanguage, voiceFileType, voiceFileInfo]);

  /**
   * Toggles the active language in the accordion view. If the selected language is already active,
   * it deactivates it; otherwise, it sets it as the active language.
   * @param {string} language - The language identifier (e.g., "en" or "es") to be toggled in the accordion.
   */
  const handleAccordionToggle = (language) => {
    const newActiveLanguage = language === activeLanguage ? null : language;
    setActiveLanguage(newActiveLanguage);
    updateVoiceFileStateInfo(newActiveLanguage, voiceFileInfo);
    
    // Reset uploadVoiceFileType when switching languages
    if (newActiveLanguage !== null) {
      setUploadVoiceFileType(voiceFileType || VOICE_FILE_UPLOAD_TYPE.LIBRARY);
    }
  };

  /**
   * Handles the upload file method change, to change the tab when type is switched
   * @param {string} type type of upload file method
   */
  const handleVoiceFileTypeChange = (type) => {
    setUploadVoiceFileType(type);
  };

  return (
    <div>
      <h4 className='border-bottom p-3'>Edit System Voice File</h4>
      <Accordion activeKey={activeLanguage} className='mt-3 pmivr-accordion px-3' style={{ height: "473px", overflowY: "auto" }}>
        {supportedLanguages.map((language) => (
          <Accordion.Item key={language} eventKey={language} className="mt-3 accordion-voice-item">
            <Accordion.Header onClick={() => handleAccordionToggle(language)}>
              <span className="pmivr-accordian-tab">
                Language : {language}
              </span>
            </Accordion.Header>
            <Accordion.Body className='px-2'>
              <ul className="nav nav-pills pmivr-rounded-circle-tabs text-center d-flex mb-4" id="pills-tab" role="tablist">
                <li className="nav-item flex-grow-1" role="presentation">
                  <button id="filePath-tab" data-bs-toggle="tooltip" data-bs-placement="top" title="Add file path" type="button" role="tab"
                    aria-controls="file-path" aria-selected={uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY}
                    className={
                      uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY
                        ? "nav-link active d-inline w-100"
                        : "nav-link d-inline w-100"
                    }
                    onClick={() => handleVoiceFileTypeChange(VOICE_FILE_UPLOAD_TYPE.LIBRARY)} >
                    <i className="bi bi-file-earmark-music"></i>
                    <p>Voice File</p>
                  </button>
                </li>
                <li className="nav-item flex-grow-1" role="presentation">
                  <button id="tts-file-tab" data-bs-toggle="tooltip" data-bs-placement="top" title="Text to speech" type="button" role="tab"
                    aria-controls="tts-voice-file" aria-selected={uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.TTS}
                    className={
                      uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.TTS
                        ? "nav-link active d-inline w-100"
                        : "nav-link d-inline w-100"
                    }
                    onClick={() => handleVoiceFileTypeChange(VOICE_FILE_UPLOAD_TYPE.TTS)} >
                    <i className="bi bi-chat-square-text"></i>
                    <p>Text To Speech</p>
                  </button>
                </li>
                <li className="nav-item flex-grow-1" role="presentation">
                  <button id="uploadFile-tab" data-bs-toggle="tooltip" data-bs-placement="top" title="Upload voice file" type="button" role="tab"
                      aria-controls="upload-file" aria-selected={uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD} 
                      className={
                        uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD
                          ? "nav-link active d-inline w-100"
                          : "nav-link d-inline w-100"
                      }
                      onClick={() => handleVoiceFileTypeChange(VOICE_FILE_UPLOAD_TYPE.UPLOAD)} >
                    <i className="bi bi-upload"></i>
                    <p>Upload File</p>
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                {uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY && (
                  <div id="file-path" role="tabpanel" aria-labelledby="filePath-tab" className="tab-pane fade show active">
                    <div className="d-flex justify-content-between">
                      <div className="pmivr-title pt-2">Library Audio File</div>
                      <AudioPlayer filePath={voiceFilePath} cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE}
                        isUploadedOnGit={true}></AudioPlayer>
                    </div>
                    <div className="form-group custom-input mb-3">
                      <label className="pmivr-label pb-2">Base file path</label>
                      <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BASE_FILE_PATH}>
                        <input disabled type="text" className="form-control pmivr-input pmivr-disabled" size="50"
                          value={`${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}`} />
                      </PmivrOverlayTrigger>
                    </div>
                    <div className="form-group custom-input">
                      <PmivrLabel label="File path (File name without .wav extension)" tooltip={TOOLTIP.VOICE_TYPE_PATH_INFO}
                        cssClass="pb-2" />
                      <PmivrOverlayTrigger tooltip={TOOLTIP.FILE_PATH_INFO}>
                        <VoiceFilesSelect onSelect={(value) => updateVoiceFilePathState(value, { filePath: value, voiceFileType: VOICE_FILE_UPLOAD_TYPE.LIBRARY, language: selectedLanguage, fileName: voiceFileInfo?.fileName })}
                          selectedItem={voiceFilePath} selectedLanguage={language} />
                      </PmivrOverlayTrigger>
                    </div>
                  </div>
                )}
                {uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.TTS && (
                  <div id="tts-voice-file" role="tabpanel" aria-labelledby="tts-file-tab" className={`tab-pane fade show active`}>
                    <SystemVoiceFileTextToSpeech selectedLanguage={language} updateVoiceFileStateInfo={updateVoiceFileStateInfo}
                      updateVoiceFilePathState={updateVoiceFilePathState} voiceFileInfo={voiceFileInfo} />
                  </div>
                )}
                {uploadVoiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD && (
                  <div id="upload-file" role="tabpanel" aria-labelledby="uploadFile-tab" className="tab-pane fade show active">
                    <SystemVoiceFileUploadOption voiceFileInfo={voiceFileInfo} selectedLanguage={selectedLanguage} 
                      updateVoiceFilePathState={updateVoiceFilePathState} />
                  </div>
                )}
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

EditSystemVoiceFiles.propTypes = {
  // array of supported languages
  supportedLanguages: PropTypes.array,
  // selected language
  selectedLanguage: PropTypes.string,
  // updates the voice file path when language tabs is changed
  updateVoiceFileStateInfo: PropTypes.func,
  // updates the voice file path for select menu
  updateVoiceFilePathState: PropTypes.func,
  // current selected voice file path
  voiceFilePath: PropTypes.string,
  // current selected voice files object
  voiceFileInfo: PropTypes.shape({
    fileName: PropTypes.string,
    language: PropTypes.object
  }),
  // voice file type of current voice file (library/tts/upload)
  voiceFileType: PropTypes.string
};

export default EditSystemVoiceFiles;
