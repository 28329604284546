/**
 * List of pages in app and paths
 */
export const APP_PAGES = {
  DEFAULT: "/",
  LOGIN: "/login",
  DIAGRAM: "/diagram",
  VARIABLES: "/variables",
  CLIENT_FLOWS: "/client-flows",
  WIZARD: "/wizard",
  LIST_VOICE_FILE: "/list-voice-file",
  HOME: "/home",
  USERS: "/users",
  NUMBERS: "/numbers",
  TEMPLATES: "/templates",
  SETTINGS: "/settings",
  API_KEYS: "/api-keys",
  CHATBOT: "/chatbot",
  CLIENTS: "/clients",
  DRAFT_OPTIONS: "/draft-options",
  LOGS: "/logs",
  DEPLOYMENT_ENVIRONMENTS: "/deployment-environments"
};
