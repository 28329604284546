import { VARIABLE_TYPES } from "../constants/flow";
import { TASK_TYPE } from "../constants/task-types";
import { EL_TYPE } from "../constants/element";
import AppUtil from "../util/app.util";

import ConfigService from "./config.service";
import ElementService from "./element.service";

/**
 * Service class for variable operations
 */
class VariableService {

  /**
   * Get all kinds of variables, and sorting it by name.
   * @returns {Promise<{variables: Array<Object>}>} sorted (by name) array of variables
   */
  static async getVariables() {
    const variablesP$ = [
      VariableService.getFlowVariables(),
      VariableService.getSystemVariables()
    ];

    const variableResponse = await Promise.all(variablesP$);
    const [flowVariables, systemVariables] = variableResponse;

    const variables = [];
    // adding to one array
    variables.push(...flowVariables, ...systemVariables);
    // sorting the elements of the array
    return AppUtil.sort(variables, "name");
  }

  /**
   * Gets the flow variables
   * @returns {Array} Flow Variables in current flow
   */
  static async getFlowVariables() {
    const flowVariables = [];
    // gets all elements from modeler of current selected flow 
    const elements = ElementService.getAllElements();
    if (elements?.length) {
      elements.forEach((element) => {
        // filter option user task and get the variable
        if ([TASK_TYPE.promptUserOption, TASK_TYPE.promptUserInput,
        TASK_TYPE.keyValueUserOption, TASK_TYPE.dynamicUserOption].includes(element?.businessObject?.taskType)) {
          const variable = element.businessObject.optionVar || element.businessObject.inputVar
            || element.businessObject.dynamicOptionVar || element.businessObject.keyValueOptionVar;
          if (variable) {
            flowVariables.push(this.createFlowVariable(variable, element.businessObject.name));
          }
        }

        // If the element is service task, then handle method call, API call, expression variables
        if (element?.businessObject?.$type === EL_TYPE.SERVICE_TASK) {
          // Response variables in csv string
          const responseVariablesCsv = element.businessObject.responseVariables;
          // Add response variables in the flow variables (in case of API call)
          if (responseVariablesCsv?.length) {
            const responseVariables = responseVariablesCsv.split(',');
            responseVariables.forEach((variable) => {
              flowVariables.push(this.createFlowVariable(variable, element.businessObject.name));
            });
          }
        }
      });
    }

    return AppUtil.sort(flowVariables, "name");
  }

  /**
   * Creates a flow variable object
   * @param {string} name - name of the flow variable
   * @param {string} stepName - name or identifier of the associated workflow step
   * @returns {Object} a flow variable object
   */
  static createFlowVariable(name, stepName) {
    return {
      name, varType: VARIABLE_TYPES.FLOW_VARIABLES, isConfigVar: false, isSysVar: false,
      isFlowVar: true, stepName
    };
  }

  /**
   * Get the system variables  
   * @returns system variables
   */
  static async getSystemVariables() {
    // get the latest state of system variables from redux
    const sysVariables = ConfigService.getSystemVariables();
    // copying the array obtained from redux into another variable because the array is frozen to prevent mutation of the redux state.
    let copiedSystemVariables = [...sysVariables];
    // sorting the system variables based on name
    copiedSystemVariables = AppUtil.sort(copiedSystemVariables, "name");
    return copiedSystemVariables.map(variable => ({
      name: variable.name, description: variable.description, varType: VARIABLE_TYPES.SYSTEM_VARIABLES, isConfigVar: false, isSysVar: true, isFlowVar: false,
    }));
  }

  /**
   * Getting all the variable names 
   * @param {boolean} doExcludeArrayValues flag to exclude array values from variables
   * @returns {Object} Returns only the names of all variables 
   */
  static async getAllVariablesNames(doExcludeArrayValues = false) {
    // get variables whose value is not array
    const variables = await VariableService.getVariables();
    const allVariablesNames = variables.map((varInfo) => varInfo.name);
    return allVariablesNames;
  }

}

export default VariableService;