import { useState, useEffect } from 'react';
import { Typeahead } from "react-bootstrap-typeahead";
import PropTypes from 'prop-types';

import { VOICE_FILE_PLAY_SPEED_RATE, VOICE_FILE_UPLOAD_TYPE, VOICE_GENDER, VOICE_PROMPT_VARIABLE_TYPES } from "../../../constants/voice-file.js";
import { TOOLTIP } from "../../../constants/messages.js";
import { CSS_CLASSES } from '../../../constants/css-classes.js';
import { ATTRIBUTES } from '../../../constants/attributes.js';
import { envConfig } from '../../../environment/index.js';
import { VoicePrompt } from '../../../models/voice-file.js';

import PmivrOverlayTrigger from '../overlay-trigger/pmivr-overlay-trigger.js';
import PmivrLabel from '../label/pmivr-label.js';

import TextToSpeechOption from "../../properties-panel/tts-option/TextToSpeechOption.js";
import UploadVoiceFileOption from "../../properties-panel/upload-voice-file-option/UploadVoiceFileOption.js";
import AudioPlayer from '../audio-player/audio-player.js';
import VoiceFilesSelect from '../voice-files-select/voice-files-select.js';

import AppUtil from '../../../util/app.util.js';

import VariableService from '../../../services/variable.service.js';
import ElementService from '../../../services/element.service.js';

/**
 * It allows to play multiple prompts like voice file , tts , upload file and variables 
 * @param {{element,promptsList,onChange,selectedLanguage}} props
 * @returns {React.Component} Html code to render for multiple eprompts selection
 */
const VoicePromptSelection = (props) => {
    const { selectedLanguage, element, onChange } = props;

    // list of variables names
    const [variablesName, setVariablesName] = useState([]);
    const [promptsList, setPromptsList] = useState();

    // set the prompts list coming from parent 
    useEffect(() => {
        setPromptsList(props.promptsList);
    }, [props.promptsList]);

    // Voice file tabs ids to render tab on change
    const VOICE_OPTION_TAB = {
        LIBRARY: "filePath-tab",
        TTS: "tts-file-tab",
        UPLOAD: "uploadFile-tab",
        VARIABLE: "variable-tab"
    }

    useEffect(() => {
        setVariables();
    }, []);

    /**
     * Get variables and set it in variables name state.
     * updates the variable name states, it filter out the names of the variables ans then set
     */
    const setVariables = async () => {
        const variablesInfo = await VariableService.getVariables();
        // names of all the variables
        const allVariablesNames = variablesInfo.map((varInfo) => varInfo.name);
        setVariablesName(allVariablesNames);
    }

    /**
     * Adds the new prompt for configuring new prompt for particular option
     */
    const addPrompt = () => {
        // get the prompt default values from constructor initilaised
        const promptInstance = new VoicePrompt({});
        const updatedPromptsList = [...promptsList, promptInstance];
        setPromptsList(updatedPromptsList);
        onChange(updatedPromptsList);
    }

    /**
     * Removes the prompt 
     * @param {number} index index value which prompt is to be removed
     */
    const removePrompt = (index) => {
        promptsList.splice(index, 1);
        setPromptsList(promptsList);
        onChange(promptsList);
    }

    /**
     * Updates the voice file path state for particular prompt in the prompt list
     * @param {string} value value of the voice file path
     * @param {number} index index of the selected prompt inside modal for setting options
     */
    const updateVoiceFilePathState = (value, index) => {
        updateFieldInPromptsList(index, "filePath", value);
    }

    /**
     * Updates the field in the prompt for selected index 
     * To update the values like filePath , variables for particular prompt
     * @param {number} index index of the prompt to be updated
     * @param {string} key key to be updated inside prompt
     * @param {string} newValue new value to be set 
     */
    const updateFieldInPromptsList = (index, key, newValue) => {
        let updatedPromptsList = [...promptsList];
        updatedPromptsList[index][key] = newValue;
        setPromptsList(updatedPromptsList);
        onChange(updatedPromptsList);
    }

    /**
     * Updates the variable selection to play with the voice file
     * @param {string} selected value of the voice file variable corresponding to selected option
     * @param {number} index index of the selected prompt inside modal for setting options
     */
    const handleVariableSelection = (selected, index) => {
        const value = selected?.length && selected[0].customOption ? selected[0].selectedVariableInField : selected[0];
        updateFieldInPromptsList(index, "variable", value);
    }

    /**
     * Updates the voice file type for the selected option
     * It will update type like tts , library , upload on tab change
     * @param {string} val value of the voiceFileType
     * @param {number} index index of the otion to update
     */
    const handleTabChange = (val, index) => {
        switch (val) {
            case VOICE_OPTION_TAB.LIBRARY:
                updateFieldInPromptsList(index, "voiceFileType", VOICE_FILE_UPLOAD_TYPE.LIBRARY);
                return;
            case VOICE_OPTION_TAB.UPLOAD:
                updateFieldInPromptsList(index, "voiceFileType", VOICE_FILE_UPLOAD_TYPE.UPLOAD);
                updateFieldInPromptsList(index, "promptId", AppUtil.getTaskName("inner_option_id"));
                // upload tab showing path of library file and tts
                updateFieldInPromptsList(index, "filePath", "");
                updateFieldInPromptsList(index, "fileSize", "");
                return;
            case VOICE_OPTION_TAB.TTS:
                updateFieldInPromptsList(index, "voiceFileType", VOICE_FILE_UPLOAD_TYPE.TTS);
                // default values need to set for tts
                updateFieldInPromptsList(index, "playSpeed", VOICE_FILE_PLAY_SPEED_RATE.NORMAL);
                updateFieldInPromptsList(index, "gender", VOICE_GENDER.FEMALE);
                updateFieldInPromptsList(index, "promptId", AppUtil.getTaskName("inner_option_id"));
                return;
            case VOICE_OPTION_TAB.VARIABLE:
                updateFieldInPromptsList(index, "voiceFileType", VOICE_FILE_UPLOAD_TYPE.VARIABLE);
                return;
            default:
                updateFieldInPromptsList(index, "voiceFileType", VOICE_FILE_UPLOAD_TYPE.LIBRARY);
                return;
        }
    }

    /**
     * Get the values of tts generated and then update the prompt in prompt list
     * @param {{gender,playSpeed,ttsText,filePath,isUploadedOnGit}} ttsValues 
     * @param {number} index index of prompt to be updated
     */
    const handleTtsValues = (ttsValues, index) => {
        updateFieldInPromptsList(index, "gender", ttsValues.gender);
        updateFieldInPromptsList(index, "playSpeed", ttsValues.playSpeed);
        updateFieldInPromptsList(index, "ttsText", ttsValues.ttsText);
        updateFieldInPromptsList(index, "filePath", ttsValues.filePath);
        updateFieldInPromptsList(index, "isUploadedOnGit", ttsValues.isUploadedOnGit);
    }

    /**
     * Get the values of upload file and then update the prompt in prompt list
     * @param {{fileSize,filePath,isUploadedOnGit}} uploadValues 
     * @param {number} index index of prompt to be updated
     */
    const handleUploadFileValues = (uploadValues, index) => {
        updateFieldInPromptsList(index, "fileSize", uploadValues.fileSize);
        updateFieldInPromptsList(index, "filePath", uploadValues.filePath);
        updateFieldInPromptsList(index, "isUploadedOnGit", uploadValues.isUploadedOnGit);
    }

    return (
        <>
            <div className="p-0">
                {promptsList?.map((variableInfo, index) => {
                    return (
                        <>
                            <div className="mt-1 p-1">
                                <ul className="nav nav-pills pmivr-rounded-circle-tabs text-center d-flex" id="pills-tab" role="tablist">
                                    <li className="nav-item flex-grow-1" role="presentation">
                                        <button
                                            className={variableInfo.voiceFileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY ? "nav-link active d-inline w-100" : "nav-link d-inline w-100"}
                                            id={VOICE_OPTION_TAB.LIBRARY}
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Add file path"
                                            data-bs-target="#file-path"
                                            type="button"
                                            role="tab"
                                            aria-controls="file-path"
                                            aria-selected={variableInfo.voiceFileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY}
                                            onClick={(event) => { handleTabChange(event.currentTarget.id, index); }}
                                        >
                                            <i className="bi bi-file-earmark-music"></i>
                                            <p className='text-dark mb-0'>Voice File</p>
                                        </button>
                                    </li>
                                    <li className="nav-item flex-grow-1" role="presentation">
                                        <button
                                            className={variableInfo.voiceFileType === VOICE_FILE_UPLOAD_TYPE.TTS ? "nav-link active d-inline w-100" : "nav-link d-inline w-100"}
                                            id={VOICE_OPTION_TAB.TTS}
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Text to speech"
                                            data-bs-target="#tts-voice-file"
                                            type="button"
                                            role="tab"
                                            aria-controls="tts-voice-file"
                                            aria-selected={variableInfo.voiceFileType === VOICE_FILE_UPLOAD_TYPE.TTS}
                                            onClick={(event) => { handleTabChange(event.currentTarget.id, index); }}
                                        >
                                            <i className="bi bi-chat-square-text"></i>
                                            <p>TTS</p>
                                        </button>
                                    </li>
                                    <li className="nav-item flex-grow-1" role="presentation">
                                        <button
                                            className={variableInfo.voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? "nav-link active d-inline w-100" : "nav-link d-inline w-100"}
                                            id={VOICE_OPTION_TAB.UPLOAD}
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Upload voice file"
                                            data-bs-target="#upload-file"
                                            type="button"
                                            role="tab"
                                            aria-controls="upload-file"
                                            aria-selected={variableInfo.voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD}
                                            onClick={(event) => { handleTabChange(event.currentTarget.id, index); }}
                                        >
                                            <i class="bi bi-upload"></i>
                                            <p>Upload File</p>
                                        </button>
                                    </li>
                                    <li className="nav-item flex-grow-1" role="presentation">
                                        <button
                                            className={variableInfo.voiceFileType === VOICE_FILE_UPLOAD_TYPE.VARIABLE ? "nav-link active d-inline w-100" : "nav-link d-inline w-100"}
                                            id={VOICE_OPTION_TAB.VARIABLE}
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Add variable"
                                            data-bs-target="#variable"
                                            type="button"
                                            role="tab"
                                            aria-controls="varaible"
                                            aria-selected={variableInfo.voiceFileType === "variable"}
                                            onClick={(event) => { handleTabChange(event.currentTarget.id, index); }}
                                            disabled={ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_IS_SPEECH_INPUT, false)}
                                        >
                                            <i className="bi bi-at"></i>
                                            <p>Variable</p>
                                        </button>
                                    </li>
                                    <button className="pmivr-btn-transparent d-flex align-items-center justify-content-center px-2 trash-btn"
                                        onClick={() => { removePrompt(index) }}
                                        style={{ flexShrink: 0 }}>
                                        <i className="bi bi-trash"></i>
                                    </button>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div
                                        className={variableInfo.voiceFileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY ? "tab-pane fade show active" : "tab-pane fade "}
                                        id="file-path"
                                        role="tabpanel"
                                        aria-labelledby="ttsFile-tab"
                                    >
                                        <div className=" mt-3">
                                            <div className="d-flex justify-content-between">
                                                <div className="pmivr-title pt-2">Library Audio File</div>
                                                <AudioPlayer filePath={variableInfo?.filePath} cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE}></AudioPlayer>
                                            </div>

                                            <div className="form-group mb-3">
                                                <div className="pmivr-label">
                                                    <label className="pb-2">Base file path</label>
                                                </div>
                                                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BASE_FILE_PATH}>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        className="form-control pmivr-input pmivr-disabled"
                                                        size="50"
                                                        value={`${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}`}
                                                    />
                                                </PmivrOverlayTrigger>
                                            </div>

                                            <div className="form-group">
                                                <PmivrLabel label="File path (File name without .wav extension)" tooltip={TOOLTIP.VOICE_TYPE_PATH_INFO} cssClass={'pb-2'} />
                                                <PmivrOverlayTrigger tooltip={TOOLTIP.FILE_PATH_INFO}>
                                                    <VoiceFilesSelect
                                                        onSelect={(filePath) => updateVoiceFilePathState(filePath, index)}
                                                        selectedItem={variableInfo?.filePath || ""}
                                                        selectedLanguage={selectedLanguage}
                                                    />
                                                </PmivrOverlayTrigger>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={variableInfo.voiceFileType === VOICE_FILE_UPLOAD_TYPE.TTS ? "tab-pane fade show active py-3" : "tab-pane fade "}
                                        id="tts-voice-file"
                                        role="tabpanel"
                                        aria-labelledby="createFile-tab"
                                    >
                                        <TextToSpeechOption
                                            element={element}
                                            selectedLanguage={selectedLanguage}
                                            isMultiplePrompts={true}
                                            autoSave={false}
                                            onChange={(ttsValues) => { handleTtsValues(ttsValues, index) }}
                                            showUploadBtn={true}
                                            voiceFileInfo={{ [selectedLanguage]: promptsList[index] }}
                                        />
                                    </div>
                                    <div
                                        className={variableInfo.voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD ? "tab-pane fade show active py-3" : "tab-pane fade "}
                                        id="upload-file"
                                        role="tabpanel"
                                        aria-labelledby={VOICE_OPTION_TAB.UPLOAD}
                                    >
                                        <UploadVoiceFileOption
                                            element={element}
                                            selectedLanguage={selectedLanguage}
                                            isMultiplePrompts={true}
                                            autoSave={true}
                                            onChange={(uploadValues) => { handleUploadFileValues(uploadValues, index) }}
                                            showUploadBtn={true}
                                            voiceFileInfo={{ [selectedLanguage]: promptsList[index] }}
                                        />
                                    </div>
                                    <div
                                        className={variableInfo.voiceFileType === VOICE_FILE_UPLOAD_TYPE.VARIABLE ? "tab-pane fade show active py-3" : "tab-pane fade "}
                                        id="variable"
                                        role="tabpanel"
                                        aria-labelledby={VOICE_OPTION_TAB.VARIABLE}
                                    >
                                        <div className="row my-2">
                                            <div className="col-md-8">
                                                <PmivrLabel label="Variable" tooltip={TOOLTIP.INFO.VOICE_FILE_VARIABLE} />
                                                <PmivrOverlayTrigger tooltip={TOOLTIP.INFO.VOICE_FILE_VARIABLE}>
                                                    <Typeahead
                                                        id="basic-typeahead-single"
                                                        className='pmivr-variable-input'
                                                        labelKey="selectedVariableInField"
                                                        onChange={(selected) => handleVariableSelection(selected, index)}
                                                        // only show the simple variables in option
                                                        options={variablesName}
                                                        onInputChange={(text, event) => updateFieldInPromptsList(index, "variable", text)}
                                                        placeholder="Variable"
                                                        multiple={false}
                                                        selected={variableInfo?.variable ? [variableInfo?.variable] : []}
                                                        allowNew
                                                        disabled={ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_IS_SPEECH_INPUT, false)}
                                                    />
                                                </PmivrOverlayTrigger>
                                            </div>
                                            <div className="col-md-4">
                                                <PmivrLabel label="Type" tooltip={TOOLTIP.INPUT.PROMPT_DATA_TYPE} />
                                                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.PROMPT_DATA_TYPE}>
                                                    <select
                                                        className='pmivr-select'
                                                        aria-label="Default select example" value={variableInfo?.variableType}
                                                        disabled={ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_IS_SPEECH_INPUT, false)}
                                                        onChange={(event) => { updateFieldInPromptsList(index, "variableType", event.target.value); }}>
                                                        {VOICE_PROMPT_VARIABLE_TYPES.map((type) => (
                                                            <option value={type.value}>
                                                                {type.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </PmivrOverlayTrigger>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr /></>)
                })}
                <div style={{ marginLeft: "37%" }}>
                    <button className=" mt-1 mb-1 p-2 text-center btn-app pmivr-btn-app"
                        onClick={() => { addPrompt(); }}>
                        <i className="bi bi-plus-circle"> </i> Add prompt
                    </button>
                </div>
            </div>
        </>
    );
};

// Types of props passed in the component
VoicePromptSelection.propTypes = {
    // array of history
    element: PropTypes.object,
    // list of prompts to play
    promptsList: PropTypes.array,
    // function to update the prompts 
    onChange: PropTypes.func,
    selectedLanguage: PropTypes.string
}

export default VoicePromptSelection;