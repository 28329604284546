import { forwardRef, useImperativeHandle, useState } from 'react';

import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import PropTypes from 'prop-types';

/**
 * Toast message on some event across the app.
 * @param {Object} props.snackbarClose - Function to close the snackbar.
 * @param {React.RefObject} ref - Reference object for imperative methods, used for exposing the functions to parent component
 * @returns {React.Component} returns toast element to render.
 */
const PmivrSnackBar = forwardRef(({ snackbarClose }, ref) => {
    const [snackbarProps, setSnackbarProps] = useState({ isOpen: false, msg: "" });

    // exposing functions to parent component
    useImperativeHandle(ref, () => ({
        open(snackbarMsg) {
            setSnackbarProps((snackbarProps) => ({...snackbarProps, isOpen: true, msg: snackbarMsg }));
        },
        close: closeAction
    }));
    

    // a default close action if snackbarClose is not provided
    const closeAction = () => {
        setSnackbarProps((snackbarProps) => ({...snackbarProps, isOpen: false, msg: ''}));
    }

    // Use snackbarClose if provided, otherwise default to closeAction function
    const handleClose = snackbarClose || closeAction;

    return (
        <ToastContainer className="p-3" position="top-center">
            <Toast className="pmivr-toast-msg" show={snackbarProps.isOpen} onClose={handleClose} delay={4000} autohide>
                <Toast.Header>{snackbarProps.msg}</Toast.Header>
            </Toast>
        </ToastContainer>
    )
});

PmivrSnackBar.propTypes = {
    // function to close the snackbar
    snackbarClose: PropTypes.func,
}

export default PmivrSnackBar;
