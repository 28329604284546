import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { APP_PAGES } from '../../../constants/app-pages';
import { envConfig } from '../../../environment';

import LEFT_BAR_OPTIONS from "./left-bar-options";
import PmivrTooltip from "../tooltip/pmivr-tooltip";
import UserAvatar from '../user-avatar/user-avatar';

import UserService from '../../../services/user.service';

/**
 * Left Bar Component in the app (diagram page, etc) for providing options to the user
 * @returns {React.Component} Html element to render left side bar 
 */
const LeftBarComponent = () => {
    // To set currently logged in user
    const [currentUser, setCurrentUser] = useState({});
    // currently selected left bar option
    const [selectedOption, setSelectedOption] = useState();
    // getting the current url location object
    const location = useLocation();
    const { businessCode } = useSelector(state => state.client);


    useEffect(() => {
        LEFT_BAR_OPTIONS.map((option) => {
            // option.linkTo should only be updated only when it is on diagram page
            if (option.id?.startsWith(APP_PAGES.DIAGRAM) && location.pathname?.startsWith(APP_PAGES.DIAGRAM)) {
                // updating the link and id of the diagram option,
                // as it requires client info (versionId, flow status, etc) as per selection
                option.linkTo = location.pathname;
                option.id = location.pathname;
            }
                // if wizard page then we need to send query as clientFlowExists as true,to show wizard,
                //  but it will be in disabled mode
            else if (option.id?.startsWith(APP_PAGES.WIZARD)) {
                option.linkTo = `${APP_PAGES.WIZARD}/${businessCode}?clientFlowExists=true`;
            }
            // returning the option variable because map method needs a return statement
            return option;
        });

        // Setting the currently logged in user.
        setCurrentUser(UserService.getCurrentUser());
    }, []);

    // it will execute every time on page changes from left bar options
    useEffect(() => {
        setSelectedOption(location.pathname);
    }, [location]);

    return (
        <div className="pmivr-leftbar">
            <div className="logo pt-2  text-center"><img src={envConfig.PUBLIC_URL + '/images/paymentus-icon-short.png'} alt="" /></div>
            <ul className="nav navbar-nav text-center leftbar-nav">
                {
                    LEFT_BAR_OPTIONS.map((item) => {
                        // making the currently selected option as active by adding class active
                        return <li key={item.title} className={(selectedOption?.startsWith(item.id)) ? 'active' : ''}>
                            {
                                item.linkTo ?
                                    <Link to={item.linkTo} onClick={() => setSelectedOption(item.id)}>
                                        <PmivrTooltip message={item.title}>
                                            <i className={item.iconClass}></i>
                                        </PmivrTooltip>
                                    </Link>
                                    :
                                    <PmivrTooltip message={item.title}>
                                        <i className={item.iconClass}></i>
                                    </PmivrTooltip>

                            }
                        </li>
                    })
                }
            </ul>

            <ul>
                <div className="pmivr-footer sidebar-user-avatar">
                    <UserAvatar user={currentUser} />
                </div>
            </ul>
        </div>
    )
}

export { LeftBarComponent }