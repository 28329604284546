import { is } from '../di/DiUtil.js';
import { getBounds } from '../utils/layoutUtil.js';

export default {
  /**
   * Creates a diagram information (DI) shape for a BPMN element at a specified grid position.
   * Adds an exclusive gateway marker if the element is an 'ExclusiveGateway'.
   * @param {Object} params - Object containing element, row, column, and DI factory.
   * @param {Object} params.element - The BPMN element for which DI shape is created.
   * @param {number} params.row - The row position of the element in the grid.
   * @param {number} params.col - The column position of the element in the grid.
   * @param {Object} params.diFactory - The factory used to create DI objects.
   * @returns {Object} - The created DI shape for the element.
   */
  'createElementDi': ({ element, row, col, diFactory }) => {

    const bounds = getBounds(element, row, col);

    const options = {
      id: element.id + '_di'
    };

    // If the element is an exclusive gateway, set the marker to be visible
    if (is(element, 'bpmn:ExclusiveGateway')) {
      options.isMarkerVisible = true;
    }

    const shapeDi = diFactory.createDiShape(element, bounds, options);
    element.di = shapeDi;
    element.gridPosition = { row, col };

    return shapeDi;
  }
};