import { clearState, updateShowLoader } from "../slices/ui-state.slice";

export const updateLoaderState = ({ showLoader }) => async dispatch => {
    dispatch(updateShowLoader({ showLoader }));
}

/**
 * dispatch the action to clear the values from redux state
 */
export const clearUiState = () => async dispatch => {
    dispatch(clearState());
}