import { useContext } from 'react';

import { ATTRIBUTES } from "../../../constants/attributes";
import { TAB_LIST } from "../../../constants/element";
import { TOOLTIP } from '../../../constants/messages';
import { VOICE_FILE_UPLOAD_TYPE } from '../../../constants/voice-file';
import { CSS_CLASSES, TASK_ICONS } from '../../../constants/css-classes';
import { VoiceContext } from '../../../contexts/app-context';
import { envConfig } from '../../../environment';

import PmivrOverlayTrigger from '../../common/overlay-trigger/pmivr-overlay-trigger';
import PmivrLabel from '../../common/label/pmivr-label';
import PmivrTooltip from '../../common/tooltip/pmivr-tooltip';
import AudioPlayer from '../../common/audio-player/audio-player';
import VoiceFilesSelect from '../../common/voice-files-select/voice-files-select';

import ElementService from '../../../services/element.service';

/**
 * Voice record task view in the diagram
 */
const VoiceRecordPropsView = () => {
  const { element, tabType } = useContext(VoiceContext);

  /**
* Update the property in the flow
* @param {string} property
* @param {Object} value
*/
  const updateProperty = (property, value) => {
    ElementService.updateElement(element, property, value);
  }

  /**
   * Updates the voice file path state.
   * @param {string} value value for the voice file path.
   */
  const updateVoiceFilePathState = (value) => {
    ElementService.updateElement(element, ATTRIBUTES.VOICE_RECORD_VOICE_FILE_PATH, value);
  }

  return (
    <>
      {
        (tabType === TAB_LIST)
          ? <button
            className="nav-link active"
            id="transfer"
            data-bs-toggle="tab"
            data-bs-target="#edit-voice-record"
            type="button"
            role="tab"
            aria-controls="edit-voice-record"
            aria-selected="false"
          >
            Voice Record
          </button>
          : <>
            <div className="tab-pane fade show active" id="edit-voice-record" role="tabpanel" aria-labelledby="nav-home-tab">
              <div className=" m-2 mt-3">
                <div className="d-flex justify-content-between mt-2">
                  <div className="pmivr-title pt-2">Library Audio File</div>
                  <AudioPlayer filePath={element.businessObject.get(ATTRIBUTES.VOICE_RECORD_VOICE_FILE_PATH)}></AudioPlayer>
                </div>

                <div className="form-group mb-3">
                  <div className="pmivr-label">
                    <label className="pb-2">Base file path</label>
                  </div>
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.BASE_FILE_PATH}>
                    <input
                      disabled={true}
                      type="text"
                      className="form-control pmivr-input pmivr-disabled"
                      size="50"
                      value={`${envConfig.REACT_APP_DEFAULT_VOICE_FILE_PATH}`}
                    />
                  </PmivrOverlayTrigger>
                </div>
                <div className="form-group mb-3">
                  <PmivrLabel label="File path (File name without .wav extension)" tooltip={TOOLTIP.VOICE_TYPE_PATH_INFO} cssClass={'pb-2'} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.FILE_PATH_INFO}>
                    <VoiceFilesSelect
                      onSelect={updateVoiceFilePathState}
                      selectedItem={element.businessObject.get(ATTRIBUTES.VOICE_RECORD_VOICE_FILE_PATH)}
                    />
                  </PmivrOverlayTrigger>
                </div>
                <div className="form-check pmivr-check-radio form-check-inline mb-3 mt-1">
                  <input className="form-check-input" type="checkbox" id="is-payment-auth-recording"
                    checked={element.businessObject.get(ATTRIBUTES.IS_PAYMENT_AUTH_RECORDING) || false}
                    onChange={(e) => {
                      updateProperty(ATTRIBUTES.IS_PAYMENT_AUTH_RECORDING, e.target.checked);
                    }}
                  />
                  <label className="form-check-label">Is Payment Auth Recording</label>
                </div>
                <PmivrTooltip message={TOOLTIP.INPUT.PAYMENT_AUTH_RECORDING}>
                  <i className={`${TASK_ICONS.DISPLAY_INFO} pmivr-text-primary float-end mt-2`}></i>
                </PmivrTooltip>
                <div className="form-group mb-3">
                  <PmivrLabel label="Enter Timeout in Seconds" tooltip={TOOLTIP.INFO.TIMEOUT} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.TIMEOUT}>
                    <input
                      id="VoiceRecordTimeout"
                      name="VoiceRecordTimeout"
                      type='number'
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.VOICE_RECORD_TIMEOUT_IN_SEC) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.VOICE_RECORD_TIMEOUT_IN_SEC, event.target.value); }}
                      placeholder=" "
                    />
                  </PmivrOverlayTrigger>
                </div>
                <div className="form-group mb-3">
                  <PmivrLabel label="Enter Audio Format" tooltip={TOOLTIP.INFO.AUDIO_FORMAT} />
                  <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.AUDIO_FORMAT}>
                    <input
                      id="VoiceRecordAudioFormat"
                      name="VoiceRecordAudioFormat"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.VOICE_RECORD_AUDIO_FORMAT) || ""}
                      onChange={(event) => { updateProperty(ATTRIBUTES.VOICE_RECORD_AUDIO_FORMAT, event.target.value); }}
                    />
                  </PmivrOverlayTrigger>
                </div>
              </div>
            </div>
          </>
      }
    </>
  )
}

export default VoiceRecordPropsView;