import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { MESSAGES, TOOLTIP } from '../../../constants/messages';

import PmivrTooltip, { TOOLTIP_POSITIONS } from '.././tooltip/pmivr-tooltip';
import PmivrSnackBar from '../dialog/pmivr-snackbar';

import AudioService from '../../../services/audio.service';

/**
 * Audio Player to play voice files
 * filePath path of the file to play
 * cssClass class that can be dynamically handled through props
 * isUploadedOnGit need to fetch the file form bitbucket or s3 based on it
 * @param {filePath, cssClass,isUploadedOnGit} props props data from parent component
 * @returns {React.Component} Html code to render audio player
 */
const AudioPlayer = (props) => {
  let { filePath, cssClass = '', isUploadedOnGit = true } = props;
  // using the open method from the snackbar component
  const snackbarRef = useRef();

  const [uiState, setUiState] = useState({ disabled: false });
  const [audioInfo, setAudioInfo] = useState({ isPlaying: false, audioUrl: null, audioFilePath: null });
  // ref to the Audio element for playback control
  const audioRef = useRef(new Audio());

  // to handle the end of audio playback and update the state accordingly.
  useEffect(() => {
    const handleAudioEnd = () => {
      // when the playback audio ends, updates the 'isPlaying' to false.
      setAudioInfo((audioInfo) => ({ ...audioInfo, isPlaying: false }));
    };
    // 'ended' event listener to the audio element
    audioRef.current.addEventListener('ended', handleAudioEnd);
    // Clean up the event listener when the component is unmounted
    return () => {
      audioRef.current.removeEventListener('ended', handleAudioEnd);
    };
  }, []);

  /**
   * get the audio URL from the server
   * @returns {string|boolean} - Returns the audio URL or false if fetching fails
   */
  const _getAudioUrl = async () => {
    try {
      // fetch the audio file from the server if it hasn't been fetched or if the file path has changed.
      if (!audioInfo.audioUrl || audioInfo.audioFilePath !== filePath) {
        // audioFile is a Buffer Type Object
        const audioFile = await AudioService.getAudioFile(filePath, isUploadedOnGit);
        if (!audioFile || !Object.keys(audioFile).length) {
          return false;
        }
        const audioBuffer = new Uint8Array(audioFile.data);
        const audioBlob = new Blob([audioBuffer], { type: 'audio/wav' });
        const objectUrl = URL.createObjectURL(audioBlob);
        setAudioInfo((audioInfo) => ({ ...audioInfo, audioFilePath: filePath, audioUrl: objectUrl }));
        return objectUrl;
      }
      return audioInfo.audioUrl;
    } catch (err) {
      setUiState({ disabled: false });
      setAudioInfo({ isPlaying: false, audioUrl: null, audioFilePath: null });
    }
  };

  /**
   * Function to play the audio.
   */
  const _playAudio = async () => {
    const audioUrlObject = await _getAudioUrl();
    if (audioUrlObject) {
      audioRef.current.src = audioUrlObject;
      audioRef.current.play();
      setAudioInfo((audioInfo) => ({ ...audioInfo, isPlaying: true }));
    } else {
      snackbarRef.current.open(MESSAGES.ERR.AUDIO_FILE_ERROR);
    }
    setUiState({ disabled: false });
  };

  /**
   * Function to pause the audio
   */
  const _pauseAudio = () => {
    audioRef.current.pause();
    setAudioInfo((audioInfo) => ({ ...audioInfo, isPlaying: false }));
  };

  /**
   * Function to toggle between playing and paused states
   */
  const togglePauseAndPlay = async () => {
    try {
      if (audioInfo.isPlaying) {
        _pauseAudio();
      } else {
        setUiState({ disabled: true });
        _playAudio();
      }
    } catch (err) {
      setUiState({ disabled: false });
      setAudioInfo({ isPlaying: false, audioUrl: null, audioFilePath: null });
    }
  };

  return (
    <>
      <PmivrSnackBar ref={snackbarRef} />
      <div onClick={togglePauseAndPlay} className={`mt-1 ${cssClass}`}>
        {uiState.disabled ? (
          <div className="spinner-border spinner-border-sm" role="status"></div>
        ) : audioInfo.isPlaying ? (
          <PmivrTooltip message={TOOLTIP.AUDIO_PAUSE} position={TOOLTIP_POSITIONS.TOP}>
            <i className="bi bi-pause-circle  fa-lg icon-font-size"></i>
          </PmivrTooltip>
        ) : (
          <PmivrTooltip message={TOOLTIP.AUDIO_PLAY} position={TOOLTIP_POSITIONS.TOP}>
            <i className="bi bi-play-circle-fill  fa-lg icon-font-size play-button"></i>
          </PmivrTooltip>
        )}
      </div>
    </>
  );
};

AudioPlayer.propTypes = {
  // audio filepath to play
  filePath: PropTypes.string,
  // new css class
  cssClass: PropTypes.string,
  // isUploadedOnGit to get file play from s3 or bitbucket
  isUploadedOnGit: PropTypes.bool
};

export default AudioPlayer;
