import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import PmivrLabel from '../label/pmivr-label';
import { useDebouncedEffect } from '../../../hooks/use-debounced-effect.hook';
import AppUtil from '../../../util/app.util';
import { isNil } from 'lodash';

/**
 * PmivrSearchInput - A customizable search input component.
 * @param {string} value - The current value of the input.
 * @param {string} placeholder - The placeholder text for the input.
 * @param {RegExp} [regex] - Optional regex for validating the input.
 * @param {function} onSearch - Function called when the input changes and the value is valid.
 * @param {function} onEnter - Function called when the Enter key is pressed.
 * @param {string} [type='text'] - The type of the input (default is 'text').
 * @param {string} [label='Search here'] - Label for the search input.
 * @param {string} [tooltip=""] - Tooltip mssage.
 * @returns {JSX.Element} The rendered search input component.
 */
const PmivrSearchInput = ({ value, placeholder = "Search here", regex, onSearch, onEnter, type = 'text', label = "Search here", tooltip = "" }) => {
    const [inputValue, setInputValue] = useState(value);

    // Effect to update inputValue when the value prop changes.
    useEffect(() => {
        setInputValue(value);
    }, [value]);

    /**
     * Debounced function to save the search value after a delay.
     * @function debounceSearch
     * @param {string} searchvalue - The value to be saved after debounce.
     */
    const debounceSearch = useCallback((searchValue) => {
        // in case valid searchvalue, filter the data accordingly
        if (!isNil(searchValue) && (!regex || regex.test(searchValue))) {
            onSearch(searchValue); // Trigger the search function if valid
        }
    }, []);

    // Using the custom debounced effect hook for inputValue
    useDebouncedEffect(() => {
        debounceSearch(inputValue);
    }, [inputValue], 500); // Debounced with 500ms delay

    /**
     * Handles input value changes and updates the state.
     * 
     * @param {Event} e - The change event from the input.
     */
    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    /**
     * Handles key down events; triggers onEnter if the Enter key is pressed.
     * 
     * @param {Event} e - The key down event.
     */
    const handleKeyDown = (e) => {
        if (AppUtil.isEnterKey(e)) {
            onEnter();
        }
    };

    return (
        <>
            <PmivrLabel label={label} tooltip={tooltip} />
            <div className="pmivr-relative">
                <input
                    type={type}
                    className="form-control pmivr-input pe-5"
                    value={inputValue}
                    placeholder={placeholder}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
                <span className="btn-search-key">
                    <button
                        title={`Click to search the ${placeholder}`}
                        disabled={!inputValue}
                        className="pmivr-btn-transparent"
                        onClick={() => onEnter()}
                    >
                        <i className="bi bi-search"></i>
                    </button>
                </span>
            </div>
        </>
    );
};

PmivrSearchInput.propTypes = {
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    regex: PropTypes.instanceOf(RegExp),
    onSearch: PropTypes.func.isRequired,
    onEnter: PropTypes.func.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    tooltip: PropTypes.string,
};

export default PmivrSearchInput;
