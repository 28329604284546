import ModelerService from "./modeler.service";

/**
 * Perform operations on element
 */
export default class ElementService {

	/**
	 * Add attribute to the passed element
	 * @param {Object} el Diagram element
	 * @param {string} key Key to be added
	 * @param {string} value Value for the key
	 */
	static updateElementAttr(el, key, value) {
		const existingAttrValue = el.businessObject.get(key);
		// no need to update elements if the attribute values is already empty or null
		if (!value && (existingAttrValue === null || existingAttrValue === undefined)) {
			return;
		} else {
			ElementService.updateElement(el, key, value);
		}
	}

	/** 
	 * Removes a specific attribute from a BPMN diagram element's business object. 
	 * Eg: (in case of type change of a particular task, remove current disabled attribute)
	 * @param {Object} el Diagram element
	 * @param {string} key Key of the attribute to be removed
	 */
	static removeElementAttr(el, key) {
		const businessObject = el.businessObject;
		// if the key is present in business object then delete
		if (businessObject && key in businessObject) {
			delete businessObject[key];
		}
	}

	/**
	 * Updating the element in the diagram
	 * @param {Object} el 
	 * @param {string} key 
	 * @param {string} value 
	 */
	static updateElement(el, key, value) {
		const modeler = ModelerService.getModeler();
		const modeling = modeler.get('modeling');
		const attrInfo = {};
		attrInfo[key] = value;
		modeling.updateProperties(el, attrInfo);
	}

	/**
	 * Update the connection condition
	 * @param {Object} connectionEl 
	 * @param {Object} condition 
	 */
	static updateConnectionCondition(connectionEl, condition) {
		const modeler = ModelerService.getModeler();
		const modeling = modeler.get('modeling');
		let conditionExpression = connectionEl.businessObject.conditionExpression;
		if (!conditionExpression) {
			const moddle = modeler.get('moddle');
			conditionExpression = moddle.create('bpmn:FormalExpression', { body: condition, language: "javascript" });
		} else {
			// otherwise udpate the condition body with new condition
			conditionExpression.body = condition;
		}
		modeling.updateProperties(connectionEl, { conditionExpression: conditionExpression });
	}

	/**
	 * Get the condition expression for connection
	 * @param {Object} connectionEl 
	 */
	static getConnectionCondition(connectionEl) {
		const conditionExpression = connectionEl.businessObject.conditionExpression;
		return conditionExpression?.body;
	}

	/**
	 * Getting the attribute from the element bussniess object
	 */
	static getAttribute(el, key, defaultValue = null) {
		return el.businessObject.get(key) || defaultValue;
	}

	/**
	 * Return the name of element
	 * @param {Object} el 
	 */
	static getElementName(el) {
		return el.businessObject.name;
	}

	/**
	 *  Return the name of element's parent
	 * @param {Object} el 
	 */
	static getParentName(el) {
		return el.parent.businessObject.name;
	}

	/**
	 * Return the ID of element's parent
	 * @param {Object} el 
	 */
	static getParentId(el) {
		return el.parent.businessObject.id;
	}

	/**
	 * Get the element object by id
	 * @param {string} id id of the element 
	 * @returns object of the element if peresent
	 */
	static getElementById(id) {
		const modeler = ModelerService.getModeler();
		const elementRegistry = modeler.get("elementRegistry");
		return elementRegistry.get(id);
	}

	/**
	 * Getting all the elements
	 */
	static getAllElements() {
		const modeler = ModelerService.getModeler();
		// if there is no modeler or any config redirect to home
		if (modeler) {
			const elementRegistry = modeler.get("elementRegistry");
			return elementRegistry?.getAll();
		} else {
			return [];
		}
	}
}