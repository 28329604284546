import { useContext, useEffect, useState } from "react";

import { ATTRIBUTES } from "../../../constants/attributes";
import { TOOLTIP } from "../../../constants/messages";

import { SingleVoiceContext, VoiceContext } from "../../../contexts/app-context";
import { PmivrDialog } from "../../common/dialog/pmivr-dialog";
import { VoicePrompt } from "../../../models/voice-file.js";
import PmivrTooltip from "../../common/tooltip/pmivr-tooltip.js";
import OptionValueMapping from "../../common/option-value-mapping/option-value-mapping.js";
import VoicePromptSelection from "../../common/voice-prompt-selection/VoicePromptSelection";

import AppUtil from "../../../util/app.util.js";
import VoiceFileOptionUtil from "../../../util/voice-file-option.util";

import ElementService from "../../../services/element.service";
import VoiceFileOptionService from "../../../services/voice-file-option.service";
import DiagramService from "../../../services/diagram.service.js";

/**
 * Single voice file component for option user
 */
const SingleVoiceFileOption = () => {
  const [optionList, setOptionList] = useState("");
  const [optionAndVal, setOptionAndVal] = useState({});
  const [isOptionAndValStartFromZero, setIsOptionAndValStartFromZero] = useState(false);
  // option menu to render
  const [showOptionMenu, setShowOptionMenu] = useState(false);
  // boolean value to show voice file settings menu
  const [showVoiceFileSettingMenu, setShowVoiceFileSettingMenu] = useState(false);
  // array of languages supported
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  // language selected in voice file info and update if change in the modal
  const [selectedLanguage, setSelectedLanguage] = useState("");
  // inforation about voice file
  const [voiceFileInfo, setVoiceFileInfo] = useState({});

  const { element } = useContext(VoiceContext);

  useEffect(() => {
    const init = async () => {
      if (!Array.isArray(element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS))) {
        setOptionList(element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS) ? (element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS))?.split("") : "");
      }
      const tempOptionAndVal = VoiceFileOptionService.getFormattedOptionAndVal(element); // get formatted option val
      let voiceFileInfo = await ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_INFO);
      voiceFileInfo = JSON.parse(voiceFileInfo);
      const supportedLanguages = await DiagramService.getSupportedLanguages();
      const tmpVoiceFilePromptInfo = {};
      if (voiceFileInfo) {
        supportedLanguages.forEach((lang) => {
          if (voiceFileInfo[lang]?.voiceFileType) {
            tmpVoiceFilePromptInfo[lang] = [new VoicePrompt({ filePath: voiceFileInfo[lang]?.filePath, voiceFileType: voiceFileInfo[lang]?.voiceFileType })];
          } else {
            tmpVoiceFilePromptInfo[lang] = voiceFileInfo[lang];
          }
        });
      }
      setVoiceFileInfo(tmpVoiceFilePromptInfo);
      if (!Array.isArray(element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS))) {
        setOptionList(element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS) ? (element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS))?.split("") : "");
      }
      setOptionAndVal(tempOptionAndVal);
      setIsOptionAndValStartFromZero((tempOptionAndVal && tempOptionAndVal[0] && tempOptionAndVal[0][0]) ? (tempOptionAndVal[0][0].toString() === '0') : false);
      setShowOptionMenu(false);
      // flag to show voice file setting menu
      setShowVoiceFileSettingMenu(false);
      //set the supported languages
      setSupportedLanguages(supportedLanguages || []);
      // flag to set the selected language
      setSelectedLanguage(supportedLanguages[0]);
    }
    init();
  }, [element]);

  // revert the changes when closing option manue
  const handleCloseOptionMenu = (event) => {
    const tempOptionAndVal = VoiceFileOptionService.getFormattedOptionAndVal(element);
    setShowOptionMenu(false);
    setOptionList(element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS) ? (element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_OPTIONS))?.split("") : "");
    setOptionAndVal(tempOptionAndVal);
  }

  // save the changes of the option menu
  const handleSaveOptionMenu = () => {
    let keyAndVal = {};
    let optionAndValJson = AppUtil.deepClone(optionAndVal);
    for (let i of Object.keys(optionAndValJson)) {
      if (optionAndValJson[i][0] !== null && optionAndValJson[i][0] !== "" && optionAndValJson[i][1] !== null && optionAndValJson[i][1] !== "") {
        keyAndVal[optionAndValJson[i][0]] = optionAndValJson[i][1];
      }
    }
    ElementService.updateElement(element, ATTRIBUTES.PROMT_USER_OPTION_VALUE_MAP, JSON.stringify(keyAndVal));
    ElementService.updateElement(element, ATTRIBUTES.PROMT_USER_OPTION_OPTIONS, optionList);
    setShowOptionMenu(false);
  }

  // revert the changes of the voice file setting menu
  const handleCloseVoiceFileSettingMenu = async (event) => {
    let voiceFileInfo = await ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_INFO);
    voiceFileInfo = JSON.parse(voiceFileInfo);
    setShowVoiceFileSettingMenu(false);
    setSupportedLanguages(supportedLanguages || []);
    setSelectedLanguage(selectedLanguage);
    const tmpVoiceFilePromptInfo = {};
    if (voiceFileInfo) {
      supportedLanguages.forEach((lang) => {
        if (voiceFileInfo[lang]?.voiceFileType) {
          tmpVoiceFilePromptInfo[lang] = [new VoicePrompt({ filePath: voiceFileInfo[lang]?.filePath, voiceFileType: voiceFileInfo[lang]?.voiceFileType })];
        } else {
          tmpVoiceFilePromptInfo[lang] = voiceFileInfo[lang];
        }
      });
    }
    setVoiceFileInfo(tmpVoiceFilePromptInfo);
  }

  // save changes of voice fie setting
  const handleSaveVoiceFileSettingMenu = async () => {
    ElementService.updateElementAttr(element, ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(voiceFileInfo));
    setShowVoiceFileSettingMenu(false);
  }

  //   update option and val of the voice file
  const updateOptionAndValues = (event, val) => {
    const response = VoiceFileOptionService.updateOptionAndValues(event, val, optionAndVal, isOptionAndValStartFromZero)
    //  set new state value and update the model properties
    setOptionAndVal(response.optionAndVal);
    setOptionList(response.optionsListTemp);
  }

  //   add option of the voice file
  const addOption = (event) => {
    let options = AppUtil.deepClone(optionAndVal);
    options[Object.keys(options).length] = ["", ""];
    setOptionAndVal(options);
  }

  //  remove option of the voice file
  const removeOption = (event) => {
    const indx = Number(event.target.id.split("file_option_remove_")[1]);
    let options = AppUtil.deepClone(optionAndVal);
    // Convert the object to an array of values
    let optionsArray = Object.values(options);
    // Remove the array at index 
    optionsArray.splice(indx, 1);
    // Convert the array back to an object with updated indices
    options = Object.assign({}, optionsArray);

    let optionsListTemp = VoiceFileOptionUtil.getUpdatedOptions(options, isOptionAndValStartFromZero);
    setOptionAndVal(options);
    setOptionList(optionsListTemp);
  }

  /**
   * updates the prompt list and also updates the element with the updated list
   * @param {[{filePath,ttsText,fileSize,isUploadedOnGit}]} updatedPromptsList 
   * @param {string} language selected language by the user to set the voice file
   */
  const handleUpdatePromptList = (updatedPromptsList, language) => {
    setVoiceFileInfo({ ...voiceFileInfo, [language]: updatedPromptsList });
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-9 mt-3">
          Configure voice files
        </div>
        <div className="col-sm-3">
          <PmivrTooltip message={TOOLTIP.INFO.CONFIGURE_VOICE_FILE}>
            <button onClick={(event) => { setShowVoiceFileSettingMenu(true); }}
              className="mt-2 float-end pmivr-btn-rounded-circle pmivr-btn-app rounded-circle voice-file-setting-btn"
              variant="primary" data-bs-toggle="tooltip" data-bs-placement="right">
              <i className="bi bi-pencil"></i>
            </button>
          </PmivrTooltip>
        </div>
      </div>
      <div className="row mt-3 mb-3">
        <SingleVoiceContext.Provider
          value={{
            supportedLanguages: supportedLanguages, selectedLanguage: selectedLanguage,
            element: element, voiceFileDetails: voiceFileInfo,
            optionAndVal: optionAndVal, updateOptionAndValues: updateOptionAndValues,
            isOptionAndValStartFromZero: isOptionAndValStartFromZero, removeOption: removeOption, addOption: addOption
          }}>

          <OptionValueMapping optionAndVal={optionAndVal} isOptionAndValStartFromZero={isOptionAndValStartFromZero}
            showOptionMenu={showOptionMenu} handleCloseOptionMenu={handleCloseOptionMenu} addOption={addOption}
            updateOptionAndValues={updateOptionAndValues} removeOption={removeOption}
            handleSaveOptionMenu={handleSaveOptionMenu} setShowOptionMenu={setShowOptionMenu} />

          <PmivrDialog showDialog={showVoiceFileSettingMenu} closeDialog={handleCloseVoiceFileSettingMenu}
            title={`Voice File`} message={
              <>
                <div>
                  <ul className="nav nav-tabs text-center" role="tablist">
                    {
                      supportedLanguages.map((language) => {
                        return (
                          <li className="nav-item  px-2" role="presentation">
                            <button className={selectedLanguage === language ? "nav-link active " : "nav-link"} data-bs-target="#language-options" type="button" role="tab" title={language} aria-controls="language-options"
                              onClick={() => setSelectedLanguage(language)}>{language}</button>
                          </li>
                        );
                      })
                    }
                  </ul>
                </div>
                <div className="mt-3" id="language-options">
                  <div className="tab-content " id="pills-tabContent">
                    <VoicePromptSelection selectedLanguage={selectedLanguage}
                      element={element} promptsList={voiceFileInfo[selectedLanguage] || []}
                      onChange={(updatedPromptsList) => handleUpdatePromptList(updatedPromptsList, selectedLanguage)} />
                  </div>
                </div>
              </>
            }
            saveDialogChanges={handleSaveVoiceFileSettingMenu} />

        </SingleVoiceContext.Provider>
      </div>
    </>
  );
}

export default SingleVoiceFileOption;