import { useNavigate } from "react-router-dom";

import { APP_PAGES } from "../../constants/app-pages";
import { envConfig } from "../../environment";

import { propTypes } from "react-bootstrap/esm/Image";

/**
 * The component for showing page for invalid route
 * @param {object} props data to display as error 
 * @returns {React.Component} Html element to render
 */
const ErrorPage = (props) => {
  const { code, msg } = props;
  const navigate = useNavigate();

  return (
    <div className="pmivr-error-page">
      <div className="m-3 mb-1">
        <img src={`${envConfig.PUBLIC_URL}/images/logo/paymentus-logo.png`} alt="Paymentus" />
      </div>
      <div className="text-center p-5">
        <h1 className="invalid-route-error-code">{code}</h1>
        <div className="invalid-route-error-message m-3">{msg}</div>
        <button className="pmivr-btn-app mt-3" onClick={() => navigate(APP_PAGES.HOME)}>
          Back to home
        </button>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  code: propTypes.string,
  msg: propTypes.string
}

export default ErrorPage;
