import { useContext } from 'react';

import { ATTRIBUTES } from "../../../constants/attributes";
import { TAB_LIST } from "../../../constants/element";
import { TOOLTIP } from '../../../constants/messages';
import { VoiceContext } from '../../../contexts/app-context';

import PmivrOverlayTrigger from '../../common/overlay-trigger/pmivr-overlay-trigger';
import PmivrLabel from '../../common/label/pmivr-label';

import ElementService from '../../../services/element.service';

/**
 * Voice record start task view in the diagram
 */
const VoiceRecordStartPropsView = () => {
    const { element, tabType } = useContext(VoiceContext);

    /**
     * Update the property in the flow
     * @param {string} property
     * @param {Object} value
     */
    const updateProperty = (property, value) => {
        ElementService.updateElement(element, property, value);
    }

    return (
        <>
            {
                (tabType === TAB_LIST)
                    ? <button
                        className="nav-link active"
                        id="transfer"
                        data-bs-toggle="tab"
                        data-bs-target="#edit-voice-record"
                        type="button"
                        role="tab"
                        aria-controls="edit-voice-record"
                        aria-selected="false"
                    >
                        Monitor Voice Start
                    </button>
                    : <>
                        <div className="tab-pane fade show active" id="edit-voice-record" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className=" m-2 mt-3">
                                <div className="form-check pmivr-check-radio form-check-inline mb-3 mt-1">
                                    <input className="form-check-input" type="checkbox" id="is-payment-auth-recording"
                                        checked={element.businessObject.get(ATTRIBUTES.IS_PAYMENT_AUTH_VOICE_RECORD_START) || false}
                                        onChange={(e) => {
                                            updateProperty(ATTRIBUTES.IS_PAYMENT_AUTH_VOICE_RECORD_START, e.target.checked);
                                        }}
                                    />
                                    <label className="form-check-label">Is Payment Auth Recording</label>
                                </div>
                                <div className="form-group mb-3">
                                    <PmivrLabel label="Enter Audio Format" tooltip={TOOLTIP.INFO.AUDIO_FORMAT} />
                                    <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.AUDIO_FORMAT}>
                                        <input
                                            id="MonitorVoiceAudioFormat"
                                            name="MonitorVoiceAudioFormat"
                                            className="form-control pmivr-input"
                                            value={element.businessObject.get(ATTRIBUTES.VOICE_RECORD_START_AUDIO_FORMAT) || ""}
                                            onChange={(event) => { updateProperty(ATTRIBUTES.VOICE_RECORD_START_AUDIO_FORMAT, event.target.value); }}
                                        />
                                    </PmivrOverlayTrigger>
                                </div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default VoiceRecordStartPropsView;