import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";
import { useContext } from "react";
import { VoiceContext } from "../../../contexts/app-context";
import PmivrTooltip from "../../common/tooltip/pmivr-tooltip";
import { TOOLTIP } from "../../../constants/messages";

const MultiVoiceFileOptionModalFooter = () => {
  const { isAddOption, voiceFileUploadMethod, tempSelectedOption, selectedOption, handleCloseOptionMenu,
    handleDeleteOption, handleSaveOptionMenu } = useContext(VoiceContext);
  return (
    <>
      <button className="pmivr-btn-cancel" onClick={(event) => { handleCloseOptionMenu(); }}>
        Cancel
      </button>
      {isAddOption ? (
        <></>
      ) : (
        <PmivrTooltip message={TOOLTIP.INFO.DELETE_CURRENT_OPTION}>
          <button className="pmivr-btn-delete" disabled={isAddOption} onClick={(event) => { handleDeleteOption(); }}>
            Delete
          </button>
        </PmivrTooltip>
      )}

      <PmivrTooltip message={TOOLTIP.INFO.SAVE_CURRENT_OPTION}>
        <button className="pmivr-btn-app"
          onClick={(event) => {
            const optionId = isAddOption && voiceFileUploadMethod !== VOICE_FILE_UPLOAD_TYPE.LIBRARY
              ? tempSelectedOption.id
              : selectedOption.id;
            handleSaveOptionMenu(optionId);
          }}
        >Save Changes</button>
      </PmivrTooltip>

    </>
  );
};

export default MultiVoiceFileOptionModalFooter;
