import { TAB_LIST } from "../../../constants/element";
import { ATTRIBUTES } from "../../../constants/attributes";

import ElementService from "../../../services/element.service";
import { useContext } from "react";
import { VoiceContext } from "../../../contexts/app-context";

/**
 * User confirmation properties view on the diagram page
 * @param {Object} props Props data from parent component
 */
const UserConfirmationPropertiesView = () => {
  const { element, tabType } = useContext(VoiceContext);

  return (
    <>
      {
        (tabType === TAB_LIST)
          ? <button
            className="nav-link"
            id="user-input"
            data-bs-toggle="tab"
            data-bs-target="#edit-user-input"
            type="button"
            role="tab"
            aria-controls="edit-user-input"
            aria-selected="false"
          >
            Input
          </button>
          : <>
            <div className="tab-pane fade" id="edit-user-input" role="tabpanel" aria-labelledby="nav-home-tab">
              <div className=" m-2 mt-3">
                <form>
                  <div className="form-group mb-3">
                    <input
                      id="ConfirmationOption"
                      name="ConfirmationOption"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.USER_CONFIRMATION_OPTION)}
                      onChange={(event) => { ElementService.updateElement(element, ATTRIBUTES.USER_CONFIRMATION_OPTION, event.target.value); }}
                      placeholder="Enter Confirmation Options"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <input
                      id="inputVar"
                      name="inputVar"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.USER_CONFIRMATION_INPUT_VAR_TO_CONFIRM)}
                      onChange={(event) => { ElementService.updateElement(element, ATTRIBUTES.USER_CONFIRMATION_INPUT_VAR_TO_CONFIRM, event.target.value); }}
                      placeholder="Enter Input Variable"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <input
                      id="inputConfirmationVar"
                      name="inputConfirmationVar"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.USER_CONFIRMATION_INPUT_CONFIRMATION_VAR)}
                      onChange={(event) => { ElementService.updateElement(element, ATTRIBUTES.USER_CONFIRMATION_INPUT_CONFIRMATION_VAR, event.target.value); }}
                      placeholder="Enter Input Confirmation Variable"
                    />
                  </div>
                  <div className="form-group mb-3">
                    <input
                      id="RetryCountVar"
                      name="RetryCountVar"
                      className="form-control pmivr-input"
                      value={element.businessObject.get(ATTRIBUTES.USER_CONFIRMATION_RETRY_COUNT_VAR)}
                      onChange={(event) => { ElementService.updateElement(element, ATTRIBUTES.USER_CONFIRMATION_RETRY_COUNT_VAR, event.target.value); }}
                      placeholder="Enter Retry Count Variable"
                    />
                  </div>
                </form>
              </div>
            </div>
          </>
      }
    </>
  )
}

export default UserConfirmationPropertiesView;
