import React, { useState, useRef, Component, useEffect } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { EL_TYPE } from "../../constants/element";
import { TASK_TYPE } from '../../constants/task-types';

import DiagramService from '../../services/diagram.service';

import ServicePropertiesView from './service-properties/ServicePropertiesView';
import UserInputPropertiesView from './input-task-properties/UserInputPropertiesView';
import ConditionsView from '../../components/properties-panel/conditions/ConditionsView';
import UserConfirmationPropertiesView from '../../components/properties-panel/confirmation-task-properties/UserConfirmationPropertiesView';
import SayDataPropsView from '../../components/properties-panel/say-data-properties/SayDataPropsView';
import OptionUserTaskView from '../../components/properties-panel/option-user-task/OptionUserTaskView';
import TransferPropertiesView from './transfer-properties/TransferPropertiesView';
import VoicePromptsView from './voice-file-properties/VoicePromptsView';
import VoiceRecordPropsView from './voice-record-properties/VoiceRecordPropsView';
import VoiceRecordStartPropsView from './voice-record-properties/VoiceRecordStartPropsView';

import { VoiceContext } from '../../contexts/app-context';

import store from '../../redux/store/store';

/**
 * Right panel properties view on diagram page
 * @returns {React.Component} Html element to render
 */
export default class PropertiesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedElements: [],
      element: null
    };
  }

  componentDidMount() {
    const { modeler } = this.props;
    modeler.on('selection.changed', (e) => {
      this.setState({
        selectedElements: e.newSelection,
        element: e.newSelection[0]
      });
      DiagramService.closeRightPanel();
    });

    modeler.on('element.changed', (e) => {
      const { element } = e;
      const { element: currentElement } = this.state;

      if (!currentElement) {
        return;
      }

      // update panel, if currently selected element changed
      if (element.id === currentElement.id || element.businessObject.taskType !== currentElement.businessObject.taskType) {
        this.setState({ element });
      }
    });
  }

  render() {
    const {
      modeler, rightPanelEventHandler, keyValVoiceFileEventsHandler
    } = this.props;
    const { selectedElements, element } = this.state;

    return (
      // need to pass the store again as parent is class component; 
      // there were issue while converting ProprtiesView to functional component; need to check again
      <Provider store={store}>
        {
          selectedElements.length === 1
          && <ElementProperties modeler={modeler} element={element} rightPanelEventHandler={rightPanelEventHandler}
            keyValVoiceFileEventsHandler={keyValVoiceFileEventsHandler} />
        }
      </Provider>
    );
  }
}

/**
 * Child for the properties view
 * @param {Object} props props data from parent component
 */
function ElementProperties(props) {
  let {
    element, modeler, rightPanelEventHandler, keyValVoiceFileEventsHandler
  } = props;

  const taskLabelInputRef = useRef(null);
  const taskHeadingRef = useRef(null);
  // user option tasks
  const userOptionTasks = [TASK_TYPE.keyValueUserOption, TASK_TYPE.promptUserOption, TASK_TYPE.dynamicUserOption];
  if (element.labelTarget) {
    element = element.labelTarget;
  }

  // name of the task for each node in the right panel
  const [taskName, setTaskName] = useState("");

  useEffect(() => {
    //on intial rendering of new element we do not want right panel to get open
    DiagramService.closeRightPanel();
  }, []);

  /**
   * On opening of the right panel, opened element state will be shown
   */
  useEffect(() => {
    // set the task name on rendering of the element in right panel
    setTaskName(element.businessObject.name || "");
    // initially check for element name present or we should show input field
    showLabelInputField(element.businessObject.name);
  }, [element]);

  // if task type in userOptionTasks then render it
  function renderOptionUserTask(element) {
    return userOptionTasks.includes(element.businessObject.get('voice:taskType'));
  }

  // checking whether it is voice user input or not
  function renderPropVoice(element) {
    return (!userOptionTasks.includes(element.businessObject.get('voice:taskType')) && (element.businessObject.get('voice:taskType') === TASK_TYPE.playVoiceFile || element.businessObject.get('voice:taskType') === TASK_TYPE.promptUserOption)) ||
      element.businessObject.get('voice:taskType') === TASK_TYPE.promptUserInputConfirmation;
  }

  // checking whether it is promp user input or not
  function renderPropUserInput(element) {
    return !userOptionTasks.includes(element.businessObject.get('voice:taskType')) && (element.businessObject.get('voice:taskType') === TASK_TYPE.promptUserInput);
  }

  // checking whether it is gateway or not
  function renderPropFlow(element) {
    return element.type === EL_TYPE.GATEWAY;
  }

  // checking whether it is service prop or not
  function renderPropService(element) {
    return !userOptionTasks.includes(element.businessObject.get('voice:taskType'))
      && (element.businessObject.$type === 'bpmn:ServiceTask' &&
        (element.businessObject.isServiceImpl === true ||
          element.businessObject.get('serviceImpl:isServiceImpl') === 'true'));
  }

  // checking whether it is prop confirmation task ot not
  function renderPropConfirmation(element) {
    return !userOptionTasks.includes(element.businessObject.get('voice:taskType')) && (element.businessObject.get('voice:taskType') === TASK_TYPE.promptUserInputConfirmation);
  }

  // check whether it is say data task or not
  function renderSayData(element) {
    return !userOptionTasks.includes(element.businessObject.get('voice:taskType')) && (element.businessObject.isSayData === true || element.businessObject.get('sayData:isSayData') === 'true');
  }

  // checking whether it is transfer task or not
  function renderTransfer(element) {
    return !userOptionTasks.includes(element.businessObject.get('voice:taskType')) && (element.businessObject.isTransferTask === true || element.businessObject.get('trasnfer:isTransferTask') === 'true');
  }

  function renderVoiceRecord(element) {
    return !userOptionTasks.includes(element.businessObject.get('voice:taskType')) && (element.businessObject.get('voice:taskType') === TASK_TYPE.recordVoice);
  }

  function renderVoiceRecordStart(element) {
    return !userOptionTasks.includes(element.businessObject.get('voice:taskType')) && (element.businessObject.get('voice:taskType') === TASK_TYPE.voiceRecordStart);
  }

  /**
   * Show label edit field and Hide Task Label vice versa
   * @param {string} elementName name of the element
   * @param {boolean} editLabel edit the element name or not 
   */
  function showLabelInputField(elementName = "", editLabel = false) {
    const inputField = taskLabelInputRef.current;
    const heading = taskHeadingRef.current;
    // initially if element name is not there or user wants to edit it
    if (!elementName || editLabel) {
      // hide the heading
      heading.classList.add("pmivr-hide-display");
      // display the input field
      inputField.classList.remove("pmivr-hide-display");
      // when click on edit icon button it should focus on input field
      document.getElementById("task_label").focus();
    } else {
      // show the heading
      heading.classList.remove("pmivr-hide-display");
      // hide the input field
      inputField.classList.add("pmivr-hide-display");
    }
  }

  /**
   * Show the heading when click outside the input field and update the name
   */
  function saveTaskName() {
    // set the element name without space
    updateName(taskName?.trim());
    const inputField = taskLabelInputRef.current;
    const heading = taskHeadingRef.current;
    // display heading
    heading.classList.remove("pmivr-hide-display");
    if (taskName) {
      //hide the input field
      inputField.classList.add("pmivr-hide-display");
    }
  }

  // update the name of the task
  function updateName(name) {
    // updating state after trim the task name
    setTaskName(name);
    const modeling = modeler.get("modeling");
    modeling.updateLabel(element, name);
  }

  return (
    <>
      <div className="tab-content pmivr-height-100" id="v-pills-tabContent">
        <div className="pmivr-right-panel p-2 tab-pane fade show active" id="audio-message" role="tabpanel">
          <i className="bi bi-x-lg close-arrow" onClick={DiagramService.closeRightPanel}></i>
          <div>
            <div onClick={(event) => { showLabelInputField(taskName, true); }} id="task_heading" ref={taskHeadingRef} title={element.businessObject.name} className="heading  p-2 float-start ">{element.businessObject.name}</div>
            <div className='edit-heading-setting'>
              <input
                type="text"
                className={`form-control float-start pmivr-input input-sm`}
                value={taskName}
                id="task_label"
                ref={taskLabelInputRef}
                onChange={(event) => setTaskName(event.target.value)}
                onBlur={(event) => { saveTaskName(); }}
                placeholder="Enter task name"
              />
            </div>
          </div>

          <nav>
            <VoiceContext.Provider
              value={{
                tabType: "tabList",
                element: element
              }}>
              <div className="nav nav-tabs pt-2 tab-info-bar" id="custom-tab" role="tablist">
                {renderPropUserInput(element) && <UserInputPropertiesView />}
                {/* {renderPropService(element) && <ServicePropertiesView />} */}
                {renderPropConfirmation(element) && <UserConfirmationPropertiesView />}
                {renderSayData(element) && <SayDataPropsView />}
                {renderTransfer(element) && <TransferPropertiesView />}
                {renderVoiceRecord(element) && <VoiceRecordPropsView />}
                {renderVoiceRecordStart(element) && <VoiceRecordStartPropsView />}
              </div>
            </VoiceContext.Provider>
          </nav>

          <VoiceContext.Provider
            value={{
              tabType: "tabContent", element: element, rightPanelEventHandler: rightPanelEventHandler,
              keyValVoiceFileEventsHandler: keyValVoiceFileEventsHandler
            }}>
            <div className="tab-content pmivr-scroll scroll pmivr-height-100" id="nav-tabContent">
              {renderOptionUserTask(element) && <OptionUserTaskView />}
              {renderPropVoice(element) && <VoicePromptsView element={element} rightPanelEventHandler={rightPanelEventHandler} />}
              {renderPropUserInput(element) && <UserInputPropertiesView />}
              {renderPropFlow(element) && <ConditionsView tabType="tabContent" element={element} ></ConditionsView>}
              {renderPropService(element) && <ServicePropertiesView />}
              {renderPropConfirmation(element) && <UserConfirmationPropertiesView />}
              {renderSayData(element) && <SayDataPropsView />}
              {renderTransfer(element) && <TransferPropertiesView />}
              {renderVoiceRecord(element) && <VoiceRecordPropsView />}
              {renderVoiceRecordStart(element) && <VoiceRecordStartPropsView />}
            </div>
          </VoiceContext.Provider>
        </div>
      </div>
    </>
  );
}

PropertiesView.propTypes = {
  element: PropTypes.object,
  modeler: PropTypes.object,
  // function to handle right bar on diagram page
  rightPanelEventHandler: PropTypes.func,
  keyValVoiceFileEventsHandler: PropTypes.func
}