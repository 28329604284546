import { DATA_TYPE } from "../constants/data-types";

/**
 * Utility for condition operations
 */
class ConditionUtil {
  /**
   * return the value according to selected data type
   * @param {Object} condition Condition info
   * @param {string} variableType // Type of variable
   */
  static getValueOfConditionByType(variableType, condition) {
    switch (variableType) {
      case DATA_TYPE.BOOLEAN:
        return condition.value;
      case DATA_TYPE.VARIABLE:
        return `this.environment.variables.ivrVar.${condition.value}`;
      case DATA_TYPE.DATE:
        return (condition.isDateOfCodeExecution) ? `new Date()` : `new Date("${condition.value}")`;
      case DATA_TYPE.NUMBER:
        return condition.value;
      case DATA_TYPE.STRING:
        return `"${condition.value}"`;
      default:
        return "";
    }
  }

  /**
  * Construct the condition as per condition Info
  * @param {Object} conditionInfo 
  * @returns {string}
  */
  static constructCondition(conditionInfo) {
    let strCondition;
    const variable = conditionInfo.variable;
    const operator = conditionInfo.operator;
    const jsExpression = conditionInfo.jsExpression;
    const variableType = conditionInfo.selectedVariableType;

    if (operator === "IS_DEFINED") {
      strCondition = `this.environment.variables.ivrVar.${variable}`;
    } else if (operator === "NOT_DEFINED") {
      strCondition = `!this.environment.variables.ivrVar.${variable}`;
    } else if (operator === "JS_EXPRESSION") {
      // To Handle negation in js expression e.g. !(.split(..).includes(...))
      const firstDotIndex = jsExpression.indexOf(".");
      strCondition = `${jsExpression.substring(0, firstDotIndex)}this.environment.variables.ivrVar.${variable}${jsExpression.substring(firstDotIndex)}`;
    } else {
      const value = ConditionUtil.getValueOfConditionByType(variableType, conditionInfo);
      const varKeys = variable.split(".");
      // if nested variable, need to check the value from array then call the function from agi-ms
      if (varKeys.length === 3) {
        strCondition = `this.environment.services.conditionListVariableCheck(this.environment.variables.ivrVar,"${variable}","${operator}",${value})`;
      } else if (variableType === DATA_TYPE.DATE) {
        // if variable is not list and selected Variable Type is Date
        strCondition = `new Date(this.environment.variables.ivrVar.${variable})  ${operator} ${value}`;
      } else {
        // if normal vairable
        strCondition = `this.environment.variables.ivrVar.${variable} ${operator} ${value}`;
      }
    }
    return strCondition;
  }
}

export default ConditionUtil;