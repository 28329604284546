import axios from "axios";

import store from "../redux/store/store";
import { updateLoaderState } from "../redux/actions/ui-state.action";

import AppUtil from "../util/app.util";
import StorageUtil from "../util/storage.util";

import { MESSAGES } from "../constants/messages";
import { APP_PAGES } from "../constants/app-pages";

import UserService from "../services/user.service";
import ClientService from "../services/client.service";

/**
 * Component is configuring the request and response for axios.
 */
const appAxios = axios.create();

// to maintain the count of api calls, in case of multiple api calls, hide the loader only in case of last call
let activeRequestCount = 0;

/**
 * Configuring the request : Adding JWT token to the request header.
 */
appAxios.interceptors.request.use((req) => {
    // show the loader before any API call
    store.dispatch(updateLoaderState({ showLoader: true }));
    activeRequestCount++;
    // getting the selected deployment environment for the business code
    const selectedEnvironment = store.getState().client.deploymentEnvironment;
    // adding the selected deployment environment to each request header
    req.headers['environment'] = selectedEnvironment || "";
    const currentUser = UserService.getCurrentUser();
    if (currentUser) {
        req.headers['token'] = currentUser?.token;
    }
    req.headers['app-code'] = ClientService.getAppCode();
    return req;
}, null, { synchronous: true });

/**
 * Configuring the response received
 */
appAxios.interceptors.response.use(
    (res) => {
        activeRequestCount--;
        // hide the loader after getting the response, only if it is the last api call
        if (activeRequestCount === 0) {
            store.dispatch(updateLoaderState({ showLoader: false }));
        }
        return res.data;
    },
    (err) => {
        activeRequestCount = 0;
        store.dispatch(updateLoaderState({ showLoader: false }));
        if (err.response) {
            // if you are unauthorized, then we do following
            if (err.response.status === 401) {
                // clearing the local storage, as we are unauthorized now.
                StorageUtil.clearStorage();
                if (!window.location.href.endsWith(APP_PAGES.LOGIN)) {
                    alert(MESSAGES.SESSION_EXPIRED);
                    // in case of 401 save the last saved url in ls so that same can be loaded after login
                    UserService.setlastVisitedUrl(window.location.href);
                    AppUtil.navigateTo(APP_PAGES.LOGIN);
                }
            }
        }
        throw err;
    }
);

export default appAxios;