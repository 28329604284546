import { envConfig } from "../environment";

import appAxios from "../util/app.axios";

/**
 * Responsible for user roles related operations
 */
class RoleService {

    static BASE_URL = `${envConfig.REACT_APP_API_BASE_URL}`;

    /**
     * Get all the user roles configured in DB
     * @returns {Promise<{data : Array<Roles}>} response from the /users/roles api
     */
    static getRoles() {
        const url = `${this.BASE_URL}/users/roles`;
        return appAxios.get(url);
    }
}

export default RoleService;