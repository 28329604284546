// Require your custom property entries.
import userInputDetailProps from "./parts/UserInputDetailProps";
import { is } from 'bpmn-js/lib/util/ModelUtil';


// The properties are organized in groups.
function createUserInputTabGroups(element, translate) {
  if (is(element, 'bpmn:UserTask') && element.businessObject.isUserInput === true) {

    var userInputGroup = {
      id: "user-input",
      label: "Input",
      entries: []
    };

    // Add the  props to the  group.
    userInputDetailProps(userInputGroup, element, translate);
    return [userInputGroup];
  }
}

export default function UserInputPropertiesProvider(propertiesPanel, translate) {
  this.getTabs = function (element) {
    return function (entries) {
      // Add the "voiceFiles" tab
      var serviceImplementationTab = {
        id: "user-input",
        label: "Input",
        groups: createUserInputTabGroups(element, translate)
      };

      entries.push(serviceImplementationTab);

      // Show general + "voiceFiles" tab
      return entries;
    };
  };

  // Register our custom voiceFiles properties provider.
  // Use a lower priority to ensure it is loaded after the basic BPMN properties.
  propertiesPanel.registerProvider(this);
}

UserInputPropertiesProvider.$inject = ["propertiesPanel", "translate"];
