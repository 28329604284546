import BaseRenderer from 'diagram-js/lib/draw/BaseRenderer';
import { is } from 'bpmn-js/lib/util/ModelUtil';
import { isAny } from 'bpmn-js/lib/features/modeling/util/ModelingUtil';
import { append, create, attr, classes, } from 'tiny-svg';

import { TASK_TYPE } from '../../../constants/task-types';

import { SHAPE } from '../../../config/config';
import { ELEMENT_COLORS, EL_TYPE } from '../../../constants/element';

import Play from './images/play';
import Service from './images/service';
import UserInput from "./images/user-input";
import UserOption from './images/user-option';
import Info from './images/info';
import HangUpCall from './images/hangup';
import TransferCall from './images/transfer';
import micLogo from "./images/mic"
import voiceRecordLogo from "./images/record"
import threeDots from './images/three-dots';
import listOlLogo from "./images/option"
import listUtlLogo from './images/list-ul';
import VoiceRecordStart from "./images/voice-record-start";
import VoiceRecordStop from "./images/voice-record-stop";

import DiagramUtil from '../../../util/diagram.util';

import DiagramService from "../../../services/diagram.service";

/**
 * Rendering the diagram nodes and connections
 */
export default class CustomRenderer extends BaseRenderer {
  constructor(eventBus, bpmnRenderer, elementRegistry) {
    super(eventBus);
    this.bpmnRenderer = bpmnRenderer;
    this.elementRegistry = elementRegistry;
    // Track the currently active connection
    this.activeConnection = null;
    BaseRenderer.call(this, eventBus, 1500);
  }

  /**
   * Checking whether element to render or not
   * @param {Object} element Element to render or not
   */
  canRender = function (element) {
    const bo = element.businessObject;
    return isAny(element, ['bpmn:Task', 'bpmn:Event', 'bpmn:SubProcess', 'bpmn:SequenceFlow']) && !element.labelTarget &&
      (bo.taskType === TASK_TYPE.playVoiceFile || bo.isOptionInput
        || bo.isUserInput || bo.isDynamicOption
        || bo.isEditableListOption || bo.isKeyValueOption
        || bo.isLoop || bo.isSayData
        || bo.isTransferTask || bo.isRecordVoice || bo.isVoiceRecordStart || bo.isVoiceRecordStop
        || bo.isUserConfirmation  //|| element.businessObject.get('serviceImpl:isServiceImpl') === "true"
        || bo.$type === 'bpmn:SubProcess' || bo.$type === 'bpmn:ServiceTask'
        || bo.$type === 'bpmn:SequenceFlow');
  };

  /**
   * Draw the connection
   * @param {Object} parent Make connection with child
   * @param {Object} shape Type of shape to draw and connect with parent
   */
  drawConnection = function (parent, shape) {
    let conn = this.bpmnRenderer.drawShape(parent, shape);
    // if connection line is created and its source is gateway then make it disabled
    if (shape?.source?.type === EL_TYPE.GATEWAY) {
      attr(conn, {
        stroke: ELEMENT_COLORS.DISABLED_COLOR,
        strokeWidth: 3,
      });
    } else {
      attr(conn, {
        stroke: ELEMENT_COLORS.DEFAULT_CONNECTION_COLOR,
        strokeWidth: 3,
      });
    }

    //  Append event listeners for hover and click
    conn.addEventListener('mouseover', () => {
      if (this.activeConnection !== shape.id) {
        attr(conn, { stroke: 'orange' }); // Change color on hover
      }
    });

    conn.addEventListener('mouseout', () => {
      // Reset color on mouse out
      if (this.activeConnection !== shape.id) {
        // attr(conn, { stroke: ARROW_COLOR }); // Reset to original color
        setTimeout(() => {
          if (shape?.source?.type === EL_TYPE.GATEWAY) {
            attr(conn, {
              stroke: ELEMENT_COLORS.DISABLED_COLOR, // Revert to default after delay
            });
          } else {
            attr(conn, {
              stroke: ELEMENT_COLORS.DEFAULT_CONNECTION_COLOR, // Revert to default after delay
            });
          }
        }, 300); // Adjust delay (in milliseconds) as needed
      }
    });

    conn.addEventListener('click', (event) => {
      // Prevent propagation if clicking on a connection
      event.stopPropagation();

      // Retrieve all connections from the element registry
      const allConnections = this.elementRegistry.filter(
        (shape) => shape.type === 'connection'
      );

      // Reset all connections to their original color
      allConnections.forEach((connection) => {
        const gfx = this.elementRegistry.getGraphics(connection);
        attr(gfx, {
          stroke: ELEMENT_COLORS.DEFAULT_CONNECTION_COLOR, // Reset to original color
        });
      });

      // Highlight the clicked connection
      this.activeConnection = shape.id; // Set the new active connection
      attr(conn, {
        stroke: '#ec366d', // Change to the highlighted color
      });
    });

    return conn;
  }

  /**
   * Draw the shape
   * Getting called on all the events like -> element is moved, expanded, collapsed, renamed
   * @param {Object} parent Properties passed
   * @param {Object} shape Properties passed
   */
  drawShape = function (parent, shape) {
    let iconUrl = "";
    let infoIconUrl = "";
    if (is(shape, EL_TYPE.TASK) && shape.businessObject.taskName && shape.businessObject.taskName === "HangUp") {
      iconUrl = HangUpCall.dataURL; // url for icon of the control
      infoIconUrl = Info.dataURL; // url for info icon on the node in the diagram
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    }
    if (is(shape, EL_TYPE.TASK) && shape.businessObject.taskType === TASK_TYPE.playVoiceFile) {
      iconUrl = Play.dataURL; // url for icon of the control
      infoIconUrl = Info.dataURL; // url for info icon on the node in the diagram
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    } else if (is(shape, EL_TYPE.USER_TASK) && shape.businessObject.isRecordVoice) {
      iconUrl = voiceRecordLogo.dataURL;
      infoIconUrl = Info.dataURL;
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    } else if (is(shape, EL_TYPE.TASK) && shape.businessObject.isSayData) {
      iconUrl = micLogo.dataURL; // url for icon of the control
      infoIconUrl = Info.dataURL; // url for info icon on the node in the diagram
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    } else if (is(shape, EL_TYPE.USER_TASK) && (shape.businessObject.isOptionInput
      || shape.businessObject.isDynamicOption || shape.businessObject.isKeyValueOption)) {
      iconUrl = UserOption.dataURL; // url for icon of the control
      infoIconUrl = Info.dataURL; // url for info icon on the node in the diagram
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    } else if ((is(shape, EL_TYPE.USER_TASK) && shape.businessObject.isUserInput)) {
      iconUrl = UserInput.dataURL; // url for icon of the control
      infoIconUrl = Info.dataURL; // url for info icon on the node in the diagram
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    } else if (is(shape, EL_TYPE.USER_TASK) && shape.businessObject.isUserConfirmation) {
      iconUrl = listUtlLogo.dataURL; // url for icon of the control
      infoIconUrl = Info.dataURL; // url for info icon on the node in the diagram
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    } else if (is(shape, EL_TYPE.USER_TASK) && shape.businessObject.isEditableListOption) {
      iconUrl = listOlLogo.dataURL; // url for icon of the control
      infoIconUrl = Info.dataURL; // url for info icon on the node in the diagram
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    } else if (is(shape, EL_TYPE.TASK) && shape.businessObject.isTransferTask) {
      iconUrl = TransferCall.dataURL; // url for icon of the control
      infoIconUrl = Info.dataURL; // url for info icon on the node in the diagram
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    } else if (is(shape, EL_TYPE.SERVICE_TASK)) {
      iconUrl = Service.dataURL; // url for icon of the control
      infoIconUrl = Info.dataURL; // url for info icon on the node in the diagram
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    } else if (is(shape, EL_TYPE.TASK) && shape.businessObject.isVoiceRecordStart) {
      iconUrl = VoiceRecordStart.dataURL;
      infoIconUrl = Info.dataURL;
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    } else if (is(shape, EL_TYPE.TASK) && shape.businessObject.isVoiceRecordStop) {
      iconUrl = VoiceRecordStop.dataURL;
      infoIconUrl = Info.dataURL;
      return this.drawCustomShape(parent, shape, iconUrl);
    } else if (is(shape, EL_TYPE.SUB_PROCESS)) {
      if (!shape.collapsed) {
        return this.bpmnRenderer.drawShape(parent, shape);
      }
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    }
    else {
      return this.drawCustomShape(parent, shape, iconUrl, infoIconUrl);
    }
  }

  /**
   * Return the voice file path of first language 
   * @param {Object} element Object of element
   */
  getVoiceFileTitle(element) {
    let voiceFilePath = "";
    // If the element is record voice then it saves file path as a different attribute
    if (element.businessObject.taskType === TASK_TYPE.recordVoice) {
      voiceFilePath = element.businessObject.voiceRecordVoiceFilePath;
    } else if (element.businessObject.taskType === TASK_TYPE.keyValueUserOption) {
      // get the voice file path of first language
      let info = JSON.parse(element.businessObject.keyValueMap || "{}");
      if (info) {
        const keys = Object.keys(info);
        const voiceFileInfo = info[keys[0]] || [];
        if (keys.length && voiceFileInfo.length) {
          // if file path is empty then return tts 
          if (voiceFileInfo[0].filePath) {
            voiceFilePath = voiceFileInfo[0].filePath || "";
          } else {
            voiceFilePath = voiceFileInfo[0].ttsText || "";
          }
        }
      }
    } else {
      let info = JSON.parse(element.businessObject.voiceFileInfo || "{}");
      if (info) {
        const keys = Object.keys(info);
        const voiceFileInfo = info[keys[0]] || {};
        if (keys.length) {
          voiceFilePath = voiceFileInfo.filePath || "";
          // if file path is empty then return tts 
          if (voiceFileInfo.filePath) {
            voiceFilePath = voiceFileInfo.filePath || "";
          } else {
            voiceFilePath = voiceFileInfo.ttsText || "";
          }
        }
      }
    }
    return voiceFilePath;
  }

  /**
   * Draws the custom shape
   * @param {*} parent With djs-visual
   * @param {Shape} element Element being created
   * @param {string} iconUrl 
   */
  drawCustomShape(parent, element, iconUrl, infoIconUrl) {
    const BOX_WIDTH = SHAPE.SHAPE_WIDTH;
    const BOX_HEIGHT = SHAPE.SHAPE_HEIGHT;
    // for new elements update the default shape width/height to custom element's width/height
    // to solve the connection arrow problem - 
    // https://forum.bpmn.io/t/arrow-starting-point-position-is-fixed-to-certain-width-height/8037/7
    element.width = BOX_WIDTH;
    element.height = BOX_HEIGHT;
    // Append shapes and element in parent element to create UI of custom flow elements (shape of the node)  
    // outer box
    const elementShape = drawRect(BOX_WIDTH, BOX_HEIGHT, 10, '#FFFF');
    attr(elementShape, {
      stroke: ELEMENT_COLORS.OUTER_BOX_RECT_COLOR,
      "stroke-width": "1px",
    });

    append(parent, elementShape);

    // control icon
    if (iconUrl) {
      let controlIcon = create('image', {
        x: 10,
        y: 80,
        width: 25,
        height: 25,
        href: iconUrl,
      });
      append(parent, controlIcon);
    }

    // info icon
    if (infoIconUrl) {
      let infoIcon = create('image', {
        x: 190,
        y: 80,
        width: 25,
        height: 25,
        href: infoIconUrl,
        cursor: "pointer"
      });
      // Title for the icon shown on hover
      let infoTitle = '<title>Display task info</title>';
      // Append the title to the infoIcon
      infoIcon.innerHTML = infoTitle;
      classes(infoIcon).add('node-info-icon');
      // append the info icon to the parent node 
      append(parent?.parentNode, infoIcon);
    }

    // Check if the element is disabled
    const isElementDisabled = DiagramUtil.checkElementDisabled(element);

    // line at the top of node - top border
    const lineTop = create('line', {
      x1: 5,
      y1: 2,
      x2: 267,
      y2: 2,
      strokeWidth: 6,
      // if the element is disabled, stroke color will be grey
      stroke: isElementDisabled ? ELEMENT_COLORS.DISABLED_COLOR : ELEMENT_COLORS.LINE_AT_NODE_TOP_COLOR
    });
    // to identify the line element to change the border dynamically; 
    // if element has changes border top (sttroke ) will have different color
    classes(lineTop).add('el-border-top');
    append(parent, lineTop);

    // line dividing the node
    const lineDivider = create('line', {
      x1: 0,
      y1: 70,
      x2: SHAPE.SHAPE_WIDTH,
      y2: 70,
      strokeWidth: 1,
      stroke: ELEMENT_COLORS.LINE_DIVIDER_COLOR
    });
    append(parent, lineDivider);

    //three dots icon
    const threeDotIcon = create('image', {
      x: 230,
      y: 80,
      width: 25,
      height: 25,
      href: threeDots.dataURL,
      cursor: "pointer"
    });
    // Title for the icon shown on hover
    let threeDotIconTitle = '<title>More Actions</title>';
    // Append the title to the infoIcon
    threeDotIcon.innerHTML = threeDotIconTitle;
    classes(threeDotIcon).add('context-pad-element-' + element.type);
    // due to this class it will open right click context menu
    classes(threeDotIcon).add('context-pad-open');
    // append the three dot icon to the parent node
    append(parent?.parentNode, threeDotIcon);

    // Heading space and and it's text 
    // if elment has the name then add text element
    const elementName = element.businessObject.name;
    if (elementName) {
      // Heading text color will be different in case of disabled element
      const headingTextColor = isElementDisabled ?
        ELEMENT_COLORS.DISABLED_COLOR : ELEMENT_COLORS.NODE_HEADING_TEXT_COLOR;
      const wrappedHeadingText = drawText(headingTextColor, "bold", 'translate(15, 45)', 16, 170);
      classes(wrappedHeadingText).add('djs-label');
      attr(wrappedHeadingText, { x: 0, y: -18 });
      //checking the length of node name and adjusting as per configured node text length
      let elementNameDisplayed = elementName;
      if (elementNameDisplayed.length > 23) {
        elementNameDisplayed = elementNameDisplayed.substring(0, 23) + '...';
      }
      // add actual text and append to the text node (document is used for bpmn)
      append(wrappedHeadingText, document.createTextNode(elementNameDisplayed));
      // append the text node
      append(parent, wrappedHeadingText);
    }

    // position of elemnt - another text below heading
    // show voice file path
    const voiceFilePath = this.getVoiceFileTitle(element);
    // voice file text color will be grey in case of disabled component
    const voiceFileTextColor = isElementDisabled ?
      ELEMENT_COLORS.DISABLED_COLOR : ELEMENT_COLORS.VOICE_FILE_TEXT_COLOR;
    if (voiceFilePath) {
      const textEle = drawText(voiceFileTextColor, "bold", 'translate(15, 45)', 12, 20);
      classes(textEle).add('djs-label');
      attr(textEle, { x: 0, y: 3 });
      append(textEle, document.createTextNode(`Voice File:`)); // document is used for bpmn
      append(parent, textEle);

      // Voice file path in second line (if node text is above the limit, then do substring of it )
      //Checking the length of voice file path and adjusting as per configured node text length
      let voiceFilePathDisplayed = voiceFilePath;
      if (voiceFilePathDisplayed.length > 42) {
        voiceFilePathDisplayed = voiceFilePathDisplayed.substring(0, 42) + '...';
      }
      const voiceFileNameColor = isElementDisabled ?
        ELEMENT_COLORS.DISABLED_COLOR : ELEMENT_COLORS.VOICE_FILE_NAME_COLOR;
      const filePath = drawText(voiceFileNameColor, "bold", 'translate(15, 45)', 10, 20);
      classes(filePath).add('djs-label');
      attr(filePath, { x: 0, y: 15 });
      append(filePath, document.createTextNode(voiceFilePathDisplayed)); // document is used for bpmn
      append(parent, filePath);
    }

    // Element on hover it will shown
    // Need to add this element as we want to add hoverEl
    // Providing height/width over default djs-hit can work but it does not fit on condition dianmond and start/end el
    const hoverEl = drawRect(180, 120, 0, ELEMENT_COLORS.WHITE_COLOR);
    attr(hoverEl, {
      x: 0,
      y: 0,
      stroke: ELEMENT_COLORS.WHITE_COLOR,
      strokeOpacity: '0',
      strokeWidth: 15,
      fill: 'none'
    });

    classes(hoverEl).add('djs-hit');
    classes(hoverEl).add('djs-hit-all');
    // Class matches the height and width
    classes(hoverEl).add('hoverEls');
    append(parent?.parentNode, hoverEl);

    // For hover effect -> show the dotted line over the
    const hoverElOutline = drawRect(0, 0, 0, 'none');// update height width in css class accordingly
    attr(hoverElOutline, {
      x: -6,
      y: -6
    });
    classes(hoverElOutline).add('djs-outline');
    classes(hoverElOutline).add('hoverEl');
    append(parent?.parentNode, hoverElOutline);

    // As drawCustom shape is getting on any chaneg on element like move/rename/expand/collapse etc
    // If subprocess is moved then all its child elements rendered again
    // So here changed element can be highlighted
    // timeout to let render subprocess and its children element
    // Child element will highlight parent as well
    setTimeout(() => {
      DiagramService.highlightChangedEl(element.id);
    }, 500);
    return elementShape;
  }
}

/**
 * Draw the rectangle (BPMN)
 * @param {number} width 
 * @param {number} height 
 * @param {number} borderRadius 
 * @param {string} color 
 */
function drawRect(width, height, borderRadius, color) {
  const rect = create('rect');
  attr(rect, {
    width: width,
    height: height,
    rx: borderRadius,
    ry: borderRadius,
    fill: color
  });
  return rect;
}

/**
 * Draw the text (BPMN)
 * @param {string} color 
 * @param {number} fontWeight 
 * @param {string} transform 
 * @param {number} fontSize 
 * @param {number} width 
 */
function drawText(color, fontWeight, transform, fontSize, width) {
  const textEle = create('text');
  attr(textEle, {
    fill: color,
    fontWeight: fontWeight,
    transform: transform,
    fontSize: fontSize,
    inlineSize: width
  });
  return textEle;
}

CustomRenderer.$inject = ['eventBus', 'bpmnRenderer', 'elementRegistry'];
