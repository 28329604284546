import { updateVoiceFilePrefixObj, clearState } from "../slices/voice-file.slice";

export const updateVoiceFilePrefixInfo = ({ voiceFilePrefixObj }) => async dispatch => {
    dispatch(updateVoiceFilePrefixObj({ voiceFilePrefixObj }));
}

/**
 * dispatch the action to clear the values from redux state
 */
export const clearVoiceFileState = () => async dispatch => {
    dispatch(clearState());
}