import React from "react";
import { createRoot } from "react-dom/client";
import { CookiesProvider } from "react-cookie";
import * as Sentry from "@sentry/react";
import { Provider } from "react-redux";
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import store from "./redux/store/store";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import { envConfig } from "./environment";

import App from "./app";

const persistor = persistStore(store);
//dsn tells a Sentry SDK where to send events so the events are associated with the correct project
const dsnValue = envConfig.REACT_APP_DSN;
if (dsnValue) {
  Sentry.init({
    environment: envConfig.REACT_APP_ENVIRONMENT,
    dsn: dsnValue,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      // `hint` is an object that provides additional context, 
      // including the original exception that triggered the event.
      // originalException is the actual error object that is sent to sentry to log
      const error = hint?.originalException;

      // Check if the error is an HTTP 401 (Unauthorized) error.
      // This could happen, for example, during a failed login attempt with invalid credentials.
      if (error?.response?.status === 401) {
        return null;
      }
      return event;
    },
  });
}

const rootElement = document.getElementById("root");
// createRoot is same as ReactDomRender but it is supported in react 18 or above version.
createRoot(rootElement).render(
  // <CookiesProvider> is used for handling the cookies. 
  // To have this functionality across the application. So, keeing it at the top level.
  <Provider store={store}>
    {/* loading here is null as we do not want to load for data */}
    <PersistGate loading={null} persistor={persistor}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </PersistGate>
  </Provider>
);