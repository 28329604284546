import { assign, map, pick } from 'min-dash';

/**
 * Factory class for creating BPMN diagram elements with moddle, which provides
 * the BPMN model instance. This class supports creating shapes, edges, labels,
 * waypoints, bounds, planes, and diagrams for BPMN diagrams.
 */
export class DiFactory {
  /**
   * Creates an instance of DiFactory.
   * @param {Object} moddle - The moddle instance used for creating BPMN elements.
   */
  constructor(moddle) {
    this.moddle = moddle;
  }

  /**
   * Creates a BPMN element of a given type with specified attributes.
   * @param {string} type - The type of BPMN element to create.
   * @param {Object} [attrs] - Attributes for the created element.
   * @returns {Object} - The created BPMN element.
   */
  create(type, attrs) {
    return this.moddle.create(type, attrs || {});
  }

  /**
   * Creates a Bounds element in the BPMN diagram.
   * @param {Object} bounds - The bounds for the element, typically with x, y, width, and height.
   * @returns {Object} - The created Bounds element.
   */
  createDiBounds(bounds) {
    return this.create('dc:Bounds', bounds);
  }

  /**
   * Creates a Label element for a BPMN diagram shape.
   * @returns {Object} - The created BPMNLabel element.
   */
  createDiLabel() {
    return this.create('bpmndi:BPMNLabel', {
      bounds: this.createDiBounds()
    });
  }

  /**
   * Creates a Shape element for a BPMN diagram, representing nodes in the diagram.
   * @param {Object} semantic - The BPMN element represented by the shape.
   * @param {Object} bounds - The bounds for the shape element.
   * @param {Object} [attrs] - Additional attributes for the BPMNShape element.
   * @returns {Object} - The created BPMNShape element.
   */
  createDiShape(semantic, bounds, attrs) {
    return this.create('bpmndi:BPMNShape', assign({
      bpmnElement: semantic,
      bounds: this.createDiBounds(bounds)
    }, attrs));
  }

  /**
   * Creates a set of Waypoint elements for a BPMN edge, based on provided waypoints.
   * @param {Array<Object>} waypoints - Array of waypoint positions, each with x and y coordinates.
   * @returns {Array<Object>} - Array of created Point elements.
   */
  createDiWaypoints(waypoints) {
    var self = this;

    return map(waypoints, function(pos) {
      return self.createDiWaypoint(pos);
    });
  }

  /**
   * Creates a single Waypoint element for a BPMN edge.
   * @param {Object} point - The point representing a waypoint, with x and y coordinates.
   * @returns {Object} - The created Point element.
   */
  createDiWaypoint(point) {
    return this.create('dc:Point', pick(point, [ 'x', 'y' ]));
  }

  /**
   * Creates an Edge element for a BPMN diagram, representing connections between nodes.
   * @param {Object} semantic - The BPMN element represented by the edge.
   * @param {Array<Object>} waypoints - Array of waypoints defining the edge path.
   * @param {Object} [attrs] - Additional attributes for the BPMNEdge element.
   * @returns {Object} - The created BPMNEdge element.
   */
  createDiEdge(semantic, waypoints, attrs) {
    return this.create('bpmndi:BPMNEdge', assign({
      bpmnElement: semantic,
      waypoint: this.createDiWaypoints(waypoints)
    }, attrs));
  }

  /**
   * Creates a Plane element in the BPMN diagram.
   * @param {Object} attrs - Attributes for the BPMNPlane element, typically linking it to a BPMN element.
   * @returns {Object} - The created BPMNPlane element.
   */
  createDiPlane(attrs) {
    return this.create('bpmndi:BPMNPlane', attrs);
  }

  /**
   * Creates a Diagram element in the BPMN diagram.
   * @param {Object} attrs - Attributes for the BPMNDiagram element.
   * @returns {Object} - The created BPMNDiagram element.
   */
  createDiDiagram(attrs) {
    return this.create('bpmndi:BPMNDiagram', attrs);
  }
}