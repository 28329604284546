import moment from "moment";

class DateUtil {
    /**
     * Convert date string to readable string format (like data string of mongo db)
     * @param {string} date date string 
     * @returns {string}
     */
    static toDateString(date) {
        const dateObj = new Date(date);
        return dateObj.toDateString();
    };

    /**
     * Parses a date string in the format "DD-MM-YYYY HH:mm" and returns a JavaScript Date object.
     * @param {string} dateString - The date string in the format "DD-MM-YYYY HH:mm".
     * @returns {Date} - A JavaScript Date object representing the parsed date and time.
     * Example: 
     * const date = parseDateString("01-10-2024 14:30");
     * console.log(date); // Output: Wed Oct 01 2024 14:30:00 GMT+0000 (Coordinated Universal Time)
     */
    static parseDateString(dateString) {
        return moment(dateString, "DD-MM-YYYY HH:mm").toDate();
    }

    /**
     * Formats a date and time string from a specified source format to a target format.
     * @param {string} formatvalue - The date and time string to be formatted.
     * @param {string} sourceFormat - The format of the input date and time string.
     * @param {string} targetFormat - The format to convert the date and time string into.
     * @returns {string} The formatted date and time string if valid;
     */
    static format = (formatvalue, sourceFormat, targetFormat) => {
        const formattedValue = moment(formatvalue, sourceFormat);
        if (!formattedValue.isValid()) {
            return; // Return if invalid
        }
        return formattedValue.format(targetFormat);
    };

    /**
     * Utility function to check if a date string is in MMDDYYYY format.
     * @param {string} date - The date string to check.
     * @param {string} format - format to check default value is MMDDYYYY.
     * @returns {boolean} True if the date is correct format, otherwise false.
     */
    static isValidFormat = (date, format = "MMDDYYYY") => {
        return moment(date, format, true).isValid();
    };
}

export default DateUtil;