import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { string, object } from 'yup';
import { useFormik } from "formik";
import Form from 'react-bootstrap/Form';
import { useSelector } from "react-redux";

import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { APP_PAGES } from '../../../constants/app-pages';

import StorageUtil from '../../../util/storage.util';
import AppUtil from '../../../util/app.util';

import { PmivrDialog } from "../../../components/common/dialog/pmivr-dialog";
import PmivrSnackBar from '../dialog/pmivr-snackbar';
import PmivrTooltip, { TOOLTIP_POSITIONS } from "../../../components/common/tooltip/pmivr-tooltip";

import UserService from '../../../services/user.service';
import AuthService from '../../../services/auth.service';
import ErrorService from '../../../services/error.service';

/**
 * Dialog box opened for filling password to update the token (session)
 * to continue the user working
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html code to render dialog
 */
const SessionExpireDialog = ((props) => {
    const { initiateSessionCheck } = props;
    const snackbarRef = useRef();
    // get the state of sessionExpire used in openeing and closing of session dialog
    const { sessionCheckInfo, user } = useSelector(state => state.user);
    const [uiState, setUiState] = useState({ errMsg: "", passwordVisible: false });
    // time that will be left for filling password in minutes
    const timeLeftForPassword = 3;
    // remaing time left in session expire , will show the pop up 3 mins before expire
    const [timeRemaining, setTimeRemaining] = useState(timeLeftForPassword * 60 * 1000);
    let intervalId;

    const validate = object({
        password: string().trim().required(MESSAGES.ERR.FIELD_REQUIRED)
    });

    // formik values for form
    const formik = useFormik({
        initialValues: { password: "" },
        validationSchema: validate,
        onSubmit: (values) => {
            getNewSession(values);
        },
    });

    useEffect(() => {
        intervalId = setInterval(() => {
            setTimeRemaining(prevTime => {
                if (prevTime <= 0 && timeRemaining) {
                    // when session is expired then naviagate to login page
                    clearInterval(intervalId);
                    closeSessionDialog();
                    return 0;
                }
                return prevTime - 1000;
            });
            // 1000 ms is 1 sec and it checks ifuser enters the password if not then logout, 
        }, 1000);

        return () => clearInterval(intervalId);
        // time converted to milliseconds
    }, [timeLeftForPassword * 60 * 1000]);

    useEffect(() => {
        if (!sessionCheckInfo?.isSessionExpiring) {
            clearInterval(intervalId);
        }
    }, [sessionCheckInfo?.isSessionExpiring]);

    /**
     * Sets the state for password visibility to previous state on click of toggle button
     * if it was visible initially, hide it
     * if it was hidden, show it
     */
    const togglePasswordVisibility = () => {
        setUiState({ ...uiState, passwordVisible: !uiState?.passwordVisible });
    };

    /**
     * Get the new session by login through passwors eneterd in pop up of session expire
     * @param {Object} values formik values from the form to get password
     */
    const getNewSession = async (values) => {
        try {
            // below are to be done on pop dialog submit
            const currentUser = UserService.getCurrentUser();
            // passing true value to session check so that it does not returns 401.
            await AuthService.login(currentUser?.email, values.password, true);
            AuthService.setSessionCheckInfo({ isSessionCheckInitiated: true, isSessionExpiring: false });
            // once the session checked up then new session is initited
            initiateSessionCheck();
            // opening the snackbar to show session validated successfully
            snackbarRef.current.open(MESSAGES.SESSION_VALIDATED_SUCCESS);
        } catch (err) {
            setUiState({ ...uiState, errMsg: ErrorService.getErrorMessage(err) });
        }
    }

    /**
     * Close the session dialog pop up
     * if user cancels the dialog then it is logout and redirect to login page
     */
    const closeSessionDialog = () => {
        StorageUtil.clearStorage();
        AppUtil.navigateTo(APP_PAGES.LOGIN);
    }

    return (
        <>
            <PmivrSnackBar ref={snackbarRef} />
            {/* dialog box for session expire password input  */}
            <PmivrDialog showDialog={sessionCheckInfo?.isSessionExpiring} closeDialog={closeSessionDialog}
                staticBackdrop={true} showCloseButton={false}
                title={`Your session is expired. Kindly login`}
                message={<>
                    <div className="row col-sm-12">
                        <Form>
                            <div className="pmivr-label">
                                <label className="pt-3">Email</label>
                            </div>
                            <div className="password-input-wrapper">
                                <input type="text" id="email" name="email" className="form-group form-control pmivr-input"
                                    value={user?.email} placeholder={`Enter your Email`} disabled={true} />
                            </div>
                            <div className="pmivr-label">
                                <label className="pt-3">Password</label>
                            </div>
                            <div className="password-input-wrapper">
                                <input type={uiState.passwordVisible ? "text" : "password"} id="password" name="password" className="form-group form-control pmivr-input"
                                    value={formik.values.name} placeholder={`Enter your password`}
                                    onChange={(e) => { formik.handleChange(e); setUiState({ ...uiState, errMsg: "" }) }}
                                    // on pressing enter get new session
                                    onKeyDown={(e) => AppUtil.isEnterKey(e) ? getNewSession(formik.values) : null} />
                                <PmivrTooltip message={TOOLTIP.PASSWORD_VISIBILITY} position={TOOLTIP_POSITIONS.RIGHT}>
                                    <i className={`bi ${uiState?.passwordVisible ? "bi-eye" : "bi-eye-slash"}`} onClick={togglePasswordVisibility} />
                                </PmivrTooltip>
                            </div>
                            {formik.touched.password && formik.errors.password && (
                                <span className='field-error text-center'>{formik.errors.password}</span>
                            )}
                            {uiState.errMsg && (
                                <span className='field-error text-center'>{uiState.errMsg}</span>
                            )}
                        </Form>
                    </div>
                </>}
                footer={
                    <div>
                        <div className="float-end mt-2">
                            <PmivrTooltip message={TOOLTIP.LOGIN} position={TOOLTIP_POSITIONS.BOTTOM}>
                                <button className="pmivr-btn-app" type="submit" onClick={formik.handleSubmit}>
                                    Login
                                </button>
                            </PmivrTooltip>
                        </div>
                        <div className="float-end mt-2 pe-2">
                            <PmivrTooltip message={TOOLTIP.CANCEL} position={TOOLTIP_POSITIONS.BOTTOM}>
                                <button className="pmivr-btn-cancel" type="button" onClick={() => closeSessionDialog()}>
                                    Cancel
                                </button>
                            </PmivrTooltip>
                        </div>
                    </div>
                } /></>
    )
});

// proptypes used in this component
SessionExpireDialog.propTypes = {
    // func to inititate the session check 
    // on again login, inittiatw the session that is check for expiry again
    initiateSessionCheck: PropTypes.func
}

export default SessionExpireDialog;