import { connectElements } from '../utils/layoutUtil.js';
import { is } from '../di/DiUtil.js';
import { findElementInTree } from '../utils/elementUtils.js';


export default {
  /**
   * Adds an element to the grid and processes outgoing paths. Manages grid positioning
   * based on outgoing elements, and ensures traversal without revisiting elements or creating loops.
   * Handles self-loop detection and places elements in the stack by priority.
   * @param {Object} params - Parameters for adding the element to the grid.
   * @param {Object} params.element - The BPMN element to be added to the grid.
   * @param {Object} params.grid - The grid where elements are positioned.
   * @param {Set} params.visited - Set of already visited elements to prevent duplicates.
   * @param {Array} params.stack - Stack of elements for processing.
   * @returns {Array} - Array of next elements to be processed, sorted by priority.
   */
  'addToGrid': ({ element, grid, visited, stack }) => {
    let nextElements = [];

    // Handle outgoing paths
    const outgoing = (element.outgoing || [])
      .map(out => out.targetRef)
      .filter(el => el);

    let previousElement = null;

    if (outgoing.length > 1 && isNextElementTasks(outgoing)) {
      grid.adjustGridPosition(element);
    }

    outgoing.forEach((nextElement, index, arr) => {
      if (visited.has(nextElement)) {
        return;
      }

      // Avoid revisiting incoming elements and prevent loops
      if ((previousElement || stack.length > 0) && isFutureIncoming(nextElement, visited) && !checkForLoop(nextElement, visited)) {
        return;
      }

      if (!previousElement) {
        grid.addAfter(element, nextElement);
      }

      else if (is(element, 'bpmn:ExclusiveGateway') && is(nextElement, 'bpmn:ExclusiveGateway')) {
        grid.addAfter(previousElement, nextElement);
      }
      else {
        grid.addBelow(arr[index - 1], nextElement);
      }

      // Check for self-looping
      if (nextElement !== element) {
        previousElement = nextElement;
      }

      nextElements.unshift(nextElement);
      visited.add(nextElement);
    });

    // Sort elements by priority to ensure proper stack placement
    nextElements = sortByType(nextElements, 'bpmn:ExclusiveGateway'); // TODO: sort by priority
    return nextElements;
  },

  /**
   * Creates a BPMN connection diagram element (Di) between the given element and its target.
   * @param {Object} params - Parameters for creating the connection Di.
   * @param {Object} params.element - The BPMN element with outgoing connections.
   * @param {number} params.row - The row position in the layout grid.
   * @param {number} params.col - The column position in the layout grid.
   * @param {Object} params.layoutGrid - The grid layout structure for positioning elements.
   * @param {Object} params.diFactory - The factory for creating BPMN diagram elements.
   * @returns {Array} - Array of created connection diagram elements (Di).
   */
  'createConnectionDi': ({ element, row, col, layoutGrid, diFactory }) => {
    const outgoing = element.outgoing || [];

    return outgoing.map(out => {
      const target = out.targetRef;
      const waypoints = connectElements(element, target, layoutGrid);

      const connectionDi = diFactory.createDiEdge(out, waypoints, {
        id: out.id + '_di'
      });

      return connectionDi;
    });

  }
};

// Helper functions

/**
 * Sorts an array of elements, placing elements of the specified type at the beginning.
 * @param {Array} arr - The array of elements to sort.
 * @param {string} type - The BPMN element type to prioritize.
 * @returns {Array} - Sorted array with prioritized elements first.
 */
function sortByType(arr, type) {
  const nonMatching = arr.filter(item => !is(item,type));
  const matching = arr.filter(item => is(item,type));

  return [ ...matching, ...nonMatching ];

}

/**
 * Checks if a loop is present by recursively searching for a specific element.
 * @param {Object} element - The element to check for loops.
 * @param {Set} visited - Set of visited elements to prevent infinite recursion.
 * @returns {boolean} - True if a loop is found, false otherwise.
 */
function checkForLoop(element, visited) {
  for (const incomingElement of element.incoming) {
    if (!visited.has(incomingElement.sourceRef)) {
      return findElementInTree(element, incomingElement.sourceRef);
    }
  }
}

/**
 * Determines if an element has future incoming connections that have not yet been visited.
 * @param {Object} element - The element to check for future incoming connections.
 * @param {Set} visited - Set of visited elements to track processed connections.
 * @returns {boolean} - True if future incoming connections are found, false otherwise.
 */
function isFutureIncoming(element, visited) {
  if (element.incoming.length > 1) {
    for (const incomingElement of element.incoming) {
      if (!visited.has(incomingElement.sourceRef)) {
        return true;
      }
    }
  }
  return false;
}

/**
 * Checks if all elements in an array are BPMN tasks.
 * @param {Array} elements - Array of elements to check.
 * @returns {boolean} - True if all elements are tasks, false otherwise.
 */
function isNextElementTasks(elements) {
  return elements.every(element => is(element, 'bpmn:Task'));
}