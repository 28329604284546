/**
 * Class representing a dynamic grid layout with row and column management.
 */
export class Grid {
  /**
   * Creates a Grid instance.
   * @param {number} [maxRowSize=7] - Maximum number of columns per row.
   */
  constructor(maxRowSize = 7) {
    this.grid = [];
    this.maxRowSize = maxRowSize;
  }

  /**
   * Adds an element to the grid at the specified position.
   * If no position is specified, adds the element to the start of the grid.
   * @param {*} element - The element to add.
   * @param {[number, number]} [position] - The row and column position to add the element.
   * @throws Will throw an error if the specified position is already occupied.
   */
  add(element, position) {
    if (!position) {
      this._addStart(element);
      return;
    }
    const [row, col] = position;
    if (!row && !col) {
      this._addStart(element);
    }

    if (!this.grid[row]) {
      this.grid[row] = [];
    }

    if (this.grid[row][col]) {
      throw new Error(
        "Grid is occupied please ensure the place you insert at is not occupied"
      );
    }

    this.grid[row][col] = element;
  }

  /**
   * Creates a new row in the grid, either at the end or after a specified index.
   * @param {number} [afterIndex] - The index after which to create the new row.
   */
  createRow(afterIndex) {
    if (!afterIndex) {
      this.grid.push([]);
    }

    this.grid.splice(afterIndex + 1, 0, []);
  }

  /**
   * Adds an element to the start of the grid.
   * @private
   * @param {*} element - The element to add.
   */
  _addStart(element) {
    this.grid.push([element]);
  }

  /**
   * Adds a new element immediately after an existing element in the grid.
   * If the current row reaches maxRowSize, a new row is created.
   * @param {*} element - The existing element.
   * @param {*} newElement - The new element to add.
   */
  addAfter(element, newElement) {
    if (!element) {
      this._addStart(newElement);
    }
    const [row, col] = this.find(element);

    if (this.grid[row].length >= this.maxRowSize) {
      this.createRow(row);
      this.grid[row + 1].push(newElement);
    } else {
      // Otherwise, add in the current column
      this.grid[row].splice(col + 1, 0, newElement);
    }
  }

  addBelow(element, newElement) {
    if (!element) {
      this._addStart(newElement);
    }

    const [row, col] = this.find(element);

    // Create a new row if we exceed maxColSize in the below column
    if (!this.grid[row + 1] || this.grid[row + 1].length >= this.maxRowSize) {
      this.createRow(row); // Create a new column
    }

    // The element below is already occupied - insert new row
    if (this.grid[row + 1][col]) {
      this.grid.splice(row + 1, 0, []);
    }

    if (this.grid[row + 1][col]) {
      throw new Error(
        "Grid is occupied and we could not find a place - this should not happen"
      );
    }

    this.grid[row + 1][col] = newElement;
  }

  find(element) {
    let row, col;
    row = this.grid.findIndex((row) => {
      col = row.findIndex((el) => {
        return el === element;
      });

      return col !== -1;
    });

    return [row, col];
  }

  get(row, col) {
    return (this.grid[row] || [])[col];
  }

  getElementsInRange(
    { row: startRow, col: startCol },
    { row: endRow, col: endCol }
  ) {
    const elements = [];

    if (startRow > endRow) {
      [startRow, endRow] = [endRow, startRow];
    }

    if (startCol > endCol) {
      [startCol, endCol] = [endCol, startCol];
    }

    for (let row = startRow; row <= endRow; row++) {
      for (let col = startCol; col <= endCol; col++) {
        const element = this.get(row, col);

        if (element) {
          elements.push(element);
        }
      }
    }

    return elements;
  }

  adjustGridPosition(element) {
    let [row, col] = this.find(element);
    const [, maxCol] = this.getGridDimensions();

    if (col < maxCol - 1) {
      // add element in next column
      this.grid[row].length = maxCol;
      this.grid[row][maxCol] = element;
      this.grid[row][col] = null;
    }
  }

  adjustRowForMultipleIncoming(elements, currentElement) {
    const results = elements.map((element) => this.find(element));

    // filter only rows that currently exist, excluding any future or non-existent rows
    const lowestRow = Math.min(
      ...results.map((result) => result[0]).filter((row) => row >= 0)
    );

    const [row, col] = this.find(currentElement);

    // if element doesn't already exist in current row, add element
    if (lowestRow < row && !this.grid[lowestRow][col]) {
      this.grid[lowestRow][col] = currentElement;
      this.grid[row][col] = null;
    }
  }

  adjustColumnForMultipleIncoming(elements, currentElement) {
    const results = elements.map((element) => this.find(element));

    // filter only col that currently exist, excluding any future or non-existent col
    const maxCol = Math.max(
      ...results.map((result) => result[1]).filter((col) => col >= 0)
    );

    const [row, col] = this.find(currentElement);

    // add to the next column
    if (maxCol + 1 > col) {
      this.grid[row][maxCol + 1] = currentElement;
      this.grid[row][col] = null;
    }
  }

  getAllElements() {
    const elements = [];

    for (let row = 0; row < this.grid.length; row++) {
      for (let col = 0; col < this.grid[row].length; col++) {
        const element = this.get(row, col);

        if (element) {
          elements.push(element);
        }
      }
    }

    return elements;
  }

  /**
   * Gets the dimensions of the grid.
   * @returns {[number, number]} The number of rows and the maximum number of columns in the grid.
   */
  getGridDimensions() {
    const numRows = this.grid.length;
    let maxCols = 0;

    for (let i = 0; i < numRows; i++) {
      const currentRowLength = this.grid[i].length;
      if (currentRowLength > maxCols) {
        maxCols = currentRowLength;
      }
    }

    return [numRows, maxCols];
  }

  /**
   * Retrieves all elements with their respective positions in the grid.
   * @returns {{element: *, row: number, col: number}[]} An array of objects containing element data and positions.
   */
  elementsByPosition() {
    const elements = [];

    this.grid.forEach((row, rowIndex) => {
      row.forEach((element, colIndex) => {
        if (!element) {
          return;
        }
        elements.push({
          element,
          row: rowIndex,
          col: colIndex,
        });
      });
    });

    return elements;
  }

  /**
   * Counts the total unique elements in the grid.
   */
  getElementsTotal() {
    const flattenedGrid = this.grid.flat();
    const uniqueElements = new Set(flattenedGrid.filter((value) => value));
    return uniqueElements.size;
  }
}
