import { createSlice } from "@reduxjs/toolkit";

// initial state in redux
const initialState = {
    user: {},
    sessionCheckInfo: { isSessionCheckInitiated: false }
}

// auto-generate action types and action creators, based on the names of the reducer functions that we supply
// state info : user related info 
const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUser: (state, action) => ({
            ...state,
            user: action.payload.userInfo
        }),
        updateSessionCheck: (state, action) => ({
            ...state,
            sessionCheckInfo: action.payload.sessionCheckInfo
        }),
        clearState: () => initialState
    }
});

export const { updateUser, clearState, updateSessionCheck } = slice.actions;

export default slice.reducer;