import { useContext, useEffect, useState, useRef } from 'react';
import { Typeahead } from "react-bootstrap-typeahead";
import { Accordion } from 'react-bootstrap';

import { ATTRIBUTES } from '../../../constants/attributes';
import { CSS_CLASSES, TASK_ICONS } from '../../../constants/css-classes';
import { TAB_LIST } from '../../../constants/element';
import { TOOLTIP } from '../../../constants/messages';
import { TASK_TYPE } from '../../../constants/task-types';

import PmivrOverlayTrigger from '../../common/overlay-trigger/pmivr-overlay-trigger';
import PmivrTooltip from '../../common/tooltip/pmivr-tooltip';
import PmivrLabel, { LABEL_SIZE } from '../../common/label/pmivr-label';
import { PmivrDialog } from '../../common/dialog/pmivr-dialog';
import VoiceFilesSelect from '../../common/voice-files-select/voice-files-select';

import DiagramUtil from '../../../util/diagram.util';

import ElementService from '../../../services/element.service';
import VariableService from '../../../services/variable.service';
import DiagramService from '../../../services/diagram.service';
import { VoiceContext } from '../../../contexts/app-context';

/**
 * Say data view in the diagram
 */
const SayDataPropsView = () => {
    // for setting focus to input fields after adding and removing list values from loop task collection voice files dialog
    // it will be referenced to the input fields for all the values in collection voice files dialog
    const collectionListValuesRefs = useRef([]);
    const [doAcceptCollection, setDoAcceptCollection] = useState(false);
    // list of variables names
    const [variablesName, setVariablesName] = useState([]);
    // list of variables with their info
    const [variables, setVariables] = useState([]);
    // variable selected
    const [selectedVariable, setSelectedVariable] = useState("");
    // description of the selected variable in variables list
    const [selectedVariableDesc, setSelectedVariableDesc] = useState('');
    // active accordion keys for the accordion
    const [activeAccordionKey, setActiveAccordionKey] = useState(null);
    // supported languages for the flow
    const [supportedLanguages, setSupportedLanguages] = useState([]);
    // to set the selected language when adding new values for dynamic list voice files
    const [selectedLanguage, setSelectedLanguage] = useState('');
    // voice files and value list for supported languages
    // example: {"en": [{val1, voiceFile1}, {val2, voiceFile2}]}
    const [valAndVoiceFile, setValAndVoiceFile] = useState({});
    /**
     * Ui state to show and hide the Voice files list dialog for loop task collection
     */
    const [uiState, setUiState] = useState({
        showVoiceFilesListDialog: false
    });

    const { element, tabType } = useContext(VoiceContext);

    useEffect(() => {
        const init = async () => {
            setSelectedVariable(element.businessObject.get(ATTRIBUTES.SAY_DATA_TASK) ? element.businessObject.get(ATTRIBUTES.SAY_DATA_TASK) : "");
            setAllVariables();
            if (element.businessObject.get(ATTRIBUTES.XML_LOOPTASK_COLLECTION)) {
                setDoAcceptCollection(true);
            }
            // get the list of supported languages
            const supportedLanguages = await DiagramService.getSupportedLanguages();
            setSupportedLanguages(supportedLanguages);
            // get the possible values for the collection and voice files
            // this will return the object with supported languages as keys, each key will have value as array
            // the array will have voice files associated with every value of collection
            let collectionVoiceFiles = ElementService.getAttribute(
                element, ATTRIBUTES.LOOP_TASK_VOICE_FILES_FOR_COLLECTION, ""
            );
            if (collectionVoiceFiles.length) {
                collectionVoiceFiles = JSON.parse(collectionVoiceFiles);
            }
            setValAndVoiceFile(collectionVoiceFiles);
        }
        init();
    }, [element]);

    /**
     * Get variables and set it in variables state.
     */
    const setAllVariables = async () => {
        const variablesInfo = await VariableService.getVariables();
        // names of all the variables
        const allVariablesNames = variablesInfo.map((varInfo) => varInfo.name);

        // populating the variables in state after getting the variable names.
        // Using timeout as getting all variable names is consuming time.
        setTimeout(() => {
            setVariablesName(allVariablesNames);
            setVariables(variablesInfo);
        }, 1000);
    }

    /**
     * Switching between the collection and non collection data
     */
    const handleSwitchChange = () => {
        const checked = !doAcceptCollection;
        setDoAcceptCollection(checked);
    }

    /**
     * Updating the data, and nullify the collection properties
     * @param {*} data 
     */
    const updateData = (data) => {
        ElementService.updateElement(element, ATTRIBUTES.SAY_DATA_TASK, data);
        ElementService.updateElement(element, ATTRIBUTES.XML_LOOPTASK_COLLECTION, null);
        ElementService.updateElement(element, ATTRIBUTES.LOOP_TASK_ISLOOP, false);
        // update the disabled attribute and taskType
        updateDisabledAttribute(element, TASK_TYPE.sayData);
    }

    /**
     * Updating the collection name and setting the isLoop as true, 
     * Consider it as collection in IVR call.
     * Also, nullify the data and it's type if collection is selected
     * @param {*} collection 
     */
    const updateCollection = (collection) => {
        ElementService.updateElement(element, ATTRIBUTES.SAY_DATA_TASK, null);
        ElementService.updateElement(element, ATTRIBUTES.SAY_DATA_TYPE, null);
        // update the disabled attribute names and task type for element
        updateDisabledAttribute(element, TASK_TYPE.loopTask);
        ElementService.updateElement(element, ATTRIBUTES.XML_LOOPTASK_COLLECTION, collection);
        ElementService.updateElement(element, ATTRIBUTES.LOOP_TASK_ISLOOP, true);
    }

    /**
     * Updates the disabled attribute for the task
     * @param {Object} element - bpmn element 
     * @param {string} taskType - type of the task (sayData/loopTask) 
     */
    const updateDisabledAttribute = (element, taskType) => {
        const isDisabledAttribute = DiagramUtil.generateDisabledAttribute(element.businessObject.taskType);
        // Since attribute returned will be in the form of element:isDisabledElement
        const attribute = isDisabledAttribute.split(':')[1];
        // Remove the current disabled attribute (according to the previous task type) 
        ElementService.removeElementAttr(element, attribute);
        // also update the task type to current type
        ElementService.updateElement(element, "taskType", taskType);
        // set the new disabled attribute according to the task type to false
        const newIsDisabledAttribute = DiagramUtil.generateDisabledAttribute(taskType);
        ElementService.updateElement(element, newIsDisabledAttribute, "false");
    }

    /**
     * Updating the data type of the say data
     * @param {string} dataType Properties passed
     */
    const updateDataType = (dataType) => {
        ElementService.updateElement(element, ATTRIBUTES.SAY_DATA_TYPE, dataType);
    }

    /**
     * Updating the session step name
     * @param {string} stepName 
     */
    const updateStepName = (stepName) => {
        ElementService.updateElement(element, ATTRIBUTES.SESSION_STEP_NAME, stepName);
    }

    /**
     * Updates the description to show in the tooltip
     * @param {Array} selectedVariable - selected variable in the typeahead 
     */
    const updateSelectedVariableDescription = (selectedVariable) => {
        const selectedVariableDescription = variables.find((variable) => variable.name === selectedVariable);
        setSelectedVariableDesc(selectedVariableDescription?.description);
    }

    /**
     * Updates the value of a specific field in the values list for the selected language.
     * @param {number} index - The index of the item in the values list to update.
     * @param {string} field - The field of the item to update.
     * @param {string} value - The new value to set for the specified field.
     */
    const handleInputChange = (index, field, value) => {
        // Get the voice file options for selected language, to edit the id in case of input change
        const newSelectedLanguageValues = _getSelectedLanguageOptions();
        // Update the specific field of the item at the given index
        newSelectedLanguageValues[index] = { ...newSelectedLanguageValues[index], [field]: value };
        // Update the state with the modified voice files and collection value
        setValAndVoiceFile({
            ...valAndVoiceFile,
            [selectedLanguage]: newSelectedLanguageValues
        });
    };

    /**
     * Returns the selected language array for voice files related to all values
     */
    const _getSelectedLanguageOptions = () => {
        // If the option list is undefined or null, use an empty array as a fallback.
        return [...(valAndVoiceFile[selectedLanguage] || [])];
    }

    /**
     * Sets focus to the input element of a specified index in the inputRefs array 
     * whenever any element is deleted or added
     * @param {number} index - The index of the input element to focus.
     */
    const handleFocus = (index) => {
        collectionListValuesRefs.current[index]['name'].focus();
    };

    /**
     * Adds a new empty item to the values list for the selected language 
     * and focuses on its input fields.
     */
    const handleAdd = () => {
        // get the values and voice files for selected language
        const newSelectedLanguageValues = [..._getSelectedLanguageOptions(), { name: '', voiceFile: '' }];
        setValAndVoiceFile({
            ...valAndVoiceFile,
            [selectedLanguage]: newSelectedLanguageValues
        });
        // Use setTimeout to ensure focusing on the newly added input after the state update.
        setTimeout(() => {
            handleFocus(newSelectedLanguageValues.length - 1);
        }, 0);
    };

    /**
     * Deletes an item from the values list for the selected language based on its index.
     * @param {number} index - The index of the item to delete.
     */
    const handleDelete = (index) => {
        // get the values and voice files for selected language
        const newSelectedLanguageValues = _getSelectedLanguageOptions();
        // Remove the item at the specified index from the newData array.
        newSelectedLanguageValues.splice(index, 1);
        setValAndVoiceFile({
            ...valAndVoiceFile,
            [selectedLanguage]: newSelectedLanguageValues
        });
    };

    /**
     * Saves the updated collection values to the bpmn xml element and hides the dialog.
     */
    const handleSave = () => {
        ElementService.updateElement(element, ATTRIBUTES.LOOP_TASK_VOICE_FILES_FOR_COLLECTION, JSON.stringify(valAndVoiceFile));
        setUiState({ ...uiState, showVoiceFilesListDialog: false });
    }

    /**
     * Handles the selection of a variable from the Typeahead component.
     * @param {Object} selected The selected option from the Typeahead component.
     */
    const handleVariableSelection = (selected) => {
        const selectedVariable = selected.length > 0 ? selected[0] : "";
        setSelectedVariable(selectedVariable);
        updateData(selectedVariable);
        updateSelectedVariableDescription(selectedVariable);
    }

    /**
     * Handles input changes in the Typeahead component.
     * @param {string} text The current text input in the Typeahead component
     * @param {Object} event 
     */
    const handleVariableInputChange = (text, event) => {
        const selectedVariable = text;
        setSelectedVariable(selectedVariable);
        updateData(selectedVariable);
    }

    return (
        <>
            <PmivrDialog showDialog={uiState.showVoiceFilesListDialog}
                closeDialog={() => setUiState({ ...uiState, showVoiceFilesListDialog: false })}
                title={"Voice Files for Collection"} saveDialogChanges={() => handleSave()}
                message={
                    <>
                        <div className="form-group custom-input">
                            {(valAndVoiceFile[selectedLanguage] || [])?.map((item, index) => {
                                return (
                                    <div className="row mb-3" key={index}>
                                        <div className="col-sm-4">
                                            <div className="props-custom-input">
                                                <input className="form-control pmivr-input" id={`file_value_${index}`} value={item.name}
                                                    type="text" onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                                                    ref={(el) => (collectionListValuesRefs.current[index] = { ...collectionListValuesRefs.current[index], name: el })}
                                                    onFocus={() => handleFocus(index)} />
                                                <label>Value</label>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="props-custom-input">
                                                <VoiceFilesSelect onSelect={(selectedPath) => handleInputChange(index, 'voiceFile', selectedPath)}
                                                    selectedLanguage={selectedLanguage} selectedItem={item.voiceFile || ''} />
                                            </div>
                                        </div>
                                        <div className="col-sm-2 text-center mt-1">
                                            <i className="bi bi-x-lg pmivr-btn-remove" id={`file_option_remove_${index}`}
                                                onClick={() => handleDelete(index)}></i>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="text-center mt-3">
                            <button className="pmivr-add-option" onClick={handleAdd}>
                                Add Values and Voice Files<i className="ms-1">+</i>
                            </button>
                        </div>
                    </>
                }
            />
            {
                (tabType === TAB_LIST)
                    ? <button className="nav-link active" id="say-data" data-bs-toggle="tab"
                        data-bs-target="#edit-say-data" type="button" role="tab"
                        aria-controls="edit-say-data" aria-selected="false">Data</button>
                    : <>
                        <div className="tab-pane  show active" id="edit-say-data" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className=" m-2 mt-3">
                                <div className="form-check form-switch pmivr-switch-btn mb-3">
                                    <label className="form-check-label pmivr-title  float-start pt-1">
                                        Read Data from Collection
                                    </label>
                                    <PmivrTooltip message={TOOLTIP.INFO.DATA_FROM_COLLECTION}>
                                        <input
                                            className="form-check-input  toggle-btn-height float-end"
                                            type="checkbox"
                                            role="switch"
                                            id="flexSwitchCheckChecked"
                                            onChange={handleSwitchChange}
                                            checked={doAcceptCollection}
                                        />
                                    </PmivrTooltip>
                                </div>
                                <div className={doAcceptCollection ? `mt-2 ${CSS_CLASSES.HIDE_DISPLAY}`
                                    : `mt-2 ${CSS_CLASSES.BLOCK_DISPLAY}`}>
                                    <form>
                                        <div>
                                            <div className="pmivr-label">
                                                <label className='mx-1'>Enter Data Variable</label>
                                                <PmivrTooltip message={TOOLTIP.INPUT.SAY_DATA_VARIABLE}>
                                                    <i className={TASK_ICONS.DISPLAY_INFO}></i>
                                                </PmivrTooltip>
                                            </div>
                                            <PmivrOverlayTrigger tooltip={selectedVariableDesc}>
                                                <div className="form-group mb-3 props-custom-input">
                                                    {/* auto-complete */}
                                                    <Typeahead
                                                        id="basic-typeahead-single"
                                                        className='pmivr-variable-input'
                                                        labelKey="selectedVariableInField"
                                                        onChange={(selected) => handleVariableSelection(selected)}
                                                        // only show the simple variables in option
                                                        options={variablesName}
                                                        onInputChange={(text, event) => handleVariableInputChange(text, event)}
                                                        placeholder="Variable"
                                                        multiple={false}
                                                        selected={selectedVariable ? [selectedVariable] : []}
                                                    />
                                                </div>
                                            </PmivrOverlayTrigger>
                                        </div>

                                        <div className="form-group mb-3">
                                            <div className="pmivr-label">
                                                <label className='pb-2'>Type </label>
                                            </div>
                                            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.PROMPT_DATA_TYPE}>
                                                <select
                                                    className='pmivr-select'
                                                    aria-label="Default select example" value={element.businessObject.get('sayData:dataType')} onChange={(event) => { updateDataType(event.target.value); }}>
                                                    <option value="digits">Digits</option>
                                                    <option value="number">Number</option>
                                                    <option value="time">Time</option>
                                                    <option value="alpha">Alpha</option>
                                                    <option value="date">Date</option>
                                                    <option value="amount">Amount</option>
                                                </select>
                                            </PmivrOverlayTrigger>
                                        </div>
                                    </form>
                                </div>
                                <div className={doAcceptCollection ? `mt-2 ${CSS_CLASSES.BLOCK_DISPLAY}` : `mt-2 ${CSS_CLASSES.HIDE_DISPLAY}`}>
                                    <form>
                                        <div className="form-group props-custom-input mb-0">
                                            <div className="pmivr-label">
                                                <span>Select Variable</span>
                                                <PmivrTooltip message={TOOLTIP.INPUT.DYNAMIC_OPTION_COLLECTION}>
                                                    <i className={`${TASK_ICONS.DISPLAY_INFO} collection-info-icon`}></i>
                                                </PmivrTooltip>
                                            </div>
                                            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.SAY_DATA_COLLECTION}>
                                                <div className="form-group mb-3 props-custom-input">
                                                    {/* auto-complete */}
                                                    <Typeahead
                                                        id="basic-typeahead-single"
                                                        className='pmivr-variable-input'
                                                        labelKey="selectedVariableInField"
                                                        onChange={(selected) => {
                                                            // selected value is array with one element. So, getting the value as selected[0]
                                                            // Example: selected: ["lastUsedPMVoiceFiles"]
                                                            const selectedVariable = selected.length > 0 ? selected[0] : "";
                                                            // Updating the collection name and setting the isLoop as true
                                                            updateCollection(selectedVariable);
                                                        }}
                                                        onInputChange={(text, event) => {
                                                            const selectedVariable = text;
                                                            updateCollection(selectedVariable);
                                                        }}
                                                        options={variablesName}
                                                        placeholder="Select Variable Name"
                                                        multiple={false}
                                                        selected={
                                                            element.businessObject.get(ATTRIBUTES.XML_LOOPTASK_COLLECTION) ?
                                                                [element.businessObject.get(ATTRIBUTES.XML_LOOPTASK_COLLECTION)]
                                                                : []
                                                        }
                                                    />
                                                </div>
                                            </PmivrOverlayTrigger>
                                        </div>
                                        <PmivrLabel label="Voice Files for Collection" size={LABEL_SIZE.MEDIUM}
                                            tooltip={TOOLTIP.INFO.VOICE_FILES_FOR_COLLECTION} />
                                        <Accordion activeKey={activeAccordionKey} className="mb-3 pmivr-accordion"
                                            onSelect={(selectedKey) => setActiveAccordionKey(selectedKey)} flush>
                                            {
                                                [...supportedLanguages].map((language) => {
                                                    return (
                                                        <div key={language}>
                                                            <Accordion.Item eventKey={language} className="mt-1 accordion-voice-item">
                                                                <Accordion.Header onClick={() => setSelectedLanguage(language)}>
                                                                    <span className="pmivr-accordian-tab">
                                                                        Language : {language}
                                                                    </span>
                                                                </Accordion.Header>
                                                                <Accordion.Body className="pmivr-card border-none p-3 pt-0">
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="mt-2">
                                                                            <PmivrLabel label="Voice Files List" />
                                                                        </div>
                                                                        <div className="pmivr-title pmivr-label my-1 text-end">
                                                                            <PmivrOverlayTrigger tooltip={"Add voice file for dynamic list values"}>
                                                                                <button className="mt-1 pmivr-btn-app pmivr-btn-rounded-circle small-rounded-circle rounded-circle" type='button'
                                                                                    onClick={() => { setUiState({ ...uiState, showVoiceFilesListDialog: true }) }}>
                                                                                    <i className="bi bi-pencil"> </i>
                                                                                </button>
                                                                            </PmivrOverlayTrigger>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        valAndVoiceFile[language] &&
                                                                        <table className="table pmivr-table header-fixed table-body-block border mt-2">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th width="30%" className="text-center">Value</th>
                                                                                    <th width="70%" className="text-center">Voice File</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="pmivr-scroll">
                                                                                {
                                                                                    (valAndVoiceFile[language])?.map((item, index) => {
                                                                                        return (
                                                                                            <tr key={index}>
                                                                                                <td width="30%" title={item.name} className="text-center pt-2">{item.name}</td>
                                                                                                <td width="70%" title={item.voiceFile} className="text-center pt-2">{item.voiceFile}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    }
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Accordion>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade " id="edit-session" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className=" m-2 mt-3">
                                <form>
                                    <div className="form-group mb-3 props-custom-input">
                                        <input id="stepName"
                                            name="stepName" placeholder=" " className="form-control" value={element.businessObject.get(ATTRIBUTES.SESSION_STEP_NAME)} onChange={(event) => {
                                                updateStepName(event.target.value);
                                            }} />
                                        <label>Enter Step Name</label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
            }
        </>
    )
}


export default SayDataPropsView;