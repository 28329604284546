import { useContext, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";

import { ATTRIBUTES } from "../../../../constants/attributes";
import { MESSAGES, TOOLTIP } from "../../../../constants/messages";
import { SERVICE_TYPES } from "../../../../constants/task-types";
import { APP_CONFIG } from "../../../../config/config";
import { VoiceContext } from "../../../../contexts/app-context";

import PmivrOverlayTrigger from "../../../common/overlay-trigger/pmivr-overlay-trigger";
import { PmivrCodeEditor } from "../../../common/code-editor/pmivr-code-editor";
import PmivrSnackBar from "../../../common/dialog/pmivr-snackbar";
import PmivrLabel from "../../../common/label/pmivr-label";

import JsCodeSafetyCheckUtil from "../../../../util/jscode-safety-checks.util";
import AppUtil from "../../../../util/app.util";

import ElementService from "../../../../services/element.service";
import DiagramService from "../../../../services/diagram.service";

/**
 * Component for configuring custom expression to be implemented in service control
 */
const CustomExpressionServiceView = () => {

    const snackbarRef = useRef();
    const { element } = useContext(VoiceContext);

    const [uiState, setUiState] = useState({
        isExpressionSelected: (ElementService.getAttribute(element, ATTRIBUTES.SERVICE.TYPE, "") === SERVICE_TYPES.EXPRESSION),
        responseVariable: '', expressionText: '', disabled: true, isCodeValid: true
    });

    /* options to customize the editor
    automaticLayout: editor should automatically adjust the layout when its container is resized
    minimap: displays the preview of code along side the main editor (near scroll bar)
    lineNumbersMinChars: minimum width reserved for line numbers
    wordWrap: wraps the text within the visible area
    scrollbar: width of the scrollbar
    cursorStyle: visual appearance of the cursor
    matchBrackets: highlight the matching brackets */
    const editorOptions = {
        automaticLayout: true, minimap: { enabled: false }, lineNumbersMinChars: 0, wordWrap: "on",
        cursorStyle: 'line', scrollbar: { verticalSliderSize: 4, handleMouseWheel: true }, matchBrackets: 'always'
    }

    // ui attributes of the editor
    const uiAttributes = {
        width: "330px", height: "400px",
        label: { text: "Enter Expression", toolTipMsg: TOOLTIP.INFO.SERVICE_EXPRESSION }
    };

    useEffect(() => {
        // populate data only when custom expression is selected
        if (ElementService.getAttribute(element, ATTRIBUTES.SERVICE.TYPE, "") === SERVICE_TYPES.EXPRESSION) {
            setUiState({
                ...uiState,
                responseVariable: ElementService.getAttribute(element, ATTRIBUTES.SERVICE.RESPONSE_VARIABLES, ""),
                expressionText: ElementService.getAttribute(element, ATTRIBUTES.SERVICE_IMPL_EXPRESSION, "")
            });
        }
        // element in dependency array updates the state of expression service
    }, [element]);

    /**
     * Update the attributes in the element.
     * @param {string} attributeName - the name of the attribute to update.
     * @param {string} value - the new value to set for the attribute.
     */
    const updateAttribute = (attributeName, value) => {
        ElementService.updateElement(element, attributeName, value);
    };

    /**
     * handles the onchange of text editor content
     * @param {string} value 
     */
    const handleChange = (value) => {
        if (AppUtil.isValueValid(value)) {
            updateAttribute(ATTRIBUTES.SERVICE.TYPE, SERVICE_TYPES.EXPRESSION);
            setUiState({ ...uiState, expressionText: value, disabled: false, isCodeValid: true });
            /**
             * Checking whether the expression has UI Code, Db Code, Sensitive Data Code, Dynamic Code execution, 
             * Infinite loop code, Download data code, Delete file code
             */
            if (!JsCodeSafetyCheckUtil.isJSCodeSafe(value)) {
                setUiState({ ...uiState, isCodeValid: false, disabled: true });
                snackbarRef.current.open(MESSAGES.ERR.INVALID_EXPRESSION);
            }
        }
    }

    /**
     * Submission of the editor expression and response variable. Saving them in xml file.
     */
    const submitExpression = () => {
        if (uiState.isCodeValid) {
            updateAttribute(ATTRIBUTES.SERVICE_IMPL_EXPRESSION, uiState.expressionText);
            // eslint-disable-next-line
            updateAttribute(ATTRIBUTES.SERVICE_IMPLEMENTATION_METHOD, '${environment.services.executeExpression}');
            // setting the service type
            updateAttribute(ATTRIBUTES.SERVICE.RESPONSE_VARIABLES, uiState.responseVariable);
            // snackbar message : saved successfully
            snackbarRef.current.open(MESSAGES.SAVED_SUCCESSFULLY);
            // close the right panel after a pause of 3 seconds
            setTimeout(() => { DiagramService.closeRightPanel(); }, APP_CONFIG.MESSAGE_TIMEOUT);
        } else {
            snackbarRef.current.open(MESSAGES.ERR.INVALID_EXPRESSION);
        }

    }

    return (
        <div className="m-2 mt-1">
            <PmivrSnackBar ref={snackbarRef} />
            {/* implementation expression */}
            <div className="form-group props-custom-input mb-2">
                <PmivrCodeEditor expression={uiState.isExpressionSelected ?
                    uiState?.expressionText : ""}
                    handleChange={handleChange} uiAttributes={uiAttributes} editorOptions={editorOptions} />
            </div>
            {/* response variable */}
            <div className="form-group">
                <PmivrLabel label="Enter Response Variable" tooltip={TOOLTIP.INFO.SERVICE_IMPL_EXPRESSION_RESPONSE_VARIABLE} />
                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.SERVICE_IMPL_RESPONSE_VARIABLE}>
                    <input id="responseVariable" name="responseVariable" className="form-control pmivr-input" placeholder="Enter Variable"
                        value={uiState.responseVariable}
                        onChange={(event) => {
                            setUiState({ ...uiState, responseVariable: event.target.value, disabled: (!uiState?.isCodeValid) });
                        }}
                    />
                </PmivrOverlayTrigger>
            </div>
            <div className="form-group props-custom-input">
                <Button className="pmivr-btn-app float-end"
                    disabled={uiState.disabled} onClick={() => submitExpression()}>Save</Button>
            </div>
        </div>
    )
}

export default CustomExpressionServiceView;