import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import { REGEX, PAGINATION_COUNT } from "../../config/config";

import { MESSAGES } from "../../constants/messages";
import { APP_PAGES } from "../../constants/app-pages";

import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";
import { PmivrDialog } from "../../components/common/dialog/pmivr-dialog";
import PmivrTooltip, { TOOLTIP_POSITIONS } from "../../components/common/tooltip/pmivr-tooltip";
import UpdatePasswordForm from "./components/update-password-form";
import UserModal from "./components/user-modal";
import PmivrSearchInput from "../../components/common/search-input/pmivr-search";

import AppUtil from "../../util/app.util";

import UserService from "../../services/user.service";

/**
 * Lists all the users (active / inactive).
 * Also provides option to add new users or update the existing users.
 * @returns {React.Component} Html element to render
 */
const Users = () => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    /**
     * snackbar
     * dialog (option menu modal)
     */
    const [uiState, setUiState] = useState({
        showDialog: false, dialogTitle: "",
        snackbar: { isOpen: false, msg: "" },
        showUpdatePasswordDialog: false,
    });
    // permissions of the user
    const [permissions, setPermissions] = useState({ edit: false });
    // user list
    const [users, setUsers] = useState([]);
    // cache user list info
    const [userInfo, setUserInfo] = useState({ users: [], dataCount: 0, pageNo: 0 });
    // selected user to edit
    const [selectedUser, setSelectedUser] = useState(null);
    // filter object for filtering the users
    const [filterUsers, setFilterUsers] = useState({ text: '' });
    // props for the pagingation of the user list
    const [pagination, setPagination] = useState({
        totalPages: 0,    // total pages in pagination of users
        currentPage: 0, // current page displayed in pagination of users
        totalResults: 0,  // total number of users
        count: PAGINATION_COUNT  // count for number of users to be displayed at a time on users screen
    });

    // title for modal
    const TITLE = { ADD_USER: "Create New User", EDIT_USER: "Edit User" };

    useEffect(() => {
        const init = async () => {
            await loadUsers();
        }
        init();
    }, []);

    /**
     * Effect to load call logs when the filterUsers state changes.
     * @function useEffect
     * @dependencies {Array} filterUsers - The dependency array that triggers the effect when its value changes.
     */
    useEffect(() => {
        loadUsers();
    }, [filterUsers]);

    /**
     * Set permission state object
     */
    const setUserPermissions = () => {
        const updatedPermissions = { ...permissions };
        updatedPermissions.edit = UserService.hasPermission();
        setPermissions(updatedPermissions);
    }

    /**
     * Updating the pagination props in the state
     * @param {Number} dataCount total number of users
     * @param {Number} pageNo page number of the user list
     */
    const setPaginationProps = (dataCount = 0, pageNo = 1) => {
        setPagination((prevPaginationObj) => {
            const newPaginationObj = { ...prevPaginationObj };
            newPaginationObj.totalPages = Math.ceil(dataCount / newPaginationObj.count);
            newPaginationObj.currentPage = pageNo;
            newPaginationObj.totalResults = dataCount;
            return newPaginationObj;
        });
    }

    /**
     * getting the users from the DB as per count, page no, etc
     * @param {Integer} pageNo page of user list to be displayed
     */
    const loadUsers = async (pageNo = 1) => {
        setUserPermissions();

        try {
            const filter = { searchText: filterUsers.text, count: pagination.count, pageNo };
            const response = await UserService.getUsers(filter);
            setUsers(response?.data);
            // updating the cache when search request if not there
            if (!filter.searchText) {
                setUserInfo({ users: response?.data, dataCount: response?.dataCount, pageNo });
            }
            setPaginationProps(response?.dataCount, pageNo);
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * show modal to update existing user / add new user
     * @param {Object} user Selected user
     */
    const showUserModal = (user) => {
        if (user) {
            setUiState({ ...uiState, dialogTitle: TITLE.EDIT_USER, showDialog: true });
            setSelectedUser(user);
        } else {
            setUiState({ ...uiState, dialogTitle: TITLE.ADD_USER, showDialog: true });
            setSelectedUser(null);
        }
    }

    /**
     * show dialog to update user's password
     * @param {Object} user Selected user
     */
    const showUpdatePasswordDialog = (user) => {
        setUiState({ ...uiState, showUpdatePasswordDialog: true });
        setSelectedUser(user);
    }

    // close the modal by setting flag to false and update the users state
    const handleCloseAction = async (user, isExistingUser) => {
        // if user is added successfully, then reseting the filter props
        if (user) {
            setFilterUsers({ text: "" });  // reset filter option
        }
        updateUsersState(user, isExistingUser);
        setUiState({ ...uiState, showDialog: false });
    }

    // Handles closing the password dialog box
    const togglePasswordDialog = async () => {
        setUiState({ ...uiState, showUpdatePasswordDialog: false });
    }

    /**
     * Updating the users state with updated / added user
     * @param {Object} user user updated / added
     * @param {Boolean} isExistingUser flag specifying the existing user
     */
    const updateUsersState = async (user, isExistingUser) => {
        // if there is addition or updation of user
        if (user) {
            if (isExistingUser) {
                // update users list as updation of user takes place
                let userIndex = users?.findIndex((userObj) => { return userObj.email === user.email; });
                let updatedUsers = [...users];
                updatedUsers[userIndex] = user;
                setUsers(updatedUsers);
                // update the cached user list with updated user, if found in cache userInfo
                const cachedUsers = userInfo.users;
                userIndex = cachedUsers?.findIndex((userObj) => { return userObj.email === user.email; });
                if (userIndex !== -1) {
                    cachedUsers[userIndex] = user;
                    setUserInfo({ users: cachedUsers, dataCount: userInfo.dataCount, pageNo: userInfo.pageNo });
                }
            } else {
                // getting the user list cached in state
                let cachedUsers = userInfo.users;
                // adding the new user to the top of the list
                const updatedUsers = [user, ...cachedUsers];
                if (userInfo.dataCount >= PAGINATION_COUNT) {
                    // removing the last item from the list, as new item is being added above
                    updatedUsers.pop();
                }
                // updating the users state
                setUsers(updatedUsers);
                setUserInfo({ users: updatedUsers, dataCount: (userInfo.dataCount + 1), pageNo: userInfo.pageNo });
                setPaginationProps((userInfo.dataCount + 1), userInfo.pageNo);

            }
        }
    }

    /**
     * Reseting the list of users from state. 
     * If not found, then reload users from DB
     */
    const resetData = async () => {
        // reset filter option
        setFilterUsers({ text: "" });
        if (userInfo.users?.length > 0) {
            // updating the user list displayed, from cache
            setUsers(userInfo.users);
            setPaginationProps(userInfo.dataCount, userInfo.pageNo);
        } else {
            await loadUsers();
        }
    }

    /**
    * Populating values on changing the value of text field
    * @param {String} value
    */
    const setFilterText = (value) => setFilterUsers({ text: value?.toLowerCase() });

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />

            <PmivrDialog showDialog={uiState.showDialog} closeDialog={handleCloseAction}
                title={uiState.dialogTitle}
                message={<UserModal user={selectedUser} closeAction={handleCloseAction} />}
                footer={<></>}
            />

            <PmivrDialog showDialog={uiState.showUpdatePasswordDialog} closeDialog={togglePasswordDialog}
                title="Edit User Password"
                message={<UpdatePasswordForm user={selectedUser} closeAction={togglePasswordDialog} />}
                footer={<></>}
            />

            <div className="pmivr-filter-headers">
                <div className="row pt-1">
                    <div className="row border-bottom  pb-3 pt-3 ">
                        <div className="col-lg-6">
                            <div className="px-3 pmivr-breadcrumb-list">
                                <Link to={APP_PAGES.HOME}>Home</Link> / <Link to={APP_PAGES.SETTINGS}>Settings</Link>  / Users
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="row pt-2 ">
                        <div className="col-lg-6">
                            <div className="px-3 ps-1 pmivr-breadcrumb-list ">
                                <h4> <Link to={APP_PAGES.SETTINGS}><i class="bi bi-arrow-left arrow-left"></i></Link>  Users</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {/* header for search and actions */}
                <div className="row p-1 pt-0 d-flex justify-content-center align-items-center">
                    {/* left side actions */}
                    <div className="col-lg-5">
                        <div className="row ps-1">
                            <div className="col-md-7">
                                {/*Uses common search component to render the search input for filtering users */}
                                <PmivrSearchInput
                                    value={filterUsers.text}
                                    placeholder="Search text"
                                    regex={REGEX.USER}
                                    onSearch={setFilterText}
                                    onEnter={loadUsers}
                                    label="Search User"
                                />
                            </div>
                            <div className="col-md-3 d-flex align-items-end">
                                <button title="Reset the user list" type="button" onClick={() => resetData()}
                                    disabled={!(filterUsers.text)} className="float-start pmivr-btn-secondary pmivr-reset-link">
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* right side actions */}
                    <div className="col-lg-7 d-flex align-items-end justify-content-end">
                        {UserService.hasPermission() && (
                            <div className="pmivr-relative float-end pe-1">
                                <PmivrTooltip message={"Click to add the new user"} position={TOOLTIP_POSITIONS.TOP}>
                                    <button
                                        type="button" onClick={() => showUserModal()}
                                        className="pmivr-btn-app pmivr-reset-link"
                                    >
                                        <i className="bi bi-person-plus"></i> Add User
                                    </button>
                                </PmivrTooltip>
                            </div>
                        )}
                    </div>
                </div>

                {/* total users bar */}
                <div className="row pe-1">
                    <div className="col-lg-6">
                        <div className="px-3 pt-3 pmivr-breadcrumb-list">Total Users: {pagination.totalResults || 0}</div>
                    </div>
                    {
                        (pagination.totalResults > pagination.count) ?
                            <div className="col-lg-6">
                                {
                                    (pagination.currentPage < pagination.totalPages) ?
                                        <div className="float-end pt-3 ">
                                            <div className="px-3 pmivr-breadcrumb-list">
                                                <Link title="Next page of users" onClick={() => loadUsers(pagination.currentPage + 1)}>
                                                    Next <i className="bi bi-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div> : <></>
                                }
                                {
                                    (pagination.currentPage > 1) ?
                                        <div className="float-end  pb-3 pt-3 ">
                                            <div className="px-3 pmivr-breadcrumb-list">
                                                <Link title="Previous page of users" onClick={() => loadUsers(pagination.currentPage - 1)}>
                                                    <i className="bi bi-arrow-left"></i> Previous
                                                </Link>
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                            : <></>
                    }
                </div>

                {/* list of user table */}
                <div className="row me-2 ms-2">
                    <table className="table table-body-block pmivr-table header-fixed border mt-2">
                        <thead>
                            <tr>
                                <th width="15%" className="text-center">Name</th>
                                <th width="15%" className="text-center">Email</th>
                                <th width="10%" className="text-center">Role</th>
                                <th width="10%" className="text-center">Status</th>
                                <th width="10%" className="text-center">Created By</th>
                                <th width="10%" className="text-center">Created On</th>
                                <th width="10%" className="text-center">Updated By</th>
                                <th width="10%" className="text-center">Updated On</th>
                                {UserService.hasPermission() &&
                                    <th width="10%" className="text-center">Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody className="pmivr-scroll">
                            {(users?.length) ?
                                [...users.values()].map((user, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="15%" title={user.firstName + " " + (user.lastName || "")} className="text-center pt-2">
                                                {user.firstName + " " + (user.lastName || "")}
                                            </td>
                                            <td width="15%" title={user.email} className="text-center pt-2">{user.email}</td>
                                            <td width="10%" title={user.roleId} className="text-center pt-2">{user.roleId}</td>
                                            <td width="10%" title={user.status} className="text-center pt-2">{user.status}</td>
                                            <td width="10%" title={user.createdBy} className="text-center pt-2">{user.createdBy}</td>
                                            <td width="10%" title={AppUtil.formatDateInLocal(user.createdOn)} className="text-center pt-2">{AppUtil.formatDateInLocal(user.createdOn)}</td>
                                            <td width="10%" title={user.updatedBy} className="text-center pt-2">{user.updatedBy}</td>
                                            <td width="10%" title={AppUtil.formatDateInLocal(user.updatedOn)} className="text-center pt-2">{AppUtil.formatDateInLocal(user.updatedOn)}</td>
                                            {UserService.hasPermission() &&
                                                <td width="10%" className="text-center pt-2">
                                                    <span>
                                                        <PmivrTooltip message="Edit User">
                                                            <button className="pmivr-btn-transparent"
                                                                onClick={() => showUserModal(user)}>
                                                                <i className="bi bi-pencil-square pmivr-btn-icon"></i>
                                                            </button>
                                                        </PmivrTooltip>
                                                        <PmivrTooltip message="Edit Password">
                                                            <button className="pmivr-btn-transparent ms-2" onClick={() => showUpdatePasswordDialog(user)}>
                                                                <i className="bi bi-shield-lock pmivr-btn-icon"></i>
                                                            </button>
                                                        </PmivrTooltip>
                                                    </span>
                                                </td>
                                            }
                                        </tr>
                                    )
                                }) : <tr><td>No User Found</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Users;