export const FLOW_STATUS = {
  PUBLISHED: "published",
  DRAFT: "draft"
};

// status for upload flow 
export const FLOW_UPLOAD_STATUS = {
  INPROGRESS: "In progress",
  SUCCESSFUL: "Successful",
  FAILED: "Failed"
};

// types of variables that are configured for client
export const VARIABLE_TYPES = {
  ALL_TYPES_VARIABLES: "all",
  CONFIG_VARIABLES: "config",
  FLOW_VARIABLES: "flow",
  SYSTEM_VARIABLES: "system"
};

// ID of flow type required to send id for saving in DB 
// and also to disable the already created flow types on the client page
export const FLOW_TYPE_ID = {
  // Flow type id for custom flows
  CUSTOM_FLOW_ID: "CUSTOM",
  // Flow type id for AAIVR flows
  AAIVR_FLOW_ID: "AAIVR",
  // Flow type id for interactive design flows
  INTERACTIVE_DESIGN_FLOW: "INTERACTIVE_DESIGN"
};

// actions defined for either saving template or updating template in template versions collection
export const TEMPLATE_VERSIONS_ACTIONS = {
  ADD_BILLER: "addBiller"
}

// types of flow status flags
export const FLOW_FLAGS = {
  WORKING: "working",
  FAILED: "failed"
}