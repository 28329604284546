import PropTypes from 'prop-types';

import AppUtil from '../../../util/app.util';

/**
 * Component to show information related to a particular log, example: flowName, sessionStatus etc.
 * @param {{Object}} logInfo log object which has different fields and information
 */
const LogInfo = ({ logInfo }) => {
    /**
     * InfoRow component displays a label and its corresponding value in a formatted row.
     * @param {Object} 
     * @param {string} props.label - The label to be displayed on the left side of the row.
     * @param {any} props.value - The value to be displayed on the right side of the row. This can be any type (string, number, object, etc.).
     * @returns {JSX.Element} A JSX element representing a row with a label and value.
     */
    const InfoRow = ({ label, value }) => (
        <>
            <div className="row d-flex justify-content-between">
                <span className="fw-bold col-md-4">{label}</span>
                <span className="col-md-8">{formatDisplayValue(value)}</span>
            </div>
            <hr />
        </>
    );

    /**
     * Retrieves a formatted string representation of the input value.
     * @param {any} value - The value to be formatted. This can be a string, number, boolean, null, or object.
     * @returns {string} A string representation of the input value. Returns "N/A" for undefined or empty values.
     */
    const formatDisplayValue = (value) => {
        if (value === undefined) {
            return "N/A";
        } else if (typeof value === 'string' || typeof value === 'number') {
            // in case of empty string, return N/A, else return the value
            return value || "N/A";
        } else if (typeof value === "boolean" || value === null) {
            // return the string form of the value, because they will be displayed as empty space due to non-string datatype
            return `${value}`;
        } else if (typeof value === 'object') {
            return _displayObject(value);
        }
    }

    /**
     * Recursively displays the properties of an object, with indentation for nested objects.
     * @param {Object} obj - The object to be displayed. Can contain nested objects.
     * @param {number} [indentLevel=0] - The current level of indentation for nested objects.
     * @returns {JSX.Element[]} An array of JSX elements representing the object's properties.
     */
    const _displayObject = (obj, indentLevel = 0) => {
        let result = [];

        // Increase indentation if there is a nested object 
        const indentStyle = { marginLeft: `${indentLevel * 20}px` };

        for (const [key, val] of Object.entries(obj)) {
            // if there is a nested object, then recursively call the function with increased indentation
            if (typeof val === 'object' && val !== null) {
                result.push(
                    <div key={key} style={indentStyle} className="py-1">
                        <span>{key}:</span>
                        <div>{_displayObject(val, indentLevel + 1)}</div>
                    </div>
                );
            } else {
                result.push(
                    <div key={key} style={indentStyle}>
                        <span>{key}: {formatDisplayValue(val)}</span>
                    </div>
                );
            }
        }

        return result;
    };

    return (
        <div className="d-flex flex-column">
            <InfoRow label="Timestamp:" value={AppUtil.formatDateInLocal(logInfo?.startTime)} />
            <InfoRow label="DNID:" value={logInfo?.dnid} />
            <InfoRow label="Session ID:" value={logInfo?.sessionId} />
            <InfoRow label="Request ID:" value={logInfo?.requestId} />
            <InfoRow label="Flow Name:" value={logInfo?.flowName} />
            <InfoRow label="Flow Type:" value={logInfo?.flowTypeId} />
            <InfoRow label="Step Name:" value={logInfo?.stepName} />
            <InfoRow label="Caller ID Name:" value={logInfo?.callerIdName} />
            <InfoRow label="Caller ID Number:" value={logInfo?.callerIdNum} />
            <InfoRow label="Redirect Phone:" value={logInfo?.redirectPhone} />
            <InfoRow label="App Code:" value={logInfo?.appCode} />
            <InfoRow label="Business Code:" value={logInfo?.businessCode} />
            <InfoRow label="Control Type:" value={logInfo?.controlType} />
            <InfoRow label="Active:" value={logInfo?.active} />
            <InfoRow label="Call Hangup:" value={logInfo?.callHangUp} />
            <InfoRow label="Channel Code:" value={logInfo?.channelCode} />
            <InfoRow label="Payment Status Code:" value={logInfo?.paymentStatusCode} />
            <InfoRow label="Retries:" value={logInfo?.retries} />
            <InfoRow label="Session Status:" value={logInfo?.sessionStatus} />
            <InfoRow label="Environment:" value={logInfo?.environment} />
        </div>
    )
};

// Types of props passed in the component
LogInfo.propTypes = {
    // LogInfo object with information of a log
    logInfo: PropTypes.shape({
        sessionId: PropTypes.string,
        requestId: PropTypes.string,
        flowName: PropTypes.string,
        flowTypeId: PropTypes.string,
        appCode: PropTypes.string,
        businessCode: PropTypes.string,
        active: PropTypes.bool,
        callHangup: PropTypes.bool,
        callerIdName: PropTypes.string,
        callerIdNum: PropTypes.string
    })
}

export default LogInfo;