import { Alert } from "react-bootstrap";
import PropTypes from 'prop-types';
import { MESSAGE_TYPE_VARIANTS } from "../../../constants/css-classes";
import PmivrOverlayTrigger from "../overlay-trigger/pmivr-overlay-trigger";

/**
 * Message box to be displayed on screen along with some actions
 * @param {Object} props props data from parent component
 * @returns {React.Component} Html code to render dialog
 */
export const MessageBox = (props) => {
    const {
        heading, message, buttons = [], messageType = MESSAGE_TYPE_VARIANTS.INFO, dismissible = true, onClose
    } = props;

    return (
        <>
            <div className="mt-3 mb-1">
                <Alert variant={messageType} key={messageType} dismissible={dismissible} onClose={onClose}>
                    <Alert.Heading className="pmivr-title large">{heading}</Alert.Heading>
                    <p className="mt-3 text-dark" dangerouslySetInnerHTML={{ __html: message }} />
                    {buttons.length && (
                        <div className="d-flex justify-content-end">
                            {
                                buttons.map((button, index) => (
                                    <PmivrOverlayTrigger tooltip={button?.tooltipMsg}>
                                        <button
                                            key={index}
                                            className={`${button.cssClass} ms-2`}
                                            onClick={button?.handleAction}>
                                            {button?.label}
                                        </button>
                                    </PmivrOverlayTrigger>
                                ))
                            }
                        </div>
                    )}
                </Alert>
            </div>
        </>
    );
}

// Types of props passed in component
MessageBox.propTypes = {
    // heading for the alert
    heading: PropTypes.string.isRequired,
    // message for the alert
    message: PropTypes.string.isRequired,
    // array of button configurations
    buttons: PropTypes.arrayOf(PropTypes.shape({
        // label for the button
        label: PropTypes.string.isRequired,
        // function to handle button click
        handleAction: PropTypes.func.isRequired,
    })),
    // type of the message (info, warning, error)
    messageType: PropTypes.string,
    // flag to dismiss the message box
    dismissible: PropTypes.bool,
    // function which is called when the alert box is closed
    onClose: PropTypes.func
}
