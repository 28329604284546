import { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { CSS_CLASSES } from "../../../constants/css-classes.js";
import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file.js";

import PmivrOverlayTrigger from "../../../components/common/overlay-trigger/pmivr-overlay-trigger.js";
import AudioPlayer from "../../../components/common/audio-player/audio-player.js";
import PmivrSnackBar from "../../../components/common/dialog/pmivr-snackbar.js";

import AudioUtil from "../../../util/audio.util.js";
import AudioService from "../../../services/audio.service.js";

/**
 * The upload voice file option for system files which is internally used on AGI side
 */
const SystemVoiceFileUploadOption = (props) => {
    const { businessCode } = useSelector(state => state.client);
    const { voiceFileInfo, selectedLanguage, updateVoiceFilePathState } = props;

    // file should be MONO
    const CHANNELS = { MONO: 1 };
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    /**
     * State to manage file uploaded by user
     * file: file which is uploaded
     * filePath: filePath which is updated after being uploaded to server
     * fileSize: size of the file that is uploaded
     */
    const [uploadedFileInfo, setUploadedFileInfo] = useState({ file: null, filePath: "", fileSize: "" });
    /**
     * uploadBtnDisable {boolean} whether to disable or enable the upload button based on fields
     * infoMessage {string} message to show when the file is uploaded (success/failure)
     * isErrorMessage {boolean} whether the message is error message (in case of true) or success (in case of false)
     */
    const [uiState, setUiState] = useState({ uploadBtnDisable: true, infoMessage: "", isErrorMessage: false });

    useEffect(() => {
        const systemVoiceFileInfo = voiceFileInfo?.language[selectedLanguage];
        if (systemVoiceFileInfo?.voiceFileType === VOICE_FILE_UPLOAD_TYPE.UPLOAD) {
            setUploadedFileInfo({
                ...uploadedFileInfo, filePath: voiceFileInfo?.language[selectedLanguage]?.filePath,
                fileSize: voiceFileInfo?.language[selectedLanguage]?.fileSize
            });
        }
    }, []);

    /**
     * Update the state when voice file is uploaded on builder by user by generating wave audio data from buffer
     * @param {Object} event event of type object
     */
    const voiceFileUploadChangeHandler = async (event) => {
        try {
            // Check if the file extension is .wav
            if (event?.target?.files?.length && event?.target?.files[0].name.endsWith(".wav")) {
                const file = event.target.files[0];
                // read the file that is uploaded
                const reader = new FileReader();
                // runs when file reading is complete
                reader.onload = async (event) => {
                    // Decode the WAV audio data from the buffer of the file
                    const audioData = await AudioUtil.toAudioBuffer(event.target.result);
                    // Check if wav file is valid or not
                    if (!isWavFileValid(audioData)) {
                        setUiState({ ...uiState, infoMessage: `Unsupported File: ${file.name}`, isErrorMessage: true, uploadBtnDisable: true });
                        setUploadedFileInfo({ ...uploadedFileInfo, file: null });
                    } else {
                        setUploadedFileInfo({ ...uploadedFileInfo, file: file, filePath: file.name, fileSize: Math.floor((file.size) / 1000) });
                        setUiState({ ...uiState, infoMessage: `File Added Successfully`, isErrorMessage: false, uploadBtnDisable: false });
                    }
                };
                // Read the file as an array buffer
                reader.readAsArrayBuffer(file);
            } else {
                snackbarRef.current.open(MESSAGES.VOICE_FILE_UPLOAD_CORRECT_FORMAT);
            }
        } catch (err) {
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.ERR.VOICE_FILE_UPLOAD);
            }
        }
    };

    /**
     * Check if the wav file is valid or not
     * @param {Object} audioData data of the audio file
     * @returns {boolean} valid file or not
    */
    const isWavFileValid = (audioData) => {
        if (audioData.sampleRate !== 8000 || audioData.numberOfChannels !== CHANNELS.MONO) {
            return false;
        }
        return true;
    }

    /**
     * Uploads the audio file to repository and update the voice file info state of parent component
    */
    const uploadFile = async () => {
        try {
            const fileInfo = { businessCode, language: selectedLanguage, fileName: uploadedFileInfo.filePath?.replace(/\.wav$/, '') }
            fileInfo.file = uploadedFileInfo.file;
            const response = await AudioService.uploadVoiceFileToRepo(fileInfo);
            if (response?.data?.filePath) {
                const newFilePath = response.data.filePath.replace(/^voice-files\//, '').replace(/\.wav$/, '');
                const newInfo = {
                    filePath: newFilePath,
                    voiceFileType: VOICE_FILE_UPLOAD_TYPE.UPLOAD,
                    fileSize: uploadedFileInfo.fileSize
                }
                const newVoiceFileInfo = _updateVoiceFileInfo(voiceFileInfo, selectedLanguage, newInfo);
                setUploadedFileInfo({...uploadedFileInfo, filePath: newFilePath});
                updateVoiceFilePathState(response.data.filePath, newVoiceFileInfo);
                snackbarRef.current.open(MESSAGES.FILE_UPLOAD_SUCCESS);
                setUiState({ ...uiState, uploadBtnDisable: true });
            }
        } catch (err) {
            setUiState({ ...uiState, uploadBtnDisable: false });
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.ERR.FILE_UPLOAD);
            }
        }
    }

    /**
     * Updates the voice file info object with new details.
     * Old info will have details of the voice file along with respective file paths and voice file type of each language.
     * New info will have details of the voice file with updated filePath for the selected language.
     * Now need to update the entire voiceFileInfo which has info for each system voice file with the new information of selected voice file.
     * @param {Object} oldInfo - old details of the voice file with respective file paths for each language
     * @param {string} language - language of the voice file to be updated
     * @param {Object} newInfo - new details of the voice file with updated file path and voice file type
     * @returns {Object} full updated voiceFileInfo 
     */
    function _updateVoiceFileInfo(oldInfo, language, newInfo) {
        // Return a new object with the updated language info
        return {
            ...oldInfo,
            language: {
                ...oldInfo.language,
                [language]: {
                    ...oldInfo.language[language],
                    ...newInfo
                }
            }
        };
    }

    return (
        <>
            <PmivrSnackBar ref={snackbarRef} />
            <div className="d-flex justify-content-between">
                <div className="pmivr-title pt-2">Upload Audio File</div>
                <AudioPlayer filePath={uploadedFileInfo.filePath} cssClass={CSS_CLASSES.AUDIO_BUTTON_LARGE} isUploadedOnGit={true} />
            </div>
            <div className="pmivr-drop-audio-file">
                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.UPLOAD_VOICE_FILE}>
                    <div className="choose-file">
                        Drop audio file here or{" "}
                        <a href="/#">Browse <i className="bi bi-link-45deg"></i></a>
                        <input id="voiceFileUpload" className="form-control form-control-lg" accept=".wav"
                            onChange={(e) => { voiceFileUploadChangeHandler(e) }}
                            placeholder="Drop audio file here or Browse" type="file" />
                    </div>
                </PmivrOverlayTrigger>
            </div>
            <div className="pmivr-upload-file">
                <div className={uiState.isErrorMessage ? "failed-upload" : "success-upload"}>{uiState.infoMessage}</div>
                <div className="file-name mb-2">File Path : <span className="file-path">{uploadedFileInfo.filePath}</span></div>
                <div className="file-size">File Size : <span className="file-path"> {uploadedFileInfo.fileSize} {uploadedFileInfo.fileSize !== "" ? "KB" : ""}</span></div>
            </div>
            <button style={{ width: "-webkit-fill-available" }} className="pmivr-btn-secondary mt-3 p-3"
                disabled={uiState.uploadBtnDisable || !uploadedFileInfo.file} onClick={() => uploadFile()}>
                Upload File
            </button>
        </>
    );
};

SystemVoiceFileUploadOption.propTypes = {
    // voice file info object with all details of the system voice file for all languages
    voiceFileInfo: PropTypes.object,
    // name of the selected language for the system voice file
    selectedLanguage: PropTypes.string,
    // function that updates the current state of voice file info
    updateVoiceFilePathState: PropTypes.func
}

export default SystemVoiceFileUploadOption;