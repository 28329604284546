/**
 *  For voice file related task
 */
export default class VoiceFile {
    constructor() {
        // store the filePath generated from tts upload or manually eneterd in case of libraray file
        this.filePath = "";
        // tts text entered by user
        this.ttsText = "";
        // gender like female or male for tts 
        this.gender = "";
        // play speed like normal, fast, slow for tts
        this.playSpeed = "";
        // type of file tts, upload, library,variable
        this.voiceFileType = "";
        this.fileUrl = "";
        // size of the file in case of upload file type
        this.fileSize = "";
        // id of the voice file option 
        this.id = "";
        // stores values related option value
        this.option = "";
    }
}

/**
 * For Key Value Voice File related task
 */
export class KeyValueVoiceFile {
    constructor() {
        // id of the option selected
        this.id = "";
        // option value like 1, 2
        this.option = "";
        // list of prompts to play
        // Example : {filePath:"",isUploadedOnGit:"",variable:"",variableType:"",voiceFileType:"",fileSize:"",ttsText:"",promptId:""}
        this.promptsList = [];
    }
}

/**
 * Structure of prompt values , values that will be saved in array of objects of prompt list
 */
export class VoicePrompt {
    constructor(info = {}) {
        // file path for the voice file
        this.filePath = info?.filePath || "";
        // tts text generated by uploading gender speed for the text
        this.ttsText = info?.ttsText || "";
        // gender to define the voice of tts text
        this.gender = info?.gender || "";
        // speed like normal , fast , slow for tts text
        this.playSpeed = info?.playSpeed || "";
        // type of the voice file , tts , upload , library
        this.voiceFileType = info?.voiceFileType || "";
        this.fileUrl = info?.fileUrl || "";
        // size of file uploaded in case of upload file
        this.fileSize = info?.fileSize || "";
        // variable to play like todayDate
        this.variable = info?.variable || "";
        // flag to maintiain if we have file uploaded on git bitbucket
        this.isUploadedOnGit = info?.isUploadedOnGit || false;
        // type of variable like in case of todatDate , type will be "date"
        this.variableType = info?.variableType || "digits";
        // id of the prompt to check which prompt to update in case of isUploadedOngit and tts filePaths
        this.promptId = info?.promptId || "";
    }
}