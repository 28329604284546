import { ENVIRONMENT } from '../constants/environment';

/**
 * Environment
 */
import development from './environment.dev';
import production from './environment.prod';
import uat from './environment.uat';
import sit from './environment.sit';

// Function to determine the environment configuration
const getEnvironmentConfig = () => {
  // Check CLI environment (SIT is set through the build command by defining REACT_APP_ENV as 'sit')
  if (process.env.REACT_APP_ENV === ENVIRONMENT.SIT) {
    return sit;
  }

  // Check browser window configuration for development
  if (window.config.REACT_APP_ENV === ENVIRONMENT.DEVELOPMENT) {
    return development;
  }

  // Check browser window configuration for production
  if (window.config.REACT_APP_ENV === ENVIRONMENT.PRODUCTION) {
    return production;
  }

  // Default environment to UAT
  return uat;
};

// Export the environment configuration
export const envConfig = getEnvironmentConfig();