import { useState } from 'react';
import PropTypes from 'prop-types';

import { MESSAGES, TOOLTIP } from '../../../constants/messages';

import PmivrOverlayTrigger from '../../../components/common/overlay-trigger/pmivr-overlay-trigger';
import PmivrLabel from '../../../components/common/label/pmivr-label';

/**
* Dialog box to confirm action for API Keys
* @param {Object} props Properties from parent component
* @returns {React.Component} Html element to render
*/
const ApiKeysConfirmDialog = ({ email, appCode, closeAction, regenerateKey, deleteApiKey, action }) => {
    // to disable the confirm button as per user input and actions
    const [uiState, setUiState] = useState({ disabled:true, errorMsg: '' });
    // number of days for API key to be valid
    const [apiKeyValidDays, setApiKeyValidDays] = useState();
    const CONFIRM_ACTION = {
        REGENERATE: 'regenerate',
        DELETE: 'delete'
    }

    /**
     * handle changes in the API key validity days input
     * @param {number} validDays - The number of days for the API key validity
     */
    const handleApiKeyValidityChange = (validDays) => {
        if (action === CONFIRM_ACTION.REGENERATE) {
            if (validDays && validDays <= 0) {
                setUiState({ disabled: true, errorMsg: MESSAGES.ERR.INVALID_VALIDITY_DAYS });
            } else {
                setUiState({ disabled: !validDays, errorMsg: '' });
            }
        }
        setApiKeyValidDays(validDays);
    };

    // Confirm the action on clicking the button
    const confirmAction = () => {
        closeAction();
        switch (action) {
            case CONFIRM_ACTION.REGENERATE:
                regenerateKey(email, appCode, apiKeyValidDays);
                break;
            case CONFIRM_ACTION.DELETE:
                deleteApiKey(email, appCode);
                break;
            default: 
                break;
        }
    }
    return (
        <>
            <div className="pmivr-card card-secondary pmivr-number-input">
                <div className="pmivr-container">
                    <div className="wrapper p-3 pt-0">
                        <div className="mb-2 remove-arrows">
                            <h6>Are you sure to {action} the API key?</h6>
                        </div>
                        {/* Validity days for api key */}
                        { action === CONFIRM_ACTION.REGENERATE &&
                        <>
                            <PmivrLabel label="Validity Days" tooltip={TOOLTIP.INFO.API_KEY_VALIDITY_DAYS} cssClass="mt-3" />
                            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_KEY_VALIDITY_DAYS}>
                                <input type="number" name="apiKeyValidityDays" id="apiKeyValidityDays" value={apiKeyValidDays} 
                                onChange={(e)=>handleApiKeyValidityChange(e.target.value)} placeholder="Regenerate Key For Number Of Days" 
                                min="1" className="form-control pmivr-input"/>
                            </PmivrOverlayTrigger>
                            <div className="error-msg text-danger my-1">
                                {uiState.errorMsg}
                            </div>
                        </>
                        }
                        <div className="text-end mt-4">
                            <div className="d-inline-block mx-2">
                                <button className={`pmivr-btn-secondary`} type="button" onClick={closeAction} style={{ padding: "0.7rem" }}>Cancel</button>
                            </div>
                            <div className="d-inline-block mx-2">
                                <button className={`pmivr-btn-app`} type="submit" onClick={() => confirmAction()} 
                                disabled={action === CONFIRM_ACTION.REGENERATE && uiState.disabled} style={{ padding: "0.7rem" }}>Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

ApiKeysConfirmDialog.propTypes = {
    // closing the user dialogue
    closeAction: PropTypes.func,
    // Action on clicking confirm
    action: PropTypes.string,
    // email passed from the list
    email: PropTypes.string,
    // App code 
    appCode: PropTypes.string,
    // Regenerating api key function
    regenerateKey: PropTypes.func,
    // deleting api key function
    deleteKey: PropTypes.func
}

export default ApiKeysConfirmDialog;