import CustomContextPad from './CustomContextPad';
import RemoveElementCustomContextPad from './RemoveElementCustomContextPad';
import CustomPalette from './CustomPalette';
import CustomRenderer from './CustomRenderer';

/**
 * Called by bpmn to add custom properties, need to be added if any to the diagram elements
 */
const CustomModule = {
  __init__: ['customContextPad', 'removeElementCustomContextPad', 'customPalette', 'customRenderer'],
  customContextPad: ['type', CustomContextPad],
  removeElementCustomContextPad: ['type', RemoveElementCustomContextPad],   // added for removing the by-default added elements from context pad
  customPalette: ['type', CustomPalette],
  customRenderer: ['type', CustomRenderer]
};

export default CustomModule;