import entryFactory from "bpmn-js-properties-panel/lib/factory/EntryFactory";

import { is } from "bpmn-js/lib/util/ModelUtil";

export default function UserOptionInputDetailProps(group, element, translate) {
  // Only return an entry, if the currently selected
  // element is a start event.

  if (is(element, "bpmn:UserTask")) {
    group.entries.push(
      entryFactory.textField(translate, {
        id: "options",
        description: "Enter Valid Options ",
        label: "Valid Option",
        modelProperty: "options"
      })
    );
    group.entries.push(
      entryFactory.textField(translate, {
        id: "optionVar",
        description: "Option Variable",
        label: "Option Variable",
        modelProperty: "optionVar"
      })
    );
    group.entries.push(
      entryFactory.textField(translate, {
        id: "optionRetryCount",
        description: "Retry Count Variable",
        label: "Retry Count Variable",
        modelProperty: "optionRetryCount"
      })
    );
  }
}
