import { ATTRIBUTES } from "../constants/attributes";
import { MODELER_EVENTS } from "../constants/events";

import { ATTRIBUTES_FOR_DEAFULT_CHANGE_HISTORY_MESSAGE, CHANGE_ATTRS_TO_INCLUDE, VOICE_FILE_TASK_ATTR } from "../config/config";
import { EL_TYPE } from "../constants/element";

import VoiceUtil from "../util/audio.util";

/**
 * Service class for change history operations
 */
class ChangeHistoryService {

  // {id,type,name,eventType}
  // diagram change history
  static _changeHistory = [];

  static getChangeHistory() {
    return ChangeHistoryService._changeHistory;
  }

  static setChangeHistory(changeHistory) {
    ChangeHistoryService._changeHistory = changeHistory;
  }

  /**
   *  This is to track the changes done in the configuration for various controls
   * @param {string} property  Property name
   * @param {string} oldVal Old value of property
   * @param {string} newVal  Updated value of property
   * @returns {boolean} Boolean value to check change in attribute
   */
  static isAttributeChanged(property, oldVal, newVal) {
    if (VOICE_FILE_TASK_ATTR.includes(property)) {
      return this.isVoiceTaskChanged(oldVal, newVal);
    } else {
      return CHANGE_ATTRS_TO_INCLUDE.includes(property)
        ? oldVal !== newVal
        : false;
    }
  }

  /**
   * Getting the change history message for the updated property
   * @param {Object} history 
   * @returns {string} Change history message
   */
  static getChangeHistoryMessage(history) {
    const changedNode = history.change;
    const elementType = history?.elType?.substring(history.elType.indexOf(':') + 1);
    switch (history.eventType) {
      case MODELER_EVENTS.CREATE_SHAPE:
        return `A new ${elementType} ${history.changeValue ? "labeled with '" + history.changeValue + "'" : ''}` +
          " has been introduced";
      case MODELER_EVENTS.UPDATE_NAME:
        return `${elementType} node with name '${history.oldValue}' is updated to '${history.changeValue}'`;
      case MODELER_EVENTS.UPDATE_PROPERTY:
        if (changedNode === ATTRIBUTES.VOICE_FILE_INFO) {
          // if change is in voice file task
          const changedValue = JSON.parse(history.changeValue);
          for (let lang in changedValue) {
            const info = changedValue[lang];
            return `'${VoiceUtil.getVoiceFileTypeName(info.voiceFileType)}' is updated for ${elementType}` +
              ` named '${history.elName}'`;
          }
        } else if (changedNode === ATTRIBUTES.USER_OPTION_VALUES_MAP) {
          // if change is in user options task
          if (history.elName) {
            return `Options are updated for  ${elementType} named '${history.elName}'`;
          } else {
            return `Options are updated for  ${elementType}`;
          }
        } else if (changedNode === ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE) {
          // if change is in user input invalid option file node
          const changedValue = JSON.parse(history.changeValue);
          for (let lang in changedValue) {
            const info = changedValue[lang];
            return `'${VoiceUtil.getVoiceFileTypeName(info.voiceFileType)}' is updated for ${elementType} ` +
              ` named '${history.elName}'`;
          }
        } else if (changedNode === ATTRIBUTES.GATEWAY_CONDITION) {
          return `${elementType} named ${history.elName} is updated.`;
        } else if (ATTRIBUTES_FOR_DEAFULT_CHANGE_HISTORY_MESSAGE.includes(changedNode)) {
          // deafult update change history message
          if (history.elName) {
            return `The properties of the node ${elementType} named '${history.elName}' have been modified`;
          } else {
            return `The properties of the node ${elementType} have been modified`;
          }
        }
        break;
      case MODELER_EVENTS.CONNECTION_DELETE:
        return `Connection between '${history.changeValue.sourceNode}' and '${history.changeValue.targetNode}' is deleted`;
      case MODELER_EVENTS.CONNECTION_CREATED:
        return `Connection created from '${history.changeValue.sourceNode}' to '${history.changeValue.targetNode}'`;
      case MODELER_EVENTS.ELEMENT_DELETE:
        return `${history.taskType === EL_TYPE.SUB_PROCESS ? "Subprocess" : "Element"} named ${history.elName} has been deleted`;
      case MODELER_EVENTS.CUSTOM.CREATE_SHAPE_AND_RENAME:
        return `A new ${elementType} ${history.changeValue ? "labeled with '" + history.changeValue + "'" : ''}` +
          " has been introduced and renamed";
      default:
        return '';
    }
  }

  /**
   * Track the changes done in the configuration for voice file task
   * @param {Json String} oldVal : old value of property
   * @param {Json String} newVal : updated value of property
   * @returns {boolean} boolean value to check change in voice task
   */
  static isVoiceTaskChanged(oldVal, newVal) {
    let isChanged = false;

    // in case of updating property for new created task. It will not have the oldVal in this case
    if (newVal && !oldVal) {
      return true;
    }

    // parsing the json string and comparing the key values
    let newPropertyVal = JSON.parse(newVal);
    let oldPropertyVal = JSON.parse(oldVal);
    for (let lang in newPropertyVal) {
      const newJson = newPropertyVal[lang];
      const oldJson = oldPropertyVal[lang];
      // scenario: in option user, when we add new options, add voice info, then old voice info will not have the newly added option
      if (!oldJson) {
        continue;
      }
      let newJsonKeys = Object.keys(newJson);
      let oldJsonKeys = Object.keys(oldJson);

      // if the number of keys in new Json is not equal to old Json
      if (newJsonKeys.length !== oldJsonKeys.length) {
        isChanged = true;
        break;
      }

      // checking the difference in json key value of properties
      //for (let counter = 0; counter < newJsonKeys.length; counter++) {
      for (let counter of newJsonKeys) {
        const key = counter;
        if (newJson[key] !== oldJson[key]) {
          isChanged = true;
          break;
        }
      }
      // if change founded in nested loop, then break the outer loop also
      if (isChanged) {
        break;
      }
    }
    return isChanged;
  }

  /**
   * Empty the change history for the diagram
   */
  static clearChangeHistory() {
    ChangeHistoryService.setChangeHistory([]);
  }
}

export default ChangeHistoryService;
