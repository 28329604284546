import { configureStore } from "@reduxjs/toolkit";
import persistedReducer from "../reducers/reducer";

// configured Redux store
const store = configureStore({
    // rather than passing reducer pass persisted reducer so that state remains on refresh also
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }),
});

export default store;