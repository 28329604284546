import React, { useContext, useState, useEffect, useRef } from "react";
import Chips from 'react-chips';

import { ATTRIBUTES } from "../../../../constants/attributes";
import { MESSAGES, TOOLTIP } from "../../../../constants/messages";

import { VoiceContext } from "../../../../contexts/app-context";

import PmivrOverlayTrigger from "../../../common/overlay-trigger/pmivr-overlay-trigger";
import PmivrSnackBar from "../../../common/dialog/pmivr-snackbar";
import PmivrLabel from "../../../common/label/pmivr-label";

import ElementService from "../../../../services/element.service";
import { SERVICE_TYPES } from "../../../../constants/task-types";

/**
 * Component for configuring API Call service properties.
 */
const ApiCallServiceView = () => {

  const { element } = useContext(VoiceContext);

  const methodOptions = ["GET", "POST", "PUT", "DELETE"];

  // Initialize state to hold the parsed AUTH object
  const [auth, setAuth] = useState({
    url: "", method: "", responseVariable: "", params: "", payload: ""
  });

  // Response variables which has values from the service object
  const [responseVariables, setResponseVariables] = useState([]);

  // Available response variables as chips in the input field
  const [availableResponseVariables, setAvailableResponseVariables] = useState([]);

  const [uiState, setUiState] = useState({ apiUrl: '', apiMethod: '', apiQueryParams: '', apiHeaders: '', apiPayload: '' });

  // to use the snackbar methods
  const snackbarRef = useRef();

  useEffect(() => {
    try {
      const apiMethod = ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_METHOD);
      if (!apiMethod) {
        // if not defined, then set it to GET
        ElementService.updateElementAttr(element, ATTRIBUTES.SERVICE.API_METHOD, methodOptions[0]);
        setUiState({ ...uiState, apiMethod: ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_METHOD) });
      }
      // populate data only when api call is selected
      if (ElementService.getAttribute(element, ATTRIBUTES.SERVICE.TYPE, "") === SERVICE_TYPES.API_CALL) {
        const authJson = ElementService.getAttribute(element, ATTRIBUTES.SERVICE.AUTH, "{}");
        const parsedAuth = JSON.parse(authJson);

        // In case of auth present in the json, then only set it in the auth variable
        if (parsedAuth) {
          // query params should be in json stringify
          parsedAuth.params = JSON.stringify(parsedAuth.params);
          setAuth(parsedAuth);
        }

        const apiRespVariables = ElementService.getAttribute(element, ATTRIBUTES.SERVICE.RESPONSE_VARIABLES, "{}");
        // Because response variables are comma separated values, to convert them into array
        const apiResponseVariablesArray = apiRespVariables.split(',').map(item => item.trim());
        setResponseVariables(apiResponseVariablesArray);
        // Initially set the input field for response variables as well
        setAvailableResponseVariables(apiResponseVariablesArray);
        setUiState({
          ...uiState,
          apiUrl: ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_URL, ""),
          apiMethod: ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_METHOD),
          apiQueryParams: ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_QUERY_PARAMS, ""),
          apiHeaders: ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_HEADERS, ""),
          apiPayload: (ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_METHOD) === "POST" ||
            ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_METHOD) === "PUT") ?
            ElementService.getAttribute(element, ATTRIBUTES.SERVICE.API_PAYLOAD, "") : ''
        });
      }
    } catch (error) {
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.JSON_PARSE_ERROR);
      }
    }
  }, [element]);

  /**
   * Update an API Call attribute in the element.
   * @param {string} attributeName - The name of the attribute to update.
   * @param {string} value - The new value to set for the attribute.
   */
  const updateAttribute = (attributeName, value) => {
    ElementService.updateElement(element, attributeName, value);
  };

  /**
   * Changes the value of response variables when any value is deleted or added from
   * @param {Array<string>} updatedResponseVariables
   */
  const updateApiResponseVariables = (updatedResponseVariables) => {
    setAvailableResponseVariables(updatedResponseVariables);

    // Convert the updated response variables to a CSV string
    const responseVariablesCsv = updatedResponseVariables.join(',');
    // Update the response variables attribute in the element
    ElementService.updateElement(element, ATTRIBUTES.SERVICE.RESPONSE_VARIABLES, responseVariablesCsv);
  };

  /**
    * Update an API Call attribute in the element and stringify AUTH object.
    * @param {string} attributeName - The name of the attribute to update.
    * @param {string} value - The new value to set for the attribute.
    */
  const updateAuthAttribute = (attributeName, value) => {
    // Update the AUTH state
    setAuth({ ...auth, [attributeName]: value });

    // Stringify the updated AUTH object
    const authJson = JSON.stringify({ ...auth, [attributeName]: value });

    // Update the AUTH attribute in the element
    ElementService.updateElement(element, ATTRIBUTES.SERVICE.AUTH, authJson);
  };

  return (
    <>
      <PmivrSnackBar ref={snackbarRef} />
      <div className="m-2 mt-3">
        <div className="form-group mb-4">
          <PmivrLabel label="Enter API URL" tooltip={TOOLTIP.INFO.API_URL} />
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_URL}>
            <input id="apiUrl" name="apiUrl" className="form-control pmivr-input" placeholder="Enter URL" value={uiState.apiUrl}
              onChange={(event) => {
                updateAttribute(ATTRIBUTES.SERVICE.API_URL, event.target.value);
                setUiState({ ...uiState, apiUrl: event.target.value });
                // setting the name of the method that will do api call
                // eslint-disable-next-line
                updateAttribute(ATTRIBUTES.SERVICE_IMPLEMENTATION_METHOD, "${environment.services.handleServiceApiCall}");
                // setting the service type
                updateAttribute(ATTRIBUTES.SERVICE.TYPE, SERVICE_TYPES.API_CALL);
              }}
            />
          </PmivrOverlayTrigger>
        </div>
        <div className="form-group props-custom-input mb-3">
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_METHOD}>
            <select id="apiMethod" name="apiMethod" className="pmivr-select" value={uiState.apiMethod}
              onChange={(event) => {
                updateAttribute(ATTRIBUTES.SERVICE.API_METHOD, event.target.value);
                setUiState({ ...uiState, apiMethod: event.target.value });
              }}
            >
              {methodOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <label >Select API Method</label>
          </PmivrOverlayTrigger>
        </div>

        {/* Add input field for API_QUERY_PARAMS */}
        <PmivrLabel label="Enter API Query Parameters" tooltip={TOOLTIP.INFO.SERVICE_TASK_INPUT_VARIABLE} />
        <div className="form-group mb-3">
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_QUERY_PARAMS}>
            <textarea id="apiQueryParams" name="apiQueryParams" className="form-control pmivr-input" rows="4"
              value={uiState.apiQueryParams}
              onChange={(event) => {
                updateAttribute(ATTRIBUTES.SERVICE.API_QUERY_PARAMS, event.target.value);
                setUiState({ ...uiState, apiQueryParams: event.target.value });
              }}
              placeholder=" "
            />
          </PmivrOverlayTrigger>
        </div>

        {/* Add input field for API_HEADERS */}
        <PmivrLabel label="Enter API Headers" tooltip={TOOLTIP.INFO.SERVICE_TASK_INPUT_VARIABLE} />
        <div className="form-group mb-3">
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_HEADERS}>
            <textarea id="apiHeaders" name="apiHeaders" className="form-control pmivr-input" rows="4"
              value={uiState.apiHeaders}
              onChange={(event) => {
                updateAttribute(ATTRIBUTES.SERVICE.API_HEADERS, event.target.value);
                setUiState({ ...uiState, apiHeaders: event.target.value });
              }}
              placeholder=" "
            />
          </PmivrOverlayTrigger>
        </div>

        {/* Add input field for API_Payload */}
        <PmivrLabel label="Enter API Payload" tooltip={TOOLTIP.INFO.SERVICE_TASK_INPUT_VARIABLE} />
        <div className="form-group mb-3">
          <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_PAYLOAD}>
            <textarea id="apiPayload" name="apiPayload" className="form-control pmivr-input" rows="4" value={uiState.apiPayload}
              onChange={(event) => {
                updateAttribute(ATTRIBUTES.SERVICE.API_PAYLOAD, event.target.value);
                setUiState({ ...uiState, apiPayload: event.target.value });
              }}
              placeholder=" "
              // Field will be disabled if api method is GET
              disabled={uiState.apiMethod !== "POST" && uiState.apiMethod !== "PUT"}
            />
          </PmivrOverlayTrigger>
        </div>

        {/* Response Variables section */}
        <div className="response-variables-section">
          <PmivrLabel label="API Response Variables" tooltip={TOOLTIP.INFO.SERVICE_TASK_RESPONSE_VARIABLE} />
          <div className="form-group props-custom-input pt-1">
            <Chips value={availableResponseVariables} onChange={updateApiResponseVariables}
              suggestions={responseVariables}
            />
          </div>
        </div>

        {/* Create Auth section */}
        <div className="auth-section">
          <h6>Auth Configuration</h6>
          <div className="form-group mb-4">
            <PmivrLabel label="Enter Auth URL" tooltip={TOOLTIP.INFO.AUTH_URL} />
            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.AUTH_URL}>
              <input id="authUrl" name="authUrl" className="form-control pmivr-input" placeholder="Enter URL" value={auth.url}
                onChange={(event) => {
                  updateAuthAttribute("url", event.target.value);
                }}
              />
            </PmivrOverlayTrigger>
          </div>

          <div className="form-group props-custom-input mb-2">
            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.API_METHOD}>
              <select id="authMethod" name="authMethod" className="pmivr-select" value={auth.method}
                onChange={(event) => {
                  updateAuthAttribute("method", event.target.value);
                }}
              >
                {methodOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <label>Select Auth Method</label>
            </PmivrOverlayTrigger>
          </div>
          <div className="form-group mb-3">
            <PmivrLabel label="Enter Auth Response Variable" tooltip={TOOLTIP.INFO.AUTH_RESPONSE_VARIABLE} />
            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.AUTH_RESPONSE_VARIABLE}>
              <input id="authResponseVariable" name="authResponseVariable" className="form-control pmivr-input" placeholder="Enter Variable"
                value={auth.responseVariable}
                onChange={(event) => {
                  updateAuthAttribute("responseVariable", event.target.value);
                }}
              />
            </PmivrOverlayTrigger>
          </div>

          <div className="form-group props-custom-input mb-3">
            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.AUTH_PAYLOAD}>
              <textarea id="authPayload" name="authPayload" className="form-control pmivr-input" rows="4"
                // In case of get method, empty the field
                value={
                  auth.method === "POST" ? auth.payload : ''
                }
                onChange={(event) => {
                  updateAuthAttribute("payload", event.target.value);
                }}
                placeholder=" "
                // Field will be disabled if auth method is GET
                disabled={auth.method !== "POST" && auth.method !== "PUT"}
              />
              <label className="pt-1">Enter Auth Payload</label>
            </PmivrOverlayTrigger>
          </div>

          <div className="form-group mb-3">
            <PmivrLabel label="Enter Query Params (JSON string)" tooltip={TOOLTIP.INFO.AUTH_PARAMS_JSON_STRING} />
            <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.AUTH_PARAMS_JSON_STRING}>
              <textarea id="authParams" name="authParams" className="form-control pmivr-input" value={auth.params} rows="4"
                onChange={(event) => {
                  updateAuthAttribute("params", event.target.value);
                }}
              />
            </PmivrOverlayTrigger>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiCallServiceView;