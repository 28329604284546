import {
    updateDraftsArray, updateFlowType, updateLanguagesConfigured, updateAppCodeData, updateDeploymentEnvData,
    updatePublishedArray, updateBusinessCodeName, updateVariables, updateVersionId, updateMapOfVoiceFiles, updateSelectedFlowTypeInfo,
    updateFlows, updateClientCreatedFlows, clearState, updateClientVerificationData, updateElementActionDialogParams,
    updateChangeHistoryData, clearChangeHistoryData, updateSystemVoiceFilesInfo
} from "../slices/client.slice";

export const updateAppCode = ({ appCode }) => async dispatch => {
    dispatch(updateAppCodeData({ appCode }));
}

export const updateDeploymentEnv = ({ deploymentEnvironment }) => async dispatch => {
    dispatch(updateDeploymentEnvData({ deploymentEnvironment }));
}

export const updateSystemVoiceFiles = ({ systemVoiceFiles }) => async dispatch => {
    dispatch(updateSystemVoiceFilesInfo({ systemVoiceFiles }));
}

export const updateIsVerifiedClient = ({ isVerifiedClient }) => async dispatch => {
    dispatch(updateClientVerificationData({ isVerifiedClient }));
}

export const updateLanguages = ({ languagesConfigured }) => async dispatch => {
    dispatch(updateLanguagesConfigured({ languagesConfigured }));
}

export const updateBusinessCode = ({ businessCode }) => async dispatch => {
    dispatch(updateBusinessCodeName({ businessCode }));
}

export const updateDraft = ({ draft }) => async dispatch => {
    dispatch(updateDraftsArray({ draft }));
}

export const updatePublished = ({ published }) => async dispatch => {
    dispatch(updatePublishedArray({ published }));
}

export const updateVersion = ({ versionId }) => async dispatch => {
    dispatch(updateVersionId({ versionId }));
}

export const updateSelectedFlowType = ({ flowType }) => async dispatch => {
    dispatch(updateFlowType({ flowType }));
}

export const updateVariablesList = ({ variables }) => async dispatch => {
    dispatch(updateVariables({ variables }));
}

export const updateVoiceFiles = ({ mapOfVoiceFiles }) => async dispatch => {
    dispatch(updateMapOfVoiceFiles({ mapOfVoiceFiles }));
}

export const updateSelectedFlowTypeInformation = ({ selectedFlowTypeInfo }) => async dispatch => {
    dispatch(updateSelectedFlowTypeInfo({ selectedFlowTypeInfo }));
}

export const updateAvailableFlows = ({ flows }) => async dispatch => {
    dispatch(updateFlows({ flows }));
}

export const updateClientFlows = ({ clientFlows }) => async dispatch => {
    dispatch(updateClientCreatedFlows({ clientFlows }));
}

export const updateTaskActionParams = ({ elementActionDialogParams }) => async dispatch => {
    dispatch(updateElementActionDialogParams({ elementActionDialogParams }));
}

export const updateChangeHistory = ({ changeHistory }) => async dispatch => {
    dispatch(updateChangeHistoryData({ changeHistory }));
}

export const clearChangeHistory = () => async dispatch => {
    dispatch(clearChangeHistoryData());
}

/**
 * dispatch the action to clear the values from redux state
 */
export const clearClientState = () => async dispatch => {
    dispatch(clearState());
}