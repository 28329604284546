// Require your custom property entries.
import { TASK_TYPE } from "../../../../constants/task-types";
import voiceFileDetailprops from "./parts/VoiceFileDetailProps";

// Create the custom voice Files tab.
// The properties are organized in groups.
function createVoiceFilesTabGroups(element, translate) {
  if (element.businessObject.taskType === TASK_TYPE.playVoiceFile || element.businessObject.taskType === TASK_TYPE.promptUserInput) {
    // Create a group called "Voice Files".
    let voiceFilesGroup = {
      id: "voice-files",
      label: "Voice Files",
      entries: []
    };

    // Add the voice files props to the black voiceFiles group.
    voiceFileDetailprops(voiceFilesGroup, element, translate);
    return [voiceFilesGroup];
  }
}

export default function VoiceFilesPropertiesProvider(propertiesPanel, translate) {
  this.getTabs = function (element) {
    return function (entries) {
      // Add the "voiceFiles" tab
      let voiceFilesTab = {
        id: "voiceFiles",
        label: "Voice Files",
        groups: createVoiceFilesTabGroups(element, translate)
      };

      entries.push(voiceFilesTab);

      // Show general + "voiceFiles" tab
      return entries;
    };
  };

  // Register our custom voiceFiles properties provider.
  // Use a lower priority to ensure it is loaded after the basic BPMN properties.
  propertiesPanel.registerProvider(this);
}

VoiceFilesPropertiesProvider.$inject = ["propertiesPanel", "translate"];
