class JsCodeSafetyCheckUtil {

    /**
     * Checking whether the js code is safe or not.
     * If the code has UI Code, Db Code, Sensitive Data Code, Dynamic Code execution, Delete file code,
     * Infinite loop code, Download data code, then it is not safe.
     * @param {String} expression expression to be checked
     * @returns true, if code is safe. Otherwise, return false
     */
    static isJSCodeSafe = (expression) => {
        return (!(JsCodeSafetyCheckUtil.hasUiCode(expression) || JsCodeSafetyCheckUtil.hasDBCode(expression)
            || JsCodeSafetyCheckUtil.hasSensitiveDataCode(expression)
            || JsCodeSafetyCheckUtil.hasDeleteFileCode(expression) || JsCodeSafetyCheckUtil.hasInfiniteLoopCode(expression)
        ));
    }

    /**
     * Checks whether the expression has UI related code (document, alert, etc)
     * @param {String} expression expression to be checked
     * @returns true, if it has ui based code. Otherwise, returns false
     */
    static hasUiCode = (expression) => {
        const uiRelatedCodeRegex = /(document\.|window\.|alert\(|confirm\(|prompt\()/g;
        return uiRelatedCodeRegex.test(expression);
    }

    /**
     * Checks whether the expression has DB related code (sql, mongo, etc)
     * @param {String} expression expression to be checked
     * @returns true, if it has db related code. Otherwise, returns false
     */
    static hasDBCode = (expression) => {
        const dbRelatedCodeRegex = /(require\(['"]{1}mongodb['"]{1}\)|mongoose\.|sql\.|sqlite3\.|mysql\.|postgresql\.|oracle\.)/g;
        return dbRelatedCodeRegex.test(expression);
    }

    /**
     * Checks whether the expression has used sensitive data (password, api_key, etc)
     * @param {String} expression expression to be checked
     * @returns true, if it has used sensitive data. Otherwise, returns false
     */
    static hasSensitiveDataCode = (expression) => {
        const sensitiveData = ['password', 'api_key', 'access_token'];
        const sensitiveDataRegex = new RegExp(`\\b(${sensitiveData.join('|')})\\b`, 'gi');
        return sensitiveDataRegex.test(expression);
    }

    /**
     * Checks whether the expression has dynamic code execution (eval(), new Function()) 
     * @param {String} expression expression to be checked
     * @returns true, if it has dynamic code execution expression. Otherwise, returns false
     */
    static hasDynamicCode = (expression) => {
        const dynamicExecutionRegex = /(eval\s*\(|Function\s*\(|new\s+Function\s*\()/g;
        return dynamicExecutionRegex.test(expression);
    }

    /**
     * Checks whether the expression has delete file code (deleteFile, remove, etc)
     * @param {String} expression expression to be checked
     * @returns true, if has delete file related code. Otherwise, returns false
     */
    static hasDeleteFileCode = (expression) => {
        const deleteFileRegex = /\b(deleteFile|delete|unlink|fs\.unlink|rm\s+-rf|os\.remove)\b/g;
        return deleteFileRegex.test(expression);
    }

    /**
     * Checks whether the expression has infinite loop code
     * @param {String} expression expression to be checked
     * @returns true, if it has the inifinite loop code. Otherwise, returns false
     */
    static hasInfiniteLoopCode = (expression) => {
        const infiniteLoopRegex = /\b(while\s*\(true\)|for\s*\(;;\))/g;
        return infiniteLoopRegex.test(expression);
    }

    /**
     * Checks whether the expression has download data code (fetch, download, etc)
     * @param {String} expression expression to be checked
     * @returns true, if has download data code. Otherwise, returns false
     */
    static hasDownloadDataCode = (expression) => {
        const downloadDataRegex = /\b(fetch|XMLHttpRequest|axios|download)\b/g;
        return downloadDataRegex.test(expression);
    }

}

export default JsCodeSafetyCheckUtil;