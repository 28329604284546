import { EL_NAME } from "../constants/element";

import ConditionUtil from "../util/condition.util";

import { ELEMENT_TYPE_NAME_MAPPING } from "../flow-builder/bpmn/elements/diagram-element.mapping.js";

/**
 * service functions to handle manipulation on conditons on right panel diagram page on conditions view
 */
class ConditionService {
    /**
        * Make condition using condition info
        * @param {[{variable,value,operator,elementId}]} conditions Condition Info
        * @returns {string}
        */
    static getConditionInStr(conditions) {
        let strCondition = `next(null,`;
        let condition = ConditionUtil.constructCondition(conditions[0]);
        strCondition += condition;
        // add logicl "AND" and "OR" operators
        conditions[0].logicalOperators?.forEach((conditionInfo) => {
            strCondition += ` ${conditionInfo.logicalOperator} `;
            condition = ConditionUtil.constructCondition(conditionInfo);
            strCondition += condition;
        });
        strCondition += `);`;
        return strCondition;
    }

    /**
     * Getting the valid elements list for condition 
     * @param {Object} elements Elements list 
     * @returns {Array} Array of valid elements
     */
    static getValidElementsForCondition(elements) {
        const validElements = elements?.filter((element) => element.businessObject)
            .map((element) => {
                return {
                    id: element.businessObject.id,
                    name: (element.businessObject.name) ? element.businessObject.name : ELEMENT_TYPE_NAME_MAPPING[element.type],
                    element: element
                };
            });

        // add End option in validElements if its not already present
        let commanElements = { id: EL_NAME.END_EVENT_NAME, name: EL_NAME.END_EVENT_NAME };
        let isEndElementPresent = validElements?.filter((element) => element.name === EL_NAME.END_EVENT_NAME);
        if (isEndElementPresent.length === 0) {
            validElements.push(commanElements);
        }
        return validElements;
    }
}

export default ConditionService;


