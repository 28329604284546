import {
  DEFAULT_CELL_HEIGHT,
  DEFAULT_CELL_WIDTH,
  connectElements,
  getBounds,
  getDockingPoint,
  getMid
} from '../utils/layoutUtil.js';

export default {
  /**
   * Adds an element to a grid and returns the next elements to be processed.
   * @param {Object} params - Object containing the element, grid, and visited elements.
   * @param {Object} params.element - The current element to add to the grid.
   * @param {Object} params.grid - The layout grid to add elements into.
   * @param {Set} params.visited - A set of already visited elements.
   * @returns {Array} - An array of next elements to be processed.
   */
  'addToGrid': ({ element, grid, visited }) => {
    const nextElements = [];

    const attachedOutgoing = (element.attachers || [])
      .map(attacher => (attacher.outgoing || []).reverse())
      .flat()
      .map(out => out.targetRef);

    // handle boundary events
    attachedOutgoing.forEach((nextElement, index, arr) => {
      if (visited.has(nextElement)) {
        return;
      }

      // Add below and to the right of the element
      insertIntoGrid(nextElement, element, grid);
      nextElements.push(nextElement);
    });

    return nextElements;
  },

  /**
   * Creates diagram information (DI) for an element at a specified grid position.
   * @param {Object} params - Object containing element, row, column, and DI factory.
   * @param {Object} params.element - The element for which DI is created.
   * @param {number} params.row - The row position of the element in the grid.
   * @param {number} params.col - The column position of the element in the grid.
   * @param {Object} params.diFactory - The factory used to create DI objects.
   * @returns {Array} - An array of DI shapes created for the element and its attachers.
   */
  'createElementDi': ({ element, row, col, diFactory }) => {
    const hostBounds = getBounds(element, row, col);

    const DIs = [];
    (element.attachers || []).forEach((att, i, arr) => {
      att.gridPosition = { row, col };
      const bounds = getBounds(att, row, col, element);

      // Distribute along lower edge
      bounds.x = hostBounds.x + (i + 1) * (hostBounds.width / (arr.length + 1)) - bounds.width / 2;

      const attacherDi = diFactory.createDiShape(att, bounds, {
        id: att.id + '_di'
      });
      att.di = attacherDi;
      att.gridPosition = { row, col };

      DIs.push(attacherDi);
    });

    return DIs;
  },

  /**
   * Creates DI connections for an element’s attachers at a specific grid position.
   * @param {Object} params - Object containing element, row, column, layout grid, and DI factory.
   * @param {Object} params.element - The element whose connections are created.
   * @param {number} params.row - The row position of the element in the grid.
   * @param {number} params.col - The column position of the element in the grid.
   * @param {Object} params.layoutGrid - The grid used for layout calculations.
   * @param {Object} params.diFactory - The factory used to create DI objects.
   * @returns {Array} - An array of DI edges created for the element’s outgoing connections.
   */
  'createConnectionDi': ({ element, row, col, layoutGrid, diFactory }) => {
    const attachers = element.attachers || [];

    return attachers.flatMap(att => {
      const outgoing = att.outgoing || [];

      return outgoing.map(out => {
        const target = out.targetRef;
        const waypoints = connectElements(att, target, layoutGrid);

        // Correct waypoints if they don't automatically attach to the bottom
        ensureExitBottom(att, waypoints, [ row, col ]);

        const connectionDi = diFactory.createDiEdge(out, waypoints, {
          id: out.id + '_di'
        });

        return connectionDi;
      });
    });
  }
};

/**
 * Inserts a new element into the grid below and to the right of the host element.
 * @param {Object} newElement - The element to insert into the grid.
 * @param {Object} host - The host element to position relative to.
 * @param {Object} grid - The grid structure where elements are inserted.
 */
function insertIntoGrid(newElement, host, grid) {
  const [ row, col ] = grid.find(host);

  // Check if grid positions are occupied
  if (grid.get(row + 1, col) || grid.get(row + 1, col + 1)) {
    grid.createRow(row);
  }

  grid.add(newElement, [ row + 1, col + 1 ]);
}

/**
 * Ensures the connection exits from the bottom of the source element.
 * @param {Object} source - The source element for the connection.
 * @param {Array} waypoints - The waypoints defining the connection path.
 * @param {Array} gridPosition - An array containing the row and column of the source element.
 */
function ensureExitBottom(source, waypoints, [ row, col ]) {
  const sourceDi = source.di;
  const sourceBounds = sourceDi.get('bounds');
  const sourceMid = getMid(sourceBounds);

  const dockingPoint = getDockingPoint(sourceMid, sourceBounds, 'b');
  if (waypoints[0].x === dockingPoint.x && waypoints[0].y === dockingPoint.y) {
    return;
  }

  if (waypoints.length === 2) {
    const newStart = [
      dockingPoint,
      { x: dockingPoint.x, y: (row + 1) * DEFAULT_CELL_HEIGHT },
      { x: (col + 1) * DEFAULT_CELL_WIDTH, y: (row + 1) * DEFAULT_CELL_HEIGHT },
      { x: (col + 1) * DEFAULT_CELL_WIDTH, y: (row + 0.5) * DEFAULT_CELL_HEIGHT },
    ];

    waypoints.splice(0, 1, ...newStart);
    return;
  }

  // Add waypoints to exit bottom and connect to existing path
  const newStart = [
    dockingPoint,
    { x: dockingPoint.x, y: (row + 1) * DEFAULT_CELL_HEIGHT },
    { x: waypoints[1].x, y: (row + 1) * DEFAULT_CELL_HEIGHT },
  ];

  waypoints.splice(0, 1, ...newStart);
  return;
}