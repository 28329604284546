import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import { MESSAGES, TOOLTIP } from "../../../constants/messages.js";
import { EL_TYPE } from "../../../constants/element.js";

import AppUtil from "../../../util/app.util.js";
import DiagramUtil from "../../../util/diagram.util.js";
import { SERVICE_NAME_MAPPING, MENU_NAMES_MAPPING } from "../elements/diagram-element.mapping.js";

import PmivrTooltip from "../../../components/common/tooltip/pmivr-tooltip.js";
import PmivrSnackBar from '../../../components/common/dialog/pmivr-snackbar.js';
import { PmivrDialog } from "../../../components/common/dialog/pmivr-dialog.js";

import FlowService from "../../../services/flow.service.js";
import ModelerService from "../../../services/modeler.service.js";

/**
 * Tools panel view in the diagram
 * @param {Object} props Props data from parent component
 * @returns {React.Component} Html element containg list of toolls displayed on diagram.js file with left side bar.
 */
const ToolsPanelView = (props) => {
  const [entries, setEntries] = useState(null);
  const [keys, setKeys] = useState([]);
  // system sub process that will fixed like language selection , greetings and nacha compliance
  const [systemReUsableElements, setSystemReUsableElements] = useState([]);
  // custom sub processes that the user creates
  const [customReUsableElements, setCustomReUsableElements] = useState([]);
  const [uiState, setUiState] = useState({ showDeleteDialog: false });
  // info of the sub process its id and index used in deleteing the custom sub process
  const [selectedElement, setSelectedElement] = useState({ id: "", index: "" });

  // using the open method from the snackbar component
  const snackbarRef = useRef();

  useEffect(() => {
    const init = () => {
      const entriesAll = props.modeler.get("palette").getEntries();
      setEntries(entriesAll);
      /**
       * keys: [create.start-event, create.end-event, create.intermediate-event, create.exclusive-gateway, 
       * create-service-impl-task, create-play-voice-file-task, create-option-user-task, create-user-input-task, 
       * create-say-data-task, create-hangup-task, create-transfer-task, create-voice-record-task]
       */
      setKeys(Object.keys(entriesAll));
    }
    init();
  }, []);

  useEffect(() => {
    loadReUsableElements();
  }, []);

  /**
   * Load the list of re usable elements
   */
  const loadReUsableElements = async () => {
    const reUsableElements = await FlowService.getReUsableElements();
    if (reUsableElements?.data) {
      const systemReUsableList = reUsableElements?.data?.system;
      const customReUsableList = reUsableElements?.data?.custom;
      if (systemReUsableList?.length) {
        setSystemReUsableElements(systemReUsableList);
      }
      if (customReUsableList?.length) {
        setCustomReUsableElements(customReUsableList);
      }
    }
  }

  /**
   * Delete the re usable elements based on element id
   */
  const deleteReUsableElements = async () => {
    try {
      // delete the custom re usable element based on its id
      const response = await FlowService.deleteReUsableElements(selectedElement?.id);
      // if deleted successfully then we will get the deleted count as 1
      if (!response?.err?.msg) {
        customReUsableElements.splice(selectedElement?.index, 1);
        setCustomReUsableElements(customReUsableElements);
        snackbarRef.current.open(MESSAGES.REUSABLE_ELEMENT_DELETED);
      } else {
        snackbarRef.current.open(MESSAGES.ERR.REUSABLE_ELEMENT_DELETE_ERR);
      }
    } catch (err) {
      snackbarRef.current.open(MESSAGES.ERR.REUSABLE_ELEMENT_DELETE_ERR);
    } finally {
      setUiState({ ...uiState, showDeleteDialog: false });
    }
  }

  /**
   * Handle delete dialog for custom re usable elements.
   * @param {string} reUsableElementId
   * @param {number} index
   * @returns 
   */
  const handleDeleteDialog = (reUsableElementId, index) => {
    setSelectedElement({ id: reUsableElementId, index });
    setUiState({ ...uiState, showDeleteDialog: true });
  }

  const getIcon = (key) => {
    switch (SERVICE_NAME_MAPPING[key]) {
      case "Start":
        return <i className="bi bi-circle"></i>;
      case "End":
        return <i className="bpmn-icon-end-event-none fs-4 pe-1"></i>;
      case "Intermediate":
        return <i className="bpmn-icon-intermediate-event-none fs-4 pe-1"></i>;
      case "Voice":
        return <i className="bi bi-mic"></i>;
      case "Task":
        return <i className="bi bi-list-task"></i>;
      case "SubProcess":
        return <i className="bi bi-aspect-ratio"></i>;
      case "Option User":
        return <i className="bi bi-grid-3x3-gap-fill"></i>;
      case "Condition":
        return <i className="bi bi-diamond"></i>;
      case "User Input":
        return <i className="bi bi-person-vcard"></i>;
      case "Prompt":
        return <i className="bi bi-megaphone"></i>;
      case "Hang Up":
        return <i className="bi bi-telephone-x"></i>;
      case "Transfer":
        return <i className="bi bi-telephone-forward"></i>;
      case "Voice Record":
        return <i className="bi bi bi-record-btn"></i>;
      case "Voice Record Start":
        return <i class="bi bi-record-circle"></i>;
      case "Voice Record Stop":
        return <i class="bi bi-stop-circle"></i>;
      case "Service":
        return <i className="bi bi-person-workspace"></i>
      default:
        return;
    }
  }

  /**
   * Get the name of the key
   * @param {string} key 
   * @returns {string} Returns a string based on key
   */
  const getName = (key) => {
    return MENU_NAMES_MAPPING[key];
  }

  /**
   * Execute the action for drag event
   * @param {Object} entries 
   * @param {Object} activity 
   * @param {Object} e Event of type object
   */
  const executeActionDrag = (entries, activity, e) => {
    entries[activity].action.dragstart(e);
  }

  /**
   * Execute the action for click event
   * @param {Object} entries 
   * @param {Object} activity 
   * @param {Object} e Event of type object
   */
  const executeActionClick = (entries, activity, e) => {
    e.stopPropagation();
    e.preventDefault();
    entries[activity].action.click(e);
  }

  /**
  * Check if sub process already exists
  * @param {Object} elementRegistry
  * @param {string} subProcessId id of the sub process to check
  * @returns {boolean} boolean value is sub process exists
  */
  const isSubProcessExists = (elementRegistry, subProcessId) => {
    return elementRegistry.get(subProcessId) ? true : false;
  }

  /**
   * It imports the new element other than the sub process 
   * @param {{bpmnFactory,modeling,elementToImport,modeler,elementFactory}} importElementData
   */
  const handleElementToImport = (importElementData) => {
    const { bpmnFactory, modeling, elementToImport, modeler, elementFactory, process } = importElementData;
    // get the posiiton of elementToImport
    const newElementPosition = DiagramUtil.getNewElementPosition(modeler);
    const { posX, posY } = newElementPosition;
    const elementToImportBusinessObject = bpmnFactory.create(elementToImport?.elementType);
    if (elementToImport?.attributes) {
      DiagramUtil.setBusinessObject(elementToImportBusinessObject, elementToImport?.attributes);
    }
    // Create the elementToImport shape
    const newElementShape = elementFactory.createShape({ type: elementToImport?.type, businessObject: elementToImportBusinessObject });
    // Add the event of new element to the diagram
    modeling.createShape(newElementShape, { x: posX, y: posY }, process);
    // set the name of the new element
    modeling.updateProperties(newElementShape, { name: elementToImport.name });
  }

  /**
   * Inserts the re usable elements in xml
   * reusable components meta data is saved in json format in db that can be dragged and reused
   * xml component will be created if id is present in meta data that means it can be used once as fixed id
   * otherwise new id will be genrated and component can be used multiple times
   * It creates its child elements with flow lines and everything
   * @param {{name,children,flows}} elementToImport object to be added
   */
  const insertExportedElement = async (elementToImport) => {
    // get the modeler and from that we gets elemets info 
    const modeler = await ModelerService.getModeler();
    const modeling = modeler.get('modeling'),
      bpmnFactory = modeler.get('bpmnFactory'),
      elementFactory = modeler.get('elementFactory'),
      elementRegistry = modeler.get('elementRegistry');
    const process = elementRegistry.get('Process_1');

    // if the element to import is not the sub process 
    if (elementToImport?.elementType !== EL_TYPE.SUB_PROCESS) {
      const importElementData = { bpmnFactory, modeling, elementToImport, modeler, elementFactory, process };
      // handles element other than the sub process
      handleElementToImport(importElementData);
    } else {
      const subProcess = elementToImport;
      // get the posiiton of new element
      const subProcessPosition = DiagramUtil.getNewElementPosition(modeler);
      const { posX, posY } = subProcessPosition;

      // handle sub process
      const subProcessBusinessObject = bpmnFactory.create(EL_TYPE.SUB_PROCESS);
      // if sub process id is not present in the flow then create new one 
      // subProcess id coming from metadata json save in DB , if id field is there then
      // it will assign that id in the bussinessObject of the sub process and can sub process be used once
      // else it will be dynamically generated
      if (subProcess?.id) {
        const subProcessExists = isSubProcessExists(elementRegistry, subProcess.id);
        if (!subProcessExists) {
          // if id is already there then we asign that id and can be created only once
          // else dynamically id will be allocated
          subProcessBusinessObject.id = subProcess.id;
        } else {
          // one time allow to create sub process
          snackbarRef.current.open(MESSAGES.ERR.SUBPROCESS_EXISTS);
          return;
        }
      }
      // Create the SubProcess shape
      const eventSubProcess = elementFactory.createShape({ type: EL_TYPE.SUB_PROCESS, businessObject: subProcessBusinessObject });
      // Add the event sub process to the diagram
      modeling.createShape(eventSubProcess, { x: posX, y: posY }, process);
      // set the name of the sub process
      modeling.updateProperties(eventSubProcess, { name: subProcess.name });

      // handle sequence flow lines for inner child elements
      const subProcessChildInfo = { subProcess, bpmnFactory, modeling, subProcessPosition, eventSubProcess, elementFactory };
      const subProcessChildElementsMap = await handleSubProcessChildren(subProcessChildInfo);
      // handle sequence flow lines for inner child elements
      const sequenceFlowInfo = { subProcess, bpmnFactory, modeling, subProcessChildElementsMap };
      await handleSequenceFlows(sequenceFlowInfo);

      // toggle twice to hide the inner child elements of the sub process
      // on creating it gets displayed along with the sub process but needs to be inside it.
      modeling.toggleCollapse(eventSubProcess);
      modeling.toggleCollapse(eventSubProcess);
    }
  }

  /**
   * Handle subProcess childrens
   * It will create elements inside the sub process.
   * @param {Object} subProcessChildInfo // data for handling subProcess childrens
   * @returns {Object} subProcessChildElementsMap references to subprocess child elements
   */
  const handleSubProcessChildren = async (subProcessChildInfo) => {
    const { subProcess, bpmnFactory, modeling, subProcessPosition, eventSubProcess, elementFactory } = subProcessChildInfo;
    const { posX, posY } = subProcessPosition;
    // Mapping to store references to subprocess child elements
    const subProcessChildElementsMap = {};
    // handling child elements
    subProcess?.children?.forEach((innerElements, index) => {
      // create business object of inner element i.e child element
      const businessObject = bpmnFactory.create(innerElements.task);
      // set the necessary field in the business object of the element
      if (innerElements?.attributes) {
        DiagramUtil.setBusinessObject(businessObject, innerElements?.attributes);
      }
      // if fixed id then assign that id to the child element as well like in choose language
      // choose language is child of anguage selection
      if (innerElements?.isFixedId) {
        businessObject.id = innerElements?.id;
      }
      // position of the inner child elements
      //posX is the position for subProcess and index is basically the element index
      // that is rendering inside it and we multiply it with 250 to create spacing between the two
      // elements else it will overlap
      const elementsPerRow = 4;
      const row = Math.floor(index / elementsPerRow);
      const positionInRow = index % elementsPerRow;

      // Determine X position with reverse logic for the second row
      // if we are manually creating any sub process from json, we need to set the position as well
      // so 250 is the distance on x-axis and y-axis and 4 elemets are shown in one single row
      const childPosX = posX + (row % 2 === 0 ? positionInRow : (elementsPerRow - 1 - positionInRow)) * 250;
      const childPosY = posY + row * 250;

      // create shape for that inner child element
      const subProcessChild = elementFactory.createShape({ type: innerElements.task, businessObject: businessObject, });
      // create it as the child of sub process
      const addedChild = modeling.createShape(subProcessChild, { x: childPosX, y: childPosY }, eventSubProcess);
      // set the name of the inner child elements
      modeling.updateProperties(subProcessChild, { name: AppUtil.getTaskName(innerElements.name) });
      // Store reference to the subprocess child element in the mapping
      subProcessChildElementsMap[innerElements.id] = addedChild;
    });
    return subProcessChildElementsMap;
  }

  /**
   * Handle sequence flows for re usable sub process.
   * Creates flow lines based on sourceRef and targetRef between the elements
   * @param {Object} sequenceFlowInfo // data for handling sequence flows 
   */
  const handleSequenceFlows = async (sequenceFlowInfo) => {
    const { subProcess, bpmnFactory, modeling, subProcessChildElementsMap } = sequenceFlowInfo;
    // handling sequence flows (flow lines)
    subProcess?.flows.forEach(async (flow) => {
      // Get source and target elements using the mapping
      const sourceElement = subProcessChildElementsMap[flow.sourceRef];
      // in case of gateway, there will be multiple sequence flows.
      if (Array.isArray(flow.targetRef)) {
        // handling of the gateway control
        flow.targetRef?.forEach(async (targetRef, index) => {
          const targetElement = subProcessChildElementsMap[targetRef];
          const sequenceFlowInfo = { flow, sourceElement, targetElement, index, bpmnFactory, modeling };
          await createSequenceFlow(sequenceFlowInfo);
        });
      } else {
        const targetElement = subProcessChildElementsMap[flow.targetRef];
        // get the sequence flow info and create a sequence line
        const sequenceFlowInfo = { flow, sourceElement, targetElement, index: 0, bpmnFactory, modeling };
        await createSequenceFlow(sequenceFlowInfo);
      }
    });
  }

  /**
   * Creates the sequence flow with condition expression if condition is there then we need condition expression 
   * inside businessObject of sequence flow
   * @param {{flow,sourceElement,targetElement,index,modeling,bpmnFactory}} sequenceInfo
   */
  const createSequenceFlow = async (sequenceInfo) => {
    const { flow, sourceElement, targetElement, index, modeling, bpmnFactory } = sequenceInfo;
    // Create a new sequence flow
    const newSequenceFlow = await modeling.createConnection(
      sourceElement, targetElement,
      {
        type: EL_TYPE.SEQUENCE_FLOW,
      },
      sourceElement.parent
    );
    // layout the flow lines properly
    modeling.layoutConnection(newSequenceFlow);
    // if sequence flow has fixed id then 
    if (flow?.isFixedId) {
      newSequenceFlow.businessObject.id = flow.id;
      // Set the BPMNEdge id to match the bpmnElement id with a _di suffix
      // BPMNEdge is basically the flow line  that is seen on the UI and has unique id and is mentioned by _di
      const bpmnElementId = flow.id;
      const bpmndiId = `${bpmnElementId}_di`;
      newSequenceFlow.businessObject.di.id = bpmndiId;
    }

    // Add condition expression if it exists
    if (flow?.conditionExpression?.length) {
      // add the condition expression since it is used in case of sequence flow attribute in condition case
      const conditionExpression = await bpmnFactory.create(EL_TYPE.FORMAL_EXPRESSION, {
        body: flow?.conditionExpression[index],
        language: 'javascript'
      });
      newSequenceFlow.businessObject.conditionExpression = conditionExpression;
    }
  }

  return (
    <>
      <PmivrSnackBar ref={snackbarRef} />
      <PmivrDialog showDialog={uiState.showDeleteDialog} closeDialog={() => setUiState({ ...uiState, showDeleteDialog: false })}
        title={"Are you sure to delete this sub process"}
        message={<>
          {"After deleting this re-usable sub process, it will be removed from the list and will not able to re use it"}
        </>}
        footer={<>
          <button className="pmivr-btn-cancel" onClick={() => setUiState({ ...uiState, showDeleteDialog: false })}>
            Cancel
          </button>
          <button className="pmivr-btn-app" onClick={() => deleteReUsableElements()}>
            Delete
          </button>
        </>} />
      <div className="control-menu float-start">
        <nav className="border-bottom">
          <ul className="nav pb-2" id="nav-tab" role="tablist">
            <li className="nav-item" role="presentation" style={{ "width": "37%" }}>
              <button
                className="nav-link active"
                id="control-btn"
                data-bs-toggle="tab"
                data-bs-target="#control"
                type="button"
                role="tab"
                aria-controls="control"
                aria-selected="true"
              >
                Controls
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link "
                id="sub-process-btn"
                data-bs-toggle="tab"
                data-bs-target="#process-btn"
                type="button"
                role="tab"
                aria-controls="process-btn"
                aria-selected="false"
              >
                Saved Controls
              </button>
            </li>
          </ul>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="control" role="tabpanel" aria-labelledby="control-btn">
            <div className=" p-3 pt-1 pmivr-scroll scroll">
              {keys.map((key, index) => (
                <button
                  key={index} type="button"
                  onClick={(e) => executeActionClick(entries, key, e)}
                  onDragStart={(e) => executeActionDrag(entries, key, e)}
                  className="btn control-menu-btn" draggable="true" title={entries[key].title} id={key.replace('.', '-')}
                >
                  {getIcon(key)}
                  <span className="float-start ps-4"> {getName(key)} </span>
                  <div className="float-end">
                    <i className="bi bi-three-dots-vertical"></i>
                    <i className="bi bi-three-dots-vertical"></i>
                  </div>
                </button>
              ))}
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="process-btn"
            role="tabpanel"
            aria-labelledby="sub-process-btn"
          >
            <nav className="border-bottom">
              <ul className="nav pb-2" id="nav-tab-1" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="system-btn"
                    data-bs-toggle="tab"
                    data-bs-target="#system"
                    type="button"
                    role="tab"
                    aria-controls="system"
                    aria-selected="true"
                  >
                    System
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="custom-btn"
                    data-bs-toggle="tab"
                    data-bs-target="#custom"
                    type="button"
                    role="tab"
                    aria-controls="custom"
                    aria-selected="false"
                  >
                    Custom
                  </button>
                </li>
              </ul>
            </nav>
            <div className="tab-content" id="nav-tabContent-1">
              <div className="tab-pane fade show active" id="system" role="tabpanel" aria-labelledby="system-btn">
                <div className="p-3 scroll">
                  {/* document usage cannot be removed as it is done for bpmn element that is created dynamically*/}
                  {systemReUsableElements.map((reUsableElement) => (
                    <button
                      key={reUsableElement?.id}
                      type="button"
                      onClick={() => { insertExportedElement(reUsableElement) }}
                    >
                      <span className="float-start">
                        {reUsableElement?.name}
                      </span>
                      <div className="float-end">
                        <i className="bi bi-three-dots-vertical"></i>
                        <i className="bi bi-three-dots-vertical"></i>
                      </div>
                    </button>
                  ))}
                </div>
              </div>
              <div className="tab-pane fade" id="custom" role="tabpanel" aria-labelledby="custom-btn">
                <div className="p-3 scroll">
                  {customReUsableElements?.map((reUsableElement, index) => {
                    return (
                      <div className="row">
                        <div className="col-md-9">
                          <button key={reUsableElement?.id} type="button"
                            onClick={() => { insertExportedElement(reUsableElement) }}>
                            <span className="float-start">
                              {reUsableElement?.name}
                            </span>
                          </button>
                        </div>
                        <div className="col-md-3">
                          <button key={reUsableElement?.id} onClick={() => handleDeleteDialog(reUsableElement?.id, index)}>
                            <i className="bi bi-trash"></i>
                          </button>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper "></div>
      <div id="drawflow">
        <div className="bar-zoom">
          <i className="bi bi-zoom-out"></i>
          <i className="bi bi-search"></i>
          <i className="bi bi-zoom-in"></i>
        </div>
      </div>
    </>
  );
};

ToolsPanelView.propTypes = {
  modeler: PropTypes.object,
};

export default ToolsPanelView;