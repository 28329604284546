/** 
 * Need to avoid creating different build for each environment, Therefore use this constant
 * From Terraform chart environment values will be provided, Default value is set to SIT
 */
export const ENVIRONMENT = {
  PRODUCTION: 'prod',
  DEVELOPMENT: 'dev',
  UAT: 'uat',
  SIT: 'sit',
};
