import { envConfig } from "../environment";

import appAxios from "../util/app.axios";

/**
 * service class for wizard operations
 */
class WizardService {

    static BASE_URL = `${envConfig.REACT_APP_API_BASE_URL}/systemConfig`;

    /**
     * Getting the wizard questions
     * @param {String} flowTypeId selected flow type id
     * @returns {Promise<Array<
     *  {questionId: string, elementId: string, title: string, fieldType: string, options: array, 
    *  childRenderOption: array, children: array, flowTypeId: array, default: array}
     * >>} response from the /systemConfig/wizardConfigQuestions api
     */
    static getWizard(flowTypeId) {
        const url = `${this.BASE_URL}/wizardConfigQuestions?flowTypeId=${flowTypeId}`;
        return appAxios.get(url);
    }
}

export default WizardService;