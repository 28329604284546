import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import UserService from "../../../services/user.service";
import ErrorPage from '../../../pages/error-page/error-page';
import { MESSAGES } from '../../../constants/messages';

/**
 * This will check for Admin role if admin then render component
 * else redirect to 403 page
 */
const PmivrAuthorization = ({ children }) => {
    // hasPermission to set false means it is user
    const [hasPermission, setHasPermission] = useState(false);

    useEffect(() => {
        // allow only admin to access the page else will redirect to home page
        //if admin then set permission true
        if (UserService.hasPermission()) {
            setHasPermission(true);
        }
    }, []);

    return (
        <>
            {hasPermission ? (
                <main>{children}</main>
            ) : (
                <ErrorPage code={"403"} msg={MESSAGES.PAGE_FORBIDDEN} />
            )}
        </>
    );
};

PmivrAuthorization.propTypes = {
    // content to be displayed
    children: PropTypes.oneOfType([
        PropTypes.any,
        PropTypes.arrayOf(PropTypes.element)
    ])
}

export default PmivrAuthorization;