//  constant for task type , can not make it capital because task type are in camel case
export const TASK_TYPE = {
  promptUserOption: "promptUserOption",
  keyValueUserOption: "keyValueUserOption",
  dynamicUserOption: "dynamicUserOption",
  promptUserInput: "promptUserInput",
  playVoiceFile: "playVoiceFile",
  promptUserInputConfirmation: "promptUserInputConfirmation",
  recordVoice: "recordVoice",
  transferCall: "transferCall",
  sayData: "sayData",
  service: 'service',
  loopTask: 'loopTask',
  voiceRecordStart: "voiceRecordStart",
  voiceRecordStop: "voiceRecordStop",
};

// (PLAYVOICEFILE, PROMPTUSEROPTION, KEYVALUEUSEROPTION, PROMPTUSERINPUT) :
// are in same name format as found in flow xml file, so have no spaces in it.
// are used as key to find the display name for task types.
export const TASK_TYPE_NAME = {
  PLAYVOICEFILE: "Play Voice File",
  PROMPTUSEROPTION: "Prompt User Option",
  KEYVALUEUSEROPTION: "Key Value User Option",
  PROMPTUSERINPUT: "Prompt User Input",
};

// voice file task
export const VOICE_FILE = "voiceFile";

/**
 * Service task types
 */
export const SERVICE_TYPES = {
  API_CALL: "API_CALL",
  METHOD_CALL: "METHOD_CALL",
  EXPRESSION: "EXPRESSION"
};

// Prefix for attributes in tasks
export const TASK_PREFIX = {
  'recordVoice': 'voiceRecord',
  'promptUserOption': 'dynamicOpt',
  'promptUserInput': 'usrInput',
  'sayData': 'sayData',
  'playVoiceFile': 'voice',
  'transferCall': 'transfer',
  'service': 'serviceImpl',
  'dynamicUserOption': 'dynamicOpt',
  'keyValueUserOption': 'keyValueOpt',
  'loopTask': 'loopTask'
}