import { useState, useEffect } from 'react';

import DiagramService from '../../../services/diagram.service';

/**
 * Render missing voice files warning information when publishing the flow
 * @returns {React.Component} Html code to render info for missing voice files
 */
const MissingVoiceFileWarning = (() => {
    const [missingVoiceFileInfo, setMissingVoiceFileInfo] = useState([]);

    useEffect(() => {
        const missingVoiceFileInfo = DiagramService.getMissingVoiceFiles();
        setMissingVoiceFileInfo(missingVoiceFileInfo);
    }, []);

    return (
        <>
            {missingVoiceFileInfo?.length !== 0 &&
                <>
                    <p className="pmivr-text-primary fs-6">Missing Voice Files</p>
                    <table className="table voice-file-list pmivr-table header-fixed border mt-2" id="main-table">
                        <thead>
                            <tr style={{ lineHeight: "2rem" }}>
                                <th width="10%" className="text-center">
                                    S.no
                                </th>
                                <th width="30%" className="text-center">
                                    Element Name
                                </th>
                                <th width="40%" className="text-center">
                                    Sub Process Name
                                </th>
                                <th width="20%" className="text-center">
                                    Language
                                </th>
                            </tr>
                        </thead>
                        <tbody className="pmivr-scroll">
                            {missingVoiceFileInfo?.map((voiceFileInfo, index) => {
                                return (
                                    <tr style={{ lineHeight: "2rem" }}>
                                        <td width="10%">
                                            {index + 1}
                                        </td>
                                        <td width="30%">
                                            {voiceFileInfo?.elementName}
                                        </td>
                                        <td width="40%">
                                            {voiceFileInfo?.subProcessName || "-"}
                                        </td>
                                        <td width="20%">
                                            {voiceFileInfo?.language}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </>
            }
        </>
    )
});

export default MissingVoiceFileWarning;