import { useEffect, useState, useContext, useRef } from "react";
import { Accordion, Form } from "react-bootstrap";

import { ATTRIBUTES } from "../../../constants/attributes";
import { TASK_TYPE } from "../../../constants/task-types.js";
import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { TASK_ICONS } from "../../../constants/css-classes.js";

import { DEFAULT_RETRY_COUNT, DEFAULT_USER_INPUT_INVALID_VOICE_FILE, DEFAULT_USER_OPTION_INPUT_TIMEOUT } from "../../../config/config.js";

import PmivrOverlayTrigger from "../../common/overlay-trigger/pmivr-overlay-trigger";
import { PmivrDialog } from "../../common/dialog/pmivr-dialog.js";
import PmivrLabel, { LABEL_SIZE } from "../../common/label/pmivr-label.js";
import { PmivrCheckbox } from "../../common/checkbox/pmivr-checkbox.js";
import { VoiceContext } from "../../../contexts/app-context";
import SpeechInput from "../speech-input/SpeechInput";
import PmivrTooltip from "../../common/tooltip/pmivr-tooltip.js";
import MultiVoiceFileOptionView from "../multiple-voice-file-option/MultiVoiceFileOptionView.js";
import SingleVoiceFileOption from "../single-voice-file-option/SingleVoiceFileOption.js";
import DynamicOptionPropertiesView from "../dynamic-option-task-properties/DynamicOptionPropertiesView.js";
import { useSelector } from "react-redux";
import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file.js";
import UserInputProperties from "../input-task-properties/UserInputPropertiesModal.js";

import UserInputUtil from "../../../util/user-input.util.js";
import AppUtil from "../../../util/app.util.js";
import AudioUtil from "../../../util/audio.util.js";

import ElementService from "../../../services/element.service.js";
import DiagramService from "../../../services/diagram.service.js";
import AudioService from "../../../services/audio.service.js";

//  constant for isOption attribute
const OPTION_VAR = {
  keyValueUserOption: "keyValueOpt:keyValueOptionVar",
  promptUserOption: "usrOpt:optionVar",
  dynamicUserOption: "dynamicOpt:dynamicOptionVar"
};
//  constant for retryCount attribute
const RETRY_VAR = {
  keyValueUserOption: "keyValueOpt:keyValueOptionRetryCount",
  promptUserOption: "usrOpt:optionRetryCount",
  dynamicUserOption: "dynamicOpt:dynamicOptionRetryCount"
};

const OptionUserTaskView = () => {
  const { element, rightPanelEventHandler } = useContext(VoiceContext);
  
  /**
   * ui state object to update values on builder when user interacts
   * selectedTaskType: the task type selected by user (dynamic option, single voice file option etc.)
   * isSingleVoiceFileUserOption: boolean value that shows if the option is of single voice file type
   * isMultiVoiceFileUserOption: boolean value that shows if the option is of multi voice file type
   * isUserOptionsFromCollectionArray: boolean value that shows if the option is of dynamic option type
   * selectedTaskType: the task type selected by user (dynamic option, single voice file option etc.)
   * title: title of the task type
   * info: info message of the task type for info icon
   */
  const [uiState, setUiState] = useState({
    selectedTaskType: '', isSingleVoiceFileUserOption: false, isMultiVoiceFileUserOption: false,
    isUserOptionsFromCollectionArray: false, title: '', infoMessage: '', showOptionMenu: false
  });

  // array of supported languages
  const [supportedLang, setSupportedLang] = useState([]);
  // language selected
  const [selectedLanguage, setSelectedLanguage] = useState("");
  // saves invalid voice file path
  const [invalidVoiceFilePath, setInvalidVoiceFilePath] = useState("");
  // information for invalid voice file 
  const [invalidVoiceFileInfo, setInvalidVoiceFileInfo] = useState({});
  // key of the currently active accordion item
  const [activeAccordionKey, setActiveAccordionKey] = useState(null);
  // since we have empty voiceFileInfo in the element
  // so create tmp voice file info to keep the track of selected voice file methods and paths
  const [tmpVoiceFileInfo, setTmpVoiceFileInfo] = useState({});
  const [tmpVoiceFileType, setTmpVoiceFileType] = useState({});
  // maintain the original state of voice file info to update when user press the cancel button 
  const [originalVoiceFileInfo, setOriginalVoiceFileInfo] = useState({});
  const [userInputInvalidInputVoiceFileUploadType, setUserInputInvalidInputVoiceFileUploadType] = useState("");

  // latest state from redux store
  const { voiceFilePrefixObj } = useSelector(state => state.voiceFile);

  const ttsRef = useRef();
  const uploadRef = useRef();

  // title for different user option types
  const TITLE = {
    PROMPT_USER_OPTION: "Single Voice File for All Options",
    DYNAMIC_USER_OPTION: "List Items As Options",
    KEY_VALUE_USER_OPTION: "Voice File for Every Option"
  };

  // User option types for selection
  const userOptionTypes = [
    { text: TITLE.PROMPT_USER_OPTION, value: TASK_TYPE.promptUserOption },
    { text: TITLE.KEY_VALUE_USER_OPTION, value: TASK_TYPE.keyValueUserOption },
    { text: TITLE.DYNAMIC_USER_OPTION, value: TASK_TYPE.dynamicUserOption }
  ];

  useEffect(() => {
    const init = async () => {
      // reading the value of allowInputDuringPrompt. On creation of new user option, this value will be undefined.
      const allowInputDuringPrompt = element.businessObject.get(ATTRIBUTES.ALLOW_INPUT_DURING_PROMPT);
      const skipSingleOptionConfigured = element.businessObject.get(ATTRIBUTES.SKIP_SINGLE_OPTION_CONFIGURED);
      const endCallOnInvalidInputAfterRetries = element.businessObject.get(ATTRIBUTES.END_CALL_ON_INVALID_OPTION_INPUT_AFTER_RETRIES);
      if (!AppUtil.isValueValid(allowInputDuringPrompt)) {
        // if not defined, then set it to true
        ElementService.updateElementAttr(element, ATTRIBUTES.ALLOW_INPUT_DURING_PROMPT, true);
      }
      // check the regex validity if valid or not
      if (!AppUtil.isValueValid(skipSingleOptionConfigured)) {
        // if not defined, then set it to true
        ElementService.updateElementAttr(element, ATTRIBUTES.SKIP_SINGLE_OPTION_CONFIGURED, true);
      }
      if (!AppUtil.isValueValid(endCallOnInvalidInputAfterRetries)) {
        // if not defined, then set it to true
        ElementService.updateElementAttr(element, ATTRIBUTES.END_CALL_ON_INVALID_OPTION_INPUT_AFTER_RETRIES, true);
      }
      const retryCount = element.businessObject.get(RETRY_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)]);
      if (!retryCount) {
        updateOptionRetryCount(DEFAULT_RETRY_COUNT);
      }

      const inputTimeout = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INPUT_TIMEOUT);
      if (!inputTimeout) {
        // if not defined, then set it to 3000 ms
        ElementService.updateElementAttr(element, ATTRIBUTES.USER_OPTION_INPUT_TIMEOUT, DEFAULT_USER_OPTION_INPUT_TIMEOUT);
      }

      const taskType = ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE, TASK_TYPE.promptUserOption);
      updateUiState(taskType);

      const invalidOptionVoiceFile = await AudioService.getVoiceFileInfo(
        element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE, selectedLanguage
      );
      // info of the only selected language voice file to display on UI
      const formattedInvalidOptionVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(
        invalidOptionVoiceFile.selectedLanguage, invalidOptionVoiceFile.voiceFileInformation
      );
      // update the attribute and default invalid voice file for a new user input component.
      setDefaultInvalidVoiceFile(invalidOptionVoiceFile);
      setUserInputInvalidInputVoiceFileUploadType(VOICE_FILE_UPLOAD_TYPE.LIBRARY);
      setInvalidVoiceFilePath(formattedInvalidOptionVoiceFileInfo[invalidOptionVoiceFile.selectedLanguage]?.filePath ||
        (voiceFilePrefixObj[invalidOptionVoiceFile.selectedLanguage] + DEFAULT_USER_INPUT_INVALID_VOICE_FILE));
      // save the selected language
      setSelectedLanguage(invalidOptionVoiceFile.selectedLanguage);
      // save the voice file info
      setInvalidVoiceFileInfo(formattedInvalidOptionVoiceFileInfo);
      setSupportedLang(invalidOptionVoiceFile.supportedLanguages);
      // initially se the upload mehtod 
      setUserInputInvalidInputVoiceFileUploadType(
        formattedInvalidOptionVoiceFileInfo[invalidOptionVoiceFile.selectedLanguage].voiceFileType
      );
      // set the temp information of voice file for initial render of filePaths
      setTempVoiceFileInfo(invalidOptionVoiceFile);

      // get the original voice file info list and set it
      getOriginalVoiceFileInfo();

    }
    init();
  }, [element]);

  /**
   * Get the original voice file info
   */
  const getOriginalVoiceFileInfo = async () => {
    const invalidVoiceFile = await AudioService.getVoiceFileInfo(element,
      ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE, selectedLanguage);
    // info of the only selected language voice file to display on UI
    const formattedVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(invalidVoiceFile.selectedLanguage,
      invalidVoiceFile.voiceFileInformation);
    setOriginalVoiceFileInfo(formattedVoiceFileInfo);
  }

  /**
   * Sets the default invalid voice file for a new user input component and updates attribute in flow xml
   * @param {Object} invalidVoiceFile - details of the invalid voice file for component 
   */
  const setDefaultInvalidVoiceFile = async (invalidVoiceFile) => {
    // supported languages for the flow
    const supportedLanguages = await DiagramService.getSupportedLanguages();
    let defaultInvalidVoiceFileInfo = {};
    if (supportedLanguages?.length) {
      // append the filePath for each supported language into the default voice file info, for a new component
      supportedLanguages.forEach((language) => {
        const invalidVoiceFileFormattedInfo = AudioUtil.getFormattedVoiceFileInfo(language, invalidVoiceFile.voiceFileInformation);
        // if the component is new, it will not have filePath for default voice file
        if (!invalidVoiceFileFormattedInfo[language]?.filePath) {
          invalidVoiceFileFormattedInfo[language].filePath =
            (voiceFilePrefixObj[language] + DEFAULT_USER_INPUT_INVALID_VOICE_FILE);
        }
        defaultInvalidVoiceFileInfo[language] = invalidVoiceFileFormattedInfo[language];
      });
      // update the attribute in xml
      ElementService.updateElementAttr(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE,
        JSON.stringify(defaultInvalidVoiceFileInfo));
    }
  }

  // update voice file state whenever user selects different langauge option to display the information of that language file.
  const updateVoiceFileStateInfo = async (language) => {
    const languageSelected = language ? language : "en";
    const userInputInvalidVoiceFileUploadType = invalidVoiceFileInfo[language]?.voiceFileType || VOICE_FILE_UPLOAD_TYPE.LIBRARY;
    const invalidVoiceFilePathNew = tmpVoiceFileInfo[language]?.filePath || invalidVoiceFileInfo[language]?.filePath;
    const { voiceFileInformation } = await AudioService.getVoiceFileInfo(
      element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE, languageSelected
    );

    // set the invalidVoiceFileInfo of the latest language selected
    setInvalidVoiceFileInfo(voiceFileInformation);
    // get the temp voice fle info and set the values on language change in tab values
    const formatedInvalidVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(languageSelected, voiceFileInformation);
    formatedInvalidVoiceFileInfo[languageSelected].voiceFileType = userInputInvalidInputVoiceFileUploadType;
    formatedInvalidVoiceFileInfo[languageSelected].filePath = invalidVoiceFilePath;

    // set the selected language
    setSelectedLanguage(languageSelected);
    // set the upload method of voice file tts, library or upload
    setUserInputInvalidInputVoiceFileUploadType(userInputInvalidVoiceFileUploadType);
    setInvalidVoiceFilePath(invalidVoiceFilePathNew || voiceFilePrefixObj[language] + DEFAULT_USER_INPUT_INVALID_VOICE_FILE);
    setInvalidVoiceFileInfo(formatedInvalidVoiceFileInfo);
  }

  // reset the state of this component and it's child components
  const resetStates = () => {
    // get the voice file info attribute from element
    let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE);
    if (invalidVoiceFileInfo) {
      invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);
      // get the temporary data for the attribute
      const voiceFileInfoTemplate = AudioUtil.getFormattedVoiceFileInfo(selectedLanguage, invalidVoiceFileInfo);
      // set the fileds necessary for the attribute
      setInvalidVoiceFileInfo(voiceFileInfoTemplate);
      setUserInputInvalidInputVoiceFileUploadType(voiceFileInfoTemplate[selectedLanguage].voiceFileType);
    }
    // need to clear the values from state for resetting in case of tts and upload file after updation in xml file
    if (userInputInvalidInputVoiceFileUploadType !== VOICE_FILE_UPLOAD_TYPE.LIBRARY) {
      setInvalidVoiceFilePath(voiceFilePrefixObj[selectedLanguage] + DEFAULT_USER_INPUT_INVALID_VOICE_FILE);
    }
    ttsRef.current.resetTtsOption(); // reset the voice file info states of TTS component so that changes reflect on UI
    uploadRef.current.resetUploadOption();  // reset the voice file info states of UPLOAD component so that changes reflect on UI
  }

  /**
  * Update the voiceFileUploadMethod state
  * @param {string} val Value of voice file type
  */
  const handleVoiceFileUploadMethod = (val) => {
    // get the voice file info attribute from element
    let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE);
    if (invalidVoiceFileInfo) {
      invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);
      // get the temporary data for the attribute
      const voiceFileInfoTemplate = AudioUtil.getFormattedVoiceFileInfo(selectedLanguage, invalidVoiceFileInfo);
      // set the fileds necessary for the attribute
      setInvalidVoiceFilePath(voiceFileInfoTemplate[selectedLanguage]?.filePath);
    }
    setUserInputInvalidInputVoiceFileUploadType(UserInputUtil.getConfiguredVoiceFileUploadMethod(val));
  }

  /**
   * Set the temp voice file info
   * @param {{supportedLanguages, selectedLanguage, voiceFileInformation}} invalidVoiceFile
   */
  const setTempVoiceFileInfo = (invalidVoiceFile) => {
    const tempVoiceInfo = {};
    const tempVoiceFileType = {};
    // initially set the temp file info for the respective languages and set temp voice file info
    invalidVoiceFile?.supportedLanguages.forEach((lang) => {
      const tmpVoiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(lang, null);
      tempVoiceInfo[lang] = tmpVoiceFileInfo;
      tempVoiceFileType[lang] = { "fileType": VOICE_FILE_UPLOAD_TYPE.LIBRARY };
    });
    setTmpVoiceFileInfo(tempVoiceInfo);
    setTmpVoiceFileType(tempVoiceFileType);
  }

  /**
  * Update the invaildVoiceFilePath state
  * @param {string} value Value of invalidVoice file path
  */
  const updateVoiceFilePathState = (value) => {
    setInvalidVoiceFilePath(value);
    tmpVoiceFileInfo[selectedLanguage].filePath = value;
    tmpVoiceFileInfo[selectedLanguage].voiceFileType = VOICE_FILE_UPLOAD_TYPE.LIBRARY;
    setTmpVoiceFileInfo(tmpVoiceFileInfo);
  }

  /**
   * Update the state with the given task type info
   * @param {string} taskType selected task type
   */
  const updateUiState = (taskType) => {
    const detail = getTitleWithInfoMessage(taskType);
    setUiState({
      ...uiState, selectedTaskType: taskType,
      isSingleVoiceFileUserOption: taskType === TASK_TYPE.promptUserOption,
      isMultiVoiceFileUserOption: taskType === TASK_TYPE.keyValueUserOption,
      isUserOptionsFromCollectionArray: taskType === TASK_TYPE.dynamicUserOption,
      title: detail.title, infoMessage: detail.infoMessage, showOptionMenu: false
    });

  }

  /**
   * Getting the title with info message for the given task type
   * @param {string} taskType selected task type
   * @returns {{ title, infoMessage }} title along with it's the info message
   */
  const getTitleWithInfoMessage = (taskType) => {
    let title = '';
    let infoMessage = '';
    switch (taskType) {
      case TASK_TYPE.promptUserOption:
        title = TITLE.PROMPT_USER_OPTION;
        infoMessage = TOOLTIP.INFO.SINGLE_VOICE_FILE_USER_OPTION;
        break;
      case TASK_TYPE.dynamicUserOption:
        title = TITLE.DYNAMIC_USER_OPTION;
        infoMessage = TOOLTIP.INFO.COLLECTION_ARRAY_AS_USER_OPTIONS;
        break;
      case TASK_TYPE.keyValueUserOption:
        title = TITLE.KEY_VALUE_USER_OPTION;
        infoMessage = TOOLTIP.INFO.MULTIPLE_VOICE_FILE_USER_OPTION;
        break;
      default:
        break;
    }
    return { title, infoMessage };
  }

  // setting the properties as per change in selection of task type
  const setOptionEnableByTaskType = () => {
    switch (element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)) {
      case TASK_TYPE.promptUserOption:
        updateElementAttr(ATTRIBUTES.PROMT_USER_OPTION_IS_OPTION, true);
        updateElementAttr(ATTRIBUTES.DYNAMIC_IS_DYNAMIC_OPTION, false);
        updateElementAttr(ATTRIBUTES.KEY_VALUE_USER_OPTION, false);
        break;
      case TASK_TYPE.dynamicUserOption:
        updateElementAttr(ATTRIBUTES.PROMT_USER_OPTION_IS_OPTION, false);
        updateElementAttr(ATTRIBUTES.DYNAMIC_IS_DYNAMIC_OPTION, true);
        updateElementAttr(ATTRIBUTES.KEY_VALUE_USER_OPTION, false);
        break;
      case TASK_TYPE.keyValueUserOption:
        updateElementAttr(ATTRIBUTES.PROMT_USER_OPTION_IS_OPTION, false);
        updateElementAttr(ATTRIBUTES.DYNAMIC_IS_DYNAMIC_OPTION, false);
        updateElementAttr(ATTRIBUTES.KEY_VALUE_USER_OPTION, true);
        break;
      default:
        // do nothing
        break;
    }
  }

  // update element's attbributes
  const updateElementAttr = (key, value) => {
    ElementService.updateElement(element, key, value);
  }

  // handles the action of closing the pop up for configuring invalid voice file popup
  const handleCloseOptionMenu = () => {
    ElementService.updateElement(element, ATTRIBUTES.VOICE_TEXT, "");
    let invalidVoiceFileUploadType = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE);
    setUserInputInvalidInputVoiceFileUploadType(invalidVoiceFileUploadType || VOICE_FILE_UPLOAD_TYPE.LIBRARY);
    setInvalidVoiceFileInfo(originalVoiceFileInfo);
    ElementService.updateElementAttr(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE, JSON.stringify(originalVoiceFileInfo));
    setUiState({ ...uiState, showOptionMenu: false });
  }

  // handles the action for saving the invalid voice file
  const handleSaveOptionMenu = () => {
    // create a copy of tmpVoiceFileType since we append it for updating the state
    const originalTmpVoiceFileType = { ...tmpVoiceFileType };
    if (userInputInvalidInputVoiceFileUploadType === VOICE_FILE_UPLOAD_TYPE.LIBRARY) {
      handleSaveLibraryFile();
    } else {
      // if not library method then get the info
      let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE);
      invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);

      delete tmpVoiceFileType[selectedLanguage];
      //get the info of all languages except the current selected language
      const remainingLangFileTypes = Object.keys(tmpVoiceFileType);
      remainingLangFileTypes?.length && remainingLangFileTypes.forEach((lang) => {
        const voiceFileInfoTemplate = {};
        let obj = AudioUtil.getFormattedVoiceFileInfo(lang, null);
        voiceFileInfoTemplate[lang] = obj;
        // set the fields for all the languages for voice file info
        if (tmpVoiceFileType[lang]?.fileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY) {
          voiceFileInfoTemplate[lang]["filePath"] = tmpVoiceFileInfo[lang]?.filePath || invalidVoiceFileInfo[lang]?.filePath;
          voiceFileInfoTemplate[lang]["voiceFileType"] = VOICE_FILE_UPLOAD_TYPE.LIBRARY;
          voiceFileInfoTemplate[lang]["ttsText"] = "";
          invalidVoiceFileInfo[lang] = voiceFileInfoTemplate[lang];
        }
      });
      //update the attribute
      ElementService.updateElementAttr(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE, JSON.stringify(invalidVoiceFileInfo));
    }
    setTmpVoiceFileType(originalTmpVoiceFileType);
    setUiState({ ...uiState, showOptionMenu: false });
    // set the original file info now as the saved info
    let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE);
    invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);
    setOriginalVoiceFileInfo(invalidVoiceFileInfo);
  }

  /**
   * Saves the voice file info if voice file method is libraray
   */
  const handleSaveLibraryFile = () => {
    // get the voice file info attribute from element
    let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE);
    if (invalidVoiceFileInfo) {
      invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);
      // get the temporary data for the attribute
      const voiceFileInfoTemplate = AudioUtil.getFormattedVoiceFileInfo(selectedLanguage, invalidVoiceFileInfo);
      // set the fileds necessary for the attribute
      voiceFileInfoTemplate[selectedLanguage].filePath = invalidVoiceFilePath;
      voiceFileInfoTemplate[selectedLanguage].voiceFileType = VOICE_FILE_UPLOAD_TYPE.LIBRARY;
      voiceFileInfoTemplate[selectedLanguage].ttsText = "";

      delete tmpVoiceFileType[selectedLanguage];
      const remainingLangFileTypes = Object.keys(tmpVoiceFileType);
      // get the remaining languages info and check for library file and set its path and then save the attribute 
      remainingLangFileTypes?.length && remainingLangFileTypes.forEach((lang) => {
        if (tmpVoiceFileType[lang].fileType === VOICE_FILE_UPLOAD_TYPE.LIBRARY) {
          voiceFileInfoTemplate[lang].filePath = tmpVoiceFileInfo[lang].filePath || invalidVoiceFileInfo[lang].filePath;
          voiceFileInfoTemplate[lang].voiceFileType = VOICE_FILE_UPLOAD_TYPE.LIBRARY;
          voiceFileInfoTemplate[lang].ttsText = "";
        }
      });
      // updating invalid voice file state
      setInvalidVoiceFileInfo(voiceFileInfoTemplate);
      // finally update the attribute on save
      ElementService.updateElementAttr(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE, JSON.stringify(voiceFileInfoTemplate));
    } else {
      ElementService.updateElementAttr(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE, JSON.stringify(tmpVoiceFileInfo));
    }
  }

  /**
   * Show the save voice file options and setting in right panel
   * @param {string} lang language configured
   * @returns {string} file path of the invalid voice file info 
   */
  const getInvaildVoiceFileInfo = (lang) => {
    let invalidVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INVALID_OPTION_VOICE_FILE);
    if (invalidVoiceFileInfo != null) {
      invalidVoiceFileInfo = JSON.parse(invalidVoiceFileInfo);
      if (Object.keys(invalidVoiceFileInfo).length) {
        return invalidVoiceFileInfo[lang]?.filePath;
      }
    }
    return "Enter Invalid Voice File";
  }

  // update retry count of the task
  const updateOptionRetryCount = (optionRetryCount) => {
    const attr = RETRY_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)];
    updateElementAttr(attr, optionRetryCount?.toString());
  }

  /**
   * Handles the event of changing the selection of user option type.
   * Updates the uiState as well as flow attributes for the current element
   * @param {object} event current event
   */
  const handleUserOptionTypeChange = (event) => {
    // getting the task type, that was before change
    const oldOptionTaskType = uiState.selectedTaskType;
    let preOptionVar;
    let preRetryCount;
    // reading the values of retry count and option variable, before change
    switch (oldOptionTaskType) {
      case TASK_TYPE.promptUserOption:
        preOptionVar = ElementService.getAttribute(element, OPTION_VAR.promptUserOption);
        preRetryCount = ElementService.getAttribute(element, RETRY_VAR.promptUserOption);
        break;
      case TASK_TYPE.dynamicUserOption:
        preOptionVar = ElementService.getAttribute(element, OPTION_VAR.dynamicUserOption);
        preRetryCount = ElementService.getAttribute(element, RETRY_VAR.dynamicUserOption);
        break;
      case TASK_TYPE.keyValueUserOption:
        preOptionVar = ElementService.getAttribute(element, OPTION_VAR.keyValueUserOption);
        preRetryCount = ElementService.getAttribute(element, RETRY_VAR.keyValueUserOption);
        break;
      default:
        break;
    }

    // nullify the retry count for old task type (that was before change) in flow xml
    updateElementAttr(RETRY_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)], MESSAGES.EMPTY_STRING);
    // nullify the option variable for old task type (that was before change) in flow xml
    updateElementAttr(OPTION_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)], MESSAGES.EMPTY_STRING);

    // getting the new changed task type
    const selectedTaskType = event.target.value;
    // updates the flow attributes for the current element, as per new selection of task type
    switch (selectedTaskType) {
      case TASK_TYPE.promptUserOption:
        ElementService.updateElementAttr(element, OPTION_VAR.promptUserOption, preOptionVar);
        ElementService.updateElementAttr(element, RETRY_VAR.promptUserOption, preRetryCount);
        updateElementAttr(ATTRIBUTES.VOICE_FILE_TASK_TYPE, TASK_TYPE.promptUserOption);
        // clearing the mapping of option and it's value, on change in drop down
        updateElementAttr(ATTRIBUTES.PROMT_USER_OPTION_VALUE_MAP, null);
        break;
      case TASK_TYPE.dynamicUserOption:
        ElementService.updateElementAttr(element, OPTION_VAR.dynamicUserOption, preOptionVar);
        ElementService.updateElementAttr(element, RETRY_VAR.dynamicUserOption, preRetryCount);
        updateElementAttr(ATTRIBUTES.VOICE_FILE_TASK_TYPE, TASK_TYPE.dynamicUserOption);
        break;
      case TASK_TYPE.keyValueUserOption:
        ElementService.updateElementAttr(element, OPTION_VAR.keyValueUserOption, preOptionVar);
        ElementService.updateElementAttr(element, RETRY_VAR.keyValueUserOption, preRetryCount);
        updateElementAttr(ATTRIBUTES.VOICE_FILE_TASK_TYPE, TASK_TYPE.keyValueUserOption);
        // clearing the mapping of option and it's value, on change in drop down
        updateElementAttr(ATTRIBUTES.PROMT_USER_OPTION_VALUE_MAP, null);
        break;
      default:
        break;
    }
    // populating flags (usrOpt:isOptionInput, dynamicOpt:isDynamicOption, keyValueOpt:isKeyValueOption, etc)
    // in flow xml as per new selection
    setOptionEnableByTaskType();
    // updating the ui state as change in task type
    updateUiState(selectedTaskType);
  }

  return (
    <>
      <VoiceContext.Provider
        value={{
          supportedLanguages: supportedLang, selectedLanguage: selectedLanguage, uploadRef: uploadRef,
          updateVoiceFileStateInfo: updateVoiceFileStateInfo, userInputInvalidInputVoiceFileUploadType: userInputInvalidInputVoiceFileUploadType,
          handleVoiceFileUploadMethod: handleVoiceFileUploadMethod,
          invalidVoiceFilePath: invalidVoiceFilePath, invalidVoiceFileInfo: invalidVoiceFileInfo,
          updateVoiceFilePathState: updateVoiceFilePathState, ttsRef: ttsRef, element: element,
          rightPanelEventHandler: rightPanelEventHandler, resetStates: resetStates
        }}>
      <div className="m-2 mt-3 pmivr-option-user">
        <Form.Group controlId="formBasicSelect">
          <PmivrLabel label="Select User Option Type" tooltip={TOOLTIP.INFO.SELECT_USER_OPTION_INPUT_TYPE} />
          <select className="pmivr-sub-title small mt-2 pmivr-select" as="select" value={uiState.selectedTaskType}
            onChange={(event) => handleUserOptionTypeChange(event)}>
            {
              userOptionTypes.map((userOptionType) => {
                return (
                  <option className="pmivr-sub-title small" key={userOptionType.value} value={userOptionType.value}>
                    {userOptionType.text}
                  </option>
                );
              })
            }
          </select>
        </Form.Group>
        {(uiState.title) && (
          <div className="mt-3">
            <PmivrLabel label={uiState.title} tooltip={uiState.infoMessage} size={LABEL_SIZE.MEDIUM} />
            <hr className="mt-1" />
          </div>
        )}

        <div className="form-check pmivr-check-radio form-check-inline mb-3 mt-1">
          <input className="form-check-input" type="checkbox" id="is-instant-response"
            checked={element.businessObject.get(ATTRIBUTES.ALLOW_INPUT_DURING_PROMPT) || false}
            onChange={(e) => {
              ElementService.updateElement(element, ATTRIBUTES.ALLOW_INPUT_DURING_PROMPT, e.target.checked);
            }}
          />
          <label className="form-check-label">Allow Input During Prompt</label>
        </div>
        <PmivrTooltip message={TOOLTIP.INFO.IS_INSTANT_RESPONSE_USER_OPTION}>
          <i className={`${TASK_ICONS.DISPLAY_INFO} pmivr-text-primary float-end mt-2`}></i>
        </PmivrTooltip>

        <div className="form-check pmivr-check-radio form-check-inline mb-3 mt-1">
          <input className="form-check-input" type="checkbox" id="is-skip-if-single-option"
            checked={element.businessObject.get(ATTRIBUTES.SKIP_SINGLE_OPTION_CONFIGURED) || false}
            onChange={(e) => {
              ElementService.updateElement(element, ATTRIBUTES.SKIP_SINGLE_OPTION_CONFIGURED, e.target.checked);
            }}
          />
          <label className="form-check-label">Skip Options If Single Option Configured</label>
        </div>
        <PmivrTooltip message={TOOLTIP.INFO.SKIP_IF_SINGLE_USER_OPTION}>
          <i className={`${TASK_ICONS.DISPLAY_INFO} pmivr-text-primary float-end mt-2`}></i>
        </PmivrTooltip>

        <PmivrCheckbox label={"End Call If Invalid Option Input After Retries"}
          value={element.businessObject.get(ATTRIBUTES.END_CALL_ON_INVALID_OPTION_INPUT_AFTER_RETRIES) || false}
          info={TOOLTIP.INFO.END_CALL_IF_INVALID_INPUT_AFTER_RETRY}
          onChange={(value) => {
            ElementService.updateElement(element, ATTRIBUTES.END_CALL_ON_INVALID_OPTION_INPUT_AFTER_RETRIES, value);
          }} />

        <div className="m-1">
          {uiState.isSingleVoiceFileUserOption && (
            <SingleVoiceFileOption />
          )}
          {uiState.isMultiVoiceFileUserOption && (
            <MultiVoiceFileOptionView />
          )}
          {uiState.isUserOptionsFromCollectionArray && (
            <DynamicOptionPropertiesView />
          )}

            <Accordion activeKey={activeAccordionKey} className="mt-3 mb-3 pmivr-accordion"
              onSelect={(selectedKey) => setActiveAccordionKey(selectedKey)} flush>
              <Accordion.Item eventKey={"validation-user-input"} className="mt-3 accordion-voice-item">
                <Accordion.Header>
                  <span className="pmivr-accordian-tab">
                    Invalid Input Voice File
                  </span>
                </Accordion.Header>
                <Accordion.Body className="p-3 pt-2">
                  {supportedLang.map((lang) => {
                    return (
                      <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.INVALID_VOICE_FILE}>
                        <div className="form-group custom-input invalid-voice-file-info">
                          <input type="text" className="pmivr-input lang-input" value={lang}
                            disabled={true} size="10" placeholder={"Text"} />
                          <input type="text" className="pmivr-input path-input" disabled={true} size="40" placeholder={"Text"}
                            value={getInvaildVoiceFileInfo(lang)} />
                        </div>
                      </PmivrOverlayTrigger>
                    )
                  })}
                  <div className="text-center mt-2">
                    <button className=" mt-2 mb-3 p-2 text-center pmivr-btn-app"
                      onClick={(event) => { setUiState({ ...uiState, showOptionMenu: true }); }}>
                      <i className="bi bi-pencil"> </i> Invalid Option Voice File
                    </button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <PmivrDialog showDialog={uiState.showOptionMenu} closeDialog={handleCloseOptionMenu}
              title={`Invalid Option Voice File`} message={<UserInputProperties />} saveDialogChanges={handleSaveOptionMenu} />

          <SpeechInput />
          <div className="pmivr-clearfix pt-2">
              <div className="form-group mb-3">
                <PmivrLabel label="Input Timeout" tooltip={TOOLTIP.INFO.USER_OPTION_INPUT_TIMEOUT} />
                <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.USER_INPUT_TIMEOUT}>
                  <input
                    id="optionInputTimeout" name="optionInputTimeout" type="number" className="form-control pmivr-input"
                    placeholder="Enter Timeout"
                    value={ElementService.getAttribute(element, ATTRIBUTES.USER_OPTION_INPUT_TIMEOUT) || ""}
                    onChange={(event) => {
                      ElementService.updateElement(element, ATTRIBUTES.USER_OPTION_INPUT_TIMEOUT, event.target.value);
                    }}
                  />
                </PmivrOverlayTrigger>
              </div>
              <div className="form-group mb-3">
              <PmivrLabel label="Response Variable" tooltip={TOOLTIP.INFO.OPTION_VARIABLE} />
              <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.OPTION_VARIABLE}>
                <input id="optionVar" name="optionVar" className="form-control pmivr-input" placeholder="Enter Variable"
                  value={element.businessObject.get(OPTION_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)])}
                  onChange={(event) => {
                    updateElementAttr(OPTION_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)],
                      event.target.value);
                  }} />
              </PmivrOverlayTrigger>
            </div>
            <div className="form-group mb-3">
              <PmivrLabel label="Retry Count" tooltip={TOOLTIP.INFO.RETRY_COUNT} />
              <PmivrOverlayTrigger tooltip={TOOLTIP.INPUT.RETRY_COUNT}>
                <input id="optionRetryCount" name="optionRetryCount" className="form-control pmivr-input"
                  value={element.businessObject.get(RETRY_VAR[element.businessObject.get(ATTRIBUTES.VOICE_FILE_TASK_TYPE)])}
                  defaultValue={DEFAULT_RETRY_COUNT} onChange={(event) => { updateOptionRetryCount(event.target.value); }} />
              </PmivrOverlayTrigger>
            </div>
          </div>
        </div>
      </div>
      </VoiceContext.Provider>
    </>
  );
}

export default OptionUserTaskView;