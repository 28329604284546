import { useEffect, useRef, useState, useContext } from "react";
import { Accordion } from "react-bootstrap";

import { ATTRIBUTES } from "../../../constants/attributes";
import { VOICE_FILE_UPLOAD_TYPE } from "../../../constants/voice-file";

import { PmivrDialog } from "../../../components/common/dialog/pmivr-dialog";
import { TOOLTIP } from "../../../constants/messages";
import { SingleVoiceContext, VoiceContext } from "../../../contexts/app-context";
import PmivrTooltip from "../../common/tooltip/pmivr-tooltip";

import MultiVoiceFileOptionModal from "./MultiVoiceFileOptionModal";
import MultiVoiceFileOptionModalFooter from "./MultiVoiceFileOptionModalFooter";
import OptionValueMapping from "../../common/option-value-mapping/option-value-mapping";

import AppUtil from "../../../util/app.util";
import VoiceFileOptionUtil from "../../../util/voice-file-option.util";

import ElementService from "../../../services/element.service";
import DiagramService from "../../../services/diagram.service";
import VoiceFileOptionService from "../../../services/voice-file-option.service";

/**
 * Multi voice file option view in diagram
 * @param {Object} props Props data from parent component
 */
const MultiVoiceFileOptionView = () => {
  // option menu to render 
  const [showOptionMenu, setShowOptionMenu] = useState(false);
  // array of supported languages
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  // language selected
  const [selectedLanguage, setSelectedLanguage] = useState("");
  // info for voice file
  const [voiceFileInfo, setVoiceFileInfo] = useState({});
  // key of the currently active accordion item
  const [activeAccordionKey, setActiveAccordionKey] = useState(null);
  // isAddOption is true then set temporary and send it to tts and upload voiceFile component
  const [isAddOption, setIsAddOption] = useState(false);
  // set temporary voice file infromation state for tts and upload case. 
  // set temporary state in case of Add new option as need that voice file info and index in tts and upload component
  const [tempVoiceInfo, setTempVoiceInfo] = useState({});
  // example as if selected option is "add option" and voiceFileUploadType is tts or upload 
  // then set temporary voice file state so that we can perform tts and upload voice file operation
  const [tempSelectedOption, setTempSelectedOption] = useState({ id: null, index: null });
  // actual option that is selected 
  const [selectedOption, setSelectedOption] = useState({ option: "", index: null, id: null });
  // mapping of option and it's value to be considered 
  const [optionAndVal, setOptionAndVal] = useState({});
  // option menu to render 
  const [showMappingOptionMenu, setShowMappingOptionMenu] = useState(false);
  const [uiState, setUiState] = useState({
    showLoader: false, promptsList: []
  });
  const ttsRef = useRef();
  const uploadRef = useRef();

  const {
    rightPanelEventHandler,
    keyValVoiceFileEventsHandler,
    element
  } = useContext(VoiceContext);

  useEffect(() => {
    const init = async () => {
      const supportedLanguages = await DiagramService.getSupportedLanguages();
      let voiceFileInformation = getMultiVoiceFileInfo(supportedLanguages);
      const tempOptionAndVal = VoiceFileOptionService.getFormattedOptionAndVal(element); // get formatted option val
      setShowOptionMenu(false);
      setSelectedLanguage(supportedLanguages[0] || "");
      setVoiceFileInfo(voiceFileInformation);
      setIsAddOption(false);
      setTempVoiceInfo(AppUtil.deepClone(voiceFileInformation));
      setTempSelectedOption({ id: null, index: null });
      setSelectedOption({ option: "", index: null, id: null });
      setSupportedLanguages(supportedLanguages);
      setOptionAndVal(tempOptionAndVal);
      setShowMappingOptionMenu(false);
    }
    init();
  }, [element]);

  // on selected language change we need to update the prompt list to avoid showing other language list
  // onto other language
  useEffect(() => {
    setUiState({ ...uiState, promptsList: [] });
  }, [selectedLanguage]);

  // return the list of all the avaiable options
  const getAvailableOptions = () => {
    return VoiceFileOptionUtil.getAvailableOptions(voiceFileInfo[selectedLanguage]);
  }

  /**
   * Return the voice file information, add empty array if supported language is not present in voice file info.
   * @param {Array} supportedLanguages List of supported languages
   * @returns {Object} Returns voice file information
   */
  const getMultiVoiceFileInfo = (supportedLanguages) => {
    return VoiceFileOptionService.getMultiVoiceFileInfo(supportedLanguages, element);
  }

  // update SelectedOption state
  const updateSelectedOption = (value) => {
    const selectedOptionUpdate = selectedOption;
    selectedOptionUpdate.option = value;
    setSelectedOption(selectedOptionUpdate);
  }

  // set varibale's value before option the pop up
  const handleShowOptionMenu = () => {
    setShowOptionMenu(true);
    setSelectedOption({ option: "", index: null, id: null });
    setIsAddOption(true);
    const updatedRows = [{
      filePath: "", ttsText: "", gender: "", playSpeed: "", voiceFileType: "", fileUrl: "", fileSize: "", variable: "",
      variableType: "digits", promptId: "", isUploadedOnGit: false
    }];
    setUiState({
      ...uiState, promptsList: updatedRows
    });
  }

  // update values for the option
  const updateOptionAndValues = (event, val) => {
    const response = VoiceFileOptionService.updateOptionAndValues(event, val, optionAndVal, false);
    //  set new state value and update the model properties
    setOptionAndVal(response.optionAndVal);
  }

  // set variable's value when user close the pop up
  const handleCloseOptionMenu = () => {
    const voiceFileInformation = getMultiVoiceFileInfo(supportedLanguages);
    setShowOptionMenu(false);
    setSelectedOption({ option: "", index: null, id: null });
    setIsAddOption(false);
    setVoiceFileInfo(voiceFileInformation);
    setUiState({ ...uiState, promptsList: [] });
    setTempSelectedOption({ id: null, index: null });
    setTempVoiceInfo(AppUtil.deepClone(voiceFileInformation));
  }

  /**
   * Update Voice file information
   * @param {string} id Voice fileInfo id
   */
  const updateVoiceFileInfo = (voiceFileInfoId) => {
    const stateObj = getValuesInState();
    const updatedpromptsList = getUpdatedPromptsList();
    stateObj["promptsList"] = updatedpromptsList;
    VoiceFileOptionService.updateMultiVoiceFileInfo(voiceFileInfoId, stateObj, element);
  }

  /**
   * Get the updated prompts list before saving it
   * It will remove empty item in the list
   * @returns {Array} updatedpromptsList
   */
  const getUpdatedPromptsList = () => {
    // if type is variable to play then filePath and other field must be set to ""
    const updatedpromptsList = uiState.promptsList.map(item => {
      switch (item.voiceFileType) {
        // in case of variable if we are saving it then all other field should be empty
        // example if earlier we did tts and have text ttsText = "hello" then on variable updating we need to empty the fields
        case VOICE_FILE_UPLOAD_TYPE.VARIABLE:
          for (let key in item) {
            if (key !== "variable" && key !== "variableType" && key !== "voiceFileType") {
              item[key] = "";
            }
          }
          break;
        // in case of library , empty the below mentioned fields
        case VOICE_FILE_UPLOAD_TYPE.LIBRARY:
          for (let key in item) {
            if (["ttsText", "gender", "playSpeed", "promptId", "isUploadedOnGit", "fileSize"].includes(key)) {
              item[key] = "";
            }
          }
          break;
        // in case of upload , no need for ttsText , gender , playSpeed
        case VOICE_FILE_UPLOAD_TYPE.UPLOAD:
          for (let key in item) {
            if (["ttsText", "gender", "playSpeed"].includes(key)) {
              item[key] = "";
            }
          }
          break;
        case VOICE_FILE_UPLOAD_TYPE.TTS:
          // in case of tts , fileSize and fileUrl not reqd
          for (let key in item) {
            if (["fileSize", "fileUrl"].includes(key)) {
              item[key] = "";
            }
          }
          break;
        default:
          break;
      }
      return item;
    }).filter(item => {
      // Check if voiceFileType exists and is not empty
      return ![undefined, null, ""].includes(item.voiceFileType);
    });
    return updatedpromptsList;
  }

  // getting values saved in state
  const getValuesInState = () => {
    return {
      "voiceFileInfo": voiceFileInfo,
      "selectedLanguage": selectedLanguage,
      "promptsList": uiState.promptsList,
      "selectedOption": selectedOption,
      "tempVoiceInfo": tempVoiceInfo,
      "showOptionMenu": showOptionMenu,
      "supportedLanguages": supportedLanguages,
      "isAddOption": isAddOption,
      "tempSelectedOption": tempSelectedOption,
    }
  }

  // handle saving the option value mapping
  const handleSaveOptionValueMapping = () => {
    let keyAndVal = {};
    let optionAndValJson = AppUtil.deepClone(optionAndVal);
    for (let i of Object.keys(optionAndValJson)) {
      if (optionAndValJson[i][0] && optionAndValJson[i][1]) {
        keyAndVal[optionAndValJson[i][0]] = optionAndValJson[i][1];
      }
    }
    ElementService.updateElement(element, ATTRIBUTES.PROMT_USER_OPTION_VALUE_MAP, JSON.stringify(keyAndVal));
    setShowMappingOptionMenu(false);
  }

  /**
   * Save variables value and reset them when user save the pop up
   * @param {string} id Id of the voice file info
   */
  const handleSaveOptionMenu = async (id) => {
    try {
      setUiState({ ...uiState, showLoader: true });
      updateVoiceFileInfo(id);
      handleCloseOptionMenu();
      setUiState({ ...uiState, showLoader: false });
    } catch (err) {
      setUiState({ ...uiState, showLoader: false });
    }
  }

  /**
   * Open fiiled pop up (when user cliecked on edit button)
   * @param {*} id Id of the selected option
   */
  const handleShowFilledOptionMenu = (id) => {
    const uid = id.split("file_option_open_")[1];
    let indx;
    const fileInfo = voiceFileInfo[selectedLanguage].find((info, index) => {
      indx = index;
      return info.id === uid;
    });
    const selectedOption = { option: fileInfo.option, id: fileInfo.id, index: indx };
    // if old filePath saved then add into the array of promptsList to display data
    if (fileInfo.filePath) {
      setUiState({
        ...uiState, promptsList: [
          {
            filePath: fileInfo.filePath, ttsText: fileInfo.ttsText, gender: fileInfo.gender, playSpeed: fileInfo.playSpeed,
            voiceFileType: fileInfo.voiceFileType, fileUrl: fileInfo.fileUrl, fileSize: fileInfo.fileSize, variable: fileInfo?.variable || "",
            variableType: fileInfo?.variableType || "digits", isUploadedOnGit: fileInfo?.isUploadedOnGit || false,
            promptId: fileInfo?.promptId || (fileInfo.voiceFileType !== VOICE_FILE_UPLOAD_TYPE.LIBRARY ? AppUtil.getTaskName("inner_option_id") : ""),
          }
        ]
      });
    } else {
      setUiState({ ...uiState, promptsList: fileInfo?.promptsList });
    }
    setShowOptionMenu(true);
    setSelectedOption(selectedOption);
  }

  //   add value to the new option
  const addValueToOption = (event) => {
    let options = AppUtil.deepClone(optionAndVal);
    options[Object.keys(options).length] = ["", ""];
    setOptionAndVal(options);
  }

  //  remove option value mapping
  const removeOptionValueMapping = (event) => {
    const indx = Number(event.target.id.split("file_option_remove_")[1]);
    let options = AppUtil.deepClone(optionAndVal);
    let updatedOptionsAndVal = [];
    let counter = 0;
    let arrayCounter = optionAndVal[0][0]; // initializing the actual array counter

    // re-indexing the array when the element is deleted from in between
    Object.keys(options).map((key, index) => {
      if (index !== indx) {
        options[index][0] = arrayCounter.toString();
        updatedOptionsAndVal[counter++] = options[index];
        arrayCounter++;
      }
      // doing this because map method needs a return statement
      return options[index];
    });

    setOptionAndVal(updatedOptionsAndVal);
  }

  // handle the closing of option mapping menu
  const handleCloseOptionMappingMenu = () => {
    // handling the option value mapping on close of option menu
    const tempOptionAndVal = VoiceFileOptionService.getFormattedOptionAndVal(element);
    setOptionAndVal(tempOptionAndVal);
    setShowMappingOptionMenu(false);
  }

  // delete option and update optionAndValue attribute
  const handleDeleteOption = () => {
    let voiceFileInformation = voiceFileInfo;
    voiceFileInformation[selectedLanguage] = voiceFileInformation[selectedLanguage].filter((info) => {
      return info.id !== selectedOption.id;
    });
    ElementService.updateElementAttr(element, ATTRIBUTES.KEY_VALUE_MAP, JSON.stringify(voiceFileInformation));
    setShowOptionMenu(false);
    setSelectedOption({ option: "", id: null, index: null });
  }

  /**
   * Updates the state for prompts list to get saved in element
   * @param {Array} updatedPomptsList 
   */
  const updatePromptsState = (updatedPomptsList) => {
    setUiState({ ...uiState, promptsList: updatedPomptsList });
  }

  /**
   * Render all the voice file option for specific language
   * @param {string} language selected language
   * @returns {React.Component} Html element to render
   */
  const renderVoiceFileOption = (language) => {
    return (
      <>
        <VoiceContext.Provider
          value={{
            voiceFileDetails: voiceFileInfo, selectedLanguage: selectedLanguage, selectedOption: selectedOption,
            updateSelectedOption: updateSelectedOption, updatePromptsState: updatePromptsState,
            getAvailableOptions: getAvailableOptions,
            ttsRef: ttsRef, element: element, isAddOption: isAddOption, tempVoiceInfo: tempVoiceInfo,
            tempSelectedOption: tempSelectedOption, rightPanelEventHandler: rightPanelEventHandler,
            keyValVoiceFileEventsHandler: keyValVoiceFileEventsHandler, uploadRef: uploadRef,
            handleCloseOptionMenu: handleCloseOptionMenu, handleDeleteOption: handleDeleteOption,
            handleSaveOptionMenu: handleSaveOptionMenu, promptsList: uiState.promptsList,
            showLoader: uiState.showLoader
          }}>

          {Object.keys(voiceFileInfo[language]).map(
            (key, index) => {
              return (
                <div className="row mb-1 mt-1">
                  <div className="col-sm-10 p-1 mt-2">
                    <select disabled={true} className="pmivr-select" aria-label="Default select example"
                      id={`file_option_${voiceFileInfo[language][index].id}`} >
                      <option selected>{voiceFileInfo[language][index].option}</option>
                    </select>
                  </div>

                  <div className="col-sm-2 text-end mt-3 p-1">
                    <PmivrTooltip message={TOOLTIP.INFO.EDIT_EXISTING_OPTION_VOICE_FILE}>
                      <button
                        type="button"
                        className="pmivr-btn-app rounded-circle pmivr-btn-rounded-circle"
                        id={`file_option_open_${voiceFileInfo[language][index].id}`}
                        onClick={(event) => { handleShowFilledOptionMenu(event.currentTarget.id); }}
                      >
                        <i className="bi bi-pencil"></i>
                      </button>
                    </PmivrTooltip>
                  </div>
                </div>
              );
            }
          )}
          <div className="text-center">
            <PmivrTooltip message={TOOLTIP.INFO.ASSIGN_NEW_VOICE_FOR_USER_OPTION}>
              <button className=" mt-3 mb-2 p-2 text-center btn-app pmivr-btn-app"
                onClick={(event) => { handleShowOptionMenu(); }}>
                <i className="bi bi-plus-circle"> </i> Add options
              </button>
            </PmivrTooltip>
          </div>

          <PmivrDialog showDialog={showOptionMenu} closeDialog={handleCloseOptionMenu}
            title={`Add Options`} message={<MultiVoiceFileOptionModal />}
            footer={<MultiVoiceFileOptionModalFooter />} />
        </VoiceContext.Provider>
      </>
    )
  }

  /**
   * Update selected langauge state
   * @param {string} language Currently selected language
   */
  const updateVoiceFileStateInfo = (language) => setSelectedLanguage(language);

  return (
    <>
      <Accordion activeKey={activeAccordionKey} className="mt-3 pmivr-accordion"
        onSelect={(selectedKey) => setActiveAccordionKey(selectedKey)} flush>
        {
          [...supportedLanguages].map((language) => {
            return (
              <div key={language}>
                <Accordion.Item eventKey={language} className="mt-3 accordion-voice-item">
                  <Accordion.Header onClick={(event) => updateVoiceFileStateInfo(language)}>
                    <span className="pmivr-accordian-tab">
                      Language : {language}
                    </span>
                  </Accordion.Header>
                  <Accordion.Body className="p-3 pt-0">
                    {renderVoiceFileOption(language)}
                  </Accordion.Body>
                </Accordion.Item>
              </div>
            )
          })
        }
      </Accordion>
      <SingleVoiceContext.Provider
        value={{
          optionAndVal: optionAndVal, addOption: addValueToOption, updateOptionAndValues: updateOptionAndValues,
          removeOption: removeOptionValueMapping,
        }}>
        <div className="row mt-3 mb-3">
          <OptionValueMapping optionAndVal={optionAndVal} isOptionAndValStartFromZero={false}
            showOptionMenu={showMappingOptionMenu} handleCloseOptionMenu={handleCloseOptionMappingMenu} addOption={addValueToOption}
            updateOptionAndValues={updateOptionAndValues} removeOption={removeOptionValueMapping}
            handleSaveOptionMenu={handleSaveOptionValueMapping} setShowOptionMenu={setShowMappingOptionMenu} />
        </div>
      </SingleVoiceContext.Provider>
    </>
  );
}

export default MultiVoiceFileOptionView;