import { useEffect, useState, useRef } from "react";

import * as Yup from 'yup';
import { useFormik } from "formik";
import PropTypes from 'prop-types';

import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { STATUS_USER, USER_ROLES } from "../../../constants/user-role";
import { REGEX } from "../../../config/config";

import PmivrSnackBar from "../../../components/common/dialog/pmivr-snackbar";
import PmivrLabel from "../../../components/common/label/pmivr-label";
import PmivrTooltip, { TOOLTIP_POSITIONS } from "../../../components/common/tooltip/pmivr-tooltip";

import StringUtil from "../../../util/string.util";

import RoleService from "../../../services/role.service";
import UserService from "./../../../services/user.service";

/**
 * User form for adding new users or updating values of existing users
 * @param {Object} props Properties from parent component
 * @returns {React.Component} Html element to render
 */
const UserModal = (props) => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    // ui state object having following flag
    // disabled: flag to enable / disable the button and form
    // message: object having message text and error specifying flag
    const [uiState, setUiState] = useState({ disabled: false, message: { text: '', isError: false } });
    // flag for existing user
    const [isExistingUser, setIsExistingUser] = useState(false);
    // list of all the roles of the user
    const [roles, setRoles] = useState([]);
    // to show or hide the password of the user
    const [passwordVisible, setPasswordVisible] = useState(false);

    // validation schema for the form
    const validateUserSchema = Yup.object().shape({
        email: Yup.string()
            .required(MESSAGES.ERR.FIELD_REQUIRED)
            .matches(REGEX.EMAIL, {
                message: MESSAGES.ERR.INVALID_EMAIL,
                excludeEmptyString: false
            }),
        firstName: Yup.string().required(MESSAGES.ERR.FIELD_REQUIRED),
        lastName: Yup.string().required(MESSAGES.ERR.FIELD_REQUIRED),
        roleId: Yup.string().required(MESSAGES.ERR.FIELD_REQUIRED),
        status: Yup.string().required(MESSAGES.ERR.FIELD_REQUIRED),
        // Apply validation for password only if a new user is being created
        password: !isExistingUser
            ? Yup.string()
                .required(MESSAGES.ERR.FIELD_REQUIRED)
                .min(8, MESSAGES.ERR.PASSWORD_LENGTH)
                .matches(
                    REGEX.PASSWORD,
                    MESSAGES.ERR.PASSWORD_PATTERN
                )
            : Yup.string().nullable()
    });

    // formik values for form
    const formik = useFormik({
        initialValues: { email: "", password: null, roleId: "", firstName: "", lastName: "", status: "" },
        validationSchema: validateUserSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    useEffect(() => {
        const init = async () => {
            if (props?.user) {
                // case of update the existing user
                setIsExistingUser(true);
                formik.values.status = props.user.status;
                formik.values.email = props.user.email;
                formik.values.firstName = props.user.firstName;
                formik.values.lastName = props.user.lastName;
                formik.values.roleId = props.user.roleId;
            } else {
                // case of adding the new user
                setIsExistingUser(false);
            }
            // get all the user roles
            const response = await RoleService.getRoles();
            let userRoles = response?.data;
            const currentUser = UserService.getCurrentUser();
            // if current user is not super admin, then we need not to provide option of super admin in roles
            if (currentUser?.roleId !== USER_ROLES.SUPER_ADMIN) {
                userRoles = userRoles?.filter((role) => {
                    return role.roleId !== USER_ROLES.SUPER_ADMIN
                })
            }
            setRoles(userRoles);
        };
        init();
    }, []);

    /**
     * Adding or Updating the values got from user form in DB
     * @param {Object} user formik values: email, password, lastName, firstName, roleId, status
     */
    const handleSubmit = async (user) => {
        setUiState({ ...uiState, disabled: true });
        try {
            const response = isExistingUser ? await UserService.updateUser(user) : await UserService.saveUser(user);
            setUiState({ ...uiState, disabled: true, message: { text: response?.msg, isError: false } });
            // giving timeout to remain on the same screen for displaying message
            setTimeout(() => { props?.closeAction(response?.data, isExistingUser); }, 2000);
        } catch (err) {
            let _message = MESSAGES.SOMETHING_WENT_WRONG;
            if (err.response?.status === 403) {
                _message = MESSAGES.ERR.UNAUTHORIZED;
            } else if (err.response?.status === 409) {
                _message = MESSAGES.ERR.EXISTING_EMAIL;
            }
            setUiState({ ...uiState, disabled: false, message: { text: _message, isError: true } });
        }
    }

    /**
     * Sets the state for password visibility to previous state on click of toggle button
     * if it was visible initially, hide it
     * if it was hidden, show it
     */
    const togglePasswordVisibility = () => {
        setPasswordVisible(prevState => !prevState);
    };

    /**
     * Generates a random password using the StringUtil.generateRandomPassword method
     * and sets it as the value of the "password" field in the formik form.
     * @returns {void} This function does not return a value.
     */
    const generateRandomPassword = () => {
        // Generate a random password using the utility function
        const password = StringUtil.generateRandomPassword();
        // Set the generated password in the formik state
        formik.setFieldValue("password", password);
    };


    return (
        <>
            <PmivrSnackBar ref={snackbarRef} />
            <div className="pmivr-user-modal">
                <div className={uiState.message.isError ? "field-error text-center" : "field-success text-center"}>{uiState.message?.text}</div>
                <form onSubmit={formik.handleSubmit} disabled={uiState.disabled}>
                    <div className="pmivr-container">
                        <div className="wrapper p-3 pt-0">
                            <div className="mb-2">
                                <PmivrLabel label="Email" tooltip={TOOLTIP.INFO.USER.EMAIL} />
                                <input type="text" name="email" value={formik.values.email || ""}
                                    className="form-control pmivr-input" disabled={(isExistingUser) ? "disabled" : ""}
                                    placeholder=" " onChange={formik.handleChange} />
                                {formik.touched.email && formik.errors.email && (
                                    <span className='field-error text-center'>{formik.errors.email}</span>
                                )}

                                {/* password should be entered only in case of new user */}
                                {
                                    !isExistingUser &&
                                    <>
                                        <PmivrLabel label="Password" tooltip={TOOLTIP.INFO.USER.PASSWORD} cssClass="mt-3" />
                                        <div className="password-input-wrapper">
                                            <input type={passwordVisible ? "text" : "password"} name="password" value={formik.values.password || ""}
                                                className="form-control pmivr-input" placeholder=" " onChange={formik.handleChange} />
                                            <PmivrTooltip message={TOOLTIP.GENERATE_RANDOM_PASSWORD} position={TOOLTIP_POSITIONS.LEFT}>
                                                <i className="bi bi-arrow-repeat pe-4" onClick={generateRandomPassword} />
                                            </PmivrTooltip>
                                            <PmivrTooltip message={TOOLTIP.PASSWORD_VISIBILITY} position={TOOLTIP_POSITIONS.RIGHT}>
                                                <i className={`bi ${passwordVisible ? "bi-eye" : "bi-eye-slash"}`} onClick={togglePasswordVisibility} />
                                            </PmivrTooltip>
                                        </div>
                                        {formik.touched.password && formik.errors.password && (
                                            <span className='field-error text-center'>{formik.errors.password}</span>
                                        )}
                                    </>
                                }

                                <PmivrLabel label="First Name" tooltip={TOOLTIP.INFO.USER.FIRST_NAME} cssClass="mt-3" />
                                <input type="text" name="firstName" value={formik.values.firstName || ""}
                                    className="form-control pmivr-input" placeholder=" " onChange={formik.handleChange} />
                                {formik.touched.firstName && formik.errors.firstName && (
                                    <span className='field-error text-center'>{formik.errors.firstName}</span>
                                )}

                                <PmivrLabel label="Last Name" tooltip={TOOLTIP.INFO.USER.LAST_NAME} cssClass="mt-3" />
                                <input type="text" name="lastName" value={formik.values.lastName || ""}
                                    className="form-control pmivr-input" placeholder=" " onChange={formik.handleChange} />
                                {formik.touched.lastName && formik.errors.lastName && (
                                    <span className='field-error text-center'>{formik.errors.lastName}</span>
                                )}

                                <PmivrLabel label="Role" tooltip={TOOLTIP.INFO.USER.ROLE} cssClass="mt-3" />
                                <select className="pmivr-select" name="roleId" value={formik.values.roleId}
                                    onChange={formik.handleChange}>
                                    <option value='' disabled>Select</option>
                                    {
                                        [...roles.values()].map((role, _index) => {
                                            return (<option key={role.roleId} value={role.roleId}>
                                                {role.name}
                                            </option>)
                                        })
                                    }
                                </select>
                                {formik.touched.roleId && formik.errors.roleId && (
                                    <span className='field-error text-center'>{formik.errors.roleId}</span>
                                )}

                                <PmivrLabel label="Status" tooltip={TOOLTIP.INFO.USER.STATUS} cssClass="mt-3" />
                                <select className="pmivr-select" name="status" value={formik.values.status}
                                    onChange={formik.handleChange}>
                                    <option value='' disabled>Select</option>
                                    {
                                        [...STATUS_USER].map((status, index) => {
                                            return (<option key={index} value={status.id}>
                                                {status.name}
                                            </option>)
                                        })
                                    }
                                </select>
                                {formik.touched.status && formik.errors.status && (
                                    <span className='field-error text-center'>{formik.errors.status}</span>
                                )}
                            </div>
                            <button className={`pmivr-btn-app w-100 p-3 mt-2`} disabled={uiState.disabled}
                                type="submit">{isExistingUser ? "Update" : "Create"}</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

UserModal.propTypes = {
    // selected user to be updated
    user: PropTypes.object,
    // closing the user dialogue
    closeAction: PropTypes.func
}

export default UserModal;
