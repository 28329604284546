import { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';

import { MESSAGES } from "../../constants/messages";

import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";

import AppUtil from "../../util/app.util";

import FlowService from "../../services/flow.service";
import UserService from "../../services/user.service";

/**
 * List the versions of the base template for the selected flow type
 * Also provides option to download the specific version of base template.
 * @returns {React.Component} Html element to render.
 */
const TemplateHistory = ({ selectedFlowType }) => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    // base templates info 
    const [templatesInfo, setTemplatesInfo] = useState([]);
    // disabled flag to make the item of the component disabled
    const [uiState, setUiState] = useState({ disabled: false });

    useEffect(() => {
        const init = async () => {
            try {
                // getting the info regarding the templates for the selected flow type
                setTemplatesInfo(await FlowService.getTemplatesInfo(selectedFlowType?.flowTypeId));
            } catch (err) {
                setUiState({ ...uiState, disabled: true });
                // opening the snackbar
                if (snackbarRef?.current) {
                    snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
                }
            }
        }
        init();
    }, []);

    /**
     * Download the base flow template as per version id and flow type
     * @param {String} versionId version id of the flow to be downloaded
     * @param {String} flowTypeId flow type whose flow to be downloaded
     */
    const downloadTemplate = async (versionId, flowTypeId) => {
        try {
            setUiState({ ...uiState, disabled: true });
            snackbarRef.current.close();
            const response = await FlowService.dowloadBaseFlow(versionId, flowTypeId);
            // downloading the file
            AppUtil.downloadFile(response.data, `flow_${flowTypeId}_${versionId}.xml`);
            setUiState({ ...uiState, disabled: false });
            snackbarRef.current.close();
        } catch (err) {
            setUiState({ ...uiState, disabled: false });
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />
            <table className="table pmivr-table header-fixed table-body-block border mt-2">
                <thead>
                    <tr>
                        <th width="20%" className="text-center">Version Id</th>
                        <th width="30%" className="text-center">Comments</th>
                        <th width="20%" className="text-center">Updated By</th>
                        <th width="20%" className="text-center">Updated On</th>
                        <th width="10%" className="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody className="pmivr-scroll">
                    {
                        (UserService.hasPermission() && templatesInfo?.length) ?
                            [...templatesInfo.values()].map((info, index) => {
                                return (
                                    <>
                                        <tr key={index}>
                                            <td width="20%" title={info.versionId} className="text-center pt-2">{info.versionId}</td>
                                            <td width="30%" title={info.comments} className="text-center pt-2">
                                                {info.comments || MESSAGES.NO_COMMENTS_AVAILABLE}
                                            </td>
                                            <td width="20%" title={info.updatedBy} className="text-center pt-2">{info.updatedBy}</td>
                                            <td width="20%" title={info.updatedOn} className="text-center pt-2">{info.updatedOn}</td>
                                            <td width="10%" title="Download Template File"
                                                className="text-center pt-2">
                                                <i disabled={uiState.disabled} className="bi bi-download pmivr-btn-icon"
                                                    onClick={() => downloadTemplate(info.versionId, selectedFlowType?.flowTypeId)}>
                                                </i>
                                            </td>
                                        </tr>
                                    </>
                                )
                            })
                            : <tr><td>Nothing to show</td></tr>
                    }
                </tbody>
            </table>
        </>
    );
}

// Types of props passed in the component
TemplateHistory.propTypes = {
    // selected flow type whose history is to be displayed
    selectedFlowType: PropTypes.object
}

export default TemplateHistory;