import PropTypes from 'prop-types';

import { CSS_CLASSES } from "../../../constants/css-classes";
/**
 * Loader
 * @param {Object} props Props data from parent component
 * @returns {React.Component} Html code to render loader
 */
const PmivrLoader = (props) => {
  return (
    <>
      <div className={props.showLoader ? CSS_CLASSES.BLOCK_DISPLAY : CSS_CLASSES.HIDE_DISPLAY}>
        <div className="pmivr-loader">
          <div className="loader-icon"></div>
        </div>
      </div>
    </>
  );
};

// Types of props passed in component
PmivrLoader.propTypes = {
  // boolean value to show loader
  showLoader: PropTypes.bool
}

export default PmivrLoader;
