import { clearState, updateShowGuide } from "../slices/tour-guide.slice";

export const updateShowTourGuide = ({ showGuide }) => async dispatch => {
    dispatch(updateShowGuide({ showGuide }));
}

/**
 * dispatch the action to clear the values from redux state
 */
export const clearTourGuideState = () => async dispatch => {
    dispatch(clearState());
}