import Joyride, { STATUS } from 'react-joyride';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { updateShowTourGuide } from '../../../redux/actions/tour-guide.action';

/**
 * Component for User Tour Guide
 * @param {Array} steps content and order of the target element to display the tour guides
 * @returns {React.Component} JSX code to render tour guide
 */
const TourGuide = ({ steps }) => {
  const dispatch = useDispatch();
  const { showGuide } = useSelector((state) => state.tourGuide);
  const styleOptions = { options: { primaryColor: '#124c74', zIndex: 10000 } };
  /**
   * Handles the skip and end tour guide action
   * @param {{status}} data CallBackProps
   */
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      dispatch(updateShowTourGuide({ showGuide: false }));
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback} continuous hideCloseButton run={showGuide} scrollToFirstStep
      showSkipButton steps={steps} styles={styleOptions}
      locale={{ last: 'Finish' }}
    />
  );
};

// Types of props passed in component
TourGuide.propTypes = {
  // steps for tour guide
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.object,
      target: PropTypes.string,
    })
  ).isRequired,
};

export default TourGuide;
