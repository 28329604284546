import { useState, useEffect } from 'react';

import { MESSAGES } from '../../../constants/messages';

import DiagramService from '../../../services/diagram.service';

/**
 * Render service expression warning information when publishing the flow
 * @returns {React.Component} Html code to render information for console.log in expression
 */
const ServiceExpressionWarning = (() => {
    const [serviceExpressionWarningInfo, setServiceExpressionWarningInfo] = useState([]);

    useEffect(() => {
        const serviceExpressionWarningInfo = DiagramService.getServiceExpressionWarnings();
        setServiceExpressionWarningInfo(serviceExpressionWarningInfo);
    }, []);

    return (
        <>
            {serviceExpressionWarningInfo?.length !== 0 &&
                <>
                    <p className="pmivr-text-primary fs-6 mt-4">{MESSAGES.EXPRESSION_WARNING}</p>
                    <table className="table voice-file-list pmivr-table header-fixed border mt-2" id="main-table">
                        <thead>
                            <tr style={{ lineHeight: "2rem" }}>
                                <th width="5%" className="text-center">
                                    S.no
                                </th>
                                <th width="20%" className="text-center">
                                    Element Name
                                </th>
                                <th width="20%" className="text-center">
                                    Sub Process Name
                                </th>
                            </tr>
                        </thead>
                        <tbody className="pmivr-scroll">
                            {serviceExpressionWarningInfo?.map((expressionInfo, index) => {
                                return (
                                    <tr style={{ lineHeight: "2rem" }}>
                                        <td width="5%">
                                            {index + 1}
                                        </td>
                                        <td width="20%">
                                            {expressionInfo?.elementName}
                                        </td>
                                        <td width="20%">
                                            {expressionInfo?.subProcessName || "-"}
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </>}
        </>
    )
});

export default ServiceExpressionWarning;