import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dropdown, ButtonGroup } from "react-bootstrap";

import { EVENT_TYPE } from "../../constants/events";
import { APP_PAGES } from "../../constants/app-pages";
import { MESSAGES, TOOLTIP } from "../../constants/messages";
import { COMMON_ACTIONS } from "../../constants/element";
import { FLOW_STATUS, TEMPLATE_VERSIONS_ACTIONS } from "../../constants/flow";
import { CSS_CLASSES } from "../../constants/css-classes";
import { APP_CONFIG } from "../../config/config";

import {
  updateDraft, updatePublished, updateBusinessCode, updateVariablesList, updateVoiceFiles, updateTaskActionParams,
  updateChangeHistory, clearChangeHistory, updateSelectedFlowTypeInformation, updateVersion, updateSelectedFlowType,
  updateLanguages, updateDeploymentEnv
} from "../../redux/actions/client.action";
import { updateAgiServiceMethodsList, updateSystemVariablesList } from "../../redux/actions/config.action";
import { updateShowReUsableDialog } from "../../redux/actions/re-usable-control.action";

import { PmivrDialog } from "../../components/common/dialog/pmivr-dialog";
import ToolsPanel from "../../flow-builder/bpmn/tools-panel";
import PropertiesPanel from "../../components/properties-panel";
import ChangeHistory from "../../components/change-history/change-history";
import SaveChangesDialog from "./components/save-changes-dialog";
import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";
import PmivrTooltip from "../../components/common/tooltip/pmivr-tooltip";
import PmivrLoader from "../../components/common/loader/pmivr-loader";
import CustomFlow from "../client-flows/components/custom-flow/custom-flow";
import FlowWarningDialog from "./components/flow-warning-dialog";

import AppUtil from "../../util/app.util";
import UrlUtil from "../../util/url.util";
import ElementUtil from "../../util/element.util";

import ClientService from "../../services/client.service";
import FlowService from "../../services/flow.service";
import DiagramService from "../../services/diagram.service";
import ModelerService from "../../services/modeler.service";
import FlowLayoutService from "../../services/flow-layout.service";
import ChangeHistoryService from "../../services/change-history.service";
import DiagramEventHandlerService from "../../services/diagram-event-handler.service";
import VariableService from "../../services/variable.service";
import AudioService from "../../services/audio.service";
import UserService from "../../services/user.service";
import ConfigService from "../../services/config.service";
import ErrorService from "../../services/error.service";
import BpmnEventHandlerService from "../../services/bpmn-event-handler.service";

import ServiceExpressionWarning from "./components/service-expression-warning";
import MissingVoiceFileWarning from "./components/missing-voice-file-warning";

/**
 * Diagram to show the flow
 * @returns {React.Component} Html code to render flow of the client IVR on diagram page
 */
const Diagram = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // avoid using JSON.parse on strings it will work only on lowercase true or false
  // else it will give errors
  const autoLayout = searchParams.get('autoLayout') === "true";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // using the open method from the snackbar component
  const snackbarRef = useRef();
  // to toggle the visibility of save changes dialog
  const saveChangesDialogRef = useRef();
  // to toggle the visibility of flow warning dialog
  const flowWarningDialogRef = useRef();
  // tools panel container in which the tools panel will be displayed
  const toolsContainerRef = useRef();
  // properties container in which the properties for a control can be edited and displyed
  const propertiesContainerRef = useRef();

  // confirm dialog properties 
  // (confirmTitleMessage: title to be shown on pmivr dialog, 
  // confirmMessage: message to be shown on pmivr dialog)
  const [confirmDialogProps, setConfirmDialogProps] = useState({ confirmTitleMessage: "", confirmMessage: "" });

  // flow error dialogue properties
  const [flowErrDialog, setFlowErrDialog] = useState({ showDialog: false, title: "Select  Action", messageBody: "" });

  // show custom flow name pop up
  const [showCustomNamePopup, setShowCustomNamePopup] = useState(false);

  // change history dialog properties 
  // (showHistoryDialog: flag for popping up the history dialog box, historyInfoTitle: itle for the history box that popped up to display 
  // history of changes in diagram, changeHistory: maintains the record for tha changes happening on diagram)
  const [historyDialogProps, setHistoryDialogProps] = useState({
    showHistoryDialog: false, historyInfoTitle: "Document Change History", changeHistory: []
  });
  // bpmn modeler for the diagram 
  const [modeler, setModeler] = useState({});
  // maitains the flowInfo in single state like businessCode versionId etc
  const [flowInformation, setFlowInformation] = useState({ businessCode: "", versionId: "", status: "", flowTypeId: "", flowName: "" });
  // diagram xml
  const [diagram, setDiagram] = useState("");
  // flag adding classname for dragging on or off of control panel
  const [addClassToDragControlPanel, setAddClassToDragControlPanel] = useState(false);
  // type of document to be saved (draft / publish)
  const [publishOrDraft, setPublishOrDraft] = useState(FLOW_STATUS.DRAFT);
  /**
   * ui state to control ui based event
   * flowRendered - to check if the flow is visible on the diagram page or not
   * showConfirmationDialog - to show and hide the confirmation dialog for drafting flow to other environment
   */
  const [uiState, setUiState] = useState({
    flowRendered: false, showConfirmationDialog: false
  });

  /**
   * flowStatus - flow status to which the current flow is to be migrated on another environment
   * migrateWizard - flag specifying whether to migrate the wizard along with flow to other environment
   */
  const [migrationState, setMigrationState] = useState({ flowStatus: '', migrateWizard: '' });

  // right panel events array to execute (events are saved in array and then executed)
  const [rightPanelEvents, setRightPanelEvents] = useState([]);
  // key value voice file events array to execute (events are saved in array and then executed)
  const [keyValVoiceFileEvents, setKeyValVoiceFileEvents] = useState([]);
  // Xml for the currently opened flow
  const [currentDiagramXml, setCurrentDiagramXml] = useState('');
  // for selected draft option from dropdown
  const [selectedDraftOption, setSelectedDraftOption] = useState(null);
  // date time when last saved
  let lastSaved = "";
  // latest state from redux store
  let { businessCode, versionId, flowType, selectedFlowTypeInfo, elementActionDialogParams, deploymentEnvironment } = useSelector(state => state.client);
  // latest state of loader from redux, to show local loader for diagram page while the diagram is rendered after all successful api calls
  const { showLoader } = useSelector(state => state.uiState);
  const { showReUsableDialog } = useSelector(state => state.reUsableControl);
  // get the seleted flow name (flow information is required when saving the flow)
  let { flowName, flowTypeId } = selectedFlowTypeInfo;
  // get the draft options configured by user
  const [draftOptions, setDraftOptions] = useState([]);
  // clear the change history on initial page render
  ChangeHistoryService.clearChangeHistory();

  useEffect(() => {
    // check if token exists if not then redirect to login page.
    const currentUser = UserService.getCurrentUser();
    if (!currentUser?.token) {
      navigate(APP_PAGES.LOGIN);
    }
    // checking the values and populating them in local state for page rendering
    const init = async () => {
      try {
        // set the configured languages in the redux state
        const configuredLanguage = await DiagramService.getSupportedLanguages();
        // updating the state in store for languages configured
        dispatch(updateLanguages({ languagesConfigured: configuredLanguage }));
        // when we hit url directly in the browser, key for location is default
        if (location?.key === 'default') {
          await resetStateFromLocation();
          businessCode = '';  // reseting the global business code value, so that all things gets reset again
        }
        // if businessCode not found in state, the try to read it from local storage
        if (!businessCode) {
          const basicFlowInfo = FlowService.getBasicFlowInfo();
          businessCode = basicFlowInfo.businessCode;
          versionId = basicFlowInfo.docVersionId;
          flowType = basicFlowInfo.flowType;
          flowName = basicFlowInfo.flowName;
          flowTypeId = basicFlowInfo.flowTypeId;

          /**
          * (location?.key === 'default') means direct url is hit.
          * When there is direct hit in the url for diagram page, then the following block executes 
          */
          if (location?.key === 'default') {
            const verificationResponse = await verifyClient();
            // verify the client to check if it exist
            if (!verificationResponse?.isClientVerified) {
              // opening the snackbar
              snackbarRef.current.open(verificationResponse?.message || MESSAGES.ERR.CLIENT_DOES_NOT_EXISTS);
              // giving timeout to remain on the same screen for displaying message
              setTimeout(() => { navigate(APP_PAGES.HOME); }, APP_CONFIG.MESSAGE_TIMEOUT);
              return;
            }
          }

          // getting the flowInfo and updating the redux state
          const flowInfo = await ClientService.getFlowInfo(businessCode);
          const { draft, published } = flowInfo;
          dispatch(updateBusinessCode({ businessCode: businessCode }));
          dispatch(updateDraft({ draft }));
          dispatch(updatePublished({ published }));
        }
        // params are invalid then redirect to home page because we can not load diagram if param are invalid
        if (!(AppUtil.isValueValid(flowType) && AppUtil.isValueValid(versionId) && AppUtil.isValueValid(businessCode))) {
          // opening the snackbar
          snackbarRef.current.open(MESSAGES.LOAD_FLOW_ERROR);
          // giving timeout to remain on the same screen for displaying message
          setTimeout(() => { navigate(APP_PAGES.HOME); }, APP_CONFIG.MESSAGE_TIMEOUT);
          return;
        }

        // populating the flow information in state for page rendering 
        // (creating a new copy and updating the values and updating the state)
        const flowInfoCloned = { ...flowInformation };
        flowInfoCloned.businessCode = businessCode;
        flowInfoCloned.status = flowType;
        flowInfoCloned.versionId = versionId;
        flowInfoCloned.flowName = flowName;
        flowInfoCloned.flowTypeId = flowTypeId;
        setFlowInformation(flowInfoCloned);
        // reseting redux state when we come on diagram for first time
        dispatch(clearChangeHistory());
        // Getting the draft options from db to show it in the dropdown
        const configuredDraftOptions = await ClientService.getDraftOptions();
        setDraftOptions(configuredDraftOptions?.data);
      } catch (err) {
        setFlowErrDialogProps(true, MESSAGES.LOAD_FLOW_ERROR);
      }
    }
    init();
  }, []);

  useEffect(() => {
    // load the modeler and read the diagram xml
    const initLoad = async () => {
      try {
        // loading the bpmn modeler
        await ModelerService.loadFlowModeler();
        // reading the diagram 
        const response = await DiagramService.getDiagramInfo(flowInformation);
        setModeler(response.modeler);
        setDiagram(response.diagram);
      } catch (err) {
        // If we pass invalid version id, then we do not have flowname for that
        (!flowName)
          ? setFlowErrDialogProps(true, `${MESSAGES.LOAD_FLOW_ERROR} (${MESSAGES.ERR.INVALID_VERSION_ID})`)
          : setFlowErrDialogProps(true, MESSAGES.LOAD_FLOW_ERROR);
      }
    }
    // load the modeler and diagram only if flow information state is updated
    if (flowInformation && flowInformation.businessCode && flowInformation.status && flowInformation.versionId) {
      initLoad();
    }
  }, [flowInformation]);

  useEffect(() => {
    // Render the diagram xml
    const initRender = async () => {
      try {
        await DiagramService.renderDiagramCanvas(diagram, autoLayout);
        // listen the changes on diagram to maintain history changes
        _listenChanges();
        // show the left and element proeprties panel
        await _setPanels();
        // to make it look clear for better visibility, re-draw all connection lines only if auto layout is not in the parameter
        if (!autoLayout) {
          FlowLayoutService.reconnectElements();
        }
        // updating the local storage
        const basicFlowInfo = FlowService.getBasicFlowInfo();
        basicFlowInfo.docVersionId = flowInformation.versionId;
        basicFlowInfo.flowType = flowInformation.status;
        basicFlowInfo.businessCode = flowInformation.businessCode;
        basicFlowInfo.flowTypeId = flowInformation.flowTypeId;
        FlowService.setBasicFlowInfo(basicFlowInfo);

        // store voice files in redux
        const voiceFiles = await AudioService.getVoiceFileDetails();
        dispatch(updateVoiceFiles({ mapOfVoiceFiles: voiceFiles }));

        // adding event listener to the info icon of the node in the diagram, to open the right panel
        // document usage cannot be removed as it is done for bpmn element that is created dynamically
        document.addEventListener('click', function (event) {
          if (!event.target.matches('.node-info-icon')) return;
          DiagramService.openRigthPanel();
          DiagramService.closeContextPad();
        }, false);

        // adding event listener to the three dots icon of the node in the diagram, to open the context pad
        // document usage cannot be removed as it is done for bpmn element that is created dynamically.
        document.addEventListener('click', function (event) {
          if (!event.target.matches('.context-pad-open')) return;
          DiagramService.openContextPad();
        }, false);

        // add event listeners for highlighting and removing highlight from elements on opening and 
        // closing properties panel
        ElementUtil.initElementClickListener();

        // updating system variables state
        const systemVariables = await ConfigService.getSysVariable();
        dispatch(updateSystemVariablesList({ systemVariables }));

        // updating state with variables object
        const variables = await VariableService.getVariables();
        dispatch(updateVariablesList({ variables }));

        // updating the redux state for agi service methods
        const agiServiceMethods = await ConfigService.getAgiServiceMethods();
        dispatch(updateAgiServiceMethodsList({ agiServiceMethods }));

        // lastly after the diagram has been rendered on the canvas, update the state to hide loader
        setUiState({ ...uiState, flowRendered: true });
      } catch (err) {
        setFlowErrDialogProps(true, MESSAGES.LOAD_FLOW_ERROR);
      }
    }
    // render the diagram only after read the diagram xml
    if (diagram) {
      initRender();
    }
    // on leaving the component that is on unmount loader is still in progress, we need to update the state
    // so that another page can be rendered immediately
    return () => {
      setUiState({ ...uiState, flowRendered: true });
    }
  }, [diagram]);

  /**
   * Checking whether the deployment environments are configured or not.
   * If not configured, then run as normal, verify the client without environment.
   * If configured, then giving preferance to the query param and if we found envKey in the query param, then save it in redux,
   * and verify client in that environment.
   * @returns {{isClientVerified: boolean, message: string}} isClientVerified true, if client is verified. Otherwise, return false.
   */
  const verifyClient = async () => {
    const isDeploymentEnvironmentExists = await ConfigService.isDeploymentEnvironmentExists();
    const envKeyParam = searchParams.get('envKey');
    if (isDeploymentEnvironmentExists && AppUtil.isValueValid(envKeyParam)) {
      dispatch(updateDeploymentEnv({ deploymentEnvironment: envKeyParam }));
    } else {
      if (isDeploymentEnvironmentExists) {
        if (!AppUtil.isValueValid(deploymentEnvironment)) {
          return { isClientVerified: false, message: MESSAGES.ERR.DEPLOYMENT_ENVIRONMENT_NOT_FOUND };
        }
      }
    }
    // verify the client first as we have url client-flows/<businessCode> businessCode can be any so
    // need to verify it if exists then ok else redirect
    const isVerifiedClient = await ClientService.getClientVerification(businessCode);
    return { isClientVerified: isVerifiedClient?.data || false };
  }

  /**
   * Reseting the state afresh after reading current location url.
   * Getting the flow details after reading businessCode, flowType and versionId from location and updating the state
   */
  const resetStateFromLocation = async () => {
    // Example: /diagram/abc/published/RiK8.Cbwj5uQAcgwfsJm_6jMdOxxXo
    const pathRoute = location.pathname || "";
    // reading the params from path route ['diagram', 'abc', 'publised', '123hjgg2u']
    // eslint-disable-next-line
    const [pageName, businessCode, flowType, docVersionId] = UrlUtil.getPathParamsFromRoute(pathRoute);
    const basicFlowInfo = { businessCode, flowType, docVersionId };
    //getting details of the flow from mongoDb
    const flowDetails = await FlowService.getFlowDetails(basicFlowInfo);
    basicFlowInfo.flowName = flowDetails?.flowName;
    basicFlowInfo.flowTypeId = flowDetails?.flowTypeId;
    // updating the local storage
    FlowService.setBasicFlowInfo(basicFlowInfo);
    // updating the redux state
    dispatch(updateSelectedFlowTypeInformation({ selectedFlowTypeInfo: flowDetails }));
    dispatch(updateVersion({ versionId: basicFlowInfo.docVersionId }));
    dispatch(updateSelectedFlowType({ flowType: basicFlowInfo.flowType }));
  }

  /**
   * Set tool and right panel
   * HACK - need to create these variables to work properly
   */
  const _setPanels = async () => {
    const $toolsContainer = toolsContainerRef.current;
    const $propertiesContainer = propertiesContainerRef.current;
    // requried to render left tool bar.
    // eslint-disable-next-line
    const toolsPanel = new ToolsPanel({ modeler: modeler, container: $toolsContainer });
    // the properties view (right panel having info for the control).
    // renders the right info panel
    // eslint-disable-next-line
    const propertiesPanel = new PropertiesPanel({
      modeler: modeler, container: $propertiesContainer,
      rightPanelEventHandler: rightPanelEventHandler,
      keyValVoiceFileEventsHandler: keyValVoiceFileEventsHandler
    });
  }

  /**
   * Updating the confirm dialog properties in state
   * @param {string} confirmTitleMessage  Title message for confirm dialog
   * @param {string} confirmMessage  Message for confirm dialog
   */
  const setConfirmDialogProperties = (confirmTitleMessage = "", confirmMessage = "") => {
    const confirmDialogObj = { ...confirmDialogProps };
    confirmDialogObj.confirmTitleMessage = confirmTitleMessage;
    confirmDialogObj.confirmMessage = confirmMessage;
    setConfirmDialogProps(confirmDialogObj);
  }

  /**
   * Updating the history dialog properties in state
   * @param {boolean} showHistoryDialog  Flag to pop up change history dialog
   * @param {Array} changeHistory  Array of change history of diagram
   */
  const setChangeHistoryDialogProperties = (showHistoryDialog = false, changeHistory = []) => {
    const historyDialogObj = { ...historyDialogProps };
    historyDialogObj.showHistoryDialog = showHistoryDialog;
    historyDialogObj.changeHistory = changeHistory;
    setHistoryDialogProps(historyDialogObj);
  }

  // Close the all dialog boxes
  const closeDialog = () => {
    saveChangesDialogRef.current.close();
    setFlowErrDialogProps(false);
  }

  // Close the flow warning dialog like missing voice file and console.log in service expressions
  const closeFlowWarningDialog = () => {
    flowWarningDialogRef.current.close();
  }

  // Setting flags in state to show the confirm dialog box
  const showConfirmDialogBox = (status) => {
    flowWarningDialogRef.current.close();
    const title = `Save ${status}?`;
    const message = `Are you sure to ${status === FLOW_STATUS.PUBLISHED ? 'publish' : 'draft'} this document?`;
    setConfirmDialogProperties(title, message);
    setPublishOrDraft(status);
    saveChangesDialogRef.current.open(message, status);
  };

  /**
   * Shows the flow warning information on publish flow like missing voice files and console.log in service expression
   * @param {string} status status of the flow
   */
  const showFlowWarningDialogBox = (status) => {
    // get the voice files that are missing
    const missingVoiceFileInfo = DiagramService.getMissingVoiceFiles();
    const serviceExpressionWarningInfo = DiagramService.getServiceExpressionWarnings();
    if (missingVoiceFileInfo?.length || serviceExpressionWarningInfo?.length) {
      const title = `Are you sure to continue?`;
      flowWarningDialogRef.current.open(title, status);
    } else {
      showConfirmDialogBox(status);
    }
  };

  /**
   * Handle the clcik of draft option that is configured from draft option in settings to publish the flow on different env
   * @param {{targetStatus,migrateWizard}} option 
   * @param {string} flowStatus
   */
  const handleDraftOptionClick = (option, flowStatus) => {
    setSelectedDraftOption(option);
    setUiState({ ...uiState, showConfirmationDialog: true });
    setMigrationState({ ...migrationState, flowStatus });
  }

  // Close the history box by setting flag false
  const closeHistoryDialog = () => setChangeHistoryDialogProperties(false, historyDialogProps.changeHistory);

  // Show history box by setting flag true
  const showHistoryDialogBox = () => setChangeHistoryDialogProperties(true, historyDialogProps.changeHistory);

  // Save/close handler from confirmation dialog
  const saveDialogChanges = async (values) => {
    saveChangesDialogRef.current.close();
    try {
      // saving the doc
      await saveDiagram(values);
      lastSaved = AppUtil.formatDateInLocal(new Date());
      navigate(`${APP_PAGES.CLIENT_FLOWS}/${flowInformation.businessCode}`);
    } catch (err) {
      // opening the snackbar
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.SAVE_DIAGRAM);
      }
    }
  };

  /**
   * Listen the changes on loaded diagram
   * Modifies change history object based on different events
   */
  const _listenChanges = () => {
    // initialize the bpmn event handler with bpmn modeler and history props
    new BpmnEventHandlerService(modeler, historyDialogProps, updateChanges);
  }

  // after listening the changes update the state and highlight element
  const updateChanges = (element, changeHistory) => {
    setChangeHistoryDialogProperties(false, [...changeHistory]);
    // save in the cache
    ChangeHistoryService.setChangeHistory(changeHistory);
    // in case of delete element, there will be no element to highlight
    if (element) {
      // giving timeout to render the elements properly and then highlighting the changed element
      setTimeout(() => { DiagramService.highlightElement(element.id); }, 500);
    }
    // updating the change history redux state 
    // (adding timeout to avoid error : Invariant failed: A state mutation was detected between dispatches.)
    const latestChange = changeHistory[changeHistory.length - 1];
    setTimeout(() => { dispatch(updateChangeHistory({ changeHistory: latestChange })); }, 1500);
  }

  // toggle the left control panel (open and close)
  const toggleControlPanel = () => setAddClassToDragControlPanel(!addClassToDragControlPanel);

  /**
   * Listener to listen right panel action events
   * @param {{eventType,element,data}} eventInfo Event info 
   */
  const rightPanelEventHandler = (eventInfo) => {
    let events = rightPanelEvents || [];

    // check if same element exists with the same file type
    const existedElement = events
      .find((_eventInfo) => _eventInfo.element.id === eventInfo.element.id && _eventInfo.fileType === eventInfo.data.fileType);

    if (existedElement) {
      events = events
        .map((_eventInfo) => {
          if (_eventInfo.element.id === eventInfo.element.id && _eventInfo.fileType === eventInfo.data.fileType) {

            // file is upload and there is no file property, delte the event from list as file can be invalid
            if (eventInfo.data.eventType === EVENT_TYPE.VOICE_FILE_UPLOAD && !eventInfo.data.file) {
              delete _eventInfo.data[eventInfo.data.language];
            } else {
              //update that with current event
              _eventInfo.data[eventInfo.data.language] = eventInfo.data;
              _eventInfo.element = eventInfo.element;
            }
            return _eventInfo;
          } else {
            return _eventInfo;
          }
        });
    } else {
      // format event info
      const _eventInfo = {};
      _eventInfo.element = eventInfo.element;
      _eventInfo.fileType = eventInfo.data.fileType;
      _eventInfo.data = {};
      _eventInfo.data[eventInfo.data.language] = eventInfo.data;
      events.push(_eventInfo);
    }

    // update state with events
    setRightPanelEvents(events);
    return eventInfo;
  }

  /**
  * Handler key value voice file events
  * @param {Object} eventInfo Diagram element to update attributes
  */
  const keyValVoiceFileEventsHandler = (eventInfo) => {
    const keyValVoiceFileEventsAll = DiagramEventHandlerService.keyValVoiceFileEventsHandler(eventInfo, keyValVoiceFileEvents);
    setKeyValVoiceFileEvents(keyValVoiceFileEventsAll?.events);
    return keyValVoiceFileEventsAll?.eventInfo;
  }

  /**
   * Saves the diagram and returns latest flow
   * @param {{ name, comments }} values Formik form values
   */
  const saveDiagram = async (values) => {
    const { name, comments } = values;

    if (diagram.length > 0) {
      // getting the updated diagram xml
      const diagramXml = await DiagramService.getCurrentDiagramXml();
      const flowData = {
        businessCode: flowInformation.businessCode,
        xml: diagramXml,
        status: publishOrDraft,
        versionId: flowInformation.versionId,
        dnid: selectedFlowTypeInfo.dnid,
        flowTypeId: selectedFlowTypeInfo.flowTypeId,
        flowName: selectedFlowTypeInfo.flowName, metaInfo: { voiceFiles: [] },
        comment: { name, description: comments }
      };
      // save the change history in db if changes exists
      if (historyDialogProps.changeHistory.length) {
        flowData.changeHistory = historyDialogProps.changeHistory
      }

      if (selectedFlowTypeInfo?.chatFlowId) {
        flowData.chatFlowId = selectedFlowTypeInfo?.chatFlowId;
      }
      // uploading the updated xml file onto the S3 server
      await FlowService.uploadFlow(flowData);
      // if isMigrated true then it means we are manually migrating and on save of flow avoid showing alert of base flow update
      // check if isMigrated flow true then on publish or draft flow do not show the message of latest flow update
      const basicFlowInfo = FlowService.getBasicFlowInfo();
      if (basicFlowInfo?.isMigratedFlow) {
        await updateTemplatesInfo();
      }
      // clear the change history
      ChangeHistoryService.clearChangeHistory();
      // opening the snackbar
      if (flowData.status === FLOW_STATUS.DRAFT) {
        snackbarRef.current.open(MESSAGES.SAVED_SUCCESSFULLY);
      } else {
        snackbarRef.current.open(MESSAGES.PUBLISH_FLOW_INPROGRESS);
      }
    }
  }

  /**
   * Updates the template info to add businessCode in template version collection
   * to maintain who has updated the latest flow so that we avoid showing alert to that biller
   */
  const updateTemplatesInfo = async () => {
    const flowTemplateDetails = await FlowService.getTemplatesInfo(selectedFlowTypeInfo.flowTypeId);
    if (flowTemplateDetails?.length) {
      // fetching latest template details from the list
      const latestTemplateInfo = flowTemplateDetails[0];
      const templateInfo = {
        versionId: latestTemplateInfo?.versionId, businessCode,
        action: TEMPLATE_VERSIONS_ACTIONS.ADD_BILLER
      };
      await FlowService.updateTemplateInfo(templateInfo);
    }
  }

  /**
   * Updating the error dialog properties in state
   * @param {boolean} showDialog  Flag to pop up error action confirm dialog
   */
  const setFlowErrDialogProps = (showDialog = false, title = "", messageBody = "") => {
    const flowErrDialogObj = { ...flowErrDialog, showDialog, title, messageBody };
    setFlowErrDialog(flowErrDialogObj);
  }

  /**
   * Reload the diagram page
   */
  const reload = () => {
    setFlowErrDialogProps(false);
    navigate(0);
  }

  /**
   * Navigate to the businessCode doc version list page
   */
  const goToDocVersionPage = () => {
    setFlowErrDialogProps(false);
    navigate(`${APP_PAGES.CLIENT_FLOWS}/${flowInformation.businessCode}`);
  }

  /**
   * Creating new custom flow from existing flow
   */
  const createCustomFlow = async () => {
    const currentXml = await DiagramService.getCurrentDiagramXml();
    setCurrentDiagramXml(currentXml);
    // Show the custom flow name popup 
    setShowCustomNamePopup(true);
  }

  /**
   * Drafts the flow to another env builder with current xml data
   * @param {{label, optionId, url}} option
   */
  const draftToAnotherEnvBuilder = async (option) => {
    try {
      setUiState({ ...uiState, showConfirmationDialog: false });
      // passing the target status of the flow in another env.
      option['targetStatus'] = migrationState.flowStatus;
      /**
       * passing the flag, specifying whether we want to override the wizard on the target environment.
       * yes: override the wizard config on target environment along with flow migration
       * no: donot override the wizard config on target envrionment . Just migrate the flow.
       */
      option['migrateWizard'] = migrationState.migrateWizard;
      const res = await ClientService.draftToAnotherEnvBuilder(option, flowInformation);
      snackbarRef.current.open(res?.msg);
    } catch (err) {
      let _message = ErrorService.getErrorMessage(err);
      snackbarRef.current.open(_message);
    } finally {
      setSelectedDraftOption(null);
      setUiState({ ...uiState, showConfirmationDialog: false });
      setMigrationState({ ...setMigrationState, migrateWizard: '' });
    }
  };

  /**
   * Close the confirm disable component dialog by dispatching false (since the popup is being opened from a service)
   */
  const closeDisableComponentDialog = async () => {
    dispatch(updateTaskActionParams({ showElementActionDialog: false, taskAction: '' }));
  }

  /**
   * Close the confirm re usable component dialog by dispatching false (since the popup is being opened from a service)
   */
  const closeReUsableComponentDialog = async () => {
    dispatch(updateShowReUsableDialog({ showReUsableDialog: false }));
  }

  /**
   * Handles the confirmation of re usable dialog and exports it as re usable
   */
  const handleConfirmReUsableDialog = async () => {
    try {
      // save the exported sub process in db
      await DiagramService.exportAsReUsableComponent();
      snackbarRef.current.open(MESSAGES.EXPORT_REUSABLE_ELEMENT);
    } catch (err) {
      if (snackbarRef?.current) {
        snackbarRef.current.open(MESSAGES.ERR.EXPORT_REUSABLE_ELEMENT);
        closeReUsableComponentDialog();
      }
    }
  }

  return (
    <>
      {/* dialog box for saving draft / published doc */}
      <SaveChangesDialog ref={saveChangesDialogRef} closeAction={closeDialog}
        saveDialogChanges={saveDialogChanges}
        changeHistory={historyDialogProps.changeHistory} />

      {/* dialog box for showing flow warning information like missing voice files and service expressions with console.log*/}
      <FlowWarningDialog ref={flowWarningDialogRef} onClose={closeFlowWarningDialog}
        onConfirm={showConfirmDialogBox} />

      {/* dialog box for displaying the history changes  */}
      <PmivrDialog showDialog={historyDialogProps.showHistoryDialog} closeDialog={() => closeHistoryDialog()}
        title={historyDialogProps.historyInfoTitle}
        message={<ChangeHistory history={historyDialogProps.changeHistory}></ChangeHistory>} />

      {/* dialog box to confirm disable component */}
      <PmivrDialog showDialog={elementActionDialogParams?.showDialog} closeDialog={() => closeDisableComponentDialog()}
        title={`Confirm ${elementActionDialogParams?.action === COMMON_ACTIONS.DISABLE_TASK ? "Disable" : "Enable"}`}
        message={
          <>
            <div>
              {elementActionDialogParams?.action === COMMON_ACTIONS.DISABLE_TASK ? MESSAGES.DISABLE_TASK_WARNING :
                MESSAGES.ENABLE_TASK_WARNING}
            </div>
            <div className="text-end mt-4">
              <div className="d-inline-block mx-2">
                <button className="pmivr-btn-secondary p-2" type="button" onClick={() => closeDisableComponentDialog()}>Cancel</button>
              </div>
              <div className="d-inline-block mx-2">
                <button className="pmivr-btn-app p-2" type="submit" onClick={() => {
                  elementActionDialogParams?.action === COMMON_ACTIONS.DISABLE_TASK ?
                    DiagramService.disableTask() : DiagramService.enableTask()
                }}
                >Confirm</button>
              </div>
            </div>
          </>
        }
        footer={<></>}
      />

      {/* dialog box to confirm export re usable compoenent */}
      <PmivrDialog showDialog={showReUsableDialog} closeDialog={() => closeReUsableComponentDialog()}
        title={"Are you sure to export as Re-Usable Component"}
        message={
          <>
            <div>
              {MESSAGES.RE_USABLE_INFORMATION}
            </div>
            <div className="text-end mt-4">
              <div className="d-inline-block mx-2">
                <button className="pmivr-btn-secondary p-2" type="button"
                  onClick={() => closeReUsableComponentDialog()}>Cancel</button>
              </div>
              <div className="d-inline-block mx-2">
                <button className="pmivr-btn-app p-2" type="submit" onClick={() => { handleConfirmReUsableDialog() }}
                >Confirm</button>
              </div>
            </div>
          </>
        }
        footer={<></>}
      />

      {/* dialog box for in case of flow load error */}
      <PmivrDialog showDialog={flowErrDialog.showDialog} closeDialog={closeDialog} title={flowErrDialog.title}
        cssClass={CSS_CLASSES.MODAL_OVERLAY} message={flowErrDialog.messageBody}
        footer={
          <>
            <PmivrTooltip message={TOOLTIP.CANCEL}>
              <button className="pmivr-btn-cancel" onClick={closeDialog} >
                Cancel
              </button>
            </PmivrTooltip>

            <PmivrTooltip message={TOOLTIP.RELOAD}>
              <button className="pmivr-btn-app" onClick={reload} >
                Reload
              </button>
            </PmivrTooltip>

            <PmivrTooltip message={TOOLTIP.DOC_VERSION_PAGE}>
              <button className="pmivr-btn-app" onClick={goToDocVersionPage} >
                Go To Doc Version Page
              </button>
            </PmivrTooltip>
          </>
        }></PmivrDialog>

      {/* dialog box for custom flow name */}
      <PmivrDialog showDialog={showCustomNamePopup} closeDialog={() => setShowCustomNamePopup(false)}
        title={MESSAGES.ENTER_CUSTOM_FLOW_NAME}
        message={<CustomFlow businessCode={businessCode} parentFlowXml={currentDiagramXml}
          closeAction={() => setShowCustomNamePopup(false)} />}
        footer={<></>} />

      <PmivrDialog showDialog={uiState.showConfirmationDialog}
        closeDialog={() => {
          setUiState({ ...uiState, showConfirmationDialog: false });
          setMigrationState({ ...migrationState, migrateWizard: '' });
          setSelectedDraftOption(null);
        }}
        title={`Confirm ${migrationState.flowStatus === FLOW_STATUS.PUBLISHED ? 'publish' : 'draft'}`}
        message={
          <>
            <p>
              {`Are you sure to ${migrationState.flowStatus === FLOW_STATUS.PUBLISHED ? 'publish' : 'draft'} this flow to other environment?`}
            </p>
            <div className="row">
              <div className="col-md-9 pmivr-required-label pmivr-label">
                <label className="text-dark">
                  {`Do you want to override the wizard config on target environment ?`}
                </label>
              </div>
              <div className="col-md-3">
                <select className="pmivr-select select-sm" value={migrationState.migrateWizard}
                  onChange={(e) => setMigrationState({ ...migrationState, migrateWizard: e.target.value })}>
                  <option key="" value="" disabled>Select</option>
                  <option key="yes" value="yes">Yes</option>
                  <option key="no" value="no">No</option>
                </select>
              </div>
            </div>
            <div className="row mt-4">
              <MissingVoiceFileWarning />
              <ServiceExpressionWarning />
            </div>
          </>
        }
        footer={<>
          <button className="pmivr-btn-cancel" onClick={() => {
            setUiState({ ...uiState, showConfirmationDialog: false });
            setMigrationState({ ...migrationState, migrateWizard: '' });
            setSelectedDraftOption(null);
          }}>
            Cancel
          </button>
          <button className="pmivr-btn-app" onClick={() => draftToAnotherEnvBuilder(selectedDraftOption)}
            disabled={!AppUtil.isValueValid(migrationState.migrateWizard)}>
            Confirm
          </button>
        </>}
      />

      <PmivrSnackbar ref={snackbarRef} />

      {/* show the loader only until the flow is not visible on the canvas and the global axios loader is not shown */}
      <PmivrLoader showLoader={!uiState.flowRendered && !showLoader} />

      <div className="pmivr-container pmivr-diagram" pmivr-left-bar-hidden="true">
        <div className={addClassToDragControlPanel ? "control-menu-outer float-start control-menu-collapse" : "control-menu-outer float-start"} id="tools-container" ref={toolsContainerRef}></div>
        <button className="toggle-arrow pmivr-text-link" onClick={toggleControlPanel}>
          <i className={`pmivr-btn-icon ${addClassToDragControlPanel ? "bi bi-chevron-right" : "bi bi-chevron-left"}`}></i>
        </button>
        <div className="wrapper diagram-wrapper">
          <div className="row pb-3 pt-3">
            <div className="col-lg-6">
              <div className="m-2 mx-4 pmivr-breadcrumb-list" >
                {deploymentEnvironment ? `${deploymentEnvironment} : ` : ``}{selectedFlowTypeInfo?.flowName} : <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.HOME}>
                  <Link to={`/home`}>Home</Link></PmivrTooltip>/
                <PmivrTooltip message={TOOLTIP.NAVIGATION_LINKS.FLOWS}>
                  <Link to={`${APP_PAGES.CLIENT_FLOWS}/${flowInformation.businessCode}`}>{flowInformation.businessCode}</Link>
                </PmivrTooltip>
                /{flowInformation.status}
              </div>
            </div>
            <div className="col-lg-6 text-end">
              <div className="last-saved d-flex justify-content-end">
                {lastSaved ? <span>Last Saved : {lastSaved}</span> : ""}
                <button className="pmivr-btn-secondary mx-2 px-1" onClick={() => showHistoryDialogBox()} title="Change History">
                  <i className="bi bi-clock-history mx-2" style={{ color: "black" }}></i>
                  <span className="count px-2 ml-2">{historyDialogProps.changeHistory.length}</span>
                </button>
                <Dropdown as={ButtonGroup} className="pmivr-dropdown pmivr-dropdown-secondary">
                  <Button onClick={() => showFlowWarningDialogBox(FLOW_STATUS.DRAFT)} className="p-2 px-3">Draft</Button>
                  <Dropdown.Toggle><i class="bi bi-chevron-down"></i></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => showFlowWarningDialogBox(FLOW_STATUS.DRAFT)}>Draft</Dropdown.Item>
                    <Dropdown.Item onClick={createCustomFlow}>Custom Flow</Dropdown.Item>
                    {/* checking if the flow is a published version and if the user is admin/super-admin */}
                    {flowInformation?.status === FLOW_STATUS.PUBLISHED && UserService.hasPermission() && draftOptions.length > 0 &&
                      <>
                        <hr className="my-2" />
                        {
                          draftOptions?.map((option) => {
                            return (
                              !option?.isPublishOption &&
                              <Dropdown.Item onClick={() => { handleDraftOptionClick(option, FLOW_STATUS.DRAFT) }}>
                                {option?.label}</Dropdown.Item>
                            )
                          })
                        }
                      </>
                    }
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown as={ButtonGroup} className="pmivr-dropdown mx-2">
                  <Button onClick={() => showFlowWarningDialogBox(FLOW_STATUS.PUBLISHED)} className="p-2 px-3">
                    Publish
                  </Button>
                  <Dropdown.Toggle><i class="bi bi-chevron-down"></i></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => showFlowWarningDialogBox(FLOW_STATUS.PUBLISHED)}>Publish</Dropdown.Item>
                    {/* checking if the flow is a published version and if the user is admin/super-admin */}
                    {flowInformation?.status === FLOW_STATUS.PUBLISHED && UserService.hasPermission() && draftOptions.length > 0 &&
                      <>
                        <hr className="my-1" />
                        {
                          draftOptions?.map((option) => {
                            return (
                              option?.isPublishOption &&
                              <Dropdown.Item onClick={() => { handleDraftOptionClick(option, FLOW_STATUS.PUBLISHED) }}>
                                {option?.label}</Dropdown.Item>
                            )
                          })
                        }
                      </>
                    }
                  </Dropdown.Menu>
                </Dropdown>

              </div>
            </div>
          </div>
          <div id="container" className="pmivr-display-flex diagram"></div>
        </div>
        <div id="properties" className="diagram-properties"></div>
        <div id="properties-container pmivr-display-flex" ref={propertiesContainerRef}></div>
      </div>
    </>
  );
};

export default Diagram;