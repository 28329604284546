import { useEffect, useState } from "react";

import UserService from "../../../services/user.service";

import UserAvatar from "../user-avatar/user-avatar";

/**
 * The component for footer across all the app pages
 * Contains:
 *        - Logout
 *        - User Email
 * @returns {React.Component} Html element to render 
 */
const FooterComponent = () => {
  // current logged in user
  const [currentUser, setCurrentUser] = useState();

  useEffect(() => {
    // Set timeout is done because when businessCode flows page is directly opened from url then this footer 
    // component renders first and current user ism called with is empty at that time so we wait for some time to set the current user
    setTimeout(() => {
      setCurrentUser(UserService.getCurrentUser());
    }, 2000);
  }, []);

  return (
    <div className="pmivr-footer">
      <UserAvatar user={currentUser} />
    </div>
  );
};

export { FooterComponent };