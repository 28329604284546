import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    chatFlowId: "",
    chatFlowTitle: ""
}

// auto-generate action types and action creators, based on the names of the reducer functions that we supply
// state info : client related info 
const slice = createSlice({
    name: 'interactiveDesign',
    initialState,
    reducers: {
        updateChatFlowObj: (state, action) => {
            return {
                ...state,
                chatFlowId: action.payload.chatFlowId,
                chatFlowTitle: action.payload.chatFlowTitle

            }
        },
        clearState: () => initialState
    }
});

export const { updateChatFlowObj, clearState } = slice.actions;

export default slice.reducer;
