
/**
 * Removing the unused by default add elements 
 * From context pad for the elements in diagram
 */

export default class RemoveElementCustomContextPad {
    constructor(bpmnFactory, config, contextPad, create, elementFactory, injector, translate) {
        this.bpmnFactory = bpmnFactory;
        this.create = create;
        this.elementFactory = elementFactory;
        this.translate = translate;

        if (config.autoPlace !== false) {
            this.autoPlace = injector.get('autoPlace', false);
        }

        contextPad.registerProvider(this);
    }

    getContextPadEntries(element) {
        return function (entries) {
            delete entries["append.append-task"];
            delete entries["append.text-annotation"];
            delete entries["replace"];

            return entries;
        };
    }
}

RemoveElementCustomContextPad.$inject = [
    'bpmnFactory',
    'config',
    'contextPad',
    'create',
    'elementFactory',
    'injector',
    'translate'
];