import { updateAgiServiceMethods, updateSystemVariables, clearConfigs } from "../slices/config.slice";

/**
 * Dispatches the agi service methods into redux
 * @param {Array} agiServiceMethods - methods for AGI call
 */
export const updateAgiServiceMethodsList = ({ agiServiceMethods }) => async dispatch => {
    dispatch(updateAgiServiceMethods({ agiServiceMethods }));
}

/**
 * Dispatches the system variables into redux
 * @param {Array} systemVariables - system variables which are used at run time by AGI
 */
export const updateSystemVariablesList = ({ systemVariables }) => async dispatch => {
    dispatch(updateSystemVariables({ systemVariables }));
}

/**
 * dispatch the action to clear the values from redux state
 */
export const clearConfigState = () => async dispatch => {
    dispatch(clearConfigs());
}