// .env.development - Development environment variables
// These variables will be used when running "npm run build:dev"
const environment = {
  REACT_APP_ENVIRONMENT: 'sit',
  // To fix the dependency tree
  SKIP_PREFLIGHT_CHECK: true,
  // Public url is prefix the url of assets,images etc. build is deployed on /pmivr for SIT
  PUBLIC_URL: '/pmivr',
  // base url for hitting backend API
  REACT_APP_API_BASE_URL: '/pmivr/api',
  // DSN url passed in sentry init function to catch the events and log it on sentry.io on a associated project
  REACT_APP_DSN: 'https://814e4baf165543f7a299027bfb0186a8@o4505006683389952.ingest.sentry.io/4505011337035776',
  REACT_APP_DEFAULT_VOICE_FILE_PATH: window.config.REACT_APP_DEFAULT_VOICE_FILE_PATH || '/var/lib/asterisk/sounds/voice-files/',
  // to disable the map files when build to avoid showing of actual code in browser
  GENERATE_SOURCEMAP: false,
  // to render phone number extension option
  REACT_APP_RENDER_EXTENSION_OPTION: true,
  // appCode to check for platform
  REACT_APP_CODE: window.config.REACT_APP_CODE || "biller-direct"
};
export default environment;
