import store from "../redux/store/store";

import { envConfig } from "../environment";

import appAxios from "../util/app.axios";

/**
 * Service for configs
 */
export default class ConfigService {

  static CLIENT_DATA_URL = `${envConfig.REACT_APP_API_BASE_URL}/clientData`;
  static BASE_URL = `${envConfig.REACT_APP_API_BASE_URL}`;
  static EXT_FILE_URL = `${envConfig.REACT_APP_API_BASE_URL}/file`;

  /**
   * Getting the external file
   * @param {string} property 
   */
  static getExtFile(propertiesList) {
    return propertiesList.map((property) => {
      const url = `${this.EXT_FILE_URL}/propertyExtension/${property}`
      return appAxios.get(url);
    })
  }

  /**
   * Getting the system variables
   * @returns system variables
   */
  static getSysVariable() {
    const url = `${this.BASE_URL}/systemConfig/sysVariables`;
    return appAxios.get(url);
  }

  /**
   * Saving the system variables
   * @param {string} name 
   * @returns return the response of saving the system variables Api
   */
  static saveSysVariable(name) {
    const url = `${this.BASE_URL}/systemConfig/sysVariables`;
    return appAxios.post(url, { name });
  }

  /**
   * Saving the deployment environment into the mongoDB
   * @param {{envName, envUrl, envKey}} envDetails 
   * @returns {Promise<Object>} return the response of saving the deployment environment Api
   */
  static saveDeploymentEnvironment(envDetails) {
    const url = `${this.BASE_URL}/systemConfig/deploymentEnv`;
    return appAxios.post(url, { envDetails });
  }

  /**
   * Gets the list of deployment environment details saved in db
   * @param {{envKey}} filter filter based on filter environment key, if exists
   * @returns {Promise<Array<{name, key, createdOn, createdBy, url}>>} list of deployment environment details saved in db
   */
  static getDeploymentEnvironments(filter = { envKey: '' }) {
    const url = `${this.BASE_URL}/systemConfig/deploymentEnv?envKey=${filter?.envKey}`;
    return appAxios.get(url);
  } 

  /**
   * Checks whether the deployment environments are configured on the system or not
   * @returns {boolean} true, if deployment env configured. Otherwise, returns false.
   */
  static async isDeploymentEnvironmentExists() {
    const configuredEnv = await ConfigService.getDeploymentEnvironments();
    return configuredEnv?.length ? true : false;
  }

  /**
   * Updates the urls in selected environment details saved in db
   * @param {{envName, envUrl, envKey}} envDetails 
   * @returns {Promise<Object>}  return the response of updating the deployment environment api
   */
  static updateDeploymentEnvironment(envDetails) {
    const url = `${this.BASE_URL}/systemConfig/deploymentEnv`;
    return appAxios.put(url, { envDetails });
  }

  /**
  * Getting the payment types from configuration for the businessCode
  * @param {string} businessCode 
  * @returns {Promise<{paymentTypes}>} Payment types for the given businessCode
  */
  static getPaymentTypes(businessCode) {
    const url = `${this.CLIENT_DATA_URL}/getPaymentTypes?businessCode=${businessCode}`;
    return appAxios.get(url);
  }

  /**
 * Getting the segment code for the businessCode
 * @param {string} businessCode 
 * @returns {Promise<{segmentCode}>} segment code from payment configuration for the given businessCode
 */
  static getSegmentCode(businessCode) {
    const url = `${this.CLIENT_DATA_URL}/getSegmentCode?businessCode=${businessCode}`;
    return appAxios.get(url);
  }

  /**
   * Get agi service methods
   * @returns {Promise<Array<{_id, name, description}>>}
   */
  static getAgiServiceMethods() {
    const url = `${this.BASE_URL}/systemConfig/agiServiceMethods`;
    return appAxios.get(url);
  }

  /**
   * Gets system variables from redux
   * @returns {Array} array of system variables
   */
  static getSystemVariables() {
    return store.getState().config.systemVariables;
  }
}