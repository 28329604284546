import FormData from "form-data";

/**
 * Utility functions to manage objects and related operations
 */
class ObjUtil {
    /**
     * Creates a FormData object from the given fields.
     * This function accepts an object where the keys represent the form field names
     * and the values represent the corresponding values to be appended to a FormData object.
     * @param {Object} fields - An object containing the form fields and their respective values.
     * @returns {FormData} The FormData object populated with the given fields.
     */
    static createFormData(fields) {
        const formData = new FormData();
        // Loop through the fields object and append each key-value pair to formData
        for (const [key, value] of Object.entries(fields)) {
            formData.append(key, value);
        }
        return formData;
    }
}

export default ObjUtil;