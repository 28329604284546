import { APP_PAGES } from './app-pages';

/**
 * The common tour guide step is a plain array-object that only requires two properties to be valid: target and content.
 * Object containing tour guide steps for different sections/pages.
 */
export const TOUR_GUIDE_STEPS = [
  {
    path: APP_PAGES.HOME,
    steps: [
      {
        // content to be displayed
        content: <h2>Let's begin our journey!</h2>,
        // Placement of the tooltip
        placement: 'center',
        // target of the tooltip
        target: 'body',
      },
      {
        content: (
          <div>
            <h2>Find Biller</h2>
            <p>Look up your biller's TLA to adjust your IVR flow.</p>
          </div>
        ),
        target: '#search-box',
      },
      {
        content: (
          <div>
            <h2>Recent Search</h2>
            <p>Shows recently searched Biller's TLA for reference and tracking.</p>
          </div>
        ),
        target: '#recent-search',
      },
    ],
  },
  {
    path: APP_PAGES.DIAGRAM,
    steps: [
      {
        content: (
          <div>
            <h2>Start</h2>
            <p>Start of the flow</p>
          </div>
        ),
        disableBeacon: true,
        target: '#create-start-event',
      },
      {
        content: (
          <div>
            <h2>Condition Control</h2>
            <p>
              The "Condition" control in the IVR Tooling system allows you to create service logic based on specific conditions that need to
              be met within your IVR flow.
            </p>
          </div>
        ),
        target: '#create-exclusive-gateway',
      },
      {
        content: (
          <div>
            <h2>Service</h2>
            <p>
              The "Service Call" functionality in the IVR Tooling system allows administrators to integrate backend business logic
              seamlessly into their IVR flows
            </p>
          </div>
        ),
        target: '#create-service-impl-task',
      },
      {
        content: (
          <div>
            <h2>Voice</h2>
            <p>Allows the playback of voice during the IVR flow via Voice File, Text to Speech & Upload File.</p>
          </div>
        ),
        target: '#create-play-voice-file-task',
      },
      {
        content: (
          <div>
            <h2>Options Selection</h2>
            <p>
              Used to select a number for routing to a new path or flow. When designing your IVR flow, you have several configuration
              options available to customize the user experience.
            </p>
          </div>
        ),
        target: '#create-option-user-task',
      },
      {
        content: (
          <div>
            <h2>User Input</h2>
            <p>
              When designing your IVR flow, you have the capability to collect user input seamlessly. This section outlines the user input
              configuration options available to enhance your interactive voice response system. Hence, Enables the collection of user input
              from the dialer
            </p>
          </div>
        ),
        target: '#create-user-input-task',
      },
      {
        content: (
          <div>
            <h2>Prompt</h2>
            <p>
              Represents a variable that provides dynamic values during the IVR flow. The "Prompt" feature in the IVR Tooling system allows
              you to set up automated voice prompts where the IVR system reads out specific data to the user. This section details how to
              configure prompts effectively.
            </p>
          </div>
        ),
        target: '#create-say-data-task',
      },
      {
        content: (
          <div>
            <h2>Hang Up</h2>
            <p>Ends the call</p>
          </div>
        ),
        target: '#create-hangup-task',
      },
      {
        content: (
          <div>
            <h2>Transfer Call</h2>
            <p>Transfers the call to a specified destination, such as customer care, based on user input</p>
          </div>
        ),
        target: '#create-transfer-task',
      },
      {
        content: (
          <div>
            <h2>Voice Record</h2>
            <p>
              Record the voice during the call for future reference or analysis. The "Record" feature in the IVR Tooling system allows
              administrators to capture voice recordings from callers.
            </p>
          </div>
        ),
        target: '#create-voice-record-task',
      },
    ],
  },
  {
    path: APP_PAGES.WIZARD,
    steps: [
      {
        // content to be displayed
        content: (
          <div>
            <h2>Biller Setup</h2>
            <p>Set up fundamental Q&A with rules applicable to all biller flows.</p>
          </div>
        ),
        // Placement of the tooltip
        placement: 'center',
        // target of the tooltip
        target: 'body',
      },
      {
        content: (
          <div>
            <h2>Select calling number</h2>
            <p>Choose the number users will dial to access the IVR call.</p>
          </div>
        ),
        target: '#wizard-dnid',
      },
      {
        content: (
          <div>
            <h2>Submit</h2>
            <p>Click on Submit after configuration</p>
          </div>
        ),
        target: '#wizard-submit',
      },
    ],
  },
];

/**
 * Client FLows Specific Tour Guide steps
 */
export const CLIENT_FLOWS_STEPS = {
  NEW_FLOW: {
    path: APP_PAGES.CLIENT_FLOWS,
    steps: [
      {
        content: (
          <div>
            <h2>IVR Information</h2>
            <p>Essential header information for utilization.</p>
          </div>
        ),
        disableBeacon: true,
        target: '#info-header',
      },
      {
        content: (
          <div>
            <h2>Start with your Flow</h2>
            <p>You can begin creating your new flow by clicking this button.</p>
          </div>
        ),
        target: '#create-new-flow',
      },
    ],
  },
  FLOW_TYPES: {
    path: APP_PAGES.CLIENT_FLOWS,
    steps: [
      {
        content: (
          <div>
            <h2>Choose your Flow Type</h2>
            <p>Choose your flow type for IVR calling</p>
          </div>
        ),
        disableBeacon: true,
        target: '#flow-types',
      },
    ],
  },
  EXISTING_FLOW: {
    path: APP_PAGES.CLIENT_FLOWS,
    steps: [
      {
        content: (
          <div>
            <h2>Review flow status</h2>
            <p>
              You can confirm the flow's status as either published or in draft. Published means it's ready for calls, while draft means
              it's still editable without any noticeable action.
            </p>
          </div>
        ),
        disableBeacon: true,
        target: '#client-flow-existing',
      },
    ],
  },
};
