import { createRoot } from 'react-dom/client';
import React from 'react';

import ToolsPanelView from './ToolsPanelView';


export default class ToolsPanel {

  constructor(options) {

    const {
      modeler,
      container
    } = options;

    // createRoot is same as ReactDomRender but it is supported in react 18 or above version
    createRoot(container).render(<ToolsPanelView modeler={modeler} />);
  }
}