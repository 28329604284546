import { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

import AudioService from '../../../services/audio.service';

/**
 * Component for voice files select drop down
 * @param {onSelect, selectedItem, selectedLanguage} props props data from parent component
 * @returns {React.Component} Html code to render voice file select drop down
 */
const VoiceFilesSelect = ({ onSelect, selectedItem, selectedLanguage = '' }) => {
  // voice files drop down list
  const [voiceFileList, setVoiceFileList] = useState([]);

  // Define a debounced function that will delay the API call by 0.5 seconds, to avoid api call for every single letter typed
  // API call will be sent after 0.5 seconds types any letter, i.e. user can enter text continuously to avoid API call for single letters
  const getVoiceFilesList = debounce(async (searchText) => {
    const voiceFilesList = await AudioService.getVoiceFileList(selectedLanguage, searchText);
    setVoiceFileList(voiceFilesList);
  }, 500);

  /**
   * Handles the change in selection
   * @param {Array<{fileName:string,filePath:string}>} selected array of selected item
   */
  const handleSelectionChange = (selected) => {
    // selectedVariable holds an array of user selections.
    // Only first element selected as multiple flag is false.
    const selectedVariable = selected.length ? selected[0] : '';
    if (selectedVariable) {
      onSelect(selectedVariable.filePath);
    }
  };

  return (
    <Typeahead
      id="basic-typeahead-single"
      labelKey="fileName"
      onChange={(selected) => handleSelectionChange(selected)}
      options={voiceFileList}
      onInputChange={(text, _event) => {
        if (text) {
          // Call the debounced function when input changes
          getVoiceFilesList(text);
        } else {
          setVoiceFileList([]);
        }
        onSelect(text);
      }}
      placeholder="Enter Voice File Name"
      className="pmivr-variable-input"
      multiple={false}
      selected={selectedItem ? [selectedItem] : []}
    />
  );
};

VoiceFilesSelect.propTypes = {
  // Function to update the selected voice file path
  onSelect: PropTypes.func,
  // Currently selected voice file path
  selectedItem: PropTypes.string,
  // selected language
  selectedLanguage: PropTypes.string,
};

export default VoiceFilesSelect;
