// Require your custom property entries.
import userOptionInputDetailProps from "./parts/UserOptionInputDetailProps";

// The properties are organized in groups.
function createOptionTabGroups(element, translate) {
  if (element.businessObject.isOptionInput === true) {
    var userOptionInputGroup = {
      id: "user-option-input",
      label: "Options",
      entries: []
    };

    // Add the  props to the  group.
    userOptionInputDetailProps(userOptionInputGroup, element, translate);
    return [userOptionInputGroup];
  }
}

export default function UserOptionInputPropertiesProvider(propertiesPanel, translate) {
  this.getTabs = function (element) {
    return function (entries) {
      // Add the "voiceFiles" tab
      var voiceFilesTab = {
        id: "user-option-input",
        label: "Options",
        groups: createOptionTabGroups(element, translate)
      };

      entries.push(voiceFilesTab);
      // Show general + "voiceFiles" tab
      return entries;
    };
  };

  // Register our custom voiceFiles properties provider.
  // Use a lower priority to ensure it is loaded after the basic BPMN properties.
  propertiesPanel.registerProvider(this);
}

UserOptionInputPropertiesProvider.$inject = ["propertiesPanel", "translate"];
