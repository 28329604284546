import { useEffect, useState, useRef } from "react";

import * as Yup from 'yup';
import { useFormik } from "formik";
import PropTypes from 'prop-types';

import { MESSAGES, TOOLTIP } from "../../../constants/messages";
import { REGEX } from "../../../config/config";

import PmivrSnackBar from "../../../components/common/dialog/pmivr-snackbar";
import PmivrLabel from "../../../components/common/label/pmivr-label";
import { PmivrCheckbox } from "../../../components/common/checkbox/pmivr-checkbox";

import ClientService from "../../../services/client.service";

/**
 * Form for adding new draft option or updating values of existing option
 * @param {Object} props Properties from parent component
 * @returns {React.Component} Html element to render
 */
const DraftOption = (props) => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    // ui state object having following flag
    // disabled: flag to enable / disable the button and form
    // message: {text: text to show in case of error and success, isError: in case of error it will be true}
    const [uiState, setUiState] = useState({ disabled: false, message: { text: '', isError: false } });
    // flag for existing option
    const [isExistingOption, setIsExistingOption] = useState(false);

    // validation schema for the form
    const validateDraftOptionSchema = Yup.object().shape({
        label: Yup.string().required(MESSAGES.ERR.FIELD_REQUIRED),
        accessToken: Yup.string().required(MESSAGES.ERR.FIELD_REQUIRED),
        url: Yup.string().matches(REGEX.URL, MESSAGES.ERR.INVALID_URL).required(MESSAGES.ERR.FIELD_REQUIRED)
    });

    // formik values for form
    const formik = useFormik({
        initialValues: { label: "", accessToken: "", url: "", isPublishOption: false },
        validationSchema: validateDraftOptionSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    useEffect(() => {
        const init = async () => {
            if (props?.draftOption) {
                // case of update the existing option
                setIsExistingOption(true);
                formik.setFieldValue('label', props.draftOption.label);
                formik.setFieldValue('url', props.draftOption.url);
                formik.setFieldValue('isPublishOption', props.draftOption.isPublishOption);
            } else {
                // case of adding the new option
                setIsExistingOption(false);
            }
        };
        init();
    }, []);

    /**
     * Adding or Updating the values got from draft options form in DB
     * @param {Object} draftOption formik values: label, url, accessToken
     */
    const handleSubmit = async (draftOption) => {
        setUiState({ ...uiState, disabled: true });
        try {
            const optionId = _generateOptionId(draftOption.label);
            // Include optionId in draftOption object
            draftOption.optionId = optionId;
            // also sending the old label so that data can be searched according to the old label and oldIsPublishedFlow and then update the details
            if (props?.draftOption?.label) {
                draftOption.oldLabel = props.draftOption.label;
                draftOption.oldIsPublishOption = props.draftOption.isPublishOption;
            }
            const response = isExistingOption ? await ClientService.updateDraftOption(draftOption) :
                await ClientService.saveDraftOption(draftOption);
            snackbarRef.current.open(response?.message);
            setUiState({ ...uiState, disabled: true, message: { text: response?.message, isError: false } });
            // giving timeout to remain on the same screen for displaying message
            setTimeout(() => { props?.closeAction(response?.data, isExistingOption) }, 2000);
        } catch (err) {
            let _message = MESSAGES.SOMETHING_WENT_WRONG;
            if (err.response?.status === 403) {
                _message = MESSAGES.ERR.UNAUTHORIZED;
            } else if (err.response?.status === 400) {
                _message = MESSAGES.ERR.EXISTING_LABEL;
            }
            setUiState({ ...uiState, disabled: false, message: { text: _message, isError: true } });
        }
    }

    /**
     * Generates option id from label by removing spaces and adding hyphen between them
     * example: for label = "Draft to Env", optionId will be "draft-to-env"
     * @param {string} label 
     * @returns {string} option id generated
     */
    const _generateOptionId = (label) => {
        if (!label) return "";

        // Convert to lowercase and replace spaces with hyphens
        return label.toLowerCase().replace(/\s+/g, '-');
    }

    return (
        <>
            <PmivrSnackBar ref={snackbarRef} />
            <div className="pmivr-card card-secondary pmivr-user-modal">
                <div className={uiState.message.isError ? "field-error text-center" : "field-success text-center"}>{uiState.message?.text}</div>
                <form onSubmit={formik.handleSubmit} disabled={uiState.disabled}>
                    <div className="pmivr-container">
                        <div className="wrapper p-3 pt-0">
                            <div className="mb-2">
                                <PmivrLabel label="Label" tooltip={TOOLTIP.INFO.DRAFT_OPTION.LABEL} />
                                <input type="text" name="label" value={formik.values.label || ""}
                                    className="form-control pmivr-input"
                                    placeholder=" " onChange={formik.handleChange} />
                                {formik.touched.label && formik.errors.label && (
                                    <span className='field-error text-center'>{formik.errors.label}</span>
                                )}


                                <PmivrLabel label="Access Token" tooltip={TOOLTIP.INFO.DRAFT_OPTION.ACCESS_TOKEN} cssClass="mt-3" />
                                <input type="password" name="accessToken" value={formik.values.accessToken || ""}
                                    className="form-control pmivr-input" placeholder=" " onChange={formik.handleChange} />
                                {formik.touched.accessToken && formik.errors.accessToken && (
                                    <span className='field-error text-center'>{formik.errors.accessToken}</span>
                                )}

                                <PmivrLabel label="URL" tooltip={TOOLTIP.INFO.DRAFT_OPTION.URL} cssClass="mt-3" />
                                <input type="text" name="url" value={formik.values.url || ""}
                                    className="form-control pmivr-input" placeholder=" " onChange={formik.handleChange} />
                                {formik.touched.url && formik.errors.url && (
                                    <span className='field-error text-center'>{formik.errors.url}</span>
                                )}
                                <div className="mt-3">
                                    <PmivrCheckbox label={"Publish Option"}
                                        value={formik.values.isPublishOption || false}
                                        info={TOOLTIP.INFO.DRAFT_OPTION.PUBLISH_FLOW_OPTION}
                                        onChange={value => {
                                            formik.setFieldValue("isPublishOption", value);
                                        }} />
                                </div>
                            </div>
                            <button className={`pmivr-btn-app w-100 p-3 mt-2`} disabled={uiState.disabled}
                                type="submit">{isExistingOption ? "Update" : "Create"}</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

DraftOption.propTypes = {
    // draft option to be edited in case of existing option
    draftOption: PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string,
        accessToken: PropTypes.string,
    }),
    // closing the user dialogue
    closeAction: PropTypes.func
}

export default DraftOption;
