// Require your custom property entries.
import sessionDetailProps from "./parts/SessionDetailProps";

// Create the custom voice Files tab.
// The properties are organized in groups.
function createSessionTabGroups(element, translate) {
  if (element.businessObject.taskType === "promptUserInput") {
    // Create a group called "Voice Files".
    var sessionGroup = {
      id: "session",
      label: "Session",
      entries: []
    };
    // Add the voice files props to the black voiceFiles group.
    sessionDetailProps(sessionGroup, element, translate);
    return [sessionGroup];
  }
}

export default function SessionPropertiesProvider(propertiesPanel, translate) {
  this.getTabs = function (element) {
    return function (entries) {
      // Add the "voiceFiles" tab
      var voiceFilesTab = {
        id: "session",
        label: "Session",
        groups: createSessionTabGroups(element, translate)
      };
      entries.push(voiceFilesTab);
      // Show general + "voiceFiles" tab
      return entries;
    };
  };

  // Register our custom voiceFiles properties provider.
  // Use a lower priority to ensure it is loaded after the basic BPMN properties.
  propertiesPanel.registerProvider(this);
}

SessionPropertiesProvider.$inject = ["propertiesPanel", "translate"];
