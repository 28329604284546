import { ATTRIBUTES } from "../constants/attributes";
import { VOICE_FILE_UPLOAD_TYPE } from "../constants/voice-file";

import AppUtil from "../util/app.util";
import VoiceFileOptionUtil from "../util/voice-file-option.util";
import AudioUtil from "../util/audio.util";

import VoiceFile, { KeyValueVoiceFile } from "../models/voice-file";

import ElementService from "../services/element.service";
import AudioService from "./audio.service";
import FlowService from "./flow.service";

/**
 * Service for the voice file options both single and multi
 */
class VoiceFileOptionService {

    /**
     * Read the option values map from element and format it.
     * Result: {0: ['1', 'en'], 1: ['2','es']}
     * @param {Object} props  props data from parent component
     * @returns {Object} option and value 
     */
    static getFormattedOptionAndVal(element) {
        let tempOptionAndVal = {};
        let curOptionAndVal = element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_VALUE_MAP)
            ? JSON.parse(element.businessObject.get(ATTRIBUTES.PROMT_USER_OPTION_VALUE_MAP))
            : {};
        for (let i = 0; i < Object.keys(curOptionAndVal).length; i++) {
            tempOptionAndVal[i] = [Object.keys(curOptionAndVal)[i], curOptionAndVal[Object.keys(curOptionAndVal)[i]]];
        }
        return tempOptionAndVal;
    }

    /**
     * Update the single voice file information
     * @param {Object} state 
     * @param {Object} element 
     */
    static updateSingleVoiceFileInfo(state, element) {
        const voiceFileInfo = state.voiceFileInfo;
        let info = new VoiceFile();
        info.voiceFileType = state.voiceFileUploadMethod ? state.voiceFileUploadMethod : VOICE_FILE_UPLOAD_TYPE.LIBRARY;
        info.filePath = state.voiceFilePath ? state.voiceFilePath : "";
        voiceFileInfo[state.selectedLanguage] = info;
        ElementService.updateElementAttr(element, ATTRIBUTES.VOICE_FILE_INFO, JSON.stringify(voiceFileInfo));
    }

    /**
     * Get the file upload method
     * @param {string} val 
     * @returns {string} returns a voice file type based on value passed
     */
    static getVoiceFileUploadMethod(val) {
        switch (val) {
            case "filePath-tab":
                return VOICE_FILE_UPLOAD_TYPE.LIBRARY;
            case "uploadFile-tab":
                return VOICE_FILE_UPLOAD_TYPE.UPLOAD;
            case "tts-file-tab":
                return VOICE_FILE_UPLOAD_TYPE.TTS;
            // default case behaviour reqd otherwise console warning will come
            default:
                return VOICE_FILE_UPLOAD_TYPE.LIBRARY;
        }
    }

    /**
     * 
     * @param {Object} event 
     * @param {string} val 
     * @param {Object} optionAndVal 
     * @param {boolean} isOptionAndValStartFromZero 
     * @returns returns object containing updated values
     */
    static updateOptionAndValues(event, val, optionAndVal, isOptionAndValStartFromZero) {
        let optionAndValTemp = AppUtil.deepClone(optionAndVal);
        if (event && !val && val !== "") {
            let indx = Number(event.target.id.split("file_option_")[1]);
            optionAndValTemp[indx] = [event.target.value, ""];
        } else if (event && (val || val === "")) {
            let optionIndex = Number(event.target.id.split("file_value_")[1]);
            optionAndValTemp[optionIndex] = [optionAndValTemp[optionIndex][0], event.target.value];
        }
        let optionsListTemp = VoiceFileOptionUtil.getUpdatedOptions(optionAndValTemp, isOptionAndValStartFromZero);
        return {
            "optionsListTemp": optionsListTemp,
            "optionAndVal": optionAndValTemp
        }
    }

    /**
  * Get multi voice file info and fetch the supportedlanguage voice info
  * @param {Array} supportedLanguages 
  * @param {Object} element 
  */
    static getMultiVoiceFileInfo(supportedLanguages, element) {
        let voiceFileInformation = element.businessObject.get(ATTRIBUTES.KEY_VALUE_MAP)
            ? JSON.parse(element.businessObject.get(ATTRIBUTES.KEY_VALUE_MAP))
            : {};
        supportedLanguages.forEach((language) => {
            voiceFileInformation[language] = voiceFileInformation[language] || [];
        });
        return voiceFileInformation;
    }

    /**
   * Update the multi voice file info in the flow
   * @param {string} voiceFileInfoId 
   * @param {Object} state 
   * @param {Object} element 
   */
    static updateMultiVoiceFileInfo(voiceFileInfoId, state, element) {
        const voiceFileInfo = state.voiceFileInfo;
        // find if this info already exist in voice file info
        const info = voiceFileInfo[state.selectedLanguage].find((info) => {
            return info.id === voiceFileInfoId;
        });
        // if info exist in voice file info then update the info
        if (info) {
            voiceFileInfo[state.selectedLanguage].forEach((fileInfo, index) => {
                if (fileInfo.id === voiceFileInfoId) {
                    let vocieFileDetails = new KeyValueVoiceFile();
                    vocieFileDetails = {
                        ...vocieFileDetails,
                        option: state.selectedOption.option,
                        promptsList: state.promptsList,
                        id: info.id,
                    };
                    if (!state.promptsList) {
                        vocieFileDetails["voiceFileType"] = state.voiceFileUploadMethod
                        vocieFileDetails["filePath"] = state.voiceFilePath
                    }
                    voiceFileInfo[state.selectedLanguage][index] = vocieFileDetails;
                }
            });
        } else {  // if info not exist in voice file info then add the info
            let voiceInfo = new KeyValueVoiceFile();
            // get the unqiue id for information option so that we can update or delete the info later.
            voiceInfo.id = AppUtil.uid();
            voiceInfo = {
                ...voiceInfo,
                option: state.selectedOption.option,
                promptsList: state.promptsList
            };
            voiceFileInfo[state.selectedLanguage].push(voiceInfo);
        }
        // sort voice file information for specific language by selected option value
        voiceFileInfo[state.selectedLanguage] = VoiceFileOptionUtil.sortVoiceFileLanguageInfoByOption(voiceFileInfo[state.selectedLanguage]);
        // update the voice file info
        ElementService.updateElementAttr(element, ATTRIBUTES.KEY_VALUE_MAP, JSON.stringify(voiceFileInfo));
    }

    /**
     * Update the option value
     * @param {string} id 
     * @param {Object} state 
     * @param {Object} element 
     */
    static async updateMultiVoiceFileOption(id, state, element) {
        const basicFlowInfo = FlowService.getBasicFlowInfo();
        const voiceFileInfo = (state.isAddOption && state.voiceFileUploadMethod !== VOICE_FILE_UPLOAD_TYPE.LIBRARY)
            ? state.tempVoiceInfo
            : state.voiceFileInfo;
        // find if this info already exist in voice file info
        const info = voiceFileInfo[state.selectedLanguage].find((info) => {
            return info.id === id;
        });
        // if info exist in voice file info then update the info
        if (info) {
            let response;
            // basic flowInfo for tts
            const flowInfo = {
                businessCode: basicFlowInfo?.businessCode, elementId: element.id,
                language: state.selectedLanguage, flowName: basicFlowInfo?.flowName, optionId: id
            };
            if (state.voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.TTS) {
                // necessary fields in case of tts
                flowInfo.text = info?.ttsText;
                flowInfo.gender = info?.gender;
                flowInfo.speedRate = info?.playSpeed;
                // get the voice type info from that voice file prefix
                const ttsVoiceInfo = AudioUtil.getTtsVoiceId(flowInfo.language, info.gender);
                flowInfo.voiceId = ttsVoiceInfo.voiceId;
                flowInfo.languageCode = ttsVoiceInfo.languageCode;
                response = await AudioService.tts(flowInfo);
            } else if (state.voiceFileUploadMethod === VOICE_FILE_UPLOAD_TYPE.UPLOAD) {
                // necessary fields in case of upload
                flowInfo.file = element?.file;
                response = await AudioService.uploadVoiceFile(flowInfo);
            }
            voiceFileInfo[state.selectedLanguage].forEach((fileInfo, index) => {
                if (fileInfo.id === id) {
                    voiceFileInfo[state.selectedLanguage][index].option = state.selectedOption.option;
                    voiceFileInfo[state.selectedLanguage][index]["filePath"] = response?.data?.metaInfo?.location;
                    voiceFileInfo[state.selectedLanguage][index]["isUploadedOnGit"] = response?.data?.metaInfo?.isUploadedOnGit;
                }
            });
        }
        voiceFileInfo[state.selectedLanguage] = VoiceFileOptionUtil.sortVoiceFileLanguageInfoByOption(voiceFileInfo[state.selectedLanguage]);
        // update the voice file info
        ElementService.updateElementAttr(element, ATTRIBUTES.KEY_VALUE_MAP, JSON.stringify(voiceFileInfo));
    }

    /**
     * Return value of option to display
     * @param {string} language 
     * @param {number} index 
     * @param {Object} state 
     */
    static getOptionDisplayValue(language, index, state) {
        let type = state.voiceFileInfo[language][index].voiceFileType;
        switch (type) {
            case VOICE_FILE_UPLOAD_TYPE.LIBRARY:
                return (`${state.voiceFileInfo[language][index].filePath}`);
            case VOICE_FILE_UPLOAD_TYPE.TTS:
                return state.voiceFileInfo[language][index].ttsText;
            case VOICE_FILE_UPLOAD_TYPE.UPLOAD:
                return state.voiceFileInfo[language][index].filePath;
            default:
                return state.voiceFileInfo[language][index].filePath;
        }
    }

}

export default VoiceFileOptionService;