import { createSlice } from "@reduxjs/toolkit";

// initial state in redux
const initialState = {
    systemVariables: [], // list of system variables
    agiServiceMethods: [] //list of AGI service methods
}

// auto-generate action types and action creators, based on the names of the reducer functions that we supply
// state info : common service methods and variables related info.
const slice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        updateSystemVariables: (state, action) => ({
            ...state,
            systemVariables: action.payload.systemVariables
        }),
        updateAgiServiceMethods: (state, action) => ({
            ...state,
            agiServiceMethods: action.payload.agiServiceMethods
        }),
        clearConfigs: () => initialState
    }
});

export const { updateSystemVariables, updateAgiServiceMethods, clearConfigs } = slice.actions;

export default slice.reducer;