import CustomPaletteProvider from "./CustomPaletteProvider";

/**
 * Configure the implementation of the custom palette with custom properties.
 * Read by bpmn to provide the custom palette in the diagram.
 */
const CustomPaletteProviderModule = {
  __init__: ["customPaletteProvider"],
  customPaletteProvider: ["type", CustomPaletteProvider]
};

export default CustomPaletteProviderModule;
