import { getDefaultSize } from "../di/DiUtil.js";

export const DEFAULT_CELL_WIDTH = 350;
export const DEFAULT_CELL_HEIGHT = 200;

const existingWaypoints = {
  main: {}, // Waypoints for main diagram connections
  subprocess: {}, // Waypoints for subprocess connections, keyed by subprocess ID
};

/**
 * Calculates the midpoint of a given bounds object.
 * @param {Object} bounds - The bounds object with x, y, width, and height properties.
 * @returns {Object} The midpoint coordinates with properties x and y.
 */
export function getMid(bounds) {
  return {
    x: bounds.x + bounds.width / 2,
    y: bounds.y + bounds.height / 2,
  };
}

/**
 * Calculates the docking point of a connection to a rectangle, based on docking direction.
 * @param {Object} point - The original connection point.
 * @param {Object} rectangle - The rectangle (target element) bounds.
 * @param {string} [dockingDirection="r"] - Docking direction ('t', 'r', 'b', 'l', 'h', 'v').
 * @param {string} [targetOrientation="top-left"] - Orientation of the target ('top-left', etc.).
 * @returns {Object} The new docking point coordinates.
 */
export function getDockingPoint(
  point,
  rectangle,
  dockingDirection = "r",
  targetOrientation = "top-left"
) {
  // ensure we end up with a specific docking direction
  // based on the targetOrientation, if <h|v> is being passed

  if (dockingDirection === "h") {
    dockingDirection = /left/.test(targetOrientation) ? "l" : "r";
  }

  if (dockingDirection === "v") {
    dockingDirection = /top/.test(targetOrientation) ? "t" : "b";
  }

  if (dockingDirection === "t") {
    return { original: point, x: point.x, y: rectangle.y };
  }

  if (dockingDirection === "r") {
    return { original: point, x: rectangle.x + rectangle.width, y: point.y };
  }

  if (dockingDirection === "b") {
    return { original: point, x: point.x, y: rectangle.y + rectangle.height };
  }

  if (dockingDirection === "l") {
    return { original: point, x: rectangle.x, y: point.y };
  }

  throw new Error("unexpected dockingDirection: <" + dockingDirection + ">");
}

/**
 * Retrieves the type and ID of the parent element, if it exists.
 * @param {Object} element - The current element.
 * @returns {Object|null} An object with the parent's type and id, or null if no parent.
 */
function getParentTypeAndId(element) {
  // Check if the parent exists and get its details
  const parent = element.$parent; // Directly access the parent
  if (parent) {
    return {
      type: parent.$type, // Get the type of the parent element (process or subprocess)
      id: parent.id, // Get the ID of the parent element
    };
  }
  return null; // If no parent found, return null
}

/**
 * Connects two elements on a modified Manhattan layout, avoiding obstacles in a layout grid.
 * @param {Object} source - The source element.
 * @param {Object} target - The target element.
 * @param {Object} layoutGrid - The grid containing the layout of elements.
 * @returns {Array} The array of waypoints.
 */
export function connectElements(source, target, layoutGrid) {
  const sourceDi = source.di;
  const targetDi = target.di;

  const sourceBounds = sourceDi.get("bounds");
  const targetBounds = targetDi.get("bounds");

  const sourceMid = getMid(sourceBounds);
  const targetMid = getMid(targetBounds);

  const dX = target.gridPosition.col - source.gridPosition.col;
  const dY = target.gridPosition.row - source.gridPosition.row;

  const dockingSource = `${dY > 0 ? "bottom" : "top"}-${
    dX > 0 ? "right" : "left"
  }`;
  const dockingTarget = `${dY > 0 ? "top" : "bottom"}-${
    dX > 0 ? "left" : "right"
  }`;

  let waypoints = [];

  // Get parent type and ID for the source element
  const parentInfo = getParentTypeAndId(source);

  const isSubprocess = parentInfo && parentInfo.type === "bpmn:SubProcess"; // Check if the parent is a subprocess
  const parentId = isSubprocess
    ? parentInfo.id
    : parentInfo && parentInfo.type === "bpmn:Process"
    ? parentInfo.id
    : null; // Get the ID of the parent if it's a process or subprocess

  // Source === Target ==> Build loop
  if (dX === 0 && dY === 0) {
    const { x, y } = coordinatesToPosition(
      source.gridPosition.row,
      source.gridPosition.col
    );
    waypoints = [
      getDockingPoint(sourceMid, sourceBounds, "r", dockingSource),
      { x: x + DEFAULT_CELL_WIDTH, y: sourceMid.y },
      { x: x + DEFAULT_CELL_WIDTH, y: y },
      { x: targetMid.x, y: y },
      getDockingPoint(targetMid, targetBounds, "t", dockingTarget),
    ];
  }

  // connect horizontally
  else if (dY === 0) {
    if (isDirectPathBlocked(source, target, layoutGrid)) {
      // Route on bottom
      waypoints = [
        getDockingPoint(sourceMid, sourceBounds, "b"),
        { x: sourceMid.x, y: sourceMid.y + DEFAULT_CELL_HEIGHT / 2 },
        { x: targetMid.x, y: sourceMid.y + DEFAULT_CELL_HEIGHT / 2 },
        getDockingPoint(targetMid, targetBounds, "b"),
      ];
    } else {
      // if space is clear, connect directly
      waypoints = [
        getDockingPoint(sourceMid, sourceBounds, "h", dockingSource),
        getDockingPoint(targetMid, targetBounds, "h", dockingTarget),
      ];
    }
  }

  // connect vertically
  else if (dX === 0) {
    if (isDirectPathBlocked(source, target, layoutGrid)) {
      // Route parallel
      const yOffset = (-Math.sign(dY) * DEFAULT_CELL_HEIGHT) / 2;
      waypoints = [
        getDockingPoint(sourceMid, sourceBounds, "r"),
        { x: sourceMid.x + DEFAULT_CELL_WIDTH / 2, y: sourceMid.y }, // out right
        { x: targetMid.x + DEFAULT_CELL_WIDTH / 2, y: targetMid.y + yOffset },
        { x: targetMid.x, y: targetMid.y + yOffset },
        getDockingPoint(
          targetMid,
          targetBounds,
          Math.sign(yOffset) > 0 ? "b" : "t"
        ),
      ];
    } else {
      // if space is clear, connect directly
      waypoints = [
        getDockingPoint(sourceMid, sourceBounds, "v", dockingSource),
        getDockingPoint(targetMid, targetBounds, "v", dockingTarget),
      ];
    }
  }

  // negative dX indicates connection from future to past
  else if (dX < 0 && dY <= 0) {
    waypoints = [
      getDockingPoint(sourceMid, sourceBounds, "b"),
      { x: sourceMid.x, y: sourceMid.y + DEFAULT_CELL_HEIGHT / 2 },
      { x: targetMid.x, y: sourceMid.y + DEFAULT_CELL_HEIGHT / 2 },
      getDockingPoint(targetMid, targetBounds, "b"),
    ];
  } else {
    const directManhattan = directManhattanConnect(source, target, layoutGrid);

    if (directManhattan) {
      const startPoint = getDockingPoint(
        sourceMid,
        sourceBounds,
        directManhattan[0],
        dockingSource
      );
      const endPoint = getDockingPoint(
        targetMid,
        targetBounds,
        directManhattan[1],
        dockingTarget
      );

      const midPoint =
        directManhattan[0] === "h"
          ? { x: endPoint.x, y: startPoint.y }
          : { x: startPoint.x, y: endPoint.y };

      waypoints = [startPoint, midPoint, endPoint];
    } else {
      let yOffset = (-Math.sign(dY) * DEFAULT_CELL_HEIGHT) / 2;

      waypoints = [
        getDockingPoint(sourceMid, sourceBounds, "r", dockingSource),
        { x: sourceMid.x + DEFAULT_CELL_WIDTH / 2, y: sourceMid.y }, // out right
        { x: sourceMid.x + DEFAULT_CELL_WIDTH / 2, y: targetMid.y + yOffset }, // to target row
        { x: targetMid.x - DEFAULT_CELL_WIDTH / 2, y: targetMid.y + yOffset }, // to target column
        { x: targetMid.x - DEFAULT_CELL_WIDTH / 2, y: targetMid.y }, // to mid
        getDockingPoint(targetMid, targetBounds, "l", dockingTarget),
      ];
    }
  }

  // Store the adjusted waypoint in the appropriate context
  if (isSubprocess) {
    if (!existingWaypoints.subprocess[parentId]) {
      existingWaypoints.subprocess[parentId] = []; // Initialize if not present
    }
    existingWaypoints.subprocess[parentId].push(waypoints);

    waypoints = adjustPathForOverlaps(
      source.name,
      target.name,
      waypoints,
      existingWaypoints.subprocess[parentId]
    );
  } else {
    if (!existingWaypoints.main[parentId]) {
      existingWaypoints.main[parentId] = []; // Initialize if not present
    }
    existingWaypoints.main[parentId].push(waypoints);

    waypoints = adjustPathForOverlaps(
      source.name,
      target.name,
      waypoints,
      existingWaypoints.main[parentId]
    );
  }
  return waypoints;
}

function adjustPathForOverlaps(
  sourcename,
  targetname,
  currentSegments,
  allConnections
) {

  if (currentSegments.length == 2) {
    return currentSegments;
  }

  // Create maps to track offsets
  const verticalOffsets = {};
  const horizontalOffsets = {};

  // Check every other connection to see if they overlap with this one
  allConnections.forEach((otherSegments) => {
    if (otherSegments === currentSegments) {
      return;
    }

    currentSegments.forEach((segment, index) => {
      const { x: x1, y: y1 } = segment; // Starting point of the current segment
      const nextSegment = currentSegments[index + 1]; // Get the next segment

      // Ensure we have a next segment to check against
      if (nextSegment) {
        const { x: x2, y: y2 } = nextSegment; // Ending point of the current segment

        // Check overlap with all other segments
        otherSegments.forEach((otherSegment, otherSegmentIndex) => {
          const { x: ox1, y: oy1 } = otherSegment; // Starting point of the other segment
          const nextOtherSegment = otherSegments[otherSegmentIndex + 1]; // Get the next segment

          // Ensure we have a next segment to check against
          if (nextOtherSegment) {
            const { x: ox2, y: oy2 } = nextOtherSegment; // Ending point of the other segment

            // Identify if it's the first, last, or only segment
            const isFirstSegment = index === 0;
            const isLastSegment = index === currentSegments.length - 2;
            // Check for vertical overlap
            if (x1 === x2 && ox1 === ox2 && x1 === ox1) {
              // Both segments are vertical
              // Check if the y ranges overlap

              if (
                (y1 >= Math.min(oy1, oy2) && y1 <= Math.max(oy1, oy2)) ||
                (y2 >= Math.min(oy1, oy2) && y2 <= Math.max(oy1, oy2)) ||
                (y1 <= Math.min(oy1, oy2) && y2 >= Math.max(oy1, oy2)) ||
                (oy1 >= Math.min(y1, y2) && oy1 <= Math.max(y1, y2)) ||
                (oy2 >= Math.min(y1, y2) && oy2 <= Math.max(y1, y2)) ||
                (oy1 <= Math.min(y1, y2) && oy2 >= Math.max(y1, y2))
              ) {
                // Calculate offset based on existing offsets
                const offsetCount =
                  verticalOffsets[x1] !== undefined ? verticalOffsets[x1] : 0;
                let dynamicOffset = Math.abs(y2 - y1) * 0.02;

                let offsetX1 = dynamicOffset * (offsetCount + 1);
                let offsetX2 = dynamicOffset * (offsetCount + 1);

                if (isFirstSegment && isLastSegment) {
                  dynamicOffset = Math.abs(y2 - y1) * 0.03;
                  offsetX1 = dynamicOffset * (offsetCount + 1);
                  offsetX2 = dynamicOffset * (offsetCount + 1);
                } else if (isFirstSegment) {
                  dynamicOffset = Math.abs(y2 - y1) * 0.03;
                  offsetX1 = dynamicOffset * (offsetCount + 1);
                  offsetX2 = dynamicOffset * (offsetCount + 1);
                } else if (isLastSegment) {
                  dynamicOffset = Math.abs(y2 - y1) * 0.03;
                  offsetX1 = dynamicOffset * (offsetCount + 1);
                  offsetX2 = dynamicOffset * (offsetCount + 1);
                }
                // Horizontal offset
                currentSegments[index] = { x: x1 + offsetX1, y: y1 };
                currentSegments[index + 1] = { x: x2 + offsetX2, y: y2 };

                verticalOffsets[x1] = offsetCount + 1;
              }
            }

            // Check for horizontal overlap
            else if (y1 === y2 && oy1 === oy2 && y1 === oy1) {
              // Both segments are horizontal
              // Check if the x ranges overlap

              if (
                (x1 >= Math.min(ox1, ox2) && x1 <= Math.max(ox1, ox2)) ||
                (x2 >= Math.min(ox1, ox2) && x2 <= Math.max(ox1, ox2)) ||
                (x1 <= Math.min(ox1, ox2) && x2 >= Math.max(ox1, ox2)) ||
                (ox1 >= Math.min(x1, x2) && ox1 <= Math.max(x1, x2)) ||
                (ox2 >= Math.min(x1, x2) && ox2 <= Math.max(x1, x2)) ||
                (ox1 <= Math.min(x1, x2) && ox2 >= Math.max(x1, x2))
              ) {
                const offsetCount =
                  horizontalOffsets[y1] !== undefined
                    ? horizontalOffsets[y1]
                    : 0;
                let dynamicOffset = Math.abs(x2 - x1) * 0.02;

                let diff = Math.abs(x2 - x1);
                let offsetY1 = dynamicOffset * (offsetCount + 1);
                let offsetY2 = dynamicOffset * (offsetCount + 1);

                if (isFirstSegment && isLastSegment) {
                  dynamicOffset = Math.abs(x2 - x1) * 0.03;
                  offsetY1 = dynamicOffset * (offsetCount + 1);
                  offsetY2 = dynamicOffset * (offsetCount + 1);
                } else if (isFirstSegment) {
                  dynamicOffset = Math.abs(x2 - x1) * 0.03;
                  offsetY1 = dynamicOffset * (offsetCount + 1);
                  offsetY2 = dynamicOffset * (offsetCount + 1);
                } else if (isLastSegment) {
                  dynamicOffset = Math.abs(x2 - x1) * 0.03;
                  offsetY1 = dynamicOffset * (offsetCount + 1);
                  offsetY2 = dynamicOffset * (offsetCount + 1);
                }
                // Apply offset for overlap
                currentSegments[index] = { x: x1, y: y1 + offsetY1 };
                currentSegments[index + 1] = { x: x2, y: y2 + offsetY2 };
                horizontalOffsets[y1] = offsetCount + 1;
              }
            }
          }
        });
      }
    });
  });
  return currentSegments;
}

// helpers /////
export function coordinatesToPosition(row, col) {
  return {
    width: DEFAULT_CELL_WIDTH,
    height: DEFAULT_CELL_HEIGHT,
    x: col * DEFAULT_CELL_WIDTH,
    y: row * DEFAULT_CELL_HEIGHT,
  };
}

/**
 * Calculates the bounds of an element based on its position in the grid.
 * @param {Object} element - The element whose bounds are being calculated.
 * @param {number} row - The row in the grid.
 * @param {number} col - The column in the grid.
 * @param {Object} [attachedTo] - Optional element this one is attached to.
 * @returns {Object} The bounds with width, height, x, and y properties.
 */
export function getBounds(element, row, col, attachedTo) {
  const { width, height } = getDefaultSize(element);

  // Center in cell
  if (!attachedTo) {
    return {
      width,
      height,
      x: col * DEFAULT_CELL_WIDTH + (DEFAULT_CELL_WIDTH - width) / 2,
      y: row * DEFAULT_CELL_HEIGHT + (DEFAULT_CELL_HEIGHT - height) / 2,
    };
  }

  const hostBounds = getBounds(attachedTo, row, col);

  return {
    width,
    height,
    x: Math.round(hostBounds.x + hostBounds.width / 2 - width / 2),
    y: Math.round(hostBounds.y + hostBounds.height - height / 2),
  };
}

/**
 * Checks if a direct path between two elements is blocked by other elements in the grid.
 * @param {Object} source - The source element.
 * @param {Object} target - The target element.
 * @param {Object} layoutGrid - The grid containing the layout of elements.
 * @returns {boolean} True if the path is blocked, false otherwise.
 */
function isDirectPathBlocked(source, target, layoutGrid) {
  const { row: sourceRow, col: sourceCol } = source.gridPosition;
  const { row: targetRow, col: targetCol } = target.gridPosition;

  const dX = targetCol - sourceCol;
  const dY = targetRow - sourceRow;

  let totalElements = 0;

  if (dX) {
    totalElements += layoutGrid.getElementsInRange(
      { row: sourceRow, col: sourceCol },
      { row: sourceRow, col: targetCol }
    ).length;
  }

  if (dY) {
    totalElements += layoutGrid.getElementsInRange(
      { row: sourceRow, col: targetCol },
      { row: targetRow, col: targetCol }
    ).length;
  }

  return totalElements > 2;
}

/**
 * Finds a direct Manhattan connection path, if possible, based on element positions.
 * @param {Object} source - The source element.
 * @param {Object} target - The target element.
 * @param {Object} layoutGrid - The grid containing the layout of elements.
 * @returns {Array|null} An array of directions (e.g., ['h', 'v']) or null if not possible.
 */
function directManhattanConnect(source, target, layoutGrid) {
  const { row: sourceRow, col: sourceCol } = source.gridPosition;
  const { row: targetRow, col: targetCol } = target.gridPosition;

  const dX = targetCol - sourceCol;
  const dY = targetRow - sourceRow;

  // Only directly connect left-to-right flow
  if (!(dX > 0 && dY !== 0)) {
    return;
  }

  // If below, go down then horizontal
  if (dY > 0) {
    let totalElements = 0;
    const bendPoint = { row: targetRow, col: sourceCol };
    totalElements += layoutGrid.getElementsInRange(
      { row: sourceRow, col: sourceCol },
      bendPoint
    ).length;
    totalElements += layoutGrid.getElementsInRange(bendPoint, {
      row: targetRow,
      col: targetCol,
    }).length;

    return totalElements > 2 ? false : ["v", "h"];
  } else {
    // If above, go horizontal than vertical
    let totalElements = 0;
    const bendPoint = { row: sourceRow, col: targetCol };

    totalElements += layoutGrid.getElementsInRange(
      { row: sourceRow, col: sourceCol },
      bendPoint
    ).length;
    totalElements += layoutGrid.getElementsInRange(bendPoint, {
      row: targetRow,
      col: targetCol,
    }).length;

    return totalElements > 2 ? false : ["h", "v"];
  }
}
