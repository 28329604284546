import { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import PropTypes from 'prop-types';
import EditOptionModal from "../../properties-panel/single-voice-file-option/EditOptionModal";

/**
 * Add/Edit the values mapped to options
 * @param {{ optionAndVal, isOptionAndValStartFromZero, showOptionMenu, handleCloseOptionMenu,
 * updateOptionAndValues, removeOption, addOption, handleSaveOptionMenu, 
 * setShowOptionMenu }} props props data from parent component
 * @returns  {React.Component} Html element to render
 */
const OptionValueMapping = ({ optionAndVal, isOptionAndValStartFromZero, showOptionMenu, handleCloseOptionMenu,
    updateOptionAndValues, removeOption, addOption, handleSaveOptionMenu, setShowOptionMenu }) => {
    const [activeAccordionKey, setActiveAccordionKey] = useState(null);

    return (
        <>
            <Accordion activeKey={activeAccordionKey} id="option-value-accordion" className="pmivr-accordion"
                onSelect={(selectedKey) => setActiveAccordionKey(selectedKey)} flush>
                <Accordion.Item eventKey={"validation-user-input"} className="mt-3 accordion-voice-item">
                    <Accordion.Header className='mx-0'>
                        <span className="pmivr-accordian-tab ">Mapping Option Values</span>
                    </Accordion.Header>
                    <Accordion.Body className="p-2 pt-0">
                        <EditOptionModal optionAndVal={optionAndVal}
                            updateOptionAndValues={updateOptionAndValues} addOption={addOption} saveDialogChanges={handleSaveOptionMenu} />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )

}

OptionValueMapping.prototype = {
    optionAndVal: PropTypes.object,
    isOptionAndValStartFromZero: PropTypes.bool,
    showOptionMenu: PropTypes.bool,
    handleCloseOptionMenu: PropTypes.func,
    updateOptionAndValues: PropTypes.func,
    removeOption: PropTypes.func,
    addOption: PropTypes.func,
    handleSaveOptionMenu: PropTypes.func,
    setShowOptionMenu: PropTypes.bool
}

export default OptionValueMapping;