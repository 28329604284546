/**
 * Returns the default width and height for a specified BPMN element type.
 * @param {Object} element - The BPMN element to get the default size for.
 * @returns {Object} - An object with `width` and `height` properties specifying
 *                     the default dimensions for the element.
 */
export function getDefaultSize(element) {
  if (is(element, 'bpmn:SubProcess')) {
    return { width: 100, height: 80 };
  }

  if (is(element, 'bpmn:Task')) {
    return { width: 100, height: 80 };
  }

  if (is(element, 'bpmn:Gateway')) {
    return { width: 50, height: 50 };
  }

  if (is(element, 'bpmn:Event')) {
    return { width: 36, height: 36 };
  }

  if (is(element, 'bpmn:Participant')) {
    return { width: 400, height: 100 };
  }

  if (is(element, 'bpmn:Lane')) {
    return { width: 400, height: 100 };
  }

  if (is(element, 'bpmn:DataObjectReference')) {
    return { width: 36, height: 50 };
  }

  if (is(element, 'bpmn:DataStoreReference')) {
    return { width: 50, height: 50 };
  }

  if (is(element, 'bpmn:TextAnnotation')) {
    return { width: 100, height: 30 };
  }

  return { width: 100, height: 80 };
}

/**
 * Checks if a BPMN element is of a specified type.
 * @param {Object} element - The BPMN element to check.
 * @param {string} type - The type to check against, e.g., 'bpmn:Task', 'bpmn:Event'.
 * @returns {boolean} - Returns `true` if the element is of the specified type; otherwise `false`.
 */
export function is(element, type) {
  return element.$instanceOf(type);
}
