import { SHAPE_HIGHLIGHT_COLOR } from "../config/config";
import { EL_TYPE } from "../constants/element";

import DiagramService from "../services/diagram.service";
import ElementService from "../services/element.service";

/**
 * For initializing click listeners of bpmn-elements, and highlighting elements based on specific conditions
 */
class ElementUtil {
    /**
     * Initializes a click listener on the BPMN diagram container to handle element selection.
     * When an element is clicked, it highlights the selected element and removes the highlight
     * from the previously selected element.
     */
    static initElementClickListener() {
        // select the diagram element from DOM
        const diagramContainer = document.querySelector('.diagram');

        // Listen for clicks on diagram elements
        diagramContainer.addEventListener('click', (event) => {
            // when any part is clicked, then only for the djs-element class, i.e. bpmn elements
            const element = event.target.closest('.djs-element');
            // Ignore clicks outside of BPMN elements
            if (element) {
                const elementId = element.getAttribute('data-element-id');
                // Remove highlight from the previously highlighted element
                if (DiagramService._highlightedElement && DiagramService._highlightedElement !== elementId) {
                    this.toggleActiveElementHighlight(DiagramService._highlightedElement, false);
                }

                // Update the highlighted element
                DiagramService._highlightedElement = elementId;
                this.toggleActiveElementHighlight(elementId, true);
            }
        });
    }

    /**
     * Toggles the highlight of a BPMN diagram element by setting or resetting
     * the stroke color, stroke width, and fill color based on the 'isHighlight' flag.
     * @param {string} elementId - The ID of the element to which the highlight should be applied or removed.
     * @param {boolean} addHighlight - Flag indicating whether to add (true) or remove (false) the highlight.
     */
    static toggleActiveElementHighlight(elementId, addHighlight) {
        const element = document.querySelector(`[data-element-id="${elementId}"]`);
        if (element) {
            // getting details of the bpmn element
            const bpmnElement = ElementService.getElementById(elementId);
            // if addHighlight is true, it means add active element highlight colors, otherwise reset colors
            const colors = addHighlight
                ? {
                    border: SHAPE_HIGHLIGHT_COLOR.ACTIVE_ELEMENT_BORDER,
                    background: SHAPE_HIGHLIGHT_COLOR.ACTIVE_ELEMENT_BACKGROUND, opacity: '1'
                }
                : {
                    border: SHAPE_HIGHLIGHT_COLOR.ELEMENT_BORDER,
                    background: SHAPE_HIGHLIGHT_COLOR.ELEMENT_BACKGROUND, opacity: '0'
                };

            // don't change highlight of the element in case of start, end, intermediate and subprocess events
            if ([EL_TYPE.SUB_PROCESS, EL_TYPE.START_EVENT, EL_TYPE.END_EVENT,
            EL_TYPE.INTERMEDIATE_THROW_EVENT_TASK].includes(bpmnElement?.type)) {
                return;
            } else if (bpmnElement?.type === EL_TYPE.GATEWAY) {
                // if the element is gateway, then apply background on polygon shape, because condition element are polygon shaped
                const polygonElement = element.querySelector('polygon');
                if (polygonElement) {
                    polygonElement.style.fill = colors.background;
                    polygonElement.style.fillOpacity = colors.opacity;
                }
            } else {
                // for normal tasks, add/remove background color from the rectangular box
                const rectElement = element.querySelector('rect');
                if (rectElement) {
                    rectElement.style.stroke = colors.border;
                    rectElement.style.strokeWidth = '1px';
                    rectElement.style.fill = colors.background;
                }
            }
        }
    }
}

export default ElementUtil;
