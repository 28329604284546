import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from "react-router-dom";

import { APP_PAGES } from "../../../constants/app-pages";

import { FooterComponent } from '../footer/footer';
import { LeftBarComponent } from '../left-bar/left-bar';
import TourGuide from '../tour-guide/tour-guide';

import AppUtil from "../../../util/app.util";
import TourGuideUtil from '../../../util/tour-guide.util';

import UserService from "../../../services/user.service";

// layout contains left bar and footer.
const PmivrAppLayout = ({ children, showLeftBar = false, showFooter = false }) => {
    const navigate = useNavigate();
    const location = useLocation();
    // the tour steps for the Tour Guide of the current page. 
    const [tourSteps, setTourSteps] = useState([]);
    // NOTE listening changes for navigate, as this does not get hit when 
    // user direcly changes the path after hash eg from #/login to #/home 
    // as first time browser does not reload but changes the internal view via routing
    // and this not done in app.js beacuse useNavigate works under router
    useEffect(() => {
        checkAuthToken();
        // Dynamically determines the tour steps based on the current URL path
        setTourSteps(TourGuideUtil.getTourGuideSteps(location.pathname));
    }, [navigate]);

    const checkAuthToken = () => {
        // check if token exists if not then redirect to login page.
        const currentUser = UserService.getCurrentUser();
        if (!currentUser?.token) {
            AppUtil.navigateTo(APP_PAGES.LOGIN);
        }
    }

    return (
        <>
            {tourSteps?.length !== 0 && (
                <TourGuide steps={tourSteps}/>
            )}
            {/* left bar component */}
            {showLeftBar && (
                <LeftBarComponent></LeftBarComponent>
            )}

            {/* inner content */}
            <main>{children}</main>

            {/* footer component */}
            {showFooter && (
                <FooterComponent />
            )}
        </>
    );
};

PmivrAppLayout.propTypes = {
    // flag to show to left bar
    showLeftBar: PropTypes.bool,
    // flag to show to left bar
    showFooter: PropTypes.bool,
    // content to be displayed
    children: PropTypes.oneOfType([
        PropTypes.any,
        PropTypes.arrayOf(PropTypes.element)
    ])
}

export default PmivrAppLayout;