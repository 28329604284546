import { ATTRIBUTES } from "../constants/attributes";
import { EVENT_TYPE } from "../constants/events";
import { TASK_TYPE } from "../constants/task-types";
import { VOICE_FILE_TYPE, VOICE_FILE_UPLOAD_TYPE } from "../constants/voice-file";

import AudioUtil from "../util/audio.util";
import DiagramUtil from "../util/diagram.util";

import AudioService from "./audio.service";
import ElementService from "./element.service";
import FlowService from "./flow.service";

/**
 *  Service for executing the event handling of the diagram events 
 */
class DiagramEventHandlerService {

    /**
     * Execute all mentioned events like file upload, tts
     * Updates the element attributes like voiceFileInfo etc..
     * @param {Promise<{voiceFiles : Array<{}>}>} event 
     * @param {string} flowName
     * @returns {Promise<{metaInfo : {voiceFiles : Array<{}>}}>}
     */
    static async executeRightPanelEvents(event, flowName) {
        const promises$ = [];
        // upload all voice file
        switch (event.data.eventType) {
            case EVENT_TYPE.VOICE_FILE_UPLOAD:
                // upload .wav voice file
                promises$.push(
                    DiagramUtil.uploadVoiceFile(event.element, event.data.file, event.data.language, flowName,
                        event.data.fileType)
                );
                break;
            case EVENT_TYPE.VOICE_TTS:
                // tts voice file
                promises$.push(
                    DiagramEventHandlerService.tts(event.element, event.data.text, event.data.language, flowName,
                        event.data.fileType)
                );
                break;
            default:
                break;
        }

        const rawVoiceFileInfo = await Promise.all(promises$);
        let metaInfo;
        // map voice file information to thier corresponding element
        rawVoiceFileInfo.forEach((voiceFileInfo) => {
            // avoid add undefined value
            if (voiceFileInfo?.metaInfo) {
                metaInfo = voiceFileInfo.metaInfo;
            }
        });

        return metaInfo;
    }

    /**
     * Handler key value voice file events
     * @param {Object} eventInfo 
     * @param {Object} keyValVoiceFileEvents 
     * @returns {Array} Returns events array 
    */
    static keyValVoiceFileEventsHandler(eventInfo, keyValVoiceFileEvents) {
        let events = keyValVoiceFileEvents || [];
        // check if same element exists with the same file type
        const existedElement = events.find((_eventInfo) => _eventInfo.element.id === eventInfo.element.id && _eventInfo.fileType === eventInfo.data.fileType);
        if (existedElement) {
            events.forEach((_eventInfo, index) => {
                if (_eventInfo.element.id === eventInfo.element.id && _eventInfo.fileType === eventInfo.data.fileType) {
                    //check if the voice file info exist  
                    const isInfoExist = _eventInfo.data[eventInfo.data.language] && _eventInfo.data[eventInfo.data.language].find((info) => {
                        return info.optionId === eventInfo.data.optionId;
                    });
                    // if exists the update the info else add the info
                    if (isInfoExist) {
                        events[index].data[eventInfo.data.language] = _eventInfo.data[eventInfo.data.language].map((info) => {
                            return (info.optionId === eventInfo.data.optionId) ? eventInfo.data : info;
                        });
                        events[index].element = eventInfo.element;
                    } else {
                        if (events[index].data[eventInfo.data.language]) {
                            events[index].data[eventInfo.data.language].push(eventInfo.data);
                        } else {
                            events[index].data[eventInfo.data.language] = [eventInfo.data];
                        }
                        events[index].element = eventInfo.element;
                    }
                }
            });
        } else { // format event info
            const _eventInfo = {};
            _eventInfo.element = eventInfo.element;
            _eventInfo.fileType = eventInfo.data.fileType;
            _eventInfo.data = {};
            _eventInfo.data[eventInfo.data.language] = [eventInfo.data];
            events.push(_eventInfo);
        }
        return { events, eventInfo };
    }

    /**
     * Text to speech save
     * example for optionId 1 for english 2 for spanish these will have their own ids that are called optionIds
     * @param {Object} element // element in the xml 
     * @param {string} text // tts text
     * @param {string} language // language english or spanish
     * @param {string} fileType // type of the file
     * @param {string} optionId // id of the option in keyValueMap options
     * @param {string} flowName // name of the flow
     * @returns {Promise<{voiceFileType, elementId, filePath, language, fileType, optionId, metaInfo: { voiceFiles : Array<{}>}}>}
     */
    static async tts(element, text, language, flowName, fileType = "", optionId = "") {
        try {
            const basicFlowInfo = FlowService.getBasicFlowInfo();
            const businessCode = basicFlowInfo.businessCode;
            let voiceFileInfo;
            let rawVoiceFileInfo;
            // if task is promptUserInput's invalid voice file upload then update invaildVoiceFile attribute
            if (ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE) === TASK_TYPE.promptUserInput
                && fileType === VOICE_FILE_TYPE.INVALID) {
                // incase of invaild voice file we get invalid voice file information from ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE attribute
                rawVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.USER_INPUT_OPTION_INVALID_OPTION_FILE) || "{}";
            } else if (ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE) === TASK_TYPE.promptUserInput
                && fileType === VOICE_FILE_TYPE.CONFIRM_VALUE) {
                // incase of confirm value voice file we get confirm input voice file information from ATTRIBUTES.CONFIRM_INPUT_VOICE_FILE attribute
                rawVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.CONFIRM_INPUT_VOICE_FILE) || "{}";
            } else {
                // incase of vaild voice file we get invalid voice file information from ATTRIBUTES.VOICE_FILE_INFO attribute
                rawVoiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_INFO) || "{}";
            }

            rawVoiceFileInfo = JSON.parse(rawVoiceFileInfo);
            voiceFileInfo = AudioUtil.getFormattedVoiceFileInfo(language, rawVoiceFileInfo);

            let gender, speedRate;
            // if task type is keyValueUserOption then get the voice file infomation from keyValueMap attribute
            if (ElementService.getAttribute(element, ATTRIBUTES.VOICE_FILE_TASK_TYPE) === TASK_TYPE.keyValueUserOption) {
                let voiceFileInfo = ElementService.getAttribute(element, ATTRIBUTES.KEY_VALUE_MAP) || "{}";
                voiceFileInfo = JSON.parse(voiceFileInfo);
                voiceFileInfo[language].forEach((info) => {
                    if (info.id === optionId) {
                        gender = info.gender;
                        speedRate = info.playSpeed;
                    }
                });
            } else {
                gender = voiceFileInfo[language].gender;
                speedRate = voiceFileInfo[language].playSpeed;
            }

            const flowInfo = { businessCode, text, elementId: element.id, gender, speedRate, language, flowName, optionId };
            // get the voice type info based on gender and language
            const ttsVoiceInfo = AudioUtil.getTtsVoiceId(language, gender);
            flowInfo.voiceId = ttsVoiceInfo.voiceId;
            flowInfo.languageCode = ttsVoiceInfo.languageCode;
            const response = await AudioService.tts(flowInfo);
            return {
                voiceFileType: VOICE_FILE_UPLOAD_TYPE.TTS,
                filePath: response.data.filePath,
                elementId: element.id,
                metaInfo: response.data.metaInfo,
                language, fileType, optionId
            };
        } catch (e) {
            throw new Error({ code: 403, message: "Error converting text to speech." });
        }
    }

}

export default DiagramEventHandlerService;