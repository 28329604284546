import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import { PAGINATION_COUNT } from "../../config/config";

import { MESSAGES } from "../../constants/messages";
import { APP_PAGES } from "../../constants/app-pages";

import PmivrSnackbar from "../../components/common/dialog/pmivr-snackbar";
import { PmivrDialog } from "../../components/common/dialog/pmivr-dialog";
import PmivrTooltip, { TOOLTIP_POSITIONS } from "../../components/common/tooltip/pmivr-tooltip";
import DraftOption from "./components/draft-option";
import PmivrSearchInput from "../../components/common/search-input/pmivr-search";

import AppUtil from "../../util/app.util";

import UserService from "../../services/user.service";
import ClientService from "../../services/client.service";

/**
 * Lists all the draft options admin has configured
 * @returns {React.Component} Html element to render
 */
const DraftOptions = () => {
    // using the open method from the snackbar component
    const snackbarRef = useRef();
    /**
     * snackbar
     * showDialog (show dialog to add or edit option)
     * dialogTitle (for title of the dialog box)
     * showConfirmationDialog for confirming delete draft option
     */
    const [uiState, setUiState] = useState({
        showDialog: false, dialogTitle: "",
        snackbar: { isOpen: false, msg: "" },
        showConfirmationDialog: false,
    });
    // draft options list
    const [draftOptions, setDraftOptions] = useState([]);
    // cache draft options list info
    const [draftOptionsInfo, setDraftOptionsInfo] = useState({ draftOptions: [], dataCount: 0, pageNo: 0 });
    // selected draft option to edit
    const [selectedDraftOption, setSelectedDraftOption] = useState(null);
    // filter object for filtering the draft options
    const [filterDraftOptions, setFilterDraftOptions] = useState({ text: '' });
    // State to track the draft option to be deleted
    const [draftOptionToDelete, setDraftOptionToDelete] = useState(null);
    // props for the pagination of the draft option list
    const [pagination, setPagination] = useState({
        totalPages: 0,    // total pages in pagination of draft options
        currentPage: 0, // current page displayed in pagination of draft options
        totalResults: 0,  // total number of draft options
        count: PAGINATION_COUNT  // count for number of draft options to be displayed at a time on screen
    });

    // title for modal
    const TITLE = { ADD_DRAFT_OPTION: "Create New Draft Option", EDIT_DRAFT_OPTION: "Edit Draft Option" };

    useEffect(() => {
        init();
    }, []);

    /**
     * Effect to load call logs when the filterDraftOptions state changes.
     * @function useEffect
     * @dependencies {Array} filterDraftOptions - The dependency array that triggers the effect when its value changes.
     */
    useEffect(() => {
        // load draft options
        loadDraftOptions();
    }, [filterDraftOptions])

    // init function which runs when the page is rendered
    const init = async () => {
        await loadDraftOptions();
    }

    /**
     * Updating the pagination props in the state
     * @param {Number} dataCount total number of draft options
     * @param {Number} pageNo page number of the draft options list
     */
    const setPaginationProps = (dataCount = 0, pageNo = 1) => {
        setPagination((prevPaginationObj) => {
            const newPaginationObj = { ...prevPaginationObj };
            newPaginationObj.totalPages = Math.ceil(dataCount / newPaginationObj.count);
            newPaginationObj.currentPage = pageNo;
            newPaginationObj.totalResults = dataCount;
            return newPaginationObj;
        });
    }

    /**
     * getting the draft options from the DB as per count, page no, etc
     * @param {Integer} pageNo page of draft options list to be displayed
     */
    const loadDraftOptions = async (pageNo = 1) => {
        try {
            const filter = { searchText: filterDraftOptions.text, count: pagination.count, pageNo };
            const response = await ClientService.getDraftOptions(filter);
            setDraftOptions(response?.data);
            // updating the cache when search request if not there
            if (!filter.searchText) {
                setDraftOptionsInfo({ draftOptions: response?.data, dataCount: response?.dataCount, pageNo });
            }
            setPaginationProps(response?.dataCount, pageNo);
        } catch (err) {
            // opening the snackbar
            if (snackbarRef?.current) {
                snackbarRef.current.open(MESSAGES.SOMETHING_WENT_WRONG);
            }
        }
    }

    /**
     * show modal to update existing draft options / add new draft options
     * @param {Object} draftOption Selected draft options
     */
    const showDraftOptionModal = (draftOption) => {
        if (draftOption) {
            setUiState({ ...uiState, dialogTitle: TITLE.EDIT_DRAFT_OPTION, showDialog: true });
            setSelectedDraftOption(draftOption);
        } else {
            setUiState({ ...uiState, dialogTitle: TITLE.ADD_DRAFT_OPTION, showDialog: true });
            setSelectedDraftOption(null);
        }
    }

    /**
     * Close the modal by setting flag to false and update the draftOption state
     * @param {Object} draftOption - draft option which will be updated in the list to re-render
     * @param {boolean} isExistingOption - check if the options is existing or not
     */
    const handleCloseAction = async (draftOption, isExistingOption) => {
        // if draftOption is added successfully, then reseting the filter props
        if (draftOption) {
            setFilterDraftOptions({ text: "" });  // reset filter option
        }
        updateDraftOptionsState(draftOption, isExistingOption);
        setUiState({ ...uiState, showDialog: false });
    }

    /**
     * Updating the draft options state with updated / added draft options
     * @param {Object} draftOption draft options updated / added
     * @param {Boolean} isExistingOption flag specifying the existing draft options
     */
    const updateDraftOptionsState = async (draftOption, isExistingOption) => {
        // if there is addition or updating of draft options
        if (draftOption) {
            if (isExistingOption) {
                // update draft options list as updating of draft options takes place
                let optionIndex = draftOptions?.findIndex((optionObj) => { return optionObj.label === selectedDraftOption.label && optionObj.isPublishOption === selectedDraftOption.isPublishOption });
                let updatedDraftOption = [...draftOptions];
                updatedDraftOption[optionIndex] = draftOption;
                setDraftOptions(updatedDraftOption);
                // update the cached draft options list with updated draft options, if found in cache draftOptionsInfo
                const cachedDraftOptions = draftOptionsInfo.draftOptions;
                optionIndex = cachedDraftOptions?.findIndex((optionObj) => { return optionObj.label === selectedDraftOption.label && optionObj.isPublishOption === selectedDraftOption.isPublishOption });
                if (optionIndex !== -1) {
                    cachedDraftOptions[optionIndex] = draftOption;
                    setDraftOptionsInfo({
                        draftOptions: cachedDraftOptions, dataCount: draftOptionsInfo.dataCount,
                        pageNo: draftOptionsInfo.pageNo
                    });
                }
            } else {
                // getting the draft options list cached in state
                let cachedOptions = draftOptionsInfo.draftOptions;
                // adding the new draft options to the top of the list
                const updatedOptions = [draftOption, ...cachedOptions];
                if (draftOptionsInfo.dataCount >= PAGINATION_COUNT) {
                    // removing the last item from the list, as new item is being added above
                    updatedOptions.pop();
                }
                // updating the draft options state
                setDraftOptions(updatedOptions);
                setDraftOptionsInfo({
                    draftOptions: updatedOptions, dataCount: (draftOptionsInfo.dataCount + 1),
                    pageNo: draftOptionsInfo.pageNo
                });
                setPaginationProps((draftOptionsInfo.dataCount + 1), draftOptionsInfo.pageNo);
            }
        }
    }

    /**
     * Resetting the list of draft options from state. 
     * If not found, then reload draft options from DB
     */
    const resetData = async () => {
        // reset filter option
        setFilterDraftOptions({ text: "" });
        if (draftOptionsInfo.draftOptions?.length > 0) {
            // updating the draft options list displayed, from cache
            setDraftOptions(draftOptionsInfo.draftOptions);
            setPaginationProps(draftOptionsInfo.dataCount, draftOptionsInfo.pageNo);
        } else {
            await loadDraftOptions();
        }
    }

    /**
    * Populating values on changing the value of text field
    * @param {String} value
    */
    const setFilterText = (value) => setFilterDraftOptions({ text: value?.toLowerCase() });

    /**
     * Deletes a draft option from db
     * @param {Object} draftOption - option to delete 
     */
    const deleteDraftOption = async (draftOption) => {
        const response = await ClientService.deleteDraftOption(draftOption);
        if (response) {
            const updatedDraftOptions = draftOptions.filter(option => option.label !== draftOption.label);
            setDraftOptions(updatedDraftOptions);
            setDraftOptionsInfo(prevState => ({
                ...prevState,
                draftOptions: updatedDraftOptions,
                dataCount: prevState.dataCount - 1
            }));
            snackbarRef.current.open(MESSAGES.DRAFT_OPTION.DELETED_SUCCESSFULLY);
            setUiState({ ...uiState, showConfirmationDialog: false });
            setPagination(prevPaginationObj => ({
                ...prevPaginationObj,
                totalResults: pagination.totalResults - 1
            }));
        }
    }

    return (
        <>
            <PmivrSnackbar ref={snackbarRef} />

            <PmivrDialog showDialog={uiState.showDialog} closeDialog={handleCloseAction}
                title={uiState.dialogTitle}
                message={<DraftOption draftOption={selectedDraftOption} closeAction={handleCloseAction} />}
                footer={<></>}
            />

            <PmivrDialog showDialog={uiState.showConfirmationDialog}
                closeDialog={() => {
                    setUiState({ ...uiState, showConfirmationDialog: false });
                    setDraftOptionToDelete(null);
                }}
                title={"Confirm Delete"}
                message={<p>Are you sure to delete the draft option?</p>}
                footer={<>
                    <button className="pmivr-btn-app" onClick={() => deleteDraftOption(draftOptionToDelete)}>
                        Delete
                    </button>
                    <button className="pmivr-btn-cancel" onClick={() => {
                        setUiState({ ...uiState, showConfirmationDialog: false });
                        setDraftOptionToDelete(null);
                    }}>
                        Cancel
                    </button>
                </>}
            />

            <div className="pmivr-filter-headers">
                <div className="row pt-1">
                    <div className="row border-bottom  pb-3 pt-3 ">
                        <div className="col-lg-6">
                            <div className="px-3 pmivr-breadcrumb-list">
                                <Link to={APP_PAGES.HOME}>Home</Link> / <Link to={APP_PAGES.SETTINGS}>Settings</Link>  / Publish & Draft Options
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-1">
                    <div className="row pt-2 ">
                        <div className="col-lg-6">
                            <div className="px-3 ps-1 pmivr-breadcrumb-list ">
                                <h4> <Link to={APP_PAGES.SETTINGS}><i class="bi bi-arrow-left arrow-left"></i></Link>  Publish & Draft Options</h4>
                            </div>
                        </div>
                    </div>
                </div>
                {/* header for search and actions */}
                <div className="row p-1 pt-0">
                    {/* left side actions */}
                    <div className="col-lg-5">
                        <div className="row ps-1">
                            <div className="col-md-7">
                                {/*Uses common search component to render the search input for filtering draft-options */}
                                <PmivrSearchInput
                                    value={filterDraftOptions.text}
                                    placeholder="Search text"
                                    onSearch={setFilterText}
                                    onEnter={loadDraftOptions}
                                    label="Search Label"
                                />
                            </div>
                            <div className="col-md-2 d-flex align-items-end">
                                <button title="Reset the draft options list" type="button" onClick={() => resetData()}
                                    disabled={!(filterDraftOptions.text)} className="float-start pmivr-btn-secondary pmivr-reset-link">
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* right side actions */}
                    <div className="col-lg-7 d-flex justify-content-end align-items-end">
                        {UserService.hasPermission() && (
                            <div className="pmivr-relative float-end pe-1">
                                <PmivrTooltip message={"Click to add the new draft option"} position={TOOLTIP_POSITIONS.TOP}>
                                    <button
                                        type="button" onClick={() => showDraftOptionModal()}
                                        className="pmivr-btn-app pmivr-reset-link"
                                    >
                                        <i className="bi bi-person-plus"></i> Add Draft Option
                                    </button>
                                </PmivrTooltip>
                            </div>
                        )}
                    </div>
                </div>

                {/* total draft options bar */}
                <div className="row pe-1">
                    <div className="col-lg-6">
                        <div className="px-3 pt-3 pmivr-breadcrumb-list">Total Draft Options: {pagination.totalResults || 0}</div>
                    </div>
                    {
                        (pagination.totalResults > pagination.count) ?
                            <div className="col-lg-6">
                                {
                                    (pagination.currentPage < pagination.totalPages) ?
                                        <div className="float-end pt-3 ">
                                            <div className="px-3 pmivr-breadcrumb-list">
                                                <Link title="Next page of draft options" onClick={() => loadDraftOptions(pagination.currentPage + 1)}>
                                                    Next <i className="bi bi-arrow-right"></i>
                                                </Link>
                                            </div>
                                        </div> : <></>
                                }
                                {
                                    (pagination.currentPage > 1) ?
                                        <div className="float-end  pb-3 pt-3 ">
                                            <div className="px-3 pmivr-breadcrumb-list">
                                                <Link title="Previous page of draft options" onClick={() => loadDraftOptions(pagination.currentPage - 1)}>
                                                    <i className="bi bi-arrow-left"></i> Previous
                                                </Link>
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                            : <></>
                    }
                </div>

                {/* list of draft options table */}
                <div className="row me-2 ms-2">
                    <table className="table table-body-block pmivr-table header-fixed border mt-2">
                        <thead>
                            <tr>
                                <th width="10%" className="text-center">Label</th>
                                <th width="10%" className="text-center">Option Id</th>
                                <th width="20%" className="text-center">URL</th>
                                <th width="15%" className="text-center">Created By</th>
                                <th width="15%" className="text-center">Created On</th>
                                <th width="15%" className="text-center">Updated By</th>
                                <th width="15%" className="text-center">Updated On</th>
                                {UserService.hasPermission() &&
                                    <th width="10%" className="text-center">Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody className="pmivr-scroll">
                            {(draftOptions?.length) ?
                                [...draftOptions.values()].map((option, index) => {
                                    return (
                                        <tr key={index}>
                                            <td width="10%" title={option.label} className="text-center pt-2">{option.label}</td>
                                            <td width="10%" title={option.optionId} className="text-center pt-2">{option.optionId}</td>
                                            <td width="20%" title={option.url} className="text-center pt-2">{option.url}</td>
                                            <td width="15%" title={option.createdBy} className="text-center pt-2">{option.createdBy}</td>
                                            <td width="15%" title={option.createdOn} className="text-center pt-2">{AppUtil.formatDateInLocal(option.createdOn)}</td>
                                            <td width="15%" title={option.updatedBy} className="text-center pt-2">{option.updatedBy}</td>
                                            <td width="15%" title={AppUtil.formatDateInLocal(option.updatedOn)} className="text-center pt-2">
                                                {AppUtil.formatDateInLocal(option.updatedOn)}
                                            </td>
                                            {UserService.hasPermission() &&
                                                <td width="10%" className="text-center pt-2">
                                                    <span>
                                                        <PmivrTooltip message="Edit Draft Option">
                                                            <button className="pmivr-btn-transparent"
                                                                onClick={() => showDraftOptionModal(option)}>
                                                                <i className="bi bi-pencil-square pmivr-btn-icon"></i>
                                                            </button>
                                                        </PmivrTooltip>
                                                        <PmivrTooltip message="Delete Draft Options">
                                                            <button className="pmivr-btn-transparent ms-2" onClick={() => {
                                                                setDraftOptionToDelete(option);
                                                                setUiState({ ...uiState, showConfirmationDialog: true });
                                                            }}>
                                                                <i className="bi bi-trash pmivr-btn-icon"></i>
                                                            </button>
                                                        </PmivrTooltip>
                                                    </span>
                                                </td>
                                            }
                                        </tr>
                                    )
                                }) : <tr><td>No Options Configured</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default DraftOptions;